import { useMemo } from "react"
import { useDispatch } from "react-redux"
import { getStyleTheme, useStyle, useUser } from "@tm/context-distribution"
import { AmountField, NumberField, TextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ECalcInputType } from "../../../data/enums"
import { bem, getCalcStateLabel, getCurrencyFromUserContext } from "../../../data/helpers"
import { CalcInput } from "../../../data/model"
import { MainActions } from "../../main/business"
import { useCallbackDebounce } from "../../../hooks/useCallbackDebounce"

type Props = {
    item: CalcInput
    onChangeCallBack?: (data: any) => void
}

export default function CalcInputNumber({ item, onChangeCallBack }: Props) {
    const dispatch = useDispatch()
    const { translateText } = useLocalization()
    const { userContext } = useUser() ?? {}
    const stepSize = item.decimalPlaces && 0.01
    const label = getCalcStateLabel(item.type, getCurrencyFromUserContext(userContext), translateText)
    const style = useMemo(() => getStyle(), [])

    const missingValue = [
        ECalcInputType.ArticlePrice,
        ECalcInputType.GlobalLabourRate,
        ECalcInputType.RepairTimeQuantity,
        ECalcInputType.ConsumablePrice,
        ECalcInputType.GenArtPrice,
        ECalcInputType.ConsumableQuantity,
        ECalcInputType.OeArticlePrice,
    ].includes(item.type)

    const handleChange = (value: string) => {
        const newValue = item.decimalPlaces ? parseFloat(value) : parseInt(value)

        if (newValue != item.value) {
            dispatch(MainActions.handleInputSubmit({ ...item, value: newValue || 0 }))
        }
    }

    const handleDelayedChange = useCallbackDebounce(handleChange)

    const handleRTQuantityChange = (value: string) => {
        if (value == null) {
            if (item.value) {
                dispatch(MainActions.handleInputSubmit({ ...item }))
            }
            return
        }

        handleDelayedChange(value)
    }

    const handleMileageChange = (value: string) => {
        const newValue = parseInt(value.replace(/[\.,\D]/g, ""))

        if (newValue != item.value) {
            onChangeCallBack?.(newValue || 0)
        }
    }

    if ([ECalcInputType.ArticleQuantity, ECalcInputType.OeArticleQuantity, ECalcInputType.GenArtQuantity].includes(item.type)) {
        return (
            <AmountField
                disabled={item.isDisabled}
                value={item.value}
                stepSize={stepSize}
                onChange={(amountItem) => {
                    ~~amountItem.value != ~~item.value && handleDelayedChange(`${amountItem.value}`)
                }}
            />
        )
    }

    if (item.type == ECalcInputType.Mileage) {
        return (
            <TextField
                floatingLabel
                label={item.label || label}
                disabled={item.isDisabled}
                value={item.value || undefined}
                onChangeConfirm={handleMileageChange}
                placeholder={item.placeholder ?? ""}
                maxLength={9}
                formatter={(value) => value.replace(/[\D]/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
            />
        )
    }
    const valueValidator = (item: CalcInput) => {
        if (item.type == ECalcInputType.RepairTimeQuantity) {
            return !item.isInvalid ? item.value : undefined
        }
        return item.value || undefined
    }

    return (
        <NumberField
            floatingLabel
            label={item.label || label}
            disabled={item.isDisabled}
            value={valueValidator(item)}
            onChangeConfirm={item.type == ECalcInputType.RepairTimeQuantity ? handleRTQuantityChange : handleDelayedChange}
            placeholder={item.placeholder ?? ""}
            stepSize={0.01}
            nullable
            minimum={0}
            enforceDecimalDigits={!!item.decimalPlaces}
            className={bem(style.custom, missingValue && valueValidator(item) == undefined && "error")}
        />
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        custom: {
            $nest: {
                "&--error": {
                    borderColor: theme.colors.danger,
                    boxShadow: " 0 1px 5px rgba(0,0,0,.2)",
                },
            },
        },
    })(CalcInputNumber)
}
