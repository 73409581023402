import { NavigationSteps, TreeOptions } from "../../../models"
import { MainSlice } from "./model"

export function moduleOpened(state: MainSlice): Partial<MainSlice> {
    return {
        main: {
            ...state.main,
            moduleOpened: true,
        },
    }
}

export function setWorktaskId(state: MainSlice, worktaskId: string): Partial<MainSlice> {
    return {
        main: {
            ...state.main,
            worktaskId,
        },
    }
}

export function setSearchOption(state: MainSlice, option: TreeOptions): Partial<MainSlice> {
    return {
        main: {
            ...state.main,
            treeOption: option,
        },
    }
}

export function toggleSearchOption(state: MainSlice): Partial<MainSlice> {
    const { treeOption } = state.main

    return {
        main: {
            ...state.main,
            treeOption: treeOption === TreeOptions.Category ? TreeOptions.Oe : TreeOptions.Category,
        },
    }
}

export function initializeFromUrl(state: MainSlice, vin: string, lockVehicle?: boolean): Partial<MainSlice> {
    return {
        main: {
            ...state.main,
            initializedFromUrl: true,
        },
        vehicle: {
            isLoading: false,
            error: undefined,
            isLocked: lockVehicle,
            data: { vin },
        },
    }
}

export function changeStep(state: MainSlice, newStep: NavigationSteps): Partial<MainSlice> {
    return {
        main: {
            ...state.main,
            view: newStep,
        },
    }
}
