import { Divider, Stack } from "@tm/components"
import { useWorkTask } from "@tm/context-distribution"
import { Suspense } from "react"
import { useCostEstimationVisible } from "../../hooks/basketState/useCostEstimationVisible"
import { useWorkTaskBasketState } from "../../hooks/basketState/useWorkTaskBasketState"
import { getBundleParams } from "../../utils"
import { LoaderSmall } from "../StyledComponents"
import { BasketTotals } from "./components/BasketTotals"
import CostEstimationTotals from "./components/CostEstimationTotals"
import { useSummaryTheme } from "./hooks/useSummaryTheme"

type Props = {
    costEstimationRoute: string
    hideBasketButton?: boolean
    partsBasketRoute: string
    swapTotals?: boolean
}

export type SummaryRouteProps = {
    page?: string
}

function Summary(props: Props & { workTaskId: string }) {
    const { hideBasketButton, swapTotals, partsBasketRoute, costEstimationRoute, workTaskId } = props
    const { hideBasket, hideCostEstimationHours } = getBundleParams()
    const { costEstimationVisible } = useCostEstimationVisible(workTaskId)
    const { componentBackgroundColor, boxShadow } = useSummaryTheme()
    const { basket, costEstimation, workTaskBasketCalculation } = useWorkTaskBasketState(workTaskId)
    const { basketLoading, basketWithErrors, basketErpInfosIndicator } = basket.state
    const { costEstimationLoading, costEstimationWithErrors, currencyCode, repairTimeDivision, showRepairTimesInHours } = costEstimation.state

    return (
        <Stack
            direction={swapTotals ? "row-reverse" : "row"}
            divider={<Divider orientation="vertical" />}
            alignItems="center"
            bgcolor={componentBackgroundColor}
            boxShadow={boxShadow}
        >
            {!hideBasketButton && !hideBasket && (
                <BasketTotals
                    workTaskId={workTaskId}
                    calculatedOrder={workTaskBasketCalculation?.calculatedOrder}
                    isLoading={basketLoading}
                    hasErrors={basketWithErrors}
                    partsBasketRoute={partsBasketRoute}
                    currencyCode={currencyCode}
                    additionalErrorTextId={basketErpInfosIndicator.hasErrors ? 13663 : undefined}
                />
            )}
            {!!costEstimationVisible && (
                <CostEstimationTotals
                    workTaskId={workTaskId}
                    costEstimationCalculation={workTaskBasketCalculation?.calculatedCostEstimation}
                    costEstimationOptions={costEstimation.state.costEstimation?.costEstimationOptions}
                    showRepairTimesInHours={showRepairTimesInHours}
                    isLoading={costEstimationLoading}
                    hasErrors={costEstimationWithErrors}
                    costEstimationRoute={costEstimationRoute}
                    hideHours={hideCostEstimationHours}
                    swapTotals={swapTotals}
                    currencyCode={currencyCode}
                    repairTimeDivision={repairTimeDivision}
                    additionalErrorTextId={basketErpInfosIndicator.hasErrors && costEstimationWithErrors ? 13663 : undefined}
                />
            )}
        </Stack>
    )
}

export default function Wrapper(props: Props) {
    const workTaskId = useWorkTask()?.workTaskId
    if (!workTaskId) {
        return null
    }
    return (
        <Suspense fallback={<LoaderSmall sx={{ flex: 0 }} />}>
            <Summary {...props} workTaskId={workTaskId} />
        </Suspense>
    )
}
