import { useWorkTask } from "@tm/context-distribution"
import { Loader } from "@tm/controls"
import { QueryType, RegistrationNoType, VehicleImportData, VehicleLookupConfig, VehicleType } from "@tm/models"
import { RouteComponentProps, withRouter, useAvailableVehicleSearchOptions } from "@tm/utils"
import { Suspense, useEffect, useMemo } from "react"
import { useLocation } from "react-router"

import { useImportedVehicleData, useSelectedVehicleLookup, useSetFilters } from "../../data/hooks"
import { publishModuleInfo } from "../../helpers"
import SearchByVinOrRegNoComponent from "./components/SearchByVinOrRegNo"
import SearchStandardComponent from "./components/SearchStandardComponent"

type RouteParams = {
    vehicleType: string
    query: string
}

type ExternalProps = RouteComponentProps<RouteParams> & {
    moduleProps?: {
        publishModuleInfo?: boolean
    }
}

export const VehicleParams = {
    SearchQuery: "query",
    RegNo: "regNo",
    RegNoType: "regNoType",
    CheckAndMergeRegNoDetails: "checkAndMergeRegNoDetails",
    ForceUpdate: "forceUpdate",
    AutoApplySingleVehicle: "autoApplySingleVehicle",
    PlateId: "plateId",
    Vin: "vin",
    InitialRegistration: "initialRegistration",
}

function Wrapper(props: ExternalProps) {
    const { moduleProps, match } = props
    const { workTaskId } = useWorkTask() ?? {}
    const { importedData, importedDataLoading } = useImportedVehicleData(workTaskId)
    const { search } = useLocation()

    useEffect(() => {
        if (moduleProps?.publishModuleInfo && workTaskId) {
            publishModuleInfo(workTaskId, "{{1601}}", "{{135}}")
        }
    }, [workTaskId, moduleProps])

    const query = decodeURIComponent(match.params.query)
    const vehicleType: VehicleType = parseInt(match.params.vehicleType)

    const { regNoType, forceUpdate, autoApplySingleVehicle, searchEngineCode } = useMemo(() => {
        const searchParams = new URLSearchParams(search)
        return {
            regNoType: searchParams.get(VehicleParams.RegNoType),
            forceUpdate: searchParams.get(VehicleParams.ForceUpdate) === "true",
            autoApplySingleVehicle: searchParams.get(VehicleParams.AutoApplySingleVehicle) === "true",
            searchEngineCode: searchParams.get("engineCode"),
        }
    }, [search])

    const setFilters = useSetFilters(vehicleType)
    useEffect(() => {
        // When the url query contains an engine code set it as active filter (only once)
        if (searchEngineCode) {
            setFilters((prev) => ({ ...prev, engineCode: searchEngineCode }))
        }
    }, [searchEngineCode, setFilters])

    const { availableVehicleSearches } = useAvailableVehicleSearchOptions()
    const { selectedVehicleLookup } = useSelectedVehicleLookup(vehicleType)

    const { lookupType, lookupTypeId, isUsedInDefaultSearch } = useMemo(() => {
        const id = selectedVehicleLookup.lookupTypeId || (regNoType ? parseInt(regNoType) : undefined)
        const lookup = availableVehicleSearches?.configuredVehicleLookups?.find((lookup: VehicleLookupConfig) => lookup.lookupTypeId === id)
        return { lookupType: lookup, lookupTypeId: id, isUsedInDefaultSearch: lookup?.isUsedInDefaultSearch }
    }, [regNoType, availableVehicleSearches, selectedVehicleLookup])

    let type: "vin" | "regNo" | undefined
    if (lookupType?.queryTypeId === QueryType.PlateIdOrVin) {
        type = query.length === 17 ? "vin" : "regNo"
    } else if (lookupType?.queryTypeId === QueryType.PlateId) {
        type = "regNo"
    } else if (lookupType?.queryTypeId === QueryType.Vin) {
        type = "vin"
    }

    return (
        <div className="tk-vehicle vehicle-search">
            <Suspense fallback={<Loader />}>
                {!isUsedInDefaultSearch && !!type && !!lookupTypeId ? (
                    <SearchByVinOrRegNoComponent
                        {...props}
                        query={query}
                        vehicleType={vehicleType}
                        autoApplySingleVehicle={autoApplySingleVehicle}
                        importedData={importedData}
                        importedDataLoading={importedDataLoading}
                        type={type}
                        lookupTypeId={lookupTypeId}
                        forceUpdate={forceUpdate}
                    />
                ) : (
                    <SearchStandardComponent
                        {...props}
                        query={query}
                        vehicleType={vehicleType}
                        autoApplySingleVehicle={autoApplySingleVehicle}
                        importedData={importedData}
                        importedDataLoading={importedDataLoading}
                    />
                )}
            </Suspense>
        </div>
    )
}

export type VehicleSearchProps = ExternalProps & {
    query: string
    vehicleType: VehicleType
    autoApplySingleVehicle?: boolean
    importedData: VehicleImportData | undefined
    importedDataLoading: boolean
    countryCode?: string
}

export default withRouter(Wrapper)
