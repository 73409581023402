import { IBundle } from "@tm/morpheus"
import Main from "./components/main"
import { initEdsBundle, version } from "./utils"
import WorktaskManager from "./components/workTaskManager"

const bundle: IBundle = {
    ...version,
    components: [Main, WorktaskManager],
    init: initEdsBundle,
}

export default bundle
