import { AddCatalogPartListRequest, PostSendOrderActionTargetType, PostSendOrderActionType, RegisteredModels } from "@tm/models"
import { useMutation } from "react-query"
import { useCallback } from "react"
import { Container } from "@tm/nexus"
import * as Data from "../../.."
import { useBasketUpdateWorkflow } from "../workflow/useBasketUpdateWorkflow"
import { reloadSupplierArticleQuantities } from "../../.."
import { useEnableCostEstimation } from "../../../../hooks/basketState/useEnableCostEstimation"
import { useBasketModuleParameter } from "../../../../hooks/useBasketModuleParameter"
import { useCreateSendOrderPostMessage } from "../../../../hooks/useCreateSendOrderPostMessage"
import { handlePostSendOrderAction } from "../../../../components/_shared/order-button/hooks/helper"

export function useAddCatalogPartList(handleBasketUpdateWorkflow: ReturnType<typeof useBasketUpdateWorkflow>) {
    const { enableCostEstimation } = useEnableCostEstimation()
    const { getMessageByCatalogPart } = useCreateSendOrderPostMessage()
    const basketModule = useBasketModuleParameter()

    const { mutateAsync: addCatalogPartList } = useMutation(
        (request: { addCatalogPartListRequest: AddCatalogPartListRequest; enableCostEstimation?: boolean }) =>
            Data.addCatalogPartList(request.addCatalogPartListRequest),
        {
            onSuccess: async (response, request) => {
                if (response) {
                    if (request.enableCostEstimation) {
                        await enableCostEstimation(request.addCatalogPartListRequest.workTaskId)
                    }

                    handleBasketUpdateWorkflow(request.addCatalogPartListRequest.workTaskId, response)

                    // Save work task in the DB
                    Container.getInstance(RegisteredModels.Worktask_BasketActivityDone).subscribe().load()
                }

                // The Add to basket buttons require the quantites added in the basket for the badge
                request.addCatalogPartListRequest.catalogParts.forEach((article) => {
                    if (article.dataSupplierArticleNumber && article.dataSupplierId && article.productGroupId) {
                        reloadSupplierArticleQuantities(request.addCatalogPartListRequest.workTaskId, article)
                    }
                })
            },
        }
    )
    const addCatalogParts = useCallback(
        (request: AddCatalogPartListRequest, enableCostEstimation?: boolean) => {
            if (basketModule?.sendPostMessage) {
                const message = getMessageByCatalogPart(request.catalogParts, request.workTaskId)
                handlePostSendOrderAction(message)
            }
            if (basketModule?.addToNextBasket !== false) {
                return addCatalogPartList({ addCatalogPartListRequest: request, enableCostEstimation })
            }

            return Promise.reject(new Error("no function"))
        },
        [addCatalogPartList, basketModule?.addToNextBasket, basketModule?.sendPostMessage, getMessageByCatalogPart]
    )

    return addCatalogParts
}
