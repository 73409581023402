import { BundleComponent } from "@tm/morpheus"
import { receive, reduce } from "./business"
import component from "./component"
import { withStoreProvider } from "../../data/helpers"

const fastcalc: BundleComponent = {
    name: "fastcalc",
    component: withStoreProvider(component as any) as any, // TODO
    reduce,
    receive,
}

export type MainState = ReturnType<typeof reduce>

export default fastcalc
