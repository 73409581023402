import { Stack, LicensePlate, Box } from "@tm/components"
import { formatMileage } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { RowStack } from "./StyledComponents"

export type Props = {
    licensePlateOnTop?: boolean
    vehicleDescription: string
    vehiclePlateId?: string
    vehicleMileage?: number
    shortCountryCode: string
    showVehicleMilage?: boolean
}

export default function VehicleInfo({
    vehicleDescription,
    vehiclePlateId,
    vehicleMileage,
    licensePlateOnTop,
    shortCountryCode,
    showVehicleMilage,
}: Props) {
    const { number } = useLocalization()

    return (
        <Stack
            direction={licensePlateOnTop ? "column-reverse" : "row"}
            spacing={licensePlateOnTop ? 0 : 1}
            alignItems={licensePlateOnTop ? undefined : "center"}
        >
            <RowStack spacing={2}>
                <Box>{vehicleDescription}</Box>
                {showVehicleMilage ? <Box>{vehicleMileage ? formatMileage(number(vehicleMileage, 0), vehicleMileage) : undefined}</Box> : <></>}
            </RowStack>
            {vehiclePlateId ? (
                <Box>
                    <LicensePlate value={vehiclePlateId} countryCode={shortCountryCode} />
                </Box>
            ) : undefined}
        </Stack>
    )
}
