import { useEffect, useState, useRef, useMemo } from "react"
import { useWorkTask } from "@tm/context-distribution"
import { Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { channel, CustomerContainer, DMS, RegisteredModels, VehicleContainer, WorkTaskStatus } from "@tm/models"
import { Container } from "@tm/nexus"
import { registerOutsideClick, uniqueId } from "@tm/utils"
import { StatusCodes } from "http-status-codes"
import { Loader, Typography, Button, Tooltip, Menu, Icon, Stack } from "@tm/components"
import { DmsContainer } from "../../business/import"
import { connectionChannel, messageChannel } from "../../business/messaging"
import { useDmsInfo, useDmsPermissions } from "../hooks"
import { useInvalidateWorkTaskBasketQueries } from "../../../../basket/src/data/hooks/workTaskBasket/workflow/useInvalidateWorkTaskBasketQueries"

export default function ExportButton() {
    const { permittedOperations } = useDmsPermissions()
    const { name: dmsName, icon: dmsIcon } = useDmsInfo()
    const { translate, translateText } = useLocalization()
    const { workTask, reloadWorkTask } = useWorkTask() ?? {}
    const { invalidateAllWorkTaskBasketRequests } = useInvalidateWorkTaskBasketQueries()

    const { id: workTaskId, vehicle: workTaskVehicle, customer: workTaskCustomer } = workTask ?? {}
    const buttonRef = useRef<HTMLButtonElement>(null)

    const [isConnected, setIsConnected] = useState(() => connectionChannel().last(1, "CONNECTION_STATUS_RECEIVED")[0]?.content?.isConnected ?? false)
    const [exporting, setExporting] = useState(false)
    const [voucherTypes, setVoucherTypes] = useState<Array<DMS.VoucherType>>([])
    const [showVoucherTypes, setShowVoucherTypes] = useState(false)

    useEffect(() => {
        return connectionChannel().subscribe(
            "CONNECTION_STATUS_RECEIVED",
            (response) => {
                setIsConnected(!!response.isConnected)
            },
            true
        )
    }, [])

    useEffect(() => {
        if (!isConnected) {
            return
        }

        const processId = uniqueId()

        const unsub = messageChannel().subscribe("DMS_RESPONSE_RECEIVED", (e) => {
            if (e.type === "findVoucherTypes" && e.processId === processId) {
                unsub()

                if (e.status === StatusCodes.OK && e.response) {
                    setVoucherTypes(e.response.voucherTypes)
                }
            }
        })

        messageChannel().publish("SEND_DMS_REQUEST", { processId, type: "findVoucherTypes", request: undefined })
    }, [isConnected])

    useEffect(() => {
        if (!showVoucherTypes || !buttonRef.current) {
            return
        }
        return registerOutsideClick(buttonRef.current, () => setShowVoucherTypes(false), true)
    }, [showVoucherTypes])

    const workTaskIsVoucher = workTask?.workTaskReadModel?.refId && workTask.workTaskReadModel.voucherType

    const disabled =
        !isConnected || // connection to DMS hasn't be established yet
        (!workTaskIsVoucher && !voucherTypes.length) || // the work task is not already a voucher and the voucher types aren't loaded yet
        exporting || // there is an ongoing export running
        workTask?.statusValue === WorkTaskStatus.Undefined // the work task isn't already saved (in database)

    const tooltipText = useMemo(() => translateText(1922).replace("{0}", dmsName), [dmsName, translateText])

    const saveVoucher = async (voucherType: DMS.VoucherType | undefined) => {
        if (!workTaskId) {
            return
        }

        setShowVoucherTypes(false)

        const handleError = (error?: string) => {
            let message = translateText(1923)

            if (error) {
                message += ` \n\n${error}\n\n`
            }

            message += ` ${translateText(401)}`

            channel("APP").publish("TOAST_MESSAGE/SHOW", { message, skin: "danger", closeDelay: false })
            setExporting(false)
        }

        let voucher: DMS.Voucher | undefined

        try {
            setExporting(true)

            const container: DmsContainer = Container.getInstance(RegisteredModels.ImportExport)
            voucher = await container.action("exportVoucher")({ workTaskId })

            if (voucherType) {
                // Set type of voucher to selected type
                voucher.voucherType = voucherType
            }

            let saveCustomerPromise
            if (voucher.customer && !voucher.customer?.referenceId) {
                voucher.customer.referenceId = await new Promise<string>((resolve, reject) => {
                    const processId = uniqueId()

                    const unsub = messageChannel().subscribe("DMS_RESPONSE_RECEIVED", (e) => {
                        if (e.type === "saveCustomer" && e.processId === processId) {
                            unsub()

                            if (e.status === StatusCodes.OK && e.response?.referenceId) {
                                resolve(e.response.referenceId)
                            } else {
                                reject(e.response?.exceptionMessage)
                            }
                        }
                    })

                    messageChannel().publish("SEND_DMS_REQUEST", { processId, type: "saveCustomer", request: { customer: voucher!.customer! } })
                })

                if (workTaskCustomer && workTaskCustomer.refId !== voucher.customer.referenceId) {
                    const customerContainer: CustomerContainer = Container.getInstance(RegisteredModels.Customer)
                    saveCustomerPromise = customerContainer.action("saveCustomer")({ ...workTaskCustomer, refId: voucher.customer.referenceId })
                }
            }

            let saveVehiclePromise
            if (voucher.vehicle && !voucher.vehicle?.referenceId) {
                voucher.vehicle.referenceId = await new Promise<string>((resolve, reject) => {
                    const processId = uniqueId()

                    const unsub = messageChannel().subscribe("DMS_RESPONSE_RECEIVED", (e) => {
                        if (e.type === "saveVehicle" && e.processId === processId) {
                            unsub()

                            if (e.status === StatusCodes.OK && e.response?.referenceId) {
                                resolve(e.response.referenceId)
                            } else {
                                reject(e.response?.exceptionMessage)
                            }
                        }
                    })

                    messageChannel().publish("SEND_DMS_REQUEST", {
                        processId,
                        type: "saveVehicle",
                        request: {
                            vehicle: {
                                ...voucher!.vehicle!,
                                customerReferenceId: voucher!.customer?.referenceId,
                            },
                        },
                    })
                })

                if (workTaskVehicle && workTaskVehicle.refId !== voucher.vehicle.referenceId) {
                    const vehicleContainer: VehicleContainer = Container.getInstance(RegisteredModels.Vehicle)
                    saveVehiclePromise = vehicleContainer.action("saveVehicle")({ ...workTaskVehicle, refId: voucher.vehicle.referenceId })
                }
            }

            voucher.referenceId = await new Promise<string>((resolve, reject) => {
                const processId = uniqueId()

                const unsub = messageChannel().subscribe("DMS_RESPONSE_RECEIVED", (e) => {
                    if (e.type === "saveVoucher" && e.processId === processId) {
                        unsub()

                        if (e.status === StatusCodes.OK && e.response?.referenceId) {
                            resolve(e.response.referenceId)
                        } else {
                            reject(e.response?.exceptionMessage)
                        }
                    }
                })

                messageChannel().publish("SEND_DMS_REQUEST", { processId, type: "saveVoucher", request: { voucher: voucher! } })
            })

            voucher = await new Promise<DMS.Voucher>((resolve, reject) => {
                const processId = uniqueId()

                const unsub = messageChannel().subscribe("DMS_RESPONSE_RECEIVED", (e) => {
                    if (e.type === "showVoucher" && e.processId === processId) {
                        unsub()

                        if (e.status === StatusCodes.OK && e.response?.voucher) {
                            resolve(e.response.voucher)
                        } else {
                            reject(e.response?.exceptionMessage)
                        }
                    }
                })

                messageChannel().publish("SEND_DMS_REQUEST", { processId, type: "showVoucher", request: { referenceId: voucher!.referenceId } })
            })

            // Wait for customer and vehicle to be saved with the new refId before calling importVoucher.
            // Otherwise a new customer and vehicle would be created in importVoucher.
            await saveCustomerPromise
            await saveVehiclePromise

            await container.action("importVoucher")({ ...voucher, workTaskId })
            await reloadWorkTask?.()

            // Reload the basket content because the part/work ids will change because of the (re-)import of the voucher - for further info see NEXT-17175
            invalidateAllWorkTaskBasketRequests(workTaskId)

            channel("APP").publish("TOAST_MESSAGE/SHOW", { message: translateText(1925), skin: "success" })
            setExporting(false)
        } catch (err: any) {
            handleError(err)
        }
    }

    const handleButtonClick = async () => {
        if (disabled) {
            return
        }

        if (workTaskIsVoucher) {
            saveVoucher(undefined)
        } else {
            setShowVoucherTypes(true)
        }
    }

    // The following permissions have to granted to be able to export a voucher
    if (
        !permittedOperations.includes("saveCustomer") ||
        !permittedOperations.includes("saveVehicle") ||
        !permittedOperations.includes("saveVoucher") ||
        !permittedOperations.includes("showVoucher") ||
        !permittedOperations.includes("findVoucherTypes")
    ) {
        return null
    }

    return (
        <Toolbar>
            {exporting ? (
                <Loader size="small" />
            ) : (
                <Tooltip title={tooltipText}>
                    <Button
                        onClick={handleButtonClick}
                        disabled={disabled}
                        startIcon={<Icon name={!exporting ? dmsIcon : undefined} />}
                        ref={buttonRef}
                    >
                        {translate(1926)}
                    </Button>
                </Tooltip>
            )}
            <Menu
                open={!disabled && showVoucherTypes}
                onClose={() => setShowVoucherTypes(false)}
                anchorEl={buttonRef.current}
                transformOrigin={{ horizontal: "center", vertical: "top" }}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Stack px={1} spacing={0.5}>
                    <Typography variant="label">{translate(1927)} ...</Typography>
                    {voucherTypes.map((voucherType) => {
                        return (
                            <Button key={voucherType.referenceId} onClick={() => saveVoucher(voucherType)} variant="outlined" color="primary">
                                {voucherType.description}
                            </Button>
                        )
                    })}
                </Stack>
            </Menu>
        </Toolbar>
    )
}
