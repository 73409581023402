import { StateCreator } from "zustand"
import { EdsStore } from ".."
import { NavigationSteps, TreeOptions } from "../../../models"
import { setError, setGroup, setLoading, setSelectedArea, setSelectedUnit, setUnit, setUnitInfo, setUnitParts } from "./graphicActions"
import { MainSlice, MainState } from "./model"
import { setSelectedNode, setTreeNavigation, setTreeNavigationError, setTreeNavigationLoading } from "./treeActions"
import { setVehicleError, setVehicleLoading, setVin, setYQVehicle } from "./vehicleActions"
import { changeStep, initializeFromUrl, moduleOpened, setSearchOption, setWorktaskId, toggleSearchOption } from "./mainActions"

const initialState: MainState = {
    main: {
        treeOption: TreeOptions.Category,
        view: NavigationSteps.Vehicle,
        initializedFromUrl: false,
        worktaskId: "",
    },
    vehicle: {
        isLoading: false,
        error: undefined,
        isLocked: false,
        data: {
            vin: "",
        },
    },
    treeNavigation: {
        isLoading: false,
        isError: false,
    },
    graphic: {
        loading: false,
        error: false,
    },
}

export const createMainSlice: StateCreator<EdsStore, [["zustand/devtools", never]], [], MainSlice> = (set) => ({
    ...initialState,
    mainActions: {
        moduleOpened: () => set((state) => moduleOpened(state)),
        setSearchOption: (option) => set((state) => setSearchOption(state, option)),
        toggleSearchOption: () => set((state) => toggleSearchOption(state)),
        initializeFromUrl: (vin, lockVehicle) => set((state) => initializeFromUrl(state, vin, lockVehicle)),
        changeStep: (step) => set((state) => changeStep(state, step)),
        setWorktaskId: (worktaskId) => set((state) => setWorktaskId(state, worktaskId)),
    },
    vehicleActions: {
        setVin: (newValue) => set((state) => setVin(state, newValue)),
        setYqVehicle: (vehicle) => set((state) => setYQVehicle(state, vehicle)),
        setLoading: (loading) => set((state) => setVehicleLoading(state, loading)),
        setError: (error) => set((state) => setVehicleError(state, error)),
    },
    treeActions: {
        setSelectedNode: (selectedNode) => set((state) => setSelectedNode(state, selectedNode)),
        setTreeNavigation: (nodes) => set((state) => setTreeNavigation(state, nodes)),
        setLoading: (loading) => set((state) => setTreeNavigationLoading(state, loading)),
        setError: (error) => set((state) => setTreeNavigationError(state, error)),
    },
    graphicActions: {
        setSelectedUnit: (unit) => set((state) => setSelectedUnit(state, unit)),
        setGroup: (group) => set((state) => setGroup(state, group)),
        setUnit: (unit) => set((state) => setUnit(state, unit)),
        setUnitInfo: (unitInfo) => set((state) => setUnitInfo(state, unitInfo)),
        setUnitParts: (unitParts) => set((state) => setUnitParts(state, unitParts)),
        setLoading: (loading) => set((state) => setLoading(state, loading)),
        setError: (error) => set((state) => setError(state, error)),
        setSelectedArea: (selectedArea) => set((state) => setSelectedArea(state, selectedArea)),
    },
    resetStore: () => set(reset, false, "Reset Main Slice"),
})

function reset(): Partial<MainSlice> {
    return {
        ...initialState,
    }
}
