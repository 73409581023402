import { Box, CellContentPosition, Icon, Table, TableCellData, TableColumnData, TableRowData, styled } from "@tm/components"
import { useEffect, useState } from "react"
import { useLocalization } from "@tm/localization"
import { getCurrencyFromUserContext } from "@tm/utils"
import { useUser } from "@tm/context-distribution"
import Accordion from "../../_shared/Accordion"
import { SelectedWork } from "../../../data/models"
import { useDrivemotiveStore } from "../../../data/state"
import NoResultHint from "../../_shared/NoResult"

export default function SummaryWorks() {
    const { translateText, currency } = useLocalization()
    const { userContext } = useUser()
    const currencyString = getCurrencyFromUserContext(userContext)
    const hourRate = useDrivemotiveStore((state) => state.summary.hourRate)
    const summaryWorks = useDrivemotiveStore((state) => state.summary.summaryWorks)
    const totalPriceWorks = useDrivemotiveStore((state) => state.summary.totalPriceWorks)

    if (!summaryWorks) {
        return <NoResultHint />
    }

    const [switchStates, setSwitchStates] = useState<{
        isExpanded: boolean
    }>({
        isExpanded: summaryWorks.length > 0,
    })

    useEffect(() => {
        setSwitchStates((prevState) => ({
            ...prevState,
            isExpanded: !(summaryWorks.length > 0),
        }))
    }, [summaryWorks])

    const handleClick = (isExpanded: boolean) => {
        setSwitchStates((prevState) => ({
            ...prevState,
            isExpanded: !isExpanded,
        }))
    }

    const tableColumns: TableColumnData[] = [{}, { alignContent: CellContentPosition.right }, { alignContent: CellContentPosition.right }]

    const getCells = (work: SelectedWork) => {
        const worksDescription = (
            <>
                <Box sx={{ fontWeight: "bold" }}>{work.mainWork}</Box>
                <br />
                {work.subWorks.map((subWork) => {
                    return (
                        <>
                            {subWork}
                            <br />
                        </>
                    )
                })}
                <br />
            </>
        )

        const cellData: TableCellData[] = [
            { displayValue: worksDescription, id: "worksDescription" },
            {
                displayValue: work.hours && (
                    <>
                        {work.hours} {translateText(12412)}
                    </>
                ),
                id: "hours",
            },
            {
                displayValue: work.hours && (
                    <Box sx={{ display: "flex" }}>
                        {translateText(1721)} <Box sx={{ marginLeft: "1em" }}>{currency(hourRate * work.hours, currencyString)}</Box>
                    </Box>
                ),
                id: "Total amount of work",
            },
        ]

        return cellData
    }

    const displayData: TableRowData[] = summaryWorks.map((work: SelectedWork) => ({
        cells: getCells(work),
        id: `${work.workId}`,
        rowIndicatorWidth: 7,
    }))

    return (
        <Box sx={{ marginTop: "1em", display: "flex", flexDirection: "column", flex: "1", gap: "0.5em" }}>
            <Accordion icon={<Icon name="down" />} expandIcon={<Icon name="up" />} expanded={!switchStates.isExpanded} onClick={handleClick}>
                <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                    {translateText(1668)}
                    <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                        {translateText(13705)}
                        <Box sx={{ fontWeight: "bold", marginLeft: "1em" }}>{currency(totalPriceWorks.value, currencyString)}</Box>
                    </Box>
                </Box>
                <StyledTable
                    columns={tableColumns}
                    rows={displayData}
                    headerBackground="transparent"
                    fontSize={14}
                    headerStyle="default"
                    rowCap={0}
                    overflowY="auto"
                    rowStyle="uniform"
                />
            </Accordion>
        </Box>
    )
}

const StyledTable = styled(Table)({
    ".row>span": {
        alignContent: "flex-start",
        flexWrap: "wrap",
    },
})
