import { History } from "history"
import { channel, AddCustomWorkListRequest, CustomWork, RepairTimeProvider, WorkCategory, ChangeItemsResponse, VehicleType } from "@tm/models"
import { AsyncAction } from "@tm/morpheus"
import { addOrRemoveItem, hasGenart } from "../../../data/helpers"
import { Info, Item } from "../../../data/model"
import { MainState, SelectedError } from "./model"
import { Repositories } from "../../../data"

export type ComponentActionType =
    | { type: "SET_ERRORS_DATA"; payload: Item[] }
    | { type: "LOAD_ERRORS_DATA" }
    | { type: "SELECT_ITEMS"; payload: SelectedError[] }
    | { type: "REMOVE_ERROR_CODE"; payload: string }
    | { type: "SET_ERROR_CODES"; payload: string }
    | { type: "ADD_ERROR_DATA"; payload: Item[] }
    | { type: "INIT" }

const DEFAULT_STATE: MainState = {
    errorData: [],
    selectedData: [],
    loadingData: false,
    lastErrorCodes: "",
}

export const reduce = (state = DEFAULT_STATE, action: ComponentActionType): MainState => {
    switch (action.type) {
        case "INIT": {
            return DEFAULT_STATE
        }
        case "LOAD_ERRORS_DATA": {
            return {
                ...state,
                loadingData: true,
            }
        }

        case "SET_ERRORS_DATA": {
            const items = action.payload
            return {
                ...state,
                selectedData: [],
                errorData: items,
                loadingData: false,
            }
        }
        case "SELECT_ITEMS": {
            const { selectedData } = state
            const { payload } = action
            let newData: SelectedError[] = []

            if (payload.length === 1) {
                const result = addOrRemoveItem(state.selectedData, payload[0])
                return {
                    ...state,
                    selectedData: result,
                }
            }
            if (selectedData.length && selectedData.filter((x) => hasGenart(payload, x.genartnr, x.code)).length) {
                newData = selectedData.filter((x) => !hasGenart(payload, x.genartnr, x.code))
            } else {
                newData = [...selectedData, ...payload]
            }
            return {
                ...state,
                selectedData: newData,
            }
        }
        case "ADD_ERROR_DATA": {
            const items = [...state.errorData, ...action.payload]
            return {
                ...state,
                errorData: items,
                loadingData: false,
            }
        }
        case "REMOVE_ERROR_CODE": {
            return {
                ...state,
                errorData: state.errorData.filter((x) => x.code !== action.payload),
            }
        }
        case "SET_ERROR_CODES": {
            return {
                ...state,
                lastErrorCodes: action.payload,
            }
        }

        default:
            return state
    }
}

const fetchErrorDatas = (
    tecDocTypeId: number,
    manufacturerId: number,
    errorCodes: Array<string>,
    vehicleType: VehicleType,
    initialRegistration: Date,
    mileAge: number,
    maxCount: number
): AsyncAction<ComponentActionType, MainState> => {
    return (dispatch) => {
        dispatch({ type: "INIT" })
        dispatch({ type: "LOAD_ERRORS_DATA" })

        Repositories.getErrorDatas({ tecDocTypeId, manufacturerId, errorCodes, vehicleType, initialRegistration, mileAge, maxCount }).then((data) => {
            if (!data.length) {
                dispatch({ type: "SET_ERRORS_DATA", payload: [] })
            } else {
                dispatch({ type: "SET_ERRORS_DATA", payload: data ?? [] })
            }
        })
    }
}

const addNewSearch = (
    tecDocTypeId: number,
    manufacturerId: number,
    errorCodes: Array<string>,
    vehicleType: VehicleType,
    initialRegistration: Date,
    mileAge: number,
    maxCount: number,
    history: History,
    noResultText: string,
    alreadyLoadedText: string
): AsyncAction<ComponentActionType, MainState> => {
    return (dispatch, getState) => {
        const lastSearch = getState().lastErrorCodes.split(",") || []
        const newSearch = errorCodes.filter((x) => !lastSearch.includes(x))

        if (!newSearch.length) {
            channel("APP").publish("TOAST_MESSAGE/SHOW", {
                message: alreadyLoadedText,
                icon: "error",
                skin: "warning",
                closeDelay: 3000,
            })
            return
        }

        dispatch({ type: "LOAD_ERRORS_DATA" })

        Repositories.getErrorDatas({
            tecDocTypeId,
            manufacturerId,
            errorCodes: newSearch,
            vehicleType,
            initialRegistration,
            mileAge,
            maxCount,
        }).then((data) => {
            if (data.length) {
                const newErrorCodesSearch =
                    lastSearch?.filter((x) => !!x)?.length > 0 ? [...lastSearch, ...newSearch].join(",") : [...newSearch].join(",")
                dispatch({ type: "ADD_ERROR_DATA", payload: data })
                dispatch({ type: "SET_ERROR_CODES", payload: newErrorCodesSearch })

                history.push({
                    search: `errorCodes=${newErrorCodesSearch}`,
                })
            } else {
                dispatch({ type: "ADD_ERROR_DATA", payload: [] })
                channel("APP").publish("TOAST_MESSAGE/SHOW", {
                    message: noResultText,
                    icon: "error",
                    skin: "warning",
                    closeDelay: 3000,
                })
            }
        })
    }
}

const saveLastSearch =
    (search: string): AsyncAction<ComponentActionType, MainState> =>
    (dispatch) =>
        dispatch({ type: "SET_ERROR_CODES", payload: search })

const selectItem =
    (items: SelectedError[]): AsyncAction<ComponentActionType, MainState> =>
    (dispatch) =>
        dispatch({ type: "SELECT_ITEMS", payload: items })

const removeError = (code: string): AsyncAction<ComponentActionType, MainState> => {
    return (dispatch, getState) => {
        const lastSearch = getState().lastErrorCodes.split(",") || []
        const newlastSearch = lastSearch.filter((x) => x !== code).join(",")

        dispatch({ type: "SET_ERROR_CODES", payload: newlastSearch })
        dispatch({ type: "REMOVE_ERROR_CODE", payload: code })
    }
}

const addCustomWorksToBasket = (
    info: Info,
    code: string,
    workTaskId: string,
    translateText: (key: React.ReactText) => string,
    addCustomWorkList: (request: AddCustomWorkListRequest, isExternalCall?: boolean | undefined) => Promise<ChangeItemsResponse | undefined>
): AsyncAction<ComponentActionType, MainState> => {
    return () => {
        const customWork: CustomWork = {
            description: `${translateText(12981)}: ${info.locationDescription}`,
            customWorkNumber: code,
            categoryOfWork: WorkCategory.Diagnose,
            time: 0.15,
        }

        const request: AddCustomWorkListRequest = {
            workTaskId,
            customWorks: [customWork],
            repairTimeProvider: RepairTimeProvider.Topmotive,
        }

        addCustomWorkList(request, true).then(
            () => {
                channel("APP").publish("TOAST_MESSAGE/SHOW", {
                    message: translateText(12998),
                    icon: "check",
                    skin: "success",
                    closeDelay: 3000,
                })
            },
            () => {
                channel("APP").publish("TOAST_MESSAGE/SHOW", {
                    message: translateText(12997),
                    icon: "error",
                    skin: "warning",
                    closeDelay: 3000,
                })
            }
        )
    }
}

export const Actions = {
    fetchErrorDatas,
    selectItem,
    removeError,
    saveLastSearch,
    addNewSearch,
    addCustomWorksToBasket,
}
