import { CategoryNode, GroupNode, YqNode } from "../../../models"
import { MainSlice } from "./model"

export function setTreeNavigationLoading(state: MainSlice, loading: boolean): Partial<MainSlice> {
    return {
        treeNavigation: {
            isLoading: loading,
            isError: false,
        },
    }
}

export function setTreeNavigationError(state: MainSlice, error: boolean): Partial<MainSlice> {
    return {
        treeNavigation: {
            isLoading: false,
            isError: error,
        },
    }
}

function setNodeParents(nodes: GroupNode | CategoryNode) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, ...parent } = nodes
    nodes?.children?.forEach((childNode) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { children: _, parent: __, ...node } = childNode
        // eslint-disable-next-line no-param-reassign
        childNode.parent = { ...parent, children: [node] }
        setNodeParents(childNode)
    })
}

export function setTreeNavigation(state: MainSlice, nodes: GroupNode | CategoryNode | undefined): Partial<MainSlice> {
    if (nodes) {
        setNodeParents(nodes)
    }

    return {
        treeNavigation: {
            data: nodes,
            isLoading: false,
            isError: false,
        },
    }
}

export function setSelectedNode(state: MainSlice, selectedNode: YqNode | undefined): Partial<MainSlice> {
    return {
        treeNavigation: {
            ...state.treeNavigation,
            selectedNode,
        },
    }
}
