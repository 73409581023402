import { margin } from "csx"
import { useSelector } from "react-redux"
import { Box, Typography, VehicleImage, useTheme } from "@tm/components"
import { Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { MainState } from "../../main"

export default function VehicleInfo() {
    const { translateText } = useLocalization()
    const vehicle = useSelector((s: MainState) => s.manager.vehicle)
    const theme = useTheme()

    if (!vehicle) {
        return null
    }

    return (
        <Box margin={theme.margin?.m} display="flex" flexDirection="column">
            <Typography margin={margin(theme.margin?.m ?? "0.5em", 0)}>
                {translateText(1462)} {translateText(1463)}
            </Typography>

            <Box display="flex" alignItems="center">
                <Typography>
                    {translateText(1464)} {translateText(1481)}
                </Typography>
                {vehicle.modelThumbnail && (
                    <Box>
                        <VehicleImage
                            modelImage={vehicle.modelThumbnail}
                            modelSeriesImage={vehicle.modelSeriesThumbnail || ""}
                            vehicleType={vehicle.vehicleType}
                            style={{ width: "3em", margin: "0 0.5em" }}
                        />
                    </Box>
                )}
                <Text modifiers={["strong"]}>
                    {vehicle.manufacturer} {vehicle.modelSeries}
                </Text>
            </Box>
        </Box>
    )
}
