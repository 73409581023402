import { useLocalization } from "@tm/localization"
import { PriceType } from "@tm/models"
import { Grid } from "@tm/components"
import { useMemo } from "react"
import { OrderCalculation } from "../../../../data/model"
import { HighlightContrastTypography, StyledBox } from "../../../StyledComponents"
import Total from "../Total"
import SummaryDetails from "../SummaryDetails"
import { useSummaryTheme } from "../../hooks/useSummaryTheme"

type Props = {
    calculatedOrder: OrderCalculation
    priceType: PriceType
    currencyCode: string
}

export default function BasketTotalsTable({ calculatedOrder, priceType, currencyCode }: Props) {
    const { netTotals, grossTotals, numberOfItems } = calculatedOrder
    const { translateText } = useLocalization()
    const { styledBoxBackgroundColor, highlightColor, styledBoxPadding } = useSummaryTheme()

    const netTotalPrice = useMemo(() => {
        if (!netTotals) {
            return
        }
        const totalPricesAllParts = Object.values(netTotals)
        let result = totalPricesAllParts.find((price) => price?.type === priceType)
        if (!result && priceType === PriceType.Retail) {
            result = totalPricesAllParts.find((price) => price?.type === PriceType.RecommendedRetail)
        }
        return result
    }, [netTotals, priceType])

    const grossTotalPrice = useMemo(() => {
        if (!grossTotals) {
            return
        }
        const totalPricesAllParts = Object.values(grossTotals)
        let result = totalPricesAllParts.find((price) => price?.type === priceType)
        if (!result && priceType === PriceType.Retail) {
            result = totalPricesAllParts.find((price) => price?.type === PriceType.RecommendedRetail)
        }
        return result
    }, [grossTotals, priceType])

    if (!netTotalPrice && !grossTotalPrice) {
        return null
    }

    return (
        <>
            <StyledBox backgroundColor={styledBoxBackgroundColor} padding={styledBoxPadding}>
                <SummaryDetails currencyCode={currencyCode} partCosts={netTotalPrice?.value || 0} partCount={numberOfItems} />
            </StyledBox>
            <StyledBox backgroundColor={highlightColor} padding={styledBoxPadding}>
                <Grid container spacing={1} alignItems="center" sx={{ rowGap: 0.5 }}>
                    {netTotalPrice?.value && <Total currencyCode={currencyCode} label={translateText(694)} value={netTotalPrice?.value} />}
                    {grossTotalPrice?.value && <Total currencyCode={currencyCode} label={translateText(13668)} value={grossTotalPrice?.value} />}
                    <Grid item xs={6} />
                    <Grid item xs={6} textAlign="right">
                        <HighlightContrastTypography variant="label">*{translateText(802)}</HighlightContrastTypography>
                    </Grid>
                </Grid>
            </StyledBox>
        </>
    )
}
