import { Box, Button, SearchtreeTip, Stack, styled, SxProps, Theme, Typography } from "@tm/components"
import { getStyleTheme, useUser, useWorkTask } from "@tm/context-distribution"
import { Widget as WidgetControl, WidgetSizes } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { CategoryType, channel, ECounterType, getCurrentWorkTaskId, IMicros, VehicleType } from "@tm/models"
import Morpheus, { connectComponent, useMicro } from "@tm/morpheus"
import { encodeUniqueId, renderRoute, RouteComponentProps, TmaHelper, uniqueId, useHeighAdjustment, withRouter } from "@tm/utils"
import { useCallback, useEffect, useMemo, useRef } from "react"
import { useHistory } from "react-router"
import { Repositories } from "../../data"
import { AutosuggestFunction } from "../../data/repositories"
import { getBundleParams } from "../../utils"
import CategorySelection, { NavigationLink } from "../_shared/category-selection"
import DirectSearchField from "../_shared/direct-search-field"
import PartsIndicator from "../_shared/parts-indicator"
import SearchField from "../_shared/search-field"
import { Actions, IActions, TreeConfig, WidgetState } from "./business"
import { getAvailableCategories, getDisabledCategories } from "./helpers"

import useBreadCrumbHandler from "../../hooks/breadCrumbhandler/useBreadCrumbHandler"
import SearchTreeWidget from "./components/SearchTreeWidget"

export type TreeConfigKeys = "vehicleParts" | "directSearch" | "universalParts" | "tiresSearch" | "eurotaxSearch" | "predictive" | "fastDTC"

export type WidgetComponentConfigProps = {
    height?: number
    className?: string
    collapsible?: boolean
    trees: Partial<Record<TreeConfigKeys, TreeConfig>>
    directSearchText?: string
    universalSearchText?: string
    calculatorRoute?: string
    tiresSearchText?: string
    title?: string
    size?: WidgetSizes
    alignHeightToWidgets?: string[]
    autofocusTimeout?: number
    navigationLinks?: NavigationLink[]
    sx?: SxProps<Theme>
}

type WidgetComponentProps = WidgetComponentConfigProps &
    RouteComponentProps<{ workTaskId?: string }> & {
        state: WidgetState
        actions: IActions
        aiSwitch?: React.ReactNode
        isOnIndustryDashboard?: boolean
    }

const SxWidgetControl = styled(WidgetControl)({})

function CatalogWidgetComponent(props: WidgetComponentProps) {
    const { translate, translateText } = useLocalization()
    const { userContext } = useUser() ?? {}
    const { renderMicro } = useMicro<IMicros>()
    const { workTask } = useWorkTask() || {}
    const history = useHistory()
    const { partsRoutes, searchtreeNodeVariant } = getBundleParams()

    const {
        actions,
        calculatorRoute,
        navigationLinks,
        state: { activeCategory, trees, queryHistory, activeCategoryManuallySelected },
        sx,
        aiSwitch,
        isOnIndustryDashboard,
    } = props
    const removeHeightAdjustment = useRef<() => void>()

    const isWM = Morpheus.getParams("parts")?.templates?.articleItem?.bundle === "wm"

    useEffect(() => {
        return () => {
            removeHeightAdjustment.current?.()
        }
    }, [])

    useEffect(() => {
        actions.loadQueryHistory()
    }, [actions])

    const availableCategories = useMemo(() => getAvailableCategories(props.trees), [props.trees])
    const disabledCategories = useMemo(
        () => getDisabledCategories(workTask?.vehicle, userContext.parameter.catalogLight),
        [workTask?.vehicle, userContext?.parameter?.catalogLight]
    )

    const handleSetActiveCategory = useCallback(
        (category: CategoryType) => {
            if (category === "tiresSearch" && isWM) {
                history.push(
                    renderRoute(partsRoutes?.tyresRoute.summary ?? "", {
                        workTaskId: encodeUniqueId(workTask?.id ?? getCurrentWorkTaskId() ?? uniqueId()),
                    })
                )
            }

            actions.setActiveCategory(category, true)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [workTask?.vehicle, actions, isWM]
    )

    useEffect(() => {
        if (isOnIndustryDashboard) {
            actions.setActiveCategory("universalParts", false)
        }
    }, [])

    useEffect(() => {
        if (
            activeCategory !== "vehicleParts" &&
            !activeCategoryManuallySelected &&
            availableCategories.includes("vehicleParts") &&
            !disabledCategories.includes("vehicleParts")
        ) {
            actions.setActiveCategory("vehicleParts", false)
        } else if (!availableCategories.includes(activeCategory)) {
            const match = availableCategories.find((x) => !disabledCategories.includes(x))

            if (match) {
                actions.setActiveCategory(match, false)
            }
        } else if (activeCategory === "vehicleParts" && !workTask?.vehicle) {
            const nextCategory = availableCategories.find((x) => x !== "vehicleParts")
            nextCategory && actions.setActiveCategory(nextCategory, false)
        } else if (activeCategory === "predictive" && workTask?.vehicle?.vehicleType !== VehicleType.PassengerCar) {
            const match = availableCategories.first()
            if (match) {
                actions.setActiveCategory(match, false)
            }
        }
    }, [availableCategories, disabledCategories, activeCategory, workTask?.vehicle, actions, activeCategoryManuallySelected, handleSetActiveCategory])

    useEffect(() => {
        if (!props.trees || !Object.keys(props.trees).length) {
            console.error(`Parts Widget: the 'trees' dictionary was not passed as property in the app config or was empty`)
            return
        }

        actions.initializeTrees(props.trees, workTask?.vehicle)
    }, [props.trees, workTask?.vehicle, workTask?.vehicle?.vehicleType, workTask?.vehicle?.tecDocTypeId, actions])

    useEffect(() => {
        return channel("APP").subscribe("SHORTCUTS", (rez) => {
            if (rez.key === "UNI") {
                handleSetActiveCategory("universalParts")
            } else if (rez.key === "DIRECT") {
                handleSetActiveCategory("directSearch")
            }
        })
    }, [handleSetActiveCategory])

    const handleSearch = (query: string) => {
        if (workTask?.vehicle) {
            TmaHelper.ArticleListFiltered.ArticleListFiltered.Search.AttachVehicle(workTask?.vehicle)
        }

        TmaHelper.Shared.ByArticleAndUniParts.KeywordSearch(activeCategory, query, workTask?.id, true)
        actions.searchArticles(query)
    }

    const handleSearchFromHistory = (query: string) => {
        handleSearch(query)
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleDirectSearchHistory)
    }

    const handleRef = (el: HTMLDivElement | null) => {
        const { alignHeightToWidgets } = props
        if (el && alignHeightToWidgets) {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            removeHeightAdjustment.current = useHeighAdjustment().setHeightAdjustment(el, alignHeightToWidgets)
        }
    }

    const renderSearchField = (
        placeholder: number,
        minLengthForSearch: number,
        getSuggestions?: AutosuggestFunction,
        tooltipText?: string,
        onSearch?: (query: string) => void
    ) => {
        return (
            <SearchField
                placeholder={translateText(placeholder)}
                minLengthForSearch={minLengthForSearch}
                getSuggestions={getSuggestions}
                onSearch={onSearch || handleSearch}
                activeCategory={activeCategory}
                tooltip={tooltipText}
                autofocusTimeout={props.autofocusTimeout}
                autofocus
                isOnIndustryDashboard
            />
        )
    }

    const renderVehiclePartsSearch = () => {
        const { vehicle } = workTask || {}

        return renderSearchField(
            998,
            getBundleParams().minimumSearchLength.vehicleSearch,
            vehicle ? (query) => Repositories.getProductGroupSuggestions(query, vehicle.tecDocTypeId) : undefined,
            undefined,
            (query) => {
                handleSearch(query)
            }
        )
    }

    const renderUniSearch = () => {
        return renderSearchField(194, getBundleParams().minimumSearchLength.universalSearch, (query) =>
            Repositories.getUniProductGroupSuggestions(query)
        )
    }

    const renderDirectSearch = () => {
        return (
            <DirectSearchField
                searchfilterResultCounts={[]}
                placeholder={translateText(138)}
                minLengthForSearch={getBundleParams().minimumSearchLength.directSearch}
                onSearch={handleSearch}
                activeCategory={activeCategory}
                tooltip={translateText(1046)}
                autofocusTimeout={props.autofocusTimeout}
                autofocus
                isOnIndustryDashboard
            />
        )
    }

    const handleTiresSearch = (query: string) => {
        channel("GLOBAL").publish("TYRES/SEARCH_TYRE_SIZE", { size: query })
    }

    const renderTiresSearch = () => {
        return renderSearchField(1828, getBundleParams().minimumSearchLength.tiresSearch, undefined, undefined, handleTiresSearch)
    }

    const renderCategorySearchField = () => {
        switch (activeCategory) {
            case "vehicleParts":
                return renderVehiclePartsSearch()
            case "universalParts":
                return renderUniSearch()
            case "directSearch":
                return renderDirectSearch()
            case "tiresSearch":
                return renderTiresSearch()
            default:
                return null
        }
    }

    const activeTree = trees[activeCategory]

    const hasPredictiveRequirements = !!workTask?.vehicle?.initialRegistration && !!workTask.vehicle.mileAge

    const { hideTipTooltip } = getBundleParams()

    const theme = getStyleTheme()

    const StyledButton = styled(Button)(() => ({
        margin: "0 3px 3px 0",
    }))

    const getSizeOrDefault = (): WidgetSizes => {
        const { size } = props
        const hasSizeFormat = /\d+x\d+/
        if (size && hasSizeFormat.test(size)) {
            return size as WidgetSizes
        }

        return "4x4"
    }

    const { breadCrumbState } = useBreadCrumbHandler(undefined, activeCategory, workTask, isOnIndustryDashboard)

    const isSecondLevel = useMemo(() => {
        return breadCrumbState.breadCrumbs.length > 0
    }, [breadCrumbState.breadCrumbs])

    const showTip = useMemo(
        () =>
            !hideTipTooltip &&
            searchtreeNodeVariant !== "fastClick" &&
            activeTree?.showTip &&
            activeCategory !== "directSearch" &&
            ((activeCategory === "vehicleParts" && workTask?.vehicle?.vehicleType !== VehicleType.CommercialVehicle) ||
                activeCategory === "universalParts"),
        [activeCategory, activeTree?.showTip, hideTipTooltip, workTask?.vehicle?.vehicleType]
    )

    return (
        <SxWidgetControl
            sx={sx}
            id="parts__widget"
            className={`tk-parts parts-widget ${props.className || ""} ${activeCategory}`}
            size={getSizeOrDefault()}
            height={props.height}
            collapsible={props.collapsible}
            iconName="catalog"
            title={`${props.title ? translate(props.title) : translate(993)}`}
            active
            forwardedRef={handleRef}
        >
            <Stack className="upper" justifyContent="space-between" style={!isOnIndustryDashboard ? { alignItems: "start" } : { display: "flex" }}>
                <Stack
                    className="search-and-navigation"
                    style={!isOnIndustryDashboard ? {} : { alignItems: "start", flexDirection: "column", marginBottom: "55px", minWidth: "545px" }}
                >
                    <CategorySelection
                        activeCategory={activeCategory}
                        onChangeCategory={handleSetActiveCategory}
                        disabledCategories={disabledCategories}
                        availableCategories={availableCategories}
                        directSearchText={translateText(props.directSearchText || 389)}
                        universalSearchText={translateText(!isOnIndustryDashboard ? props.universalSearchText || 1009 : 13836)}
                        tiresSearchText={translateText(props.tiresSearchText || 240)}
                        showPartsIndicatorIcon={hasPredictiveRequirements}
                        navigationLinks={navigationLinks}
                        hasMuiTabs={isOnIndustryDashboard}
                    />
                    {renderCategorySearchField()}
                </Stack>

                <Box sx={{ position: "absolute", right: "16px", top: "16px", textAlign: "end" }}>
                    {aiSwitch}
                    {showTip && (
                        <Box sx={{ display: "flex" }}>
                            <SearchtreeTip isSecondLevel={isSecondLevel} />
                        </Box>
                    )}
                </Box>
            </Stack>

            {/* we need 2 separate components here, otherwise the hooks within a component will block each other.
                in the ticket, Universal is loaded first and the hook is "loading" shortly afterwards, 
                the system switches to Vehicleparts and is blocked by the loading. This can happen in parallel due to the 2 components  
                https://jira.dvse.de/browse/NEXT-28079 */}
            {activeCategory === "universalParts" && (
                <SearchTreeWidget searchTreeType={activeCategory} isOnIndustryDashboard={isOnIndustryDashboard} />
            )}
            {activeCategory === "vehicleParts" && <SearchTreeWidget searchTreeType={activeCategory} isOnIndustryDashboard={isOnIndustryDashboard} />}

            {activeCategory === "predictive" && <PartsIndicator calculatorRoute={calculatorRoute} />}

            {activeCategory === "directSearch" && (
                // lower and wrapper are used in the styles project. On some traders a Background image will be added in the styles
                <div className="lower">
                    <div className="wrapper" />

                    {!!queryHistory.length && (
                        <div className="history">
                            <Typography variant="body3" component="div">
                                {translate(1073)}
                            </Typography>
                            {queryHistory.map((query) => {
                                return (
                                    <StyledButton
                                        variant={theme.overwrites?.toolkits?.parts?.widget?.history?.variant ?? "contained"}
                                        size="small"
                                        onClick={() => handleSearchFromHistory(query)}
                                        key={query}
                                    >
                                        {query}
                                    </StyledButton>
                                )
                            })}
                        </div>
                    )}
                </div>
            )}

            {activeCategory === "eurotaxSearch" && renderMicro?.("eurotax", "search", {})}
            {activeCategory === "tiresSearch" && getBundleParams().showTiresExtendedSearch && renderMicro?.("tyres", "tyres-search", {})}
            {activeCategory === "dtcSearch" && renderMicro?.("fast-dtc", "error-search", {})}
        </SxWidgetControl>
    )
}

export const CatalogWidget = connectComponent(Actions, withRouter(CatalogWidgetComponent))
