import { useEffect, useMemo, useRef } from "react"
import { useDispatch } from "react-redux"
import { Dialog } from "@tm/controls"
import { styled } from "@tm/components"
import { ECalcInputType } from "../../data/enums"
import { DialogContext } from "../../data/model"
import { MainActions } from "../main/business"

type Props = {
    selectedDialogContext?: DialogContext
}

export default function QuestionComponent(props: Props) {
    const dispatch = useDispatch()
    const { selectedDialogContext } = props
    const questionPromptRef = useRef<Dialog>(null)

    const confirmButton = useMemo(() => {
        const crossDeleteAccept = selectedDialogContext?.inputs.find((x) => x.type === ECalcInputType.CrossDeleteAccept)
        return crossDeleteAccept || selectedDialogContext?.inputs.find((x) => x.type === ECalcInputType.RemoveRepairTime)
    }, [selectedDialogContext?.inputs])

    const denyButton = useMemo(() => {
        return selectedDialogContext?.inputs.find((x) => x.type === ECalcInputType.CrossDeleteDeny)
    }, [selectedDialogContext?.inputs])

    useEffect(() => {
        questionPromptRef?.current?.show()
    }, [selectedDialogContext])

    if (!selectedDialogContext) {
        return null
    }

    const handleCloseModal = () => {
        if (!denyButton) {
            return
        }

        dispatch(MainActions.handleInputSubmit(denyButton))
    }

    const handleConfirmDialog = () => {
        if (!confirmButton) {
            return
        }

        dispatch(MainActions.handleInputSubmit(confirmButton))
    }

    if (confirmButton && denyButton) {
        return (
            <StyledDialog
                ref={questionPromptRef}
                skin="primary"
                iconName="question"
                text={selectedDialogContext.textContent}
                confirmButtonText={confirmButton.label}
                cancelButtonText={denyButton.label}
                onConfirm={handleConfirmDialog}
                onCancel={handleCloseModal}
                closeOnlyWithDialogInteraction
            />
        )
    }

    if (confirmButton) {
        return (
            <StyledDialog
                ref={questionPromptRef}
                skin="primary"
                iconName="info"
                text={selectedDialogContext.textContent}
                confirmButtonText={confirmButton.label}
                onConfirm={handleConfirmDialog}
                hideCloseButton
                closeOnlyWithDialogInteraction
            />
        )
    }

    return null
}

const StyledDialog = styled(Dialog)({
    "& .dialog-prompt__icon": {
        alignSelf: "center",
    },
    "& .dialog-prompt__title": {
        alignSelf: "center",
    },
})
