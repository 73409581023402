import { styled } from "@tm/components"
import { memo } from "react"
import SearchTreeV2 from "../../searchtreeV2/component"

export const SearchTree = memo(
    styled(SearchTreeV2)(({ theme }) => ({
        overflow: "visible",
        transform: "none",
        "> .MuiBox-root": {
            paddingLeft: theme.spacing(1),
        },
        ".MuiCollapse-root": {
            position: "absolute",
            height: "auto",
            background: "white",
            width: "100%",
            top: 46,
        },
    }))
)
