export enum ESearchArea {
    /// <summary>
    /// Manufacturer article Numbers
    /// </summary>
    EArtnr = 1,
    /// <summary>
    /// OE? References
    /// </summary>
    OE_Referenzen = 2,
    /// <summary>
    /// ?
    /// </summary>
    Gebrauchsnummern = 4,
    /// <summary>
    /// Replacement numbers
    /// </summary>
    Ersatznummern = 8,

    /// <summary>
    /// Standardwert für alle oben gelisteten
    /// </summary>
    Hersteller = 15,

    EAN = 16,
    Handelsreferenz = 96,

    /// <summary>
    /// nur für anwendungs / debugzwecke
    /// </summary>
    ALL = 2047,
}
