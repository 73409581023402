import { Stack, LicensePlate, styled } from "@tm/components"
import { VehicleSelectItem } from "./VehicleSelector"

export type Props = {
    vehicleSelectItem: VehicleSelectItem
}

const OverflowWrapper = styled("span")({
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
})

export default function VehicleSelectorViewCopmonent({ vehicleSelectItem }: Props) {
    const { vehicleDescription, vehiclePlate } = vehicleSelectItem
    return (
        <Stack direction="row" spacing={0.5}>
            {vehiclePlate && <LicensePlate value={vehiclePlate} size="extrasmall" />}
            <OverflowWrapper>{vehicleDescription}</OverflowWrapper>
        </Stack>
    )
}
