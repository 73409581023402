import { Box, Icon, IconButton, LoadingContainer, ScrollContainer, Typography, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import {
    AddCustomArticleItemsRequest,
    CreateCustomArticleRequest,
    CustomArticle,
    EditCustomArticleItemRequest,
    IMicros,
    VatRate,
    VatRateType,
} from "@tm/models"
import { useState } from "react"
import { useUser, useWorkTask } from "@tm/context-distribution"
import ConsumableItem from "./ConsumableItem"
import ConsumableItemAdd from "./ConsumableItemAdd"
import { addCustomArticleItems, editCustomArticleItem } from "../../data/repositories"
import { useArticleNotes } from "../../hooks/useArticleNotes"
import { getBundleParams } from "../../utils"
import { useWorkTaskBasketState } from "../../../../basket/src/hooks/basketState/useWorkTaskBasketState"

type Props = IMicros["parts"]["consumable-replace"]

export default function ConsumableReplace({ title, category, consumables, onConsumableSelect, onConsumableSaved }: Props) {
    const user = useUser()
    const { workTaskId } = useWorkTask() ?? {}
    const { translateText } = useLocalization()
    const { enableArticleNotes } = getBundleParams()
    const [selectedConsumable, setSelectedConsumable] = useState<CustomArticle | undefined>(undefined)
    const [addNewCustomArticle, setAddNewCustomArticle] = useState(false)
    const [disableAddNewButton, setDisableSaveButton] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const favoriteItem = consumables.find((x) => x.isDefaultForCategory)
    const showPurchasePrice = user.userSettings?.showPurchasePrice

    if (!workTaskId) {
        return null
    }

    const { costEstimation: costEstimationState } = useWorkTaskBasketState(workTaskId)
    const { costEstimation } = costEstimationState.state

    const getArticleIds = consumables.map((x) => x.id).filter((x) => x !== undefined)
    const { articleNotes, saveNote } = useArticleNotes(enableArticleNotes ? (getArticleIds as string[]) : [])

    const handleConsumableSelect = (item: CustomArticle) => {
        onConsumableSelect?.(item)
        setSelectedConsumable(item)
    }

    const handleSaveDefaultForCategory = async (item: CustomArticle) => {
        setIsLoading(true)

        if (!favoriteItem) {
            const addFavoriteItemRequest: EditCustomArticleItemRequest = {
                customArticleItemToEdit: {
                    ...item,
                    isDefaultForCategory: true,
                },
            }

            await editCustomArticleItem(addFavoriteItemRequest)
        } else if (favoriteItem && favoriteItem.id === item.id) {
            const removeFavoriteItemRequest: EditCustomArticleItemRequest = {
                customArticleItemToEdit: {
                    ...favoriteItem,
                    isDefaultForCategory: false,
                },
            }
            await editCustomArticleItem(removeFavoriteItemRequest)
        } else if (favoriteItem && favoriteItem.id !== item.id) {
            const removeFavoriteItemRequest: EditCustomArticleItemRequest = {
                customArticleItemToEdit: {
                    ...favoriteItem,
                    isDefaultForCategory: false,
                },
            }
            await editCustomArticleItem(removeFavoriteItemRequest)

            const addFavoriteItemRequest: EditCustomArticleItemRequest = {
                customArticleItemToEdit: {
                    ...item,
                    isDefaultForCategory: true,
                },
            }

            await editCustomArticleItem(addFavoriteItemRequest)
        }

        onConsumableSaved?.({ ...item, isDefaultForCategory: true })
        setIsLoading(false)
    }

    async function handleConsumableSave(newConsumable: CreateCustomArticleRequest | undefined) {
        if (!newConsumable) {
            return
        }

        setIsLoading(true)
        setDisableSaveButton(true)
        const { articleNumber, description, purchasePrice, retailPrice, memo, isDefaultForCategory } = newConsumable
        const defaultVatRate = costEstimation?.defaultPartVatRates.find((vatRate) => vatRate.isSelected) ?? costEstimation?.defaultPartVatRates[0]
        const vatRate: VatRate = {
            vatType: defaultVatRate?.vatType ?? VatRateType.WithoutRate,
            vatRate: defaultVatRate?.vatValue ?? 0,
        }

        if (isDefaultForCategory) {
            const consumableToRemoveFromFavorites = consumables.find((x) => x.isDefaultForCategory)
            if (consumableToRemoveFromFavorites) {
                const editCustomArticleItemRequest: EditCustomArticleItemRequest = {
                    customArticleItemToEdit: {
                        ...consumableToRemoveFromFavorites,
                        isDefaultForCategory: false,
                    },
                    priceVatMode: costEstimation?.costEstimationOptions?.priceVatMode,
                }
                await editCustomArticleItem(editCustomArticleItemRequest)
            }
        }

        const request: AddCustomArticleItemsRequest = {
            customArticleItemsToAdd: [
                {
                    articleNumber,
                    description,
                    vatRate,
                    category,
                    purchasePrice,
                    retailPrice,
                    isDefaultForCategory: isDefaultForCategory ?? false,
                },
            ],
            priceVatMode: costEstimation?.costEstimationOptions?.priceVatMode,
        }

        const response = await addCustomArticleItems(request)

        if (memo && response) {
            await saveNote(response.addedCustomArticleItems[0].id, memo)
        }

        if (response) {
            onConsumableSaved?.(response.addedCustomArticleItems[0])
            toggleShowAddCustomArticle()
        }

        setDisableSaveButton(false)
        setIsLoading(false)
    }

    function toggleShowAddCustomArticle() {
        setAddNewCustomArticle((prev) => !prev)
    }

    return (
        <LoadingContainer isLoading={isLoading}>
            <ScrollContainer display="flex" flexDirection="column" flex="1">
                <Typography variant="h2" gutterBottom>
                    {title}
                </Typography>
                <StyledBox>
                    <Typography variant="body3" gutterBottom>
                        {translateText(87)}
                    </Typography>
                    <Typography variant="body3" gutterBottom>
                        {translateText(25)}
                    </Typography>
                    <Typography variant="body3" gutterBottom sx={{ visibility: showPurchasePrice ? "visible" : "hidden" }}>
                        {translateText(1132)}
                    </Typography>
                    <Typography variant="body3" gutterBottom>
                        {translateText(1257)}
                    </Typography>
                    <Typography variant="body3" gutterBottom />
                    <Typography variant="body3" gutterBottom />
                </StyledBox>

                {consumables.map((item, idx) => {
                    const note = articleNotes.find((articleNote) => articleNote.noteId === item.id)
                    return (
                        <ConsumableItem
                            key={idx}
                            item={item}
                            handleConsumableSelect={handleConsumableSelect}
                            handleSaveDefaultForCategory={handleSaveDefaultForCategory}
                            isSelected={selectedConsumable === item}
                            note={note?.text}
                        />
                    )
                })}
                {addNewCustomArticle && (
                    <ConsumableItemAdd
                        favoriteItem={favoriteItem}
                        saveDisabled={disableAddNewButton}
                        handleConsumableDiscard={toggleShowAddCustomArticle}
                        handleConsumableSave={handleConsumableSave}
                    />
                )}
                <StyledAddButton size="large" onClick={toggleShowAddCustomArticle}>
                    <Icon name="plus" />
                </StyledAddButton>
            </ScrollContainer>
        </LoadingContainer>
    )
}

const StyledBox = styled(Box)({
    display: "grid",
    gridTemplateColumns: "1fr 2fr 1fr 1fr 1fr 1fr",
    padding: "0 1em",
})

const StyledAddButton = styled(IconButton)(({ theme }) => ({
    alignSelf: "center",
    backgroundColor: theme.palette.grey[300],
    "&:hover": {
        backgroundColor: `${theme.colors?.primary} !important`,
    },
}))
