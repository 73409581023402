import { Switch } from "@tm/controls"
import { useStyle } from "@tm/context-distribution"
import { useActions } from "@tm/morpheus"
import { MainActions } from "../../main/business"
import { CalcInput } from "../../../data/model"

type Props = {
    item: CalcInput
    disableTitleLabel?: boolean
    onChangeCallBack?: (data: any) => void
}

const CalcInputCheck: React.FC<Props> = (props) => {
    const actions = useActions(MainActions, "handleInputSubmit")

    function handleChange() {
        const { item, onChangeCallBack } = props
        actions.handleInputSubmit({ ...item, value: !item.value })
        onChangeCallBack?.(!item.value)
    }

    function enableSwitch(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if (e) {
            e.stopPropagation()
            e.preventDefault()
        }

        handleChange()
    }

    const { item, disableTitleLabel } = props

    const switchDisabled = item.value === undefined
    const switchStatus = item.value

    return (
        <div className={switchDisabled ? style.switchNoStatus : ""} onClick={switchDisabled ? enableSwitch : undefined}>
            <Switch
                label={item.label || (disableTitleLabel ? "" : item.title)}
                onChange={handleChange}
                disabled={switchDisabled}
                status={switchStatus}
            />
        </div>
    )
}

const style = useStyle({
    switchNoStatus: {
        $nest: {
            ".switch__content:before": {
                display: "none",
            },
            ".switch__icon": {
                visibility: "hidden",
            },
            ".switch__content .text": {
                visibility: "hidden",
            },
        },
    },
})(CalcInputCheck)

export default CalcInputCheck
