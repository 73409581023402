import { ChangeEvent, useMemo, useState } from "react"
import { Alert, Box, Button, Icon, ListItemIcon, styled } from "@tm/components"
import { CreateCustomArticleRequest, CustomArticle } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { NumberField, TextField } from "@tm/controls"
import { useUser } from "@tm/context-distribution"
import { getCurrencyFromUserContext } from "@tm/utils"
import { StyledListItem } from "./shared"

type Props = {
    handleConsumableSave: (selected: CreateCustomArticleRequest | undefined) => void
    handleConsumableDiscard: () => void
    saveDisabled?: boolean
    favoriteItem?: CustomArticle
}

export default function ConsumableItemAdd({ handleConsumableSave, handleConsumableDiscard, saveDisabled, favoriteItem }: Props) {
    const { translateText } = useLocalization()
    const user = useUser()
    const currencyString = getCurrencyFromUserContext(user?.userContext)
    const [description, setDescription] = useState<string | undefined>("")
    const [articleNumber, setArticleNumber] = useState<string | undefined>("")
    const [retailPrice, setRetailPricePrice] = useState<number | undefined>(0)
    const [purchasePrice, setPurchasePrice] = useState<number | undefined>(0)
    const [note, setNote] = useState<string | undefined>("")
    const [isDefaultForCategory, setIsDefaultForCategory] = useState<boolean>(false)
    const articleSaveDisabled = !articleNumber || !description || !purchasePrice || !retailPrice || saveDisabled

    const showAlert = useMemo(() => {
        return favoriteItem && isDefaultForCategory
    }, [favoriteItem, isDefaultForCategory])

    function handleArticleNumberChange(newArticleNumber: string | undefined) {
        setArticleNumber(newArticleNumber || "")
    }

    function handleDescriptionChange(newDescription: string | undefined) {
        setDescription(newDescription || "")
    }

    function handlePurchasePriceChange(value: number | undefined) {
        setPurchasePrice(value || 0)
    }

    function handleRetailPricePriceChange(value: number | undefined) {
        setRetailPricePrice(value || 0)
    }

    function handleToggleDefaultForCategory() {
        setIsDefaultForCategory((prev) => !prev)
    }

    function handleNoteChange(newNote: string | undefined) {
        setNote(newNote || "")
    }

    function handleSave() {
        if (!description) {
            return
        }

        const customArticle: CreateCustomArticleRequest = {
            articleNumber,
            description,
            purchasePrice,
            retailPrice,
            memo: note,
            isDefaultForCategory,
        }

        handleConsumableSave(customArticle)
    }

    return (
        <>
            <StyledListItem
                sx={{ "&:hover": { border: "none" }, display: "flex", flexDirection: "column", gap: ".5em", padding: ".5em" }}
                disablePadding
            >
                <Box alignItems="center" width="100%" display="grid" gridTemplateColumns="1fr 2fr 1fr 1fr 1fr 1fr">
                    <StyledTextField size="s" value={articleNumber} onChange={handleArticleNumberChange} floatingLabel label={translateText(87)} />
                    <StyledTextField size="s" value={description} onChange={handleDescriptionChange} floatingLabel label={translateText(25)} />
                    <StyledNumberField
                        value={purchasePrice}
                        onChangeConfirm={handlePurchasePriceChange}
                        onChange={handlePurchasePriceChange}
                        minimum={0.0}
                        maximum={9999999}
                        nullable
                        stepSize={0.01}
                        floatingLabel
                        label={`${translateText(1132)} ${currencyString}`}
                    />
                    <StyledNumberField
                        value={retailPrice}
                        onChangeConfirm={handleRetailPricePriceChange}
                        onChange={handleRetailPricePriceChange}
                        minimum={0.0}
                        maximum={9999999}
                        nullable
                        stepSize={0.01}
                        floatingLabel
                        label={`${translateText(1257)} ${currencyString}`}
                    />
                    <ListItemIcon onClick={() => handleToggleDefaultForCategory()}>
                        <Icon name={isDefaultForCategory ? "filled-star" : "vote-star"} width="1.5em" height="1.5em" />
                    </ListItemIcon>
                    <Box display="flex" justifyContent="flex-end" gap="0.5em">
                        <Button size="small" onClick={() => handleConsumableDiscard()}>
                            <Icon name="close" />
                        </Button>
                        <Button disabled={articleSaveDisabled} size="small" color="success" onClick={() => handleSave()}>
                            <Icon name="check" />
                        </Button>
                    </Box>
                </Box>
                <StyledNoteField size="s" value={note} onChange={handleNoteChange} floatingLabel label={translateText(12874)} />
            </StyledListItem>
            {showAlert && <Alert size="small" severity="error" title={translateText(13730)} variant="outlined" style={{ marginBottom: ".5em" }} />}
        </>
    )
}

const StyledNumberField = styled(NumberField)({
    margin: "0",
    marginRight: "0.5em",
})

const StyledTextField = styled(TextField)({
    margin: "0",
    marginRight: "1em",
})

const StyledNoteField = styled(TextField)({
    margin: "0",
    width: "50%",
    alignSelf: "flex-start",
})
