import { useSelector } from "react-redux"
import Categories from "./components/categories"
import VehicleDefinition from "./components/vehicleDefinition"
import Header from "./components/header"
import { fastCalculatorSelector } from "../main/business"
import { ECalcState } from "../../data/enums"
import SelectionDialog from "../_shared/modals/SelectionDialog"

export default function Summary() {
    const { selectedOverlayCalcState } = useSelector(fastCalculatorSelector)
    const interactionDialog = selectedOverlayCalcState && ECalcState.OverlayCalcStates & selectedOverlayCalcState?.type

    return (
        <div className="fastcalc-summary">
            <Header />
            <VehicleDefinition />
            {interactionDialog && <SelectionDialog calcState={selectedOverlayCalcState} />}
            <Categories maxSelections={6} />
        </div>
    )
}
