export enum ECalcStateButtonIcon {
    None = 0,
    Basket = 1,
    Bin = 2,
    Resolved = 3,
    Replace = 4,
    DropDownArrow = 5,
    Add = 6,
    TMSuggestion = 7,
    NoFavorite = 8,
    IsFavorite = 9,
    ArtList = 10,
    UniSearch = 11,
    OeSearch = 12,
    FastTD = 13,
}
