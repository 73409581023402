import { Fragment, MouseEvent, Suspense } from "react"
import { Icon, LicensePlate, styled, Typography } from "@tm/components"
import { useCountryCodeToLicensePlate, useUser, useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { CarModelDetailsResponse, channel, ECounterType, RegistrationNoType, Vehicle, VehicleType } from "@tm/models"
import {
    encodeUniqueId,
    getVehicleRegistrationNoLabelTextIds,
    RouteComponentProps,
    TmaHelper,
    useAvailableVehicleSearchOptions,
    useKeyValue,
    useModelDetails,
    useVehicleModelMemoInfo,
    withRouter,
} from "@tm/utils"
import { Link } from "react-router-dom"
import { isSpecialRegNoType } from "../../helpers"
import { constructionYearToString } from "../../helpers/construction-year"
import { getVehicleDetailsUrl } from "../../helpers/routing"
import { getBundleParams } from "../../utils"

type Props = RouteComponentProps<any> & {}

function Wrapper(props: Props) {
    return (
        <Suspense fallback={null}>
            <VehicleInfoComponent {...props} />
        </Suspense>
    )
}

const AtuMemoIcon = styled(Icon)(({ theme }) => ({
    width: 40,
    minWidth: 40,
    cursor: "pointer",
    marginRight: theme.spacing(),
}))

const StyledInfoContainer = styled("div")(({ theme }) => ({
    flex: 1,
    display: "flex",
    overflow: "hidden",
    alignItems: "center",
    backgroundColor: "#f6f6f6",
    borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
    boxShadow: `0 -1px 8px rgba(0,0,0,0.2), 0 -1px 1px rgba(0,0,0,0.2)`,
    padding: theme.spacing(1),
    ".details-link": {
        textDecoration: "none",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        "&:visited": {
            color: "inherit",
        },
    },
    ".info-content": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    ".vehicle-icon": {
        marginRight: theme.spacing(),
    },
    ".copyable": {
        userSelect: "all",
    },
}))

function VehicleInfoComponent(props: Props) {
    const { translateText, translate, number, date } = useLocalization()
    const { userContext } = useUser() ?? {}
    const { workTask, attachToWorkTask } = useWorkTask() ?? {}
    const { vehicle } = workTask ?? {}
    const { memoToolEnabled } = getBundleParams()

    const { vehicleHasMemoInfo } = useVehicleModelMemoInfo(
        {
            manufacturerId: vehicle?.tecDocManufacturerId,
            modelSeriesId: vehicle?.tecDocModelId,
            tecdoc: vehicle?.tecDocTypeId,
            engineCode: vehicle?.engineCode,
        },
        vehicle?.id,
        memoToolEnabled
    )

    const modelDetailsResponse = useModelDetails({ vehicleType: vehicle?.vehicleType, modelId: vehicle?.tecDocTypeId })
    const { availableVehicleSearches } = useAvailableVehicleSearchOptions()
    const { plateCode } = useCountryCodeToLicensePlate(vehicle?.countryCode)

    const [savedCompletedSteps] = useKeyValue({
        key: `MEMOTOOL_VEHICLEMEMOINFO_COMPLETEDSTEPS_${vehicle?.id}`,
        ownedByRepairShop: false,
    })

    if (!workTask || !modelDetailsResponse?.modelDetails) {
        return null
    }

    const { modelDetails } = modelDetailsResponse as CarModelDetailsResponse

    if (!vehicle) {
        return null
    }

    const handleSelectEngineCode = (vehicle: Vehicle, code: string | undefined, e: MouseEvent<HTMLElement>) => {
        e.preventDefault()
        e.stopPropagation()
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListStatusBarFilter, !!code)
        attachToWorkTask?.({ vehicle: { ...vehicle, engineCode: code } })
    }

    const renderRegistrationNumber = () => {
        const { registrationNo, registrationTypeId } = vehicle
        const { registrationNos = [] } = modelDetails

        const defaultRegNoType = availableVehicleSearches?.defaultVehicleLookup?.lookupTypeId
        let regNoType = registrationTypeId
        let regNos
        let hasMore = false

        // If the vehicle has a registration number render it
        if (registrationNo) {
            regNos = [registrationNo]

            // If the vehicle has a regNoType set use it for the label ?? Otherwise assume it's a KBA number stored at the vehicle
            regNoType = registrationTypeId ?? RegistrationNoType.Kba

            if (isSpecialRegNoType(regNoType)) {
                regNoType = defaultRegNoType
            }
        }
        // If not render the ones supplied by the service
        else if (registrationNos.length) {
            regNoType = defaultRegNoType

            // MLE - 09.03.2020: Currently only KBA numbers should be shown
            if (regNoType !== RegistrationNoType.Kba) {
                return
            }

            // Show only the first 5 numbers
            const numbersToShow = 5
            hasMore = registrationNos.length > numbersToShow

            regNos = registrationNos.slice(0, numbersToShow) // .join(", ") + ( numbersToShow ? ", ..." : "")
        }

        const labelTextIds = getVehicleRegistrationNoLabelTextIds(regNoType)
        const labelTextId = labelTextIds?.labelShort ?? labelTextIds?.label

        if (labelTextId === undefined) {
            return
        }

        return (
            <>
                <strong>{translate(labelTextId)}: </strong>
                {regNos?.map((regNo, index) => (
                    <Fragment key={index}>
                        <span className="copyable">{regNo}</span>
                        {index < regNos.length - 1 && ", "}
                    </Fragment>
                ))}
                {hasMore && ", ..."}
                {" | "}
            </>
        )
    }

    const renderEngineCode = (code: string, idx: number, engineCodes: Array<string>) => {
        let { engineCode } = vehicle

        code = code.trim()
        if (engineCode) {
            engineCode = engineCode.trim()
        }

        let className = "engine-codes__code "
        if (code === engineCode) {
            className += "is-selected "
        }

        return (
            <Fragment key={code}>
                <span
                    className={className}
                    onClick={(e) => handleSelectEngineCode(vehicle, code !== engineCode ? code : undefined, e)}
                    title={code !== engineCode ? translateText(1546) : translateText(1547)}
                >
                    {code}
                </span>
                {idx < engineCodes.length - 1 ? "," : false}
            </Fragment>
        )
    }

    const renderPowerKwAndHp = () => {
        if (vehicle?.vehicleType === VehicleType.CommercialVehicle) {
            let hp: string | undefined
            let kw: string | undefined

            if (modelDetails.enginePowerHpFrom) {
                hp = `${modelDetails.enginePowerHpFrom}`
            }
            if (modelDetails.enginePowerHpTo) {
                hp += ` - ${modelDetails.enginePowerHpTo}`
            }
            if (modelDetails.enginePowerKwFrom) {
                kw = `${modelDetails.enginePowerKwFrom}`
            }
            if (modelDetails.enginePowerKwTo) {
                kw += ` - ${modelDetails.enginePowerKwTo}`
            }

            return `${kw}/${hp}`
        }

        return `${modelDetails.enginePowerKw}/${modelDetails.enginePowerHp}`
    }

    const handleShowMemoInfo = () => {
        const completedSteps = savedCompletedSteps ? (JSON.parse(savedCompletedSteps) as Record<string, string[]>) : {}

        channel("GLOBAL").publish("MEMOTOOL/OPEN_VEHICLE_MEMO_DIALOG", { completedSteps })
    }

    const detailsUrl = getVehicleDetailsUrl(props.match.params, { vehicleType: vehicle.vehicleType, vehicleId: encodeUniqueId(vehicle.id) })

    return (
        <StyledInfoContainer>
            {vehicleHasMemoInfo && <AtuMemoIcon name="atu-logo-filled" onClick={handleShowMemoInfo} />}

            <Link className="details-link" to={detailsUrl}>
                <Icon name="car" className="vehicle-icon" />
                {vehicle.plateId && <LicensePlate style={{ marginRight: 5 }} countryCode={plateCode} value={vehicle.plateId} />}
                <Typography
                    className="info-content"
                    onClick={() => {
                        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListStatusBarFilter)
                    }}
                >
                    {vehicle.manufacturer} {vehicle.modelSeries} {vehicle.model}
                    {" | "}
                    {!!vehicle.vin && (
                        <>
                            <strong>{translateText(101)}: </strong>
                            <span className="copyable">{`${vehicle.vin} `}</span>
                            {" | "}
                        </>
                    )}
                    {!!vehicle.initialRegistration && (
                        <>
                            <strong>{translateText(1486)}: </strong>
                            {date(vehicle.initialRegistration, "d")}
                            {" | "}
                        </>
                    )}
                    <strong>{translateText(20)}: </strong>
                    {renderPowerKwAndHp()}
                    {" | "}
                    {modelDetails.engineCapacityCcm && (
                        <>
                            <strong>{translateText(331)}: </strong>
                            {modelDetails.engineCapacityCcm}
                            {" | "}
                        </>
                    )}
                    <strong>{translateText(1489)}: </strong>
                    <span className="engine-codes">{(modelDetails.engineCodes || []).map(renderEngineCode)}</span>
                    {" | "}
                    {renderRegistrationNumber()}
                    {modelDetails.cylinderCount && (
                        <>
                            <strong>{translateText(1488)}: </strong>
                            {modelDetails.cylinderCount}
                            {" | "}
                        </>
                    )}
                    <strong>{translateText(1487)}: </strong>
                    {constructionYearToString(modelDetails.constructionYearFrom)} - {constructionYearToString(modelDetails.constructionYearTo)}
                    {" | "}
                    {vehicle.mileAge !== undefined && (
                        <>
                            <strong>{translateText(1491)}: </strong>
                            {number(vehicle.mileAge, 0)}
                        </>
                    )}
                </Typography>
            </Link>
        </StyledInfoContainer>
    )
}

export default withRouter(Wrapper)
