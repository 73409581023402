import { channel, RequestArticleListPayload } from "@tm/models"
import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"
import { shouldUseNewModal } from "./helpers/newPartslist"

export default function handleOpenArticleListByGenarts(this: PostMessageControllerComponent, data: PostMessageRequest) {
    const { openArticleListByGenarts } = data

    if (!openArticleListByGenarts?.genArtIds) {
        return
    }

    this.checkAndCreateWorktask()

    let genArtIds = (openArticleListByGenarts.mainGenArtIds || []).filter((x) => !!x)

    if (!genArtIds.length) {
        genArtIds = openArticleListByGenarts.genArtIds
    }

    const request: RequestArticleListPayload = {
        productGroups: {
            ids: genArtIds,
        },
        fittingPosition: openArticleListByGenarts.fittingPosition,
        equipmentCode: openArticleListByGenarts.equipmentCode,
        inModal: true,
        isModal: openArticleListByGenarts.isModal,
        useNewModal: shouldUseNewModal(data.sourceId),
    }

    channel("WORKTASK").publish("PARTS/REQUEST_LIST", request)
}
