import { Box, Loader, Modal, Typography, styled, useMediaQuery, useTheme } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useMemo, useState } from "react"
import { CustomArticle, IMicros } from "@tm/models"
import { useMicro } from "@tm/morpheus"
import { Button, Scrollbar } from "@tm/controls"
import { useShowCustomArticleItems } from "../../../data/helpers/useShowCustomArticleItems"
import { useGetCustomArticlesCategory } from "../../../data/helpers/useGetCustomArticlesCategory"
import { CalcConsumable } from "../../../data/model"

type Props = {
    open: boolean
    onClose(open: number | null): void
    consumable: CalcConsumable
    replaceConsumable: (customArticle: CustomArticle, consumable: CalcConsumable) => void
}

export default function ReplaceConsumableModal({ open, onClose, consumable, replaceConsumable }: Props) {
    const { translateText } = useLocalization()
    const { renderMicro } = useMicro<IMicros>()
    const [selectedConsumable, setSelectedConsumable] = useState<CustomArticle | undefined>(undefined)
    const muitheme = useTheme()
    const matchesLg = useMediaQuery(muitheme.breakpoints.up("lg"))
    const matchesXl = useMediaQuery(muitheme.breakpoints.up("xl"))
    const percentWidth = useMemo(() => {
        if (matchesXl) {
            return 55
        }

        if (matchesLg) {
            return 70
        }

        return 85
    }, [matchesLg, matchesXl])

    const { categoryTextId: genartTextId, isError: customCategoryError } = useGetCustomArticlesCategory(consumable.genArtNr, true)
    const request: Parameters<typeof useShowCustomArticleItems>[0] = {
        categoriesFilter: genartTextId,
    }
    const { data: { pages } = {}, isError, refetch: refetchCustomArticles } = useShowCustomArticleItems(request)
    const customArticles = useMemo(() => pages?.flatMap((x) => x.data) ?? [], [pages])

    const handleClose = () => {
        onClose(null)
    }

    function onConsumableSelect(selected: CustomArticle) {
        setSelectedConsumable(selected)
    }

    function onConsumableSaved(savedConsumable?: CustomArticle) {
        if (savedConsumable?.isDefaultForCategory) {
            replaceConsumable(savedConsumable, consumable)
        }

        refetchCustomArticles()
    }

    function onConsumableConfirm() {
        if (selectedConsumable) {
            replaceConsumable(selectedConsumable, consumable)
            handleClose()
        }
    }

    let content = (
        <Box width="100%" height="100%" display="flex" alignItems="center" justifyItems="center">
            <Loader />
        </Box>
    )

    if (isError || customCategoryError) {
        content = <Typography>{translateText(12775)}</Typography>
    }

    if (customArticles) {
        content = (
            <>
                {renderMicro("parts", "consumable-replace", {
                    title: consumable.genArtLabel ?? translateText(616),
                    category: genartTextId,
                    consumables: customArticles,
                    onConsumableSelect,
                    onConsumableSaved,
                })}
            </>
        )
    }

    if (open) {
        return (
            <Modal
                keepMounted
                open={open}
                hideCloseButton
                percentWidth={percentWidth}
                sx={{ "&&  .MuiPaper-root>.MuiBox-root": { flex: 1, display: "flex" } }}
            >
                <Scrollbar>
                    <ContentFlex>{content}</ContentFlex>
                </Scrollbar>
                <StyledButtonWrapper>
                    <Button size="xl" icon="close" onClick={handleClose} />
                    <Button size="xl" skin="success" icon="check" onClick={onConsumableConfirm} />
                </StyledButtonWrapper>
            </Modal>
        )
    }

    return null
}

const StyledButtonWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.margin?.s ?? "0.5em",
}))

const ContentFlex = styled(Box)({
    padding: "2em",
    flex: 1,
    height: "100%",
})
