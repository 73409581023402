import { useLocalization } from "@tm/localization"
import { Box, Typography } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { getCurrencyFromUserContext } from "@tm/utils"
import SpareParts from "./SpareParts"
import SummaryWorks from "./SummaryWorks"

export default function CalculationDetails() {
    const { translateText, currency } = useLocalization()
    const { userContext } = useUser()
    const currencyString = getCurrencyFromUserContext(userContext)

    return (
        <>
            <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", paddingRight: "1em" }}>
                <Typography variant="h2">{translateText(13696)}</Typography>
                {/* Will be needed later */}
                {/* <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                    {translateText(13661)}
                    <Box sx={{ fontWeight: "bold", marginLeft: "1em" }}>{currency(totalPrice, currencyString)}</Box>
                </Box> */}
            </Box>
            <SpareParts />
            <SummaryWorks />
        </>
    )
}
