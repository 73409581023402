import { useEffect, useMemo, useRef } from "react"
import SignaturePad from "signature_pad"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Button } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { VehicleAndCustomer } from "."
import MuiCustomModal from "./modal/MuiCustomModal"

type Props = {
    onClose: (display: boolean) => void
    onSave: (signature: string) => void
    headLineText: string
    headerText: string
    open: boolean
    savedSignature: string | undefined
}

export default function CustomModal({ onClose, onSave, headerText, headLineText, open, savedSignature }: Props) {
    let signaturePad: SignaturePad
    let canvas = document.getElementById("signature-pad") as HTMLCanvasElement

    const { translateText } = useLocalization()
    const divWraperRef = useRef<HTMLDivElement>(null)
    const divHeaderWraperRef = useRef<HTMLDivElement>(null)
    const style = useMemo(() => getStyle(), [])

    useEffect(() => {
        canvas = document.getElementById("signature-pad") as HTMLCanvasElement
        if (canvas) {
            init()
        }
    })

    const init = () => {
        if (divWraperRef?.current && divHeaderWraperRef?.current) {
            canvas.width = divWraperRef.current.offsetWidth
            canvas.height = Math.min(canvas.width * 1.5, divWraperRef.current.offsetHeight - divHeaderWraperRef.current.offsetHeight - 20)
        }

        signaturePad = new SignaturePad(canvas, { backgroundColor: "rgb(255, 255, 255)" })
        if (savedSignature) {
            signaturePad.fromDataURL(savedSignature)
        }
    }

    const handleCloseModal = () => {
        onClose(false)
    }

    const handleSubmitSignature = () => {
        onSave(signaturePad.toDataURL())
    }

    const handleDeleteSignature = () => {
        signaturePad?.clear()
    }

    const handleUndoSignature = () => {
        const data = signaturePad?.toData()
        if (data) {
            data.pop()
            signaturePad.fromData(data)
        }
    }

    return (
        <MuiCustomModal open={open} onOutsideClick={handleCloseModal} handleOkButton={handleSubmitSignature}>
            <div className={style.wraperDiv} ref={divWraperRef}>
                <div ref={divHeaderWraperRef}>
                    <VehicleAndCustomer headerText={headLineText} showServiceText headerCustomer={headerText} />
                    <div className={style.canvasTitle}>{`${new Date().toLocaleDateString()} ${translateText(12972)}:`}</div>
                </div>
                <div className={style.contentWraper}>
                    <div className={style.canvasWrapper}>
                        <canvas id="signature-pad" className={style.canvasContent} width={300} height={300} />
                        <Button size="xl" className={style.undoButton} icon="synchronize" onClick={handleDeleteSignature} />
                    </div>
                </div>
            </div>
        </MuiCustomModal>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        wraperDiv: {
            width: "100%",
            height: "100%",
        },
        contentWraper: {
            userSelect: "none",
            display: "flex",
            justifyContent: "flex-end",
        },
        canvasWrapper: {
            userSelect: "none",
            display: "flex",
            position: "relative",
        },
        canvasContent: {
            borderBottom: "2px solid",
            borderColor: theme.colors.primary,
        },
        undoButton: {
            position: "absolute",
            top: "1em",
            right: "1em",
        },
        canvasTitle: {
            opacity: theme.opacity.secondary,
            marginTop: theme.margin.xl,
            marginBottom: theme.margin.m,
        },
    })(CustomModal)
}
