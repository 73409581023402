import { Box, Icon, SearchButton, Switch, Typography } from "@tm/components"
import { getStyleTheme } from "@tm/context-distribution"
import { InputGroup, SearchField, SelectionListItem } from "@tm/controls"
import { LocalizationProps, withLocalization } from "@tm/localization"
import { CategoryType, SearchFilters } from "@tm/models"
import { RouteComponentProps, bindSpecialReactMethods, classes, parseQueryString, registerOutsideClick, validateField, withRouter } from "@tm/utils"
import { PureComponent, createRef } from "react"
import { style as tStyle } from "typestyle"

import { SearchFilter } from "../../../data/model"
import Hint from "./components/hint"
import QueryHistory from "./components/query-history"
import { SearchFiltersComponent } from "./components/search-filter-component"

const ControlsConfig = (window as any).__NEXT_CONTROLS__
const ShowHintAsTooltip = ControlsConfig?.searchfield?.showHintAsTooltip

type RouteParams = {
    query: string
    searchFilter: string
}

export type Props = LocalizationProps &
    RouteComponentProps<RouteParams> & {
        activeCategory?: CategoryType
        autofocus?: boolean
        autofocusTimeout?: number
        extendedSearch?: boolean
        hint?: string
        minLengthForSearch: number
        placeholder?: string
        query?: string
        searchfilterResultCounts: SearchFilter[]
        tooltip?: string
        isOnIndustryDashboard?: boolean

        onFocusChange?(isFocused: boolean): void
        onSearch(query: string, searchFilters: SearchFilters, extendedSearch?: boolean): void
        onSearchFilterChanged?(selected: Array<SelectionListItem>): void
    }

export type State = {
    extendedSearch?: boolean
    hint?: string
    showPopUp: boolean
    query?: string
    searchFilters: SearchFilters
}

function getStyle() {
    const theme = getStyleTheme()

    return {
        searchoptions: tStyle({
            position: "absolute",
            background: theme.colors.light,
            zIndex: 5,
            border: `1px solid ${theme.overwrites?.toolkits?.parts?.widget?.directsearch?.options?.border?.color || theme.colors.primary}`,
            borderBottomLeftRadius: theme.box.radius,
            borderBottomRightRadius: theme.box.radius,
            $nest: {
                ".layout--dashboard-option-two .multiwidget &": {
                    borderColor: theme.colors.highlight,
                    marginTop: "-1px",
                },
            },
            minWidth: "500px",
            width: "100%",
        }),
        gap: tStyle({
            padding: theme.margin.m,
        }),
        outsideClickWrapper: tStyle({ position: "relative" }),
    }
}

class DirectSearchField extends PureComponent<Props, State> {
    private searchFieldRef = createRef<SearchField>()

    private unregisterOutsideClick: undefined | (() => void)

    constructor(props: Props) {
        super(props)
        bindSpecialReactMethods(this)

        this.state = {
            hint: this.props.hint,
            showPopUp: false,
            searchFilters: this.syncWithUrl(),
            extendedSearch: (this.props.query?.indexOf("*") || 0) > 0,
        }
    }

    syncWithUrl = () => {
        const { searchFilter } = parseQueryString(window.location.search)
        let searchFilters: SearchFilters = SearchFilters.All
        try {
            searchFilters = searchFilter ? parseFloat(searchFilter.toString()) : SearchFilters.All
        } catch (e) {
            // do nothing...
        }
        return searchFilters
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const queryChanged = nextProps.query !== this.props.query
        const hintChanged = nextProps.hint !== this.props.hint

        if (queryChanged || hintChanged) {
            const extendedSearch = !!(nextProps.query && nextProps.query.indexOf("*") > 0)
            this.setState((prevState) => {
                return {
                    extendedSearch,
                    query: queryChanged ? undefined : prevState.query,
                    hint: hintChanged ? nextProps.hint : prevState.hint,
                }
            })
        }

        if (nextProps.activeCategory !== this.props.activeCategory) {
            setTimeout(() => {
                this.searchFieldRef.current?.focus()
            }, nextProps.autofocusTimeout ?? 25)
        }
    }

    componentDidUpdate(_prevProps: Props, prevState: State) {
        const searchFilters = this.syncWithUrl()
        if (prevState.searchFilters !== searchFilters) {
            this.setState({ searchFilters })
        }
    }

    componentDidMount() {
        if (this.props.autofocus) {
            setTimeout(() => {
                this.searchFieldRef.current?.focus()
            }, this.props.autofocusTimeout ?? 25)
        }
    }

    componentWillUnmount() {
        this.unregisterOutsideClick?.()
    }

    handleChange(query: string) {
        this.setState({ query, hint: undefined })
        this.setState({ showPopUp: true })
    }

    handleSearch(query: string) {
        const {
            minLengthForSearch,
            localization: { translateText },
            activeCategory,
            extendedSearch,
        } = this.props

        if (query.length < minLengthForSearch) {
            this.setState({
                hint: translateText(812).replace("{0}", minLengthForSearch.toString()),
            })
            return
        }

        let wildcardSearch = false
        let normalizedValue = query

        if (activeCategory === "directSearch") {
            if (query.charAt(query.length - 1) === "*") {
                wildcardSearch = true
                query = query.slice(0, -1) // remove * from string, so it can be added again, after the length has been checked.
            } else if (this.state.extendedSearch || extendedSearch) {
                wildcardSearch = true
            }

            normalizedValue = query.replace(/[^a-zA-Z0-9*]/g, "") // underscore "_" is not allowed, therefore not \w
        }

        const state = validateField(normalizedValue).min(wildcardSearch ? 5 : minLengthForSearch, translateText(1231))

        if (state.messages.length) {
            this.setState({ hint: state.messages[0] })
            return
        }

        if (wildcardSearch) {
            query += "*"
        }

        this.handleChange(query)
        this.hideHintAndSuggestions()
        this.props.onSearch(query, this.state.searchFilters, this.state.extendedSearch)
    }

    handleSearchButtonClick() {
        const query = this.state.query !== undefined ? this.state.query : this.props.query
        this.handleSearch(query || "")
    }

    handleFocus() {
        this.setState({ showPopUp: true })
        this.props.onFocusChange && this.props.onFocusChange(true)
    }

    handleBlur() {
        this.setState({ showPopUp: false })
        this.props.onFocusChange && this.props.onFocusChange(false)
    }

    hideHintAndSuggestions = () => {
        this.setState({ showPopUp: false })
    }

    outsideClick = (ref: HTMLDivElement) => {
        if (ref) {
            this.unregisterOutsideClick = registerOutsideClick(ref, this.handleBlur, false)
        }
    }

    render() {
        const query = this.state.query !== undefined ? this.state.query : this.props.query
        const {
            localization: { translateText },
            isOnIndustryDashboard,
        } = this.props
        const style = getStyle()

        return (
            <div className="tk-parts search-field direct-search" style={isOnIndustryDashboard ? { width: "100%" } : {}}>
                <div
                    /* This div has to exist, otherwise the Tooltip destroys the ref to SuggestionFieldButtonGroup */
                    className={style.outsideClickWrapper}
                    ref={this.outsideClick}
                >
                    <InputGroup>
                        <div
                            className={classes(
                                "suggest",
                                "suggest--new",
                                this.searchFieldRef.current && "suggest--active",
                                this.state.showPopUp && "article-search"
                            )}
                        >
                            <SearchField
                                className="article-search"
                                showClear
                                ref={this.searchFieldRef}
                                value={query || ""}
                                onChange={this.handleChange}
                                placeholder={this.props.placeholder}
                                onChangeConfirm={this.handleSearch}
                                tooltip={ShowHintAsTooltip ? this.props.tooltip : undefined}
                                maxLength={50}
                                onInputClick={() => {
                                    if (!this.state.showPopUp) {
                                        this.handleFocus()
                                    }
                                }}
                                autoComplete="off"
                            />
                        </div>
                        <SearchButton size="large" onClick={this.handleSearchButtonClick} startIcon={<Icon name="search" />} />
                    </InputGroup>
                    {this.state.showPopUp && (
                        <div className={style.searchoptions}>
                            {this.state.hint && <Hint hint={this.state.hint} />}
                            <Box pt={1} pl={2} gap={style.gap}>
                                <Switch
                                    size="small"
                                    labelPlacement="start"
                                    checked={this.state.extendedSearch}
                                    onChange={(state, checked) => {
                                        this.setState({ extendedSearch: checked })
                                    }}
                                    label={translateText(12466)}
                                />
                            </Box>
                            <Box display="flex">
                                <Box display="flex" flexDirection="column" justifyContent="space-between">
                                    <div className={style.gap}>
                                        <QueryHistory
                                            handleSearch={(newQuery: string) => {
                                                this.setState({ extendedSearch: newQuery.indexOf("*") > 0 }, () => {
                                                    this.handleSearch(newQuery)
                                                })
                                            }}
                                        />
                                    </div>
                                </Box>
                                <Box>
                                    <div className={`search-filters-wrapper ${style.gap}`}>
                                        <SearchFiltersComponent
                                            selectedSearchFilters={this.state.searchFilters}
                                            searchfilterResultCounts={this.props.searchfilterResultCounts}
                                            onSearchFilterChanged={this.props.onSearchFilterChanged}
                                            onSelection={(selectedFilter: SearchFilters) => {
                                                if (selectedFilter != SearchFilters.All) {
                                                    this.setState({ searchFilters: selectedFilter })
                                                } else {
                                                    this.setState({ searchFilters: SearchFilters.All })
                                                }
                                            }}
                                            localization={this.props.localization}
                                        />
                                    </div>
                                </Box>
                            </Box>
                            <Box display="flex" whiteSpace="initial" paddingLeft="11px" paddingBottom="0.75em">
                                <Box pr={0.625}>
                                    <Icon name="info" size="1em" />
                                </Box>
                                <Typography variant="label">{translateText(12893)}</Typography>
                            </Box>
                        </div>
                    )}
                </div>
                {!ShowHintAsTooltip && this.props.tooltip && (
                    <div className="search-container__hint" style={{ display: "flex", alignItems: "flex-start", paddingTop: "0.5em" }}>
                        <Icon size="1.25em" name="search" />
                        <div className="text text--s" style={{ paddingLeft: "0.5em" }}>
                            {this.props.tooltip}
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default withLocalization(withRouter(DirectSearchField))
