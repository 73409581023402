import { useLocalization } from "@tm/localization"
import { ServiceBase, RepairTimeProvider } from "@tm/models"
import { Table, Text } from "@tm/controls"

const { Column, Cell } = Table

type Props = {
    includedRepairTimes: Array<ServiceBase>
    repairTimeProvider: RepairTimeProvider
}

const renderNumber = (work: ServiceBase) => (
    <Cell>
        <Text size="s">{work.repairTimeDisplayNo || work.repairTimeNo}</Text>
    </Cell>
)
const renderDescription = (work: ServiceBase) => (
    <Cell>
        <Text size="s">{work.description}</Text>
    </Cell>
)

export default function IncludedWorksList(props: Props) {
    const { translate, number } = useLocalization()

    const renderTime = (work: ServiceBase) => (
        <Cell>
            <Text size="s" modifiers={["sub"]}>
                {number(work.repairTime || 0, 2)}
            </Text>
        </Cell>
    )

    const columns = [
        <Column className="fancy-list__block--number" renderItemContent={renderNumber}>
            {translate(130)}
        </Column>,
        <Column className="fancy-list__block--description" renderItemContent={renderDescription}>
            {translate(61)}
        </Column>,
    ]
    if (props.repairTimeProvider === RepairTimeProvider.TecRmiCar || props.repairTimeProvider === RepairTimeProvider.TecRmiTruck) {
        columns.push(<Column className="fancy-list__block--included-time" renderItemContent={renderTime} />)
    }

    return <Table data={props.includedRepairTimes} columns={columns} />
}
