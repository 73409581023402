import { memo } from "react"
import { Button, Icon } from "@tm/components"
import { useLocalization } from "@tm/localization"

import { isWM } from "../../../../../utils"

export interface IProps {
    onShowCreator(): void

    showCreator: boolean
}

export const CompilationAddNavigation = memo<IProps>(({ onShowCreator, showCreator }) => {
    const { translateText } = useLocalization()

    return (
        <Button disabled={showCreator} onClick={onShowCreator} color={undefined} startIcon={!isWM() ? <Icon name="plus" /> : undefined}>
            {isWM() ? translateText(1242) : translateText(12791)}
        </Button>
    )
})
