import { combineReducers } from "redux"
import { Actions as widgetActions, ComponentActionType as WidgetComponentActionType, reduce as widgetReducer } from "../../widget/business"
import {
    Actions as fastCalculatorActions,
    ComponentActionType as FastCalculatorComponentActionType,
    reduce as fastCalculatorReducer,
    receive,
} from "../../moduleManager/business"

import { Actions as managerActions } from "../../manager/business"

export const reduce = combineReducers({
    fastCalculator: fastCalculatorReducer,
    widget: widgetReducer,
})

export { receive }

export type IMainActions = typeof MainActions

export const MainActions = {
    ...fastCalculatorActions,
    ...widgetActions,
    ...managerActions,
}

export * from "./selector"

export type MainActionType = FastCalculatorComponentActionType | WidgetComponentActionType
