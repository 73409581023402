export enum ECalcButtonStyle {
    None,
    Default,
    Success,
    Danger,
    Warning,
    Info,
    Primary,
    Secondary,
    Outlined,
}
