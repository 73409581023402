import { uniqueId } from "@tm/utils"
import { ICalculationItem } from "../../models"
import { GetCalculationDataResponse } from "./model"
import { ICalculationData, IItemsItem } from "./rawModel"

export const mapEreCalculationData = (calculationData: ICalculationData): GetCalculationDataResponse => {
    return {
        attachments: calculationData.attachments,
        continuationEreSessionId: calculationData.continuationEreSessionId,
        continuationEreUrl: calculationData.continuationEreUrl,
        iid: calculationData.iid,
        items: calculationData.items.map(mapOeArticle),
        works: calculationData.works.map((work) => ({ ...work, isSelected: true, isExpanded: false })),
    }
}

const mapOeArticle = (item: IItemsItem): ICalculationItem => {
    return {
        alternativeParts: item.parts,
        oeArticle: { ...item.oeArticle, quantityValue: 1 },
        isSelected: true,
        uniqueId: uniqueId(),
    }
}
