import { useEffect } from "react"
import { useLocation } from "react-router"
import { Box } from "@tm/components"
import { getParameterFromUserContextModule, useUser, useWorkTask } from "@tm/context-distribution"
import Morpheus from "@tm/morpheus"
import { channel, UserModuleType } from "@tm/models"
import { mainActions, resetStore, useEdsStore } from "../../data/state"
import { EdsQueryParams, NavigationSteps } from "../../models"
import Content from "../content/Content"
import Header from "../header/Header"

export default function Main() {
    const location = useLocation()
    const { userContext } = useUser()
    const vehicle = useWorkTask()?.workTask?.vehicle
    const workTaskId = useWorkTask()?.workTaskId
    const savedWorktaskId = useEdsStore((state) => state.main.worktaskId)
    const selectedUnit = useEdsStore((state) => state.graphic.selectedUnit)
    const activeView = useEdsStore((state) => state.main.view)
    const initializedFromUrl = useEdsStore((state) => state.main.initializedFromUrl)
    const queryParams = new URLSearchParams(location.search)
    const initialVin = queryParams.get(EdsQueryParams.Vin)

    const enableEdsStateSave = getParameterFromUserContextModule(userContext, UserModuleType.Eds, "ReRequestCurrentState", "boolean")

    useEffect(() => {
        Morpheus.closeView("1")
        mainActions.moduleOpened()
        return () => {
            if (!enableEdsStateSave) {
                resetStore()
            }
        }
    }, [enableEdsStateSave])

    useEffect(() => {
        if (enableEdsStateSave) {
            if (!workTaskId) {
                return
            }

            if (!savedWorktaskId) {
                mainActions.setWorktaskId(workTaskId)
            }

            if (savedWorktaskId && savedWorktaskId !== workTaskId) {
                resetStore()
                mainActions.setWorktaskId(workTaskId)
            }

            const unsub = channel("WORKTASK", workTaskId).subscribe("MODULE/CLOSED", (module) => {
                if (module.includes("eds")) {
                    resetStore()
                    useEdsStore.persist.clearStorage()
                }
            })

            return () => {
                unsub()
            }
        }
    }, [workTaskId, savedWorktaskId, enableEdsStateSave])

    useEffect(() => {
        if (!initializedFromUrl && initialVin) {
            mainActions.initializeFromUrl(initialVin)
        }

        if (!initializedFromUrl && vehicle?.vin) {
            mainActions.initializeFromUrl(vehicle.vin, true)
        }
    }, [initializedFromUrl, initialVin, vehicle])

    return (
        <Box sx={(theme) => ({ margin: theme.spacing(2), flex: "1", display: "flex" })}>
            <Box flex="1" display="flex" flexDirection="column">
                {activeView === NavigationSteps.GraphicSearch && selectedUnit ? null : <Header />}
                <Content />
            </Box>
        </Box>
    )
}
