import { useInitModuleNavigation, useWorkTask } from "@tm/context-distribution"
import { ModuleTab } from "@tm/models"
import { encodeUniqueId, renderRoute } from "@tm/utils"
import { RefObject, useCallback, useEffect, useRef, useState } from "react"
import { useRouteMatch } from "react-router-dom"
import { StylingFromConfig } from "../../data"
import { getNavigationSplitPosition } from "../../helpers"
import { getBundleParams } from "../../utils"
import { IndustryTabs } from "./components/IndustryTabs"
import { ModuleNavigationTabs } from "./components/ModuleNavigationTabs"
import { getStyleFromConfig } from "./helpers"

export type Refs = {
    wrapperElement: RefObject<HTMLDivElement>
    navigationElement: RefObject<HTMLDivElement>
    moreElement: RefObject<HTMLDivElement>
}

export type ModuleNavigationProps = StylingFromConfig & {
    worktaskDashboardTitle?: string
    activateDefaultTabs?: boolean
    defaultTabs?: { [key: string]: ModuleTab }
    workTaskRoute?: string
}

function ModuleNavigationComponent(props: ModuleNavigationProps) {
    const { activateDefaultTabs, defaultTabs, workTaskRoute } = props
    const { visibleTabs, moreTabs, visibleTabCount, setVisibleTabCount, resetVisibleTabCount } = useInitModuleNavigation(
        activateDefaultTabs,
        defaultTabs
    )
    const [showTooltip, setShowTooltip] = useState<boolean>(false)
    const { workTaskId } = useWorkTask() ?? {}
    const routeMatch = useRouteMatch()
    const refs = useRef<Refs>(null)

    const resizeTimer = useRef<number>()

    function getComponentUrl() {
        // don't use worktaskId para if not set, as example "open Usersettings"
        const worktaskObj = workTaskId ? { workTaskId: encodeUniqueId(workTaskId) } : {}
        const urlParms = { ...routeMatch.params, ...worktaskObj }
        return renderRoute(workTaskRoute ?? routeMatch.path, urlParms).replace(/\/$/, "")
        // TODO: Fix hotfix, this.props.match.path has an ending / which causes "ModuleComponent.prototype.handleClearStoresOnRouteChange" to clear the store (url is not the same anymore)
        return renderRoute(routeMatch.path, routeMatch.params).replace(/\/$/, "")
    }

    const tabClassNameExtensions = getStyleFromConfig("tab", props.style, { layout: "module" })
    const btnClassName = `more__btn tab ${moreTabs.length ? "is-visible" : ""}${tabClassNameExtensions}`

    useEffect(
        function rearangeTabs() {
            if (visibleTabCount === -1 && refs?.current?.navigationElement?.current) {
                const splitPos = getNavigationSplitPosition(refs?.current?.navigationElement.current, refs?.current?.moreElement.current)
                setVisibleTabCount(splitPos.pos)
            }
        },
        [visibleTabs.length, moreTabs.length, visibleTabCount, refs?.current?.navigationElement.current, refs?.current?.moreElement.current]
    )

    const handleResize = useCallback(() => {
        clearTimeout(resizeTimer.current)
        resizeTimer.current = window.setTimeout(() => {
            setShowTooltip(false)
            resetVisibleTabCount()
        }, 200)
    }, [])

    useEffect(() => {
        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [handleResize])

    const handleTooltipShow = useCallback(() => {
        setShowTooltip(true)
    }, [setShowTooltip])

    const handleTooltipHide = useCallback(() => {
        setShowTooltip(false)
    }, [setShowTooltip])

    // sometimes the wrapper width is changed without window resize.
    // For example when basket summay is reloaded and that's why the resizeObserver was added
    useEffect(() => {
        const resizeObserver = new ResizeObserver(handleResize)
        resizeObserver.observe(refs?.current?.wrapperElement.current as Element)

        const currentWrapperElement = refs?.current?.wrapperElement.current
        return () => {
            if (currentWrapperElement) {
                resizeObserver.unobserve(currentWrapperElement as Element)
            }
        }
    }, [handleResize])

    if (getBundleParams().hasIndustryModuleTabs) {
        return (
            <IndustryTabs
                ref={refs}
                worktaskDashboardTitle={props.worktaskDashboardTitle}
                visibleTabs={visibleTabs}
                componentUrl={getComponentUrl()}
                moreTabs={moreTabs}
                showTooltip={showTooltip}
                handleTooltipShow={handleTooltipShow}
                handleTooltipHide={handleTooltipHide}
                btnClassName={btnClassName}
            />
        )
    }

    return (
        <ModuleNavigationTabs
            ref={refs}
            {...props}
            visibleTabs={visibleTabs}
            moreTabs={moreTabs}
            showTooltip={showTooltip}
            handleTooltipShow={handleTooltipShow}
            handleTooltipHide={handleTooltipHide}
            btnClassName={btnClassName}
        />
    )
}

export default ModuleNavigationComponent
