import { UserSettings } from "@tm/context-distribution"
import { Customer } from "@tm/models"
import { Repositories, useFastServiceStore } from "../../../data"
import { PrintImage, PrintOptions, SelectedItems } from "../../../data/models"
import { PrintModel } from "../../../models"
import { createPrintRequest } from "./helper"
import { getStepNameByLocation, getTranslationByKey } from "../../../helpers"

export function printMaintenance(
    translateText: (key: string | number) => string,
    printOptions: PrintOptions,
    workshopUser: string,
    userSettings?: UserSettings,
    customer?: Customer,
    printPdf?: () => void
) {
    const fastServiceStore = useFastServiceStore.getState()

    fastServiceStore.setPrintLoading()
    const { selectedWorks, itemsForPrint } = fastServiceStore.maintenanceReview

    const items = itemsForPrint
    // create a new map to keep the right order
    // we can't use the selectedWorks because we need to set a higher es target (es6)
    // in this way can use for of for the await and we use the itemsForPrint collection
    // then we use the itemsForPrint to create a new map set in order to use it for Print
    const completeOrderedWorks: typeof selectedWorks = new Map([])

    const { stateVehicle } = fastServiceStore.maintenancePlan
    const selectedStep = fastServiceStore.navigation.active ?? ""

    const fileInfo = {
        application: "FastService",
        category: "maintenanceprotocol",
        area: selectedStep,
        vehicleId: stateVehicle?.id,
        vin: stateVehicle?.vin,
        mileage: stateVehicle?.mileAge?.toString(),
        workshopUser,
    }

    const invokePrint = (request: PrintModel) => {
        Repositories.printMaintenance(request, fileInfo).then(
            (res) => {
                const expiresAt = Date.now() + 10 * 60 * 1000 // PDF URl expires in 10 minutes
                useFastServiceStore.getState().setPrintLoaded(res.pdfUrl, expiresAt)
                printPdf?.()
            },
            () => {
                useFastServiceStore.getState().setPrintFailed()
            }
        )
    }

    if (printOptions.images) {
        mapData(items).then((sectionItems) => {
            Array.from(selectedWorks, ([key, _]) => completeOrderedWorks.set(key, sectionItems[key]))
            const request = createPrintRequest(translateText, completeOrderedWorks, printOptions, userSettings, customer)
            invokePrint(request)
        })
    } else {
        Array.from(selectedWorks, ([key, _]) => completeOrderedWorks.set(key, items[key]))
        const request = createPrintRequest(translateText, completeOrderedWorks, printOptions, userSettings, customer)
        invokePrint(request)
    }
}

const mapData = async (data: Record<string, Record<string, SelectedItems>>) => {
    const totalItems: Record<string, Record<string, SelectedItems>> = {}
    // eslint-disable-next-line no-restricted-syntax
    for (const totalItemsRaw of Object.entries(data)) {
        const [key, value] = totalItemsRaw
        // eslint-disable-next-line no-await-in-loop
        totalItems[key] = await mapWorks(key, Object.entries(value))
    }
    return totalItems
}

const mapWorks = (id: string, rawWorks: [string, SelectedItems][]) => {
    const { images } = useFastServiceStore.getState().imagesState
    const step = getStepNameByLocation(getTranslationByKey(id))
    const works: Record<string, SelectedItems> = {}

    // eslint-disable-next-line no-restricted-syntax
    for (const work of rawWorks) {
        const [key, value] = work
        const imagesForPath = images[step]?.[key]
        works[key] = { ...value, selectedImages: imagesForPath ? getImages(imagesForPath) : [] }
    }

    return works
}

const getImages = (images: PrintImage[]) => {
    return images.map((x) => ({ ...x, imageUrl: x.id }))
}
