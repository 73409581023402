import { getStoredAuthorization, ajax } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { FastCalcRequest, FastCalculatorInitResponse } from "./model"
import { mapFastCalculatorResponse } from "../../helpers"

export function initFastCalc(request: FastCalcRequest) {
    const url = `${getServiceUrl()}/FastCalc`
    const authorization = getStoredAuthorization()
    const body = { dataIn: request, vehicleId: request.vehicleId }

    return new Promise<FastCalculatorInitResponse>((resolve, reject) =>
        ajax({ url, body, authorization, method: "POST" }).then((data) => {
            const mappedData = mapFastCalculatorResponse(data)
            if (mappedData?.selectedCalcState?.context || mappedData?.isQueued) {
                resolve(mappedData)
            } else {
                reject()
            }
        }, reject)
    )
}

function getServiceUrl() {
    const bundleParams = getBundleParams()
    return bundleParams.fastCalculatorServiceUrl
}
