import { Article } from "@tm/models"
import { notUndefinedOrNull, uniqueId, useErpConfig } from "@tm/utils"
import { useRecoilState } from "recoil"
import { useEffect, useMemo } from "react"
import { entries, uniqBy } from "lodash"
import { SupplierArticleWithId, loadMultiWholesalerArticleNosByTraderId } from "../../../data/repositories"
import { tradeReferenceNumbersState } from "../states"

export type TradeReferenceNumber = {
    erpSystemId: number
    tradeReferenceId: number
    traderArticleNumber: string
}
export type ArticleTradeReferences = [SupplierArticleWithId, TradeReferenceNumber[] | undefined]

export function useTradeReferences(articles: Article[], enabled: boolean) {
    const { erpSystemConfigs, useOrderByDistributor } = useErpConfig()
    const [tradeReferenceNumbers, setTradeReferenceNumbers] = useRecoilState(tradeReferenceNumbersState)

    const tradeReferenceIds = useMemo(() => {
        if (erpSystemConfigs) {
            const ids = erpSystemConfigs.map((x) => x.tradeReferenceId).filter(notUndefinedOrNull)
            const uniqueIds = uniqBy(ids, (id) => id)
            return uniqueIds
        }
    }, [erpSystemConfigs])

    useEffect(() => {
        const erpSystemId = erpSystemConfigs?.[0].id
        const tradeReferenceId = erpSystemConfigs?.[0].tradeReferenceId
        // When MRE is not active
        if (!useOrderByDistributor && erpSystemId && tradeReferenceId) {
            setTradeReferenceNumbers(() => {
                return articles.map((article) => {
                    return [
                        { supplierId: article.supplier.id, supplierArticleNo: article.supplierArticleNo, id: article.id },
                        [
                            {
                                erpSystemId,
                                tradeReferenceId,
                                traderArticleNumber: article.traderArticleNo,
                            },
                        ],
                    ]
                })
            })
            return
        }

        setTradeReferenceNumbers((prev) => {
            const requests: SupplierArticleWithId[] = uniqBy(
                articles
                    .filter(
                        (article) =>
                            !prev.some(([key]) => key.supplierArticleNo === article.supplierArticleNo && key.supplierId === article.supplier.id)
                    )
                    .map((article) => ({
                        supplierId: article.supplier.id,
                        supplierArticleNo: article.supplierArticleNo,
                        id: uniqueId(),
                    })),
                (x) => [x.supplierArticleNo, x.supplierId].join("|")
            )
            if (requests) {
                return [...prev, ...requests.map((request) => [request, undefined] as ArticleTradeReferences)]
            }
            return prev
        })
    }, [articles, erpSystemConfigs, setTradeReferenceNumbers, useOrderByDistributor])

    useEffect(
        function loadData() {
            const fetchData = async (requests: SupplierArticleWithId[]) => {
                if (!tradeReferenceIds?.length) {
                    return
                }
                const response = await loadMultiWholesalerArticleNosByTraderId(requests, tradeReferenceIds)
                setTradeReferenceNumbers((prev) => {
                    let loadedData = prev
                    entries(response.wholesalerArticleNosDict).forEach(([articleId, articleResponse]) => {
                        const loadedKey = loadedData.find(([key]) => key.id === articleId)?.[0]

                        if (loadedKey) {
                            const entryNumbers: { erpSystemId: number; tradeReferenceId: number; traderArticleNumber: string }[] = []
                            entries(articleResponse).forEach(([tradeReferenceIdString, traderArticleNumber]) => {
                                const tradeReferenceId = parseInt(tradeReferenceIdString) || 0
                                const erpSystemIds = erpSystemConfigs
                                    ?.filter((x) => x.tradeReferenceId === tradeReferenceId)
                                    .map((erpConfig) => erpConfig.id)
                                erpSystemIds?.forEach((erpSystemId) => {
                                    entryNumbers.push({
                                        erpSystemId,
                                        tradeReferenceId,
                                        traderArticleNumber,
                                    })
                                })
                            })

                            loadedData = [[loadedKey, entryNumbers], ...loadedData.filter((x) => x[0] !== loadedKey)]
                        }
                    })

                    return loadedData
                })
            }
            const newRequests = tradeReferenceNumbers.filter(([_, numbers]) => !numbers).map(([key]) => key)
            if (enabled && newRequests.length) {
                fetchData(newRequests)
            }
        },
        [tradeReferenceNumbers, tradeReferenceIds, enabled, erpSystemConfigs, setTradeReferenceNumbers]
    )

    return tradeReferenceNumbers
}
