import { VehicleImage, Box, Typography, styled, Tooltip } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { Alert } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ESubModules, UserModuleType } from "@tm/models"
import { getCostPerCalculation, getCurrencyFromUserContext, initSelector } from "@tm/utils"
import { useSelector } from "react-redux"
import { vehicleModelsSelector, vehicleSelector } from "../../main/business"

const selector = initSelector(vehicleSelector, vehicleModelsSelector)

export default function Header() {
    const { translateText, currency } = useLocalization()

    const [vehicle] = useSelector(selector)
    const { userContext } = useUser()

    const renderVehicleInfo = () => {
        if (!vehicle) {
            return null
        }

        return (
            <>
                <Typography>
                    {translateText(1462)} {translateText(1463)}
                </Typography>

                <Box margin="1em 0">
                    <Typography sx={{ display: "flex" }}>
                        {translateText(1464)} {translateText(1481)}
                        {vehicle.modelThumbnail && (
                            <StyledImageBox>
                                <VehicleImage
                                    modelImage={vehicle.modelThumbnail}
                                    modelSeriesImage={vehicle.modelSeriesThumbnail || ""}
                                    vehicleType={vehicle.vehicleType}
                                />
                            </StyledImageBox>
                        )}
                        {vehicle.manufacturer} {vehicle.modelSeries}
                    </Typography>
                </Box>
            </>
        )
    }
    const renderBillingModeTooltip = () => {
        const calculationCost = getCostPerCalculation(UserModuleType.TMDamageCalculationEurotax, ESubModules.ShowEREClick, userContext)
        if (calculationCost) {
            const currencyCode = getCurrencyFromUserContext(userContext)
            const text = translateText(12408) + currency(calculationCost, currencyCode)

            return (
                <StyledCalculationCostBox>
                    <Tooltip showOnlyOnOverflow title={text}>
                        <Alert buttonText="info" icon="info" message={text} />
                    </Tooltip>
                </StyledCalculationCostBox>
            )
        }
    }

    return (
        <>
            <StyledHeader>
                <Box className="title">
                    <Typography variant="h2">{translateText(1458)}</Typography>
                    <Box className="provider-area">
                        <Box className="provider">
                            <Box className="provider__provider eurotax">
                                <Box className="eurotax-glass_logo" />
                            </Box>
                        </Box>
                    </Box>
                </Box>

                {renderBillingModeTooltip()}
            </StyledHeader>

            {renderVehicleInfo()}
        </>
    )
}

const StyledImageBox = styled(Box)({
    img: {
        width: "3em",
        margin: "0 1em",
    },
})

const StyledCalculationCostBox = styled(Box)({
    display: "flex",
    marginLeft: "auto",

    ".alert": {
        height: "3.5em",
        border: "1px solid transparent",
        backgroundColor: "#ffeb3b",

        ".alert__text-wrapper": {
            marginLeft: "2.5em",
        },
    },

    ".icon, .text": {
        color: "black !important",
        fill: "black !important",
    },
})
const StyledHeader = styled(Box)({
    display: "flex",
    maxHeight: "3em",
    height: "auto",

    ".title": {
        verticalAlign: "middle",
        display: "inline-flex",
        flex: "1",
        alignSelf: "flex-start",

        ".summary-title": {
            fontSize: "1.5rem",
            flex: "unset",
            whiteSpace: "nowrap",
            alignSelf: "center",
        },

        ".last-calculations-title": {
            fontSize: "1.5rem",

            "&.text": {
                flex: "unset !important",
            },
        },

        ".provider-area": {
            verticalAlign: "middle",
            flexDirection: "row",
            marginLeft: "1em",

            ".provider": {
                display: "flex",
                flex: "1",
                pointerEvents: "none",

                "&__provider": {
                    width: "160px",
                    height: "54px",
                    display: "inline-flex",
                    border: "none",
                    backgroundColor: "transparent",

                    "&.eurotax": {
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "200px 54px",

                        svg: {
                            height: "100%",
                            width: "100%",
                        },
                    },
                },
            },
        },
    },
})
