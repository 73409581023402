import { useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { SelectedOverlayCalcStateSelector } from "../../data/helpers"
import { AlternativeCalcStateContext, CalcArticle, CalcGenArt } from "../../data/model"
import Prices from "../_shared/prices"
import ArticleList from "./components/articleList"
import ConsumablesList from "./components/consumablesList"
import Workslist from "./components/worksList"
import Header from "./components/header"
import { ECalcArticle, ECalcState } from "../../data/enums"
import SelectionDialog from "../_shared/modals/SelectionDialog"
import SelectionModal from "../_shared/modals/SelectionModal"

type Props = {
    showProductGroupAlternatives(genArt: CalcGenArt): void
    showPartAlternatives(genArt: CalcGenArt, part: CalcArticle): void
    showOePartAlternatives(genArt: CalcGenArt, oePart: CalcArticle): void
    hideAlternatives?: boolean
}
export default function Calculation(props: Props) {
    const { selectedOverlayCalcState } = useSelector(SelectedOverlayCalcStateSelector)
    const interactionDialog = selectedOverlayCalcState && ECalcState.OverlayCalcStates & selectedOverlayCalcState?.type
    const worksDialog = selectedOverlayCalcState && ECalcState.WorksState & selectedOverlayCalcState?.type
    const alternativesDialog =
        selectedOverlayCalcState && ECalcState.AlternativeCalcArticles === selectedOverlayCalcState.type && selectedOverlayCalcState

    useEffect(() => {
        const alternativesContext = selectedOverlayCalcState?.context as AlternativeCalcStateContext

        if (alternativesContext?.selectedGenArt && alternativesContext?.selectedArticle) {
            switch (alternativesContext.selectedArticle.type) {
                case ECalcArticle.AlternativeArticle:
                    props.showPartAlternatives(alternativesContext.selectedGenArt, alternativesContext.selectedArticle)
                    break
                case ECalcArticle.OeAlternativeArticle:
                    props.showOePartAlternatives(alternativesContext.selectedGenArt, alternativesContext.selectedArticle)
                    break
                default:
                    break
            }
        }
    }, [alternativesDialog])

    const renderDialog = () => {
        if (!interactionDialog || alternativesDialog) {
            return null
        }

        if (worksDialog) {
            return <SelectionModal />
        }

        return <SelectionDialog calcState={selectedOverlayCalcState} />
    }

    return (
        <div className="fastcalc-calculation">
            <Header />
            <Prices />
            <ArticleList {...props} />
            <ConsumablesList />
            <Workslist />
            {renderDialog()}
        </div>
    )
}
