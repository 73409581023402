import { Box, Icon, IconButton, Loader, MenuItem, SelectTextField, Typography, styled } from "@tm/components"
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import { CriterionFilters } from "../../../data/model"

type Props = {
    description: string
    criterionFilters: CriterionFilters[]
    onSelectionChange: (criterionFilter?: CriterionFilters) => void
    removeSelectedCriteria: (criterionFilter?: CriterionFilters) => void
    onSelectionOpend: () => void
    loading: boolean
}

const SpecialSelection = styled(SelectTextField)({
    width: "140px",
    ".MuiFilledInput-root": {
        ".MuiFilledInput-input": {
            padding: "4px 32px 8px 6px",
        },
    },
})

/**
 * Select component for Criterions, orderd to Priority, displayed Prio entrys in bold, disabeld slection on Loading
 * @param props
 * @returns
 */
export function CriterionSelection(props: Props) {
    const { onSelectionChange, criterionFilters, removeSelectedCriteria, loading, description } = props
    const [selectedValue, setSelectedValue] = useState<string | undefined>()

    const sorted: CriterionFilters[] = useMemo(() => {
        return criterionFilters?.sort((a, b) => {
            if (a.name > b.name) {
                return 0
            }
            if (a.name < b.name) {
                return 1
            }
            return -1
        })
    }, [criterionFilters])

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const value = sorted?.find((e) => e.valueKey === event.target.value)
            setSelectedValue(value?.valueKey)
            if (onSelectionChange) {
                onSelectionChange(value)
            }
        },
        [sorted, onSelectionChange]
    )

    const onClick = (event: React.MouseEvent, value: CriterionFilters) => {
        event.stopPropagation()
        removeSelectedCriteria(value)
    }

    useEffect(() => {
        setSelectedValue(criterionFilters?.find((criterion) => criterion.isSelected)?.valueKey)
    }, [criterionFilters])

    return (
        <SpecialSelection
            size="small"
            select
            label={description}
            margin="none"
            value={selectedValue}
            onChange={handleChange}
            SelectProps={{ onOpen: () => props.onSelectionOpend() }}
        >
            {loading ? (
                <Loader />
            ) : (
                sorted?.map((row) => (
                    <MenuItem key={row.queryStr} value={row.valueKey}>
                        <Box display="flex" justifyContent="space-between" width="100%">
                            <Typography maxLines={1}>{row.name}</Typography>
                            {row.isSelected && (
                                <IconButton onMouseDown={(e) => onClick(e, row)} sx={{ padding: 0, opacity: 0.7 }}>
                                    <Icon size="18px" name="close" />
                                </IconButton>
                            )}
                        </Box>
                    </MenuItem>
                ))
            )}
        </SpecialSelection>
    )
}
