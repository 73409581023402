import { useSelector } from "react-redux"
import { Box, Typography, VehicleImage } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { initSelector } from "@tm/utils"
import { dateToString } from "../../../data/helpers"
import { customerSelector, vehicleSelector } from "../../main/business"

const selector = initSelector(vehicleSelector, customerSelector)

export default function Header() {
    const { translateText } = useLocalization()
    const [vehicle, customer] = useSelector(selector)

    if (!vehicle) {
        return null
    }

    return (
        <Box display="flex" gap=".5em" paddingBottom="1em">
            <Typography fontWeight="bold">{`${translateText(1575)} ${translateText(1481)}`}</Typography>
            {vehicle.modelThumbnail && (
                <VehicleImage
                    modelImage={vehicle.modelThumbnail}
                    modelSeriesImage={vehicle.modelSeriesThumbnail || ""}
                    vehicleType={vehicle.vehicleType}
                    style={{ width: "3em" }}
                />
            )}
            {vehicle.manufacturer && <Typography>{`  ${vehicle.manufacturer}`}</Typography>}
            {vehicle.modelSeries && <Typography>{`  ${vehicle.modelSeries}`}</Typography>}
            {vehicle.engineCode && <Typography>{`  ${translateText(105)}: ${vehicle.engineCode}`}</Typography>}
            {vehicle.initialRegistration && <Typography>{`  ${translateText(124)}: ${dateToString(vehicle.initialRegistration)}`}</Typography>}
            {vehicle.vin && <Typography>{`  ${translateText(101)}: ${vehicle.vin}`}</Typography>}
            {(customer?.firstName || customer?.lastName) && <Typography>{`  ${customer?.firstName ?? ""} ${customer?.lastName ?? ""}`}</Typography>}
        </Box>
    )
}
