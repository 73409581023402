import { StateCreator } from "zustand"
import { DrivemotiveStore } from ".."
import {
    setShowLoginDialog,
    drivemotiveLogin,
    setBookingsList,
    changeStep,
    setSelectedBooking,
    setShowBookingDetails,
    setIsLoginDone,
    changeQuantity,
    setCalculationArticles,
    deleteArticleFromCalculation,
    deleteWorkFromCalculation,
    setCalculationArticlesAndWorks,
    makeCalculationTabAvailable,
    moduleOpened,
    setWorktaskId,
} from "./mainActions"
import { MainSlice, MainState } from "./model"
import { NavigationSteps } from "../../models"

const initialState: MainState = {
    main: {
        moduleOpened: false,
        worktaskId: undefined,
        showLoginDialog: false,
        isLoginDone: false,
        navigationStep: NavigationSteps.Summary,
        isCalculationTabAvailable: false,
    },
    summary: {
        bookings: [],
        selectedBooking: undefined,
        showBookingDetails: false,
        summaryArticles: [],
        summaryWorks: [],
        totalPriceArticles: {
            totalQuantity: 0,
            value: 0,
        },
        totalPriceWorks: {
            totalQuantity: 0,
            value: 0,
        },
        hourRate: 0,
    },
    calculation: {
        calculationArticles: [],
        calculationWorks: [],
        totals: {
            articlesPrice: {
                totalQuantity: 0,
                value: 0,
            },
            repairTimesPrice: {
                totalQuantity: 0,
                value: 0,
            },
            totalPriceVat: {
                totalQuantity: 0,
                value: 0,
            },
            totalPrice: {
                totalQuantity: 0,
                value: 0,
            },
        },
    },
}

export const createMainSlice: StateCreator<DrivemotiveStore, [["zustand/devtools", never]], [], MainSlice> = (set) => ({
    ...initialState,
    mainActions: {
        moduleOpened: () => set((state) => moduleOpened(state)),
        setWorktaskId: (worktaskId) => set((state) => setWorktaskId(state, worktaskId)),
        setShowLoginDialog: (showLoginDialog) => set((state) => setShowLoginDialog(state, showLoginDialog)),
        setIsLoginDone: (isLoginDone) => set((state) => setIsLoginDone(state, isLoginDone)),
        drivemotiveLogin: () => set((state) => drivemotiveLogin(state)),
        changeStep: (step) => set((state) => changeStep(state, step)),
        makeCalculationTabAvailable: () => set((state) => makeCalculationTabAvailable(state)),
    },
    summaryActions: {
        setBookingsList: (bookings) => set((state) => setBookingsList(state, bookings)),

        setSelectedBooking: (booking) => set((state) => setSelectedBooking(state, booking)),
        setShowBookingDetails: (showBookingDetails) => set((state) => setShowBookingDetails(state, showBookingDetails)),
        setCalculationArticlesAndWorks: (calculation) => set((state) => setCalculationArticlesAndWorks(state, calculation)),
    },
    calculationActions: {
        changeQuantity: (item, quantity) => set((state) => changeQuantity(state, item, quantity)),
        setCalculationArticles: (articles) => set((state) => setCalculationArticles(state, articles)),
        deleteArticleFromCalculation: (article, notFoundArticle) => set((state) => deleteArticleFromCalculation(state, article, notFoundArticle)),
        deleteWorkFromCalculation: (work) => set((state) => deleteWorkFromCalculation(state, work)),
    },
    resetStore: () => set(reset, false, "Reset Main Slice"),
})

function reset(): Partial<MainSlice> {
    return {
        ...initialState,
    }
}
