import {
    Accordion,
    AccordionDetails,
    Box,
    Button,
    CellContentPosition,
    Divider,
    Icon,
    Loader,
    MuiAccordionSummary,
    Separator,
    Table,
    TableCellData,
    TableColumnData,
    TableRowData,
    Typography,
    styled,
} from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useState } from "react"
import { useFastServiceHistorySummary } from "../../helpers/hooks/useFastServiceHistory"
import { useFastServiceStore } from "../../data"
import { CalculationHistoryStatus, GenericHistoryEntry, HistorySummary } from "../../data/models"
import { getFormattedDate, getHistoryStatusFromEnum } from "../../helpers"
import { getHistoryDetails } from "../../data/repositories"
import { SavedCalculation } from "../../data/repositories/fastcalculator-getSavedCalculations/model"
import MuiCustomModal from "../_shared/modal/MuiCustomModal"
import VehicleVinField from "./components/vehicleVinField"

export default function FastServiceHistory() {
    const { translateText } = useLocalization()
    const vehicleId = useFastServiceStore((store) => store.maintenancePlan.stateVehicle?.id)
    const vehicleVin = useFastServiceStore((store) => store.maintenancePlan.vehicleFields.vin)
    const vehicleTecDoc = useFastServiceStore((store) => store.maintenancePlan.stateVehicle?.tecDocTypeId)
    const storeVersion = useFastServiceStore((store) => store.version)
    const [modalOpen, setModalOpen] = useState(false)
    const [selectedCalculation, setSelectedCalculation] = useState<SavedCalculation>()

    const historySummaryList = useFastServiceHistorySummary(vehicleVin, vehicleId, vehicleTecDoc)

    const columns: TableColumnData[] = [
        {
            header: (
                <>
                    {translateText(13492)} <Separator /> {translateText(98)} <Separator /> {translateText(335)}
                </>
            ),
            gridSize: "1fr",
        },
        { header: <>{translateText(13493)}</>, gridSize: "1fr" },
        { header: "", gridSize: "auto" },
        { header: "", gridSize: "auto" },
    ]

    async function getDetailedHistory(historyEntryId: string) {
        const { setHistoryLoading, setStateFromHistory } = useFastServiceStore.getState()
        setHistoryLoading(true)
        try {
            const detailedHistory = await getHistoryDetails(historyEntryId)
            setStateFromHistory(detailedHistory)
            setHistoryLoading(false)
        } catch (error) {
            setHistoryLoading(false)
        }
    }

    const renderStatus = (textId: string, icon: string) => {
        return (
            <Box display="flex" flexDirection="row" alignItems="center" gap="1em">
                <Icon name={icon} />
                <Typography>{translateText(textId)}</Typography>
            </Box>
        )
    }

    const getHistoryActionButton = (historyEntryId: string, buttonTextId: string, disabled?: boolean) => {
        return (
            <Button disabled={disabled} onClick={() => getDetailedHistory(historyEntryId)}>
                {translateText(buttonTextId)}
            </Button>
        )
    }

    const renderDate = (date: string) => {
        const formattedDate = getFormattedDate(new Date(date))

        if (!formattedDate) {
            return <Typography>{date}</Typography>
        }

        return (
            <Box display="flex" flexDirection="row" alignItems="center" gap="0.5em">
                <Typography>{translateText(formattedDate.dayTextId)}</Typography>
                <Divider orientation="vertical" flexItem />
                <Typography>{formattedDate.date}</Typography>
                <Divider orientation="vertical" flexItem />
                <Typography>{formattedDate.time}</Typography>
            </Box>
        )
    }

    const handleCalculationDetails = (savedCalculation: SavedCalculation) => {
        setSelectedCalculation(savedCalculation)
        setModalOpen(true)
    }

    const handleDetailsInfo = (infos: string[] | undefined) => {
        if (!infos || infos.length === 0) {
            return "-"
        }

        let infoText = ""
        infos.forEach((info) => {
            infoText += `${info}, `
        })

        return infoText.trim().replace(/,$/, "")
    }

    const cellData = (history: GenericHistoryEntry): TableCellData[] => {
        const isHistorySummary = Object.hasOwn(history, "status")

        if (isHistorySummary) {
            const historySummary: HistorySummary = history as HistorySummary
            const { textId, icon, buttonTextId } = getHistoryStatusFromEnum(historySummary.status)
            const incompatibleVersion = historySummary.version !== storeVersion

            return [
                {
                    displayValue: renderDate(historySummary.dateTime),
                    id: "1",
                },
                { displayValue: renderStatus(textId, icon), id: "2" },
                { displayValue: "", id: "3" },
                {
                    displayValue: getHistoryActionButton(historySummary.id, buttonTextId, incompatibleVersion),
                    id: "4",
                    alignContent: CellContentPosition.right,
                    maxWidth: 500,
                    className: "calcCell",
                },
            ]
        }

        const { textId, icon, buttonTextId } = getHistoryStatusFromEnum(CalculationHistoryStatus.FastCalcHistory)
        const fastCalcHistory = history as SavedCalculation
        return [
            {
                displayValue: (
                    <Box display="flex" flexDirection="row" gap="2em">
                        {renderDate(history.dateTime)}
                        <Typography varian="body3">{translateText(13523)}</Typography>
                    </Box>
                ),
                id: "1",
            },
            { displayValue: renderStatus(textId, icon), id: "2" },
            {
                displayValue: <Button onClick={() => handleCalculationDetails(fastCalcHistory)}>{translateText(35)}</Button>,
                id: "3",
                alignContent: CellContentPosition.right,
                maxWidth: 500,
            },
            {
                displayValue: <VehicleVinField buttonTextId={buttonTextId} historyCalcId={fastCalcHistory.calcId} />,
                id: "4",
                alignContent: CellContentPosition.right,
                maxWidth: 500,
                className: "calcCell",
            },
        ]
    }

    function getRowId(element: GenericHistoryEntry) {
        const isHistorySummary = Object.hasOwn(element, "status")

        if (isHistorySummary) {
            return (element as HistorySummary).id
        }

        return (element as SavedCalculation).calcId
    }

    function getData() {
        const historyEntries = historySummaryList.historyList
        const rowList: TableRowData[] = []
        if (historyEntries) {
            historyEntries.forEach((element) => {
                rowList.push({
                    cells: cellData(element),
                    id: getRowId(element),
                })
            })
        }

        return rowList
    }

    if (historySummaryList.isLoading) {
        return <Loader />
    }

    if (historySummaryList.historyList === undefined || historySummaryList.historyList.length === 0) {
        return (
            <Box height="100%" width="100%" display="flex" justifyContent="center" flexDirection="column" alignItems="center">
                <Icon width="5em" height="5em" name="no-results" />
                <Typography variant="h1">{translateText(13501)}</Typography>
            </Box>
        )
    }

    return (
        <Box height="100%" overflow="hidden">
            <StyledTable columns={columns} rows={getData()} rowCap={5} variant="normal" headerStyle="bold" />
            <MuiCustomModal open={modalOpen} onOutsideClick={() => setModalOpen(false)}>
                <Box display="flex" flexDirection="column" gap="1em">
                    <Typography variant="h1">{translateText(13624)}</Typography>
                    <Accordion defaultExpanded>
                        <MuiAccordionSummary expandIcon={<Icon name="down" />}>
                            <Typography variant="body1" fontWeight="bold">
                                {translateText(13222)}
                            </Typography>
                        </MuiAccordionSummary>
                        <AccordionDetails>
                            <StyledBox>
                                <Typography variant="body2">{translateText(1782)}:</Typography>
                                {selectedCalculation?.maintenanceSelections && (
                                    <Typography variant="body1" fontWeight="bold">
                                        {selectedCalculation?.maintenanceSelections.length > 0
                                            ? handleDetailsInfo(selectedCalculation?.maintenanceSelections[0].selectedItems)
                                            : "-"}
                                    </Typography>
                                )}
                            </StyledBox>
                            <StyledBox>
                                <Typography variant="body2">{translateText(12635)}:</Typography>
                                {selectedCalculation?.maintenanceSelections && (
                                    <Typography variant="body1" fontWeight="bold">
                                        {selectedCalculation?.maintenanceSelections.length > 1
                                            ? selectedCalculation?.maintenanceSelections[1].label
                                            : "-"}
                                    </Typography>
                                )}
                            </StyledBox>
                            <StyledBox>
                                <Typography variant="body2">{translateText(612)}:</Typography>
                                {selectedCalculation?.maintenanceSelections && (
                                    <Typography variant="body1" fontWeight="bold">
                                        {selectedCalculation?.maintenanceSelections.length > 2
                                            ? handleDetailsInfo(selectedCalculation?.maintenanceSelections[2].selectedItems)
                                            : "-"}
                                    </Typography>
                                )}
                            </StyledBox>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <MuiAccordionSummary expandIcon={<Icon name="down" />}>
                            <StyledBox>
                                <Typography variant="body1" fontWeight="bold">
                                    {translateText(644)}
                                </Typography>
                            </StyledBox>
                        </MuiAccordionSummary>
                        <AccordionDetails>
                            {selectedCalculation?.selectedAdditionalWorks?.map((selectedAdditionalWork, idx) => {
                                return (
                                    <Typography key={idx} variant="body1">
                                        {selectedAdditionalWork}
                                    </Typography>
                                )
                            })}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <MuiAccordionSummary expandIcon={<Icon name="down" />}>
                            <StyledBox>
                                <Typography variant="body1" fontWeight="bold">
                                    {translateText(797)}
                                </Typography>
                            </StyledBox>
                        </MuiAccordionSummary>
                        <AccordionDetails>
                            {selectedCalculation?.selectedFollowUpWorks?.map((selectedFollowUpWork, idx) => {
                                return (
                                    <Typography key={idx} variant="body1">
                                        {selectedFollowUpWork}
                                    </Typography>
                                )
                            })}
                        </AccordionDetails>
                    </Accordion>
                    <StyledBox>
                        <Typography variant="body1" fontWeight="bold" paddingLeft="1em">
                            {translateText(53)}
                        </Typography>
                        <Typography variant="body1" fontWeight="bold">
                            {selectedCalculation?.price.toFixed(2)} {selectedCalculation?.currencyCode}
                        </Typography>
                    </StyledBox>
                </Box>
            </MuiCustomModal>
        </Box>
    )
}

const StyledTable = styled(Table)({
    "& .TableCell__OverflowWrapper": {
        width: "100%",
    },
    ". TableCell": {
        width: "100%",
    },
    ".calcCell": {
        opacity: 1,
        justifyContent: "end",
    },
})

const StyledBox = styled(Box)({
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
})
