import { Box, Button, Icon, LinearProgress, Paper, PaperProps, Stack, styled, Typography, TypographyProps } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ERIKContentService } from "@tm/data"
import { useCallback } from "react"
import { FileShareOption, useFileShare } from "./useFileShare"
import ShareOptions from "./ShareOptions"
import EmailList from "./EmailList"

type FileShareProps = {
    file: ERIKContentService.FileMeta
    fileId?: string
    onShareCompleted?: (success: boolean) => void
}

export default function FileShare({ file, onShareCompleted }: FileShareProps) {
    const { translateText } = useLocalization()

    const shareCompleted = useCallback(
        (success: boolean) => {
            onShareCompleted?.(success)
        },
        [onShareCompleted]
    )

    const {
        fileUrl,
        isLoading: isRequestingLink,
        errorMessage,
        copyLink,
        generateLink,
        buttonText,
        selectedShareOption,
        setSelectedShareOption,
        setEmails,
        setSendCopy,
        send,
        sendDisabled,
    } = useFileShare(file, shareCompleted)

    return (
        <Box display="flex" flexDirection="column" gap="1em">
            <Typography variant="h4">{translateText(13776)}</Typography>

            <ShareOptions onShareOptionSelected={setSelectedShareOption} />

            <LinkPaper hasError={!fileUrl && !!errorMessage}>
                <RowStack>
                    <SharingLink link={fileUrl} errorMessage={errorMessage} flex={1} />
                    <Button
                        onClick={!fileUrl ? generateLink : copyLink}
                        startIcon={fileUrl ? <Icon name="copy" /> : undefined}
                        disabled={isRequestingLink}
                    >
                        {buttonText}
                    </Button>
                </RowStack>
                {isRequestingLink && <LinearLoader />}
            </LinkPaper>

            {selectedShareOption === FileShareOption.Email && <EmailList onEmailsChanged={setEmails} onSendEmailCopy={setSendCopy} />}
            <Box display="flex" justifyContent="flex-end">
                <Button
                    className="submit"
                    size="large"
                    color="success"
                    startIcon={<Icon name="paperplane" />}
                    disabled={sendDisabled}
                    onClick={() => send()}
                >
                    {translateText(642)}
                </Button>
            </Box>
        </Box>
    )
}

type SharingLinkProps = TypographyProps & {
    link: string | undefined
    errorMessage?: string
}

const SharingLink = styled(({ link, errorMessage, ref /** extract 'ref' here to not pass it to the typography */, ...rest }: SharingLinkProps) => {
    const { translate } = useLocalization()

    return (
        <>
            {!link && !!errorMessage && <Icon name="alert_W_warning" color="error" />}
            <Typography {...rest}>{link ?? errorMessage ?? translate(13300)}</Typography>
        </>
    )
})(({ theme, link, errorMessage }) => ({
    userSelect: link ? "all" : undefined,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    opacity: !link && !errorMessage ? theme.opacity?.disabled : undefined,
})) as React.ElementType<SharingLinkProps>

const LinkPaper = styled(Paper, {
    shouldForwardProp: (prop) => prop !== "hasError",
})<PaperProps & { hasError: boolean }>(({ theme, hasError }) => ({
    width: "600px",
    minWidth: "100%",
    position: "relative",
    border: `solid 1px ${hasError ? theme.palette.error.main : theme.palette.background.paper}`,
}))

const RowStack = styled(Stack)()
RowStack.defaultProps = {
    direction: "row",
    alignItems: "center",
    spacing: 2,
}

const LinearLoader = styled(LinearProgress)({
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
})
