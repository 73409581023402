import { Box, Grid, Typography, styled } from "@tm/components"
import { PropsWithChildren } from "react"

function ModuleHeaderSlotComponent(props: PropsWithChildren<{ title: string }>) {
    const { title, children, ...restProps } = props
    return (
        <Grid {...restProps} display="grid" gridTemplateRows="12px 1fr">
            <Typography variant="label" fontSize={9} textTransform="uppercase" height={12}>
                {title}
            </Typography>
            <Box>{children}</Box>
        </Grid>
    )
}

export const ModuleHeaderSlot = styled(ModuleHeaderSlotComponent)({
    position: "relative",
    paddingTop: 8,
    paddingBottom: 4,
    paddingLeft: 16,
    paddingRight: 16,
    "& + &:before": {
        position: "absolute",
        top: 0,
        left: 0,
        content: "''",
        borderLeft: "2px solid #c3c3c3",
        marginTop: "-.5rem",
        height: "75%",
    },
})
