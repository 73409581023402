import {
    CatalogPart,
    CustomPart,
    OePart,
    PostSendOrderAction,
    PostSendOrderActionTargetType,
    PostSendOrderActionType,
    WholesalerPart,
} from "@tm/models"

import { useUser, useWorkTask } from "@tm/context-distribution"
import { useCallback, useMemo } from "react"
import {
    mapCatalogpartToPostMessagePart,
    mapCustomPartToPostMessagePart,
    mapItemErpInformationToPostMessagePart,
    mapOePartToPostMessagePart,
    mapWholsalerPartToPostMessagePart,
} from "./mapper"
import { SendOrderPostMessagePart } from "../models"
import { ItemErpInformation } from "../data/model"

const SOURCEID = "NEXT"

export function useCreateSendOrderPostMessage() {
    const worktask = useWorkTask()
    const { userContext } = useUser()
    const vehicle = worktask?.workTask?.vehicle

    const externalSessionId = useMemo(() => {
        return userContext.externalAuthenticationTokens?.ExternalSessionId || ""
    }, [userContext.externalAuthenticationTokens])

    const getCreateSendOrderPostMessage = useCallback((parts: SendOrderPostMessagePart[], workTaskId: string): PostSendOrderAction[] => {
        return [
            {
                action: PostSendOrderActionType.PostMessage,
                target: PostSendOrderActionTargetType.ParentFrame,
                value: JSON.stringify({
                    sourceId: SOURCEID,
                    sendOrder: {
                        externalSessionId,
                        workTaskId,
                        parts,
                    },
                }),
            },
        ]
    }, [externalSessionId])

    const getMessageByWholsalerPart = useCallback((parts: WholesalerPart[], workTaskId: string) => {
        const sendOrderWholsalerParts = parts.map((part) => mapWholsalerPartToPostMessagePart(part, vehicle))
        return getCreateSendOrderPostMessage(sendOrderWholsalerParts, workTaskId)
    }, [getCreateSendOrderPostMessage, vehicle])

    const getMessageByCatalogPart = useCallback((parts: CatalogPart[], workTaskId: string) => {
        const sendOrderCatalogParts = parts.map((part) => mapCatalogpartToPostMessagePart(part, vehicle))
        return getCreateSendOrderPostMessage(sendOrderCatalogParts, workTaskId)
    }, [getCreateSendOrderPostMessage, vehicle])

    const getMessageByOePart = useCallback((parts: OePart[], workTaskId: string) => {
        const sendOrderByOeParts = parts.map((part) => mapOePartToPostMessagePart(part))
        return getCreateSendOrderPostMessage(sendOrderByOeParts, workTaskId)
    }, [getCreateSendOrderPostMessage])

    const getMessageByCustomPart = useCallback((parts: CustomPart[], workTaskId: string) => {
        const sendCustomParts = parts.map((part) => mapCustomPartToPostMessagePart(part))
        return getCreateSendOrderPostMessage(sendCustomParts, workTaskId)
    }, [getCreateSendOrderPostMessage])

    const getMessageByItemErpInformation = useCallback((erpInformations: ItemErpInformation[], workTaskId: string) => {
        const sendItemErpInfos = erpInformations.map((erpPart) => mapItemErpInformationToPostMessagePart(erpPart))
        return getCreateSendOrderPostMessage(sendItemErpInfos, workTaskId)
    }, [getCreateSendOrderPostMessage])

    return { getMessageByCatalogPart, getMessageByCustomPart, getMessageByOePart, getMessageByWholsalerPart, getMessageByItemErpInformation }
}
