import { useState, useRef, useEffect } from "react"
import { connectComponent } from "@tm/morpheus"
import { encodeUniqueId, RouteComponentProps } from "@tm/utils"
import { Dialog, Switch, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useWorkTask, useModuleNavigation } from "@tm/context-distribution"
import { channel, Vehicle } from "@tm/models"
import { withRouter } from "react-router-dom"

import { removeContextDependentIFrames } from "../../helper/remove-context-dependent-iframes"
import DialogInfo from "./subcomponents/DialogInfo"
import { ChangeDialogState } from "./business/model"
import { Actions, IActions } from "./business/actions"

type Props = RouteComponentProps & {
    actions: IActions
    state: ChangeDialogState
}

function WorktaskChangeConflictResolverComponent(props: Props) {
    const { translateText } = useLocalization()
    const { workTaskId, workTask, workTaskConflict } = useWorkTask() ?? {}
    const { closeTabs, resetTabs } = useModuleNavigation()
    const [newWorkTask, setNewWorkTask] = useState<boolean>(true)
    const [asCustomerVehicle, setAsCustomerVehicle] = useState<boolean>(false)
    const {
        actions,
        state: { openTabsLoading, openTabs },
        history,
    } = props

    const dialogRef = useRef<Dialog>(null)

    useEffect(() => {
        if (dialogRef.current && workTaskConflict) {
            actions.loadOpenTabs(workTaskConflict.currentWorkTaskId)
            dialogRef.current?.show()
        }
    }, [workTaskConflict, dialogRef.current])

    function handleConfirm() {
        if (workTaskConflict) {
            if (!newWorkTask || !workTaskConflict.options.showNewWorkTask) {
                if (workTaskConflict.data.conflictedTabs?.length) {
                    closeTabs(workTaskConflict.data.conflictedTabs.map((x) => x.url))
                } else {
                    resetTabs()
                    workTaskId && history.push(`/${encodeUniqueId(workTaskId)}`)
                    removeContextDependentIFrames()
                    // TODO: Remove this action when attachVehicleImage supports the vehicle id
                    channel("WORKTASK", workTaskId).publish("WORKTASK/RESET", {})
                }
            }
            workTaskConflict.onConfirm(newWorkTask, asCustomerVehicle)
        }
    }

    function handleNewWorkTaskChange(state: boolean) {
        setNewWorkTask(state)
    }

    function handleAsCustomerVehicleChange(state: boolean) {
        setAsCustomerVehicle(state)
    }

    if (!workTaskConflict) {
        return null
    }

    const vehicle = workTaskConflict.data && workTaskConflict.data.vehicle ? workTaskConflict.data.vehicle : undefined
    return (
        <Dialog
            confirmButtonText={translateText(316)}
            onConfirm={handleConfirm}
            onCancel={workTaskConflict.onCancel}
            onClose={workTaskConflict.onCancel}
            ref={dialogRef}
            text={workTaskConflict.data.conflictType ? translateText(13695) : translateText(921)}
            layout="stretch"
            iconName="not"
            className="worktask-change"
            skin="danger"
            time={new Date()}
        >
            <div>
                {workTaskConflict.options.showNewWorkTask && (
                    <div className="section">
                        <Switch status={newWorkTask} label={translateText(928)} onChange={handleNewWorkTaskChange} />
                        <Text size="s">{newWorkTask ? translateText(1190) : translateText(1191)}</Text>
                    </div>
                )}

                {vehicle && workTask?.customer && workTaskConflict.options.showNewCustomerVehicle && (
                    <div className="section">
                        <Switch status={asCustomerVehicle} label={translateText(923)} onChange={handleAsCustomerVehicleChange} />
                        <Text size="s">
                            {(asCustomerVehicle ? translateText(1192) : translateText(1193)).replace("{{0}}", getVehicleName(vehicle))}
                        </Text>
                    </div>
                )}

                {!!workTaskConflict.data.conflictedTabs?.length && (
                    <DialogInfo
                        actionsWillBeDone={workTaskConflict.data.conflictedTabs.map((x) => x.title)}
                        loading={openTabsLoading}
                        intro={translateText(1189)}
                    />
                )}

                {!workTaskConflict.data.conflictedTabs && !!openTabs?.length && (
                    <DialogInfo actionsWillBeDone={openTabs.map((tab: any) => tab.title)} loading={openTabsLoading} intro={translateText(1189)} />
                )}
            </div>
        </Dialog>
    )
}

export default connectComponent(Actions, withRouter(WorktaskChangeConflictResolverComponent))

function getVehicleName(vehicle: Vehicle | string) {
    if (typeof vehicle == "string") {
        return vehicle
    }
    return `${vehicle.manufacturer} ${vehicle.modelSeries} ${vehicle.model}`
}
