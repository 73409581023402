import { ImageMapAreaV2Dto, PartSectionsListV2Dto, PartsListByCategoryV2Dto, UnitShortListV2Dto, UnitShortV2Dto, UnitV2Dto } from "../../../models"
import { MainSlice } from "./model"

export function setUnitParts(state: MainSlice, unitParts: PartSectionsListV2Dto | undefined): Partial<MainSlice> {
    return {
        graphic: {
            ...state.graphic,
            loading: false,
            error: false,
            unitParts,
        },
    }
}

export function setUnitInfo(state: MainSlice, unitInfo: UnitV2Dto | undefined): Partial<MainSlice> {
    return {
        graphic: {
            ...state.graphic,
            loading: false,
            error: false,
            unitInfo,
        },
    }
}

export function setSelectedUnit(state: MainSlice, unit: UnitShortV2Dto | undefined): Partial<MainSlice> {
    return {
        graphic: {
            ...state.graphic,
            selectedUnit: unit,
        },
    }
}

export function setLoading(state: MainSlice, loading: boolean): Partial<MainSlice> {
    return {
        graphic: {
            ...state.graphic,
            loading,
            error: false,
        },
    }
}

export function setError(state: MainSlice, error: boolean): Partial<MainSlice> {
    return {
        graphic: {
            ...state.graphic,
            loading: false,
            error,
        },
    }
}

export function setGroup(state: MainSlice, group: PartsListByCategoryV2Dto | undefined): Partial<MainSlice> {
    return {
        graphic: {
            ...state.graphic,
            group,
            loading: false,
            error: false,
        },
    }
}

export function setUnit(state: MainSlice, unit: UnitShortListV2Dto | undefined): Partial<MainSlice> {
    return {
        graphic: {
            ...state.graphic,
            unit,
            loading: false,
            error: false,
        },
    }
}

export function setSelectedArea(state: MainSlice, area: ImageMapAreaV2Dto | undefined): Partial<MainSlice> {
    return {
        graphic: {
            ...state.graphic,
            selectedArea: area,
        },
    }
}
