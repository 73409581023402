import { useQuery, QueryClient } from "react-query"
import { useUser } from "@tm/context-distribution"
import * as Data from "../../.."
import { useWorkTaskBasketStore } from "../workflow/useWorkTaskBasketStore"

export const KEY = "basket_useWorkTaskBasket_showWorkTaskBasket"
const staleTime = 10 * 60 * 1000 // 10 Minutes

export function useWorkTaskBasketData(workTaskId: string, useCostEstimation: boolean, enabled: boolean) {
    const { userSettings } = useUser()
    const workTaskBasketIndex = useWorkTaskBasketStore((store) => store.getWorkTaskBasketIndex(workTaskId))
    const QUERY_KEY = [KEY, workTaskId, userSettings?.itemSorting, useCostEstimation, workTaskBasketIndex]

    const {
        data,
        isLoading: workTaskBasketLoading,
        isError: workTaskBasketWithError,
    } = useQuery(QUERY_KEY, () => Data.showWorkTaskBasket({ workTaskId, orderByDateAscending: userSettings?.itemSorting, useCostEstimation }), {
        enabled: !!userSettings && enabled,
        staleTime,
    })

    return {
        workTaskBasket: data,
        workTaskBasketLoading,
        workTaskBasketQueryKey: QUERY_KEY,
        workTaskBasketWithError,
    }
}

export function getCachedWorkTaskBasketData(
    queryClient: QueryClient,
    workTaskId: string,
    enabled: boolean,
    useCostEstimation: boolean,
    itemSorting: boolean | undefined
) {
    if (!enabled) {
        return Promise.resolve(undefined)
    }

    const workTaskBasketIndex = useWorkTaskBasketStore.getState().getWorkTaskBasketIndex(workTaskId)

    const QUERY_KEY = [KEY, workTaskId, itemSorting, useCostEstimation, workTaskBasketIndex]

    return queryClient.fetchQuery(QUERY_KEY, () => Data.showWorkTaskBasket({ workTaskId, orderByDateAscending: itemSorting, useCostEstimation }), {
        staleTime,
    })
}
