import { useUser, useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Article, DMS, DmsArticleInfo } from "@tm/models"
import { SeparatorListItem } from "@tm/controls"
import { encodeUniqueId, renderRoute, uniqueId } from "@tm/utils"
import { useHistory } from "react-router"
import { useDmsPermissions, useGsiConnectVersion } from "../components/hooks"
import { connectionChannel } from "../business/messaging"
import { mapStockItemsFromOldGsiConnectService, showAvailabilityBuffered } from "../components/article-info/business"
import { mapArticleToLookupItem } from "../components/article-info/mapArticleToLookupItem"
import { getDateFormat } from "../business"
import { getBundleParams } from "../utils"

export function useDmsArticleInfo(article: Article): DmsArticleInfo {
    const { workTask, workTaskId } = useWorkTask() ?? {}
    const { permittedOperations } = useDmsPermissions()
    const gsiConnectVersion = useGsiConnectVersion()
    const { translateText, currency, date, languageId, number } = useLocalization()
    const { userSettings } = useUser() ?? {}
    const vehicleReferenceId = workTask?.vehicle?.refId
    const history = useHistory()

    const [stockItem, setStockItem] = useState<DMS.StockItem>()

    useEffect(() => {
        ;(async () => {
            const isConnected = connectionChannel().last(1, "CONNECTION_STATUS_RECEIVED")[0]?.content?.isConnected

            if (isConnected && permittedOperations.includes("showAvailability")) {
                try {
                    const stockItems = await showAvailabilityBuffered({ vehicleReferenceId, items: [mapArticleToLookupItem(article)] })
                    const firstItem = mapStockItemsFromOldGsiConnectService(stockItems.first(), gsiConnectVersion)
                    setStockItem(firstItem)
                } catch {}
            }
        })()
    }, [vehicleReferenceId, article, permittedOperations, gsiConnectVersion])

    const voucherInfoItems = useMemo(() => {
        const voucher = stockItem?.vouchers?.[0]

        if (!voucher) {
            return
        }

        const items: Array<SeparatorListItem> = []

        if (voucher.plateId) {
            items.push({
                label: translateText(21),
                value: voucher.plateId,
            })
        }

        if (voucher.creationDate) {
            items.push({
                label: translateText(98),
                value: date(voucher.creationDate, getDateFormat(languageId)),
            })
        }

        if (voucher.mileage !== undefined) {
            items.push({
                label: translateText(125),
                value: number(voucher.mileage, 0),
            })
        }

        return items
    }, [stockItem?.vouchers, translateText, date, languageId, number])

    const stockInfoItems = useMemo(() => {
        if (!stockItem) {
            return
        }

        const items: Array<SeparatorListItem> = []

        stockItem.warehouses
            .filter((x) => x.name)
            .forEach((x) =>
                items.push({
                    label: translateText(1983),
                    value: x.name,
                })
            )

        if (stockItem.quantity.value !== undefined) {
            items.push({
                label: translateText(1984),
                value: `${stockItem.quantity.value}`,
            })
        }

        stockItem.prices
            .filter((x) => x.value !== undefined && (userSettings?.showPurchasePrice || x.type !== DMS.PriceType.Purchase))
            .forEach((x) =>
                items.push({
                    label: x.description,
                    value: currency(x.value ?? 0, x.currencySymbol ?? ""),
                })
            )

        if (stockItem.description) {
            items.push({
                label: translateText(25),
                value: stockItem.description,
            })
        }

        return items
    }, [currency, stockItem, translateText, userSettings?.showPurchasePrice])

    const handleVoucherClick = useCallback(
        (voucher: DMS.VoucherReference) => {
            const { dmsModuleUrl } = getBundleParams()
            if (!dmsModuleUrl) {
                return
            }

            const url = renderRoute(dmsModuleUrl, {
                workTaskId: encodeUniqueId(workTaskId ?? uniqueId()),
                voucherReferenceId: voucher.referenceId,
            })

            history.push(url)
        },
        [history, workTaskId]
    )

    return {
        stockInfoItems,
        voucherInfoItems,
        stockItem,
        permittedOperations,
        handleVoucherClick,
    }
}
