import { WheelsState } from "../model"
import { RimItemsRequest } from "../../../../data/repositories/wheels-loadRimItems/model"
import { mapSelectedFiltersForRequest } from "../helper"
import { getBundleParams } from "../../../../utils"

export function createNextRimArticlesRequest(state: WheelsState): RimItemsRequest | undefined {
    const { base: { selectedFilters, carPark, articles: { pageIndex, hasMoreItems }, isAdditionalManufacturerSelected } } = state

    if (!hasMoreItems)
        return

    if (carPark)
    return {
        carparkId: carPark.carparkId,
        rimfilter: mapSelectedFiltersForRequest(selectedFilters) || undefined,
        pageIndex: pageIndex + 1,
        pageSize: getBundleParams().wheelsPagingSize,
        isAdditionalManufacturerSelected
    }
}