import { RepairTimeOptions, useUser, useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import {
    ActiveVehicleDataProviders,
    AddRepairTimeListRequest,
    Article,
    ErpInformation,
    GetMainServicesRequest,
    GetMainServicesResponse,
    RegisteredModels,
    RepairTimeProvider,
    Vehicle,
} from "@tm/models"
import Morpheus from "@tm/morpheus"
import { Container } from "@tm/nexus"
import {
    getRepairTimeProviders,
    getRepairTimesProvider,
    getRepairTimesProviderEnumByString,
    getRepairTimesProviderStringByEnum,
    mapMainServicesToAddRepairTimeListRequest,
    renderRoute,
    showWarehouseDataMissingError,
    useDefaultErpSystem,
} from "@tm/utils"
import { useCallback, useMemo } from "react"
import { useRouteMatch } from "react-router"
import { useSupplierArticleQuantities } from "../../../../../../basket/src/data/hooks/useSupplierArticleQuantities"
import { useWarehouse } from "../../../../../../basket/src/data/hooks/useWarehouse"
import { useAddArticleExternalUrl } from "../../../../../../basket/src/helpers"
import { getBundleParams } from "../../../../utils"
import { useVehicle } from "../useVehicle"
import { useBasketWorks } from "../../../../../../basket/src/hooks/basketState/useBasketWorks"
import { useActiveVehicleDataProvider } from "../useActiveVehicleDataProvider"

export function useHandleAddToCostEstimation(
    article: Article,
    handleAddToBasket: (
        quantity: number,
        erpInfo?: ErpInformation | null,
        erpSystemId?: number,
        enableCostEstimation?: boolean
    ) => Promise<unknown> | undefined,
    activeVehicleDataProviders?: ActiveVehicleDataProviders,
    externalCatalogUrl?: string,
    repairTimeProviders?: RepairTimeProvider[]
) {
    const { translateText, languageId } = useLocalization()
    const { workTaskId, workTask } = useWorkTask() ?? {}
    const { userSettings } = useUser()
    const vehicle = useVehicle()
    const routeMatch = useRouteMatch()

    const { addRepairTimeList } = useBasketWorks()

    const { erpConfig } = useDefaultErpSystem()
    const erpSystemConfig = useMemo(() => {
        if (!erpConfig?.erpSystemConfigs?.length) {
            return
        }
        return erpConfig.erpSystemConfigs[0]
    }, [erpConfig])

    const warehouseData = useWarehouse(article?.id, erpSystemConfig?.id)
    const supplierArticleQuantities = useSupplierArticleQuantities(workTaskId, article, true) // Note: currently only working correctly for only one article
    const currentProviders = useActiveVehicleDataProvider()

    const repairTimeProvidersFromParams = getRepairTimeProviders().repairTimeProviders

    const externalBasketUrl = useAddArticleExternalUrl(
        // Note: currently only working correctly for only one article
        externalCatalogUrl,
        languageId,
        article.quantity,
        article.traderArticleNo,
        article.supplierArticleNo,
        article.supplier.id,
        article.productGroup.id
    )

    const getRepairTimesUrl = useCallback(
        (rtProviders: RepairTimeProvider | Array<RepairTimeProvider>) => {
            const { partsRoutes } = getBundleParams()

            if (article.productGroup && vehicle?.id && repairTimeProviders) {
                let provider
                if (Array.isArray(rtProviders)) {
                    let activeRTProvider = currentProviders?.repairTimes
                    if (!activeRTProvider && userSettings?.activeVehicleDataProviders.repairTimes) {
                        activeRTProvider = userSettings.activeVehicleDataProviders.repairTimes
                    }

                    provider = getRepairTimesProvider(rtProviders, repairTimeProvidersFromParams, activeRTProvider)
                } else {
                    provider = getRepairTimesProviderStringByEnum(rtProviders)
                }

                if (!provider || !partsRoutes) {
                    return
                }

                return decodeURIComponent(
                    renderRoute(partsRoutes.repairTimesRoute, {
                        ...routeMatch.params,
                        provider,
                        productGroupId: article.productGroup.id,
                        supplierId: article.supplier.id,
                        supplierArticleNo: article.supplierArticleNo.replace(/\//g, "%252F"),
                        position: article.fittingSide,
                    })
                )
            }
        },
        [article, currentProviders?.repairTimes, routeMatch.params, userSettings?.activeVehicleDataProviders.repairTimes, vehicle?.id]
    )

    const addArticlesToBasket = useCallback(
        (quantity: number, erpInfo?: ErpInformation, erpSystemId?: number) => {
            if (!workTaskId) {
                return
            }

            return handleAddToBasket(quantity, erpInfo, erpSystemId, true)
        },
        [handleAddToBasket, workTaskId]
    )
    const addRepairTimeToBasket = useCallback(() => {
        const rtProvidersFromBundle = repairTimeProvidersFromParams

        if (!workTaskId || !vehicle || !repairTimeProviders?.length || !rtProvidersFromBundle.length) {
            return
        }

        const selectedRepairTimesProvider = getRepairTimesProviderEnumByString(
            getRepairTimesProvider(
                repairTimeProviders,
                rtProvidersFromBundle,
                activeVehicleDataProviders?.repairTimes ?? userSettings?.activeVehicleDataProviders?.repairTimes
            )
        )

        if (!selectedRepairTimesProvider || !userSettings?.repairTimeOptions) {
            showModal(getRepairTimesUrl(repairTimeProviders[0]))
            return
        }

        return addRepairTimesToBasket(
            article, // Note: currently only working correctly for only one article
            workTaskId,
            vehicle,
            selectedRepairTimesProvider,
            userSettings.repairTimeOptions,
            addRepairTimeList
        ).then(({ showRepairTimesModal }) => {
            if (showRepairTimesModal) {
                showModal(getRepairTimesUrl(selectedRepairTimesProvider))
            }
        })
    }, [
        activeVehicleDataProviders?.repairTimes,
        addRepairTimeList,
        article,
        getRepairTimesUrl,
        repairTimeProviders,
        userSettings?.activeVehicleDataProviders?.repairTimes,
        userSettings?.repairTimeOptions,
        vehicle,
        workTaskId,
    ])

    const addToBasket = useCallback(
        (quantity: number, erpInfo?: ErpInformation, erpSystemId?: number) => {
            if (!workTask) {
                return
            }

            const resolver = []

            // Only add article if it is not already in basket
            if (!supplierArticleQuantities?.isInOrder) {
                if (externalBasketUrl) {
                    showModal(externalBasketUrl)
                } else {
                    resolver.push(addArticlesToBasket(quantity, erpInfo, erpSystemId))
                }
            }

            // // TODO: Create BasketContainer action for this and remove it here and in add-to-basket micro
            // if (props.vehicleImageBase64) {
            //     attachVehicleImage(workTask.id, props.vehicleImageBase64)
            // }

            if (workTask.vehicle) {
                resolver.push(addRepairTimeToBasket())
            }

            return Promise.all(resolver)
        },
        [addArticlesToBasket, addRepairTimeToBasket, externalBasketUrl, supplierArticleQuantities?.isInOrder, workTask]
    )

    const handleClickAddToCostEstimation = useCallback(
        async (quantity: number, erpInfo?: ErpInformation, erpSystemId?: number) => {
            if (warehouseData.hasErrors) {
                showWarehouseDataMissingError(translateText)
                warehouseData.refetchWarehouseData()
                return
            }

            if (erpInfo?.itemOrderableInformation) {
                console.log("TODO: SHOW ORDER WARNING PROMPT")
                // dialogRef.current?.show()
            } else {
                return addToBasket(quantity, erpInfo, erpSystemId)
            }
        },
        [warehouseData, translateText, addToBasket]
    )

    return handleClickAddToCostEstimation
}

function showModal(url: string | undefined) {
    if (url) {
        Morpheus.showView("1", url)
    }
}

function addRepairTimesToBasket(
    article: Article,
    workTaskId: string,
    vehicle: Vehicle,
    repairTimeProvider: RepairTimeProvider,
    repairTimeOptions: RepairTimeOptions,
    addRepairTimeList: (request: AddRepairTimeListRequest) => void
): Promise<{ showRepairTimesModal: boolean }> {
    const request: GetMainServicesRequest = {
        repairTimeProvider,
        modelId: vehicle.tecDocTypeId,
        productGroupId: article.productGroup.id.toString(),
        vehicleType: vehicle.vehicleType,
        fittingSideFilter: article.fittingSide,
        manufacturerId: vehicle.tecDocManufacturerId,
        defaultRepairTimeDivision: repairTimeOptions.division,
        useManufacturerRepairTimeDivision: repairTimeOptions.useManufacturerRepairTimeDivision,
    }

    return new Promise((resolve, reject) => {
        // TODO: create and use container action
        Container.getInstance<GetMainServicesResponse>(RegisteredModels.RepairTimes_MainServices)
            .subscribe(request)
            .load()
            .then(({ mainServices, repairTimeDivision }) => {
                if (!mainServices.length) {
                    reject()
                    return
                }

                if (mainServices.length > 1 || Morpheus.getParams("basket").alwaysShowRepairTimesModalAfterCostEstimationButtonClick) {
                    resolve({ showRepairTimesModal: true })
                    return
                }

                const service = mainServices[0]

                const addRepairTimeListRequest = mapMainServicesToAddRepairTimeListRequest(
                    workTaskId,
                    repairTimeProvider,
                    [
                        {
                            ...service,
                            productGroupIds:
                                !service.productGroupIds || service.productGroupIds.length // TODO: check if "service.productGroupIds.length" shouldn't rather be negotiaded
                                    ? [article.productGroup.id]
                                    : service.productGroupIds,
                        },
                    ],
                    vehicle.id,
                    repairTimeDivision,
                    article.fittingSide
                )
                addRepairTimeList(addRepairTimeListRequest)

                resolve({ showRepairTimesModal: false })
            })
    })
}
