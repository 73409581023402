import { ajax } from "@tm/utils"
import { CheckQueuedCalculationsResponse } from "./model"

export function checkQueuedCalculations(checkStatusUrl: string) {
    const url = `${checkStatusUrl}`
    return new Promise<CheckQueuedCalculationsResponse>((resolve, reject) =>
        ajax<number>({ url, method: "GET" }).then((data) => {
            if (data === 2) {
                return resolve({ done: true })
            }

            resolve({ done: false })
        }, reject)
    )
}
