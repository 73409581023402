import { Dialog } from "@tm/components"
import { useDispatch, useSelector } from "react-redux"
import { CalcState } from "../../../data/model"
import { MainActions } from "../../main/business"
import { StatusSelector } from "../../../data/helpers"
import Content from "./shared/Content"

type Props = {
    calcState: CalcState
}

export default function SelectionDialog({ calcState }: Props) {
    const { loading } = useSelector(StatusSelector)
    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(MainActions.closeSelectionDialog(calcState.type))
    }

    return (
        <Dialog open onOutsideClick={handleClose} fullWidth maxWidth="md" position="top">
            <Content calcState={calcState} loading={loading} onClose={handleClose} />
        </Dialog>
    )
}
