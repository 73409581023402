import { Box, ScrollContainer, Typography } from "@tm/components"
import { Modal } from "@tm/controls"
import {
    ActiveVehicleDataProviders,
    Article,
    ArticleAttribute,
    ArticleIdentifier,
    ArticleInfoType,
    AttributeAction,
    AttributeFilterModel,
    ConfigParams,
    ErpContainer,
    LinkedItemsRole,
    LogEntry,
    OrderItem,
    RegisteredModels,
    RegistrationNoType,
    RepairTimeProvider,
    RequestArticleDetailsPayload,
    SearchFilters,
    UserContext,
    VehicleRecordsContainer,
    channel,
} from "@tm/models"
import Morpheus, { connectComponent, importMicro } from "@tm/morpheus"
import { Container, IModelContainer } from "@tm/nexus"
import {
    TmaHelper,
    bindSpecialReactMethods,
    classes,
    createErpInformationRequestItem,
    createQueryString,
    encodeUniqueId,
    equals,
    getArticleBonusSystemsWithoutPoints,
    getCategoryTypeFromUrl,
    getRepairTimeProviders,
    getRepairTimesProvider,
    getRepairTimesProviderStringByEnum,
    isCentralOrder,
    parseQueryString,
    renderRoute,
    withRouter,
} from "@tm/utils"
import { Component, ReactNode, createRef } from "react"

import { UserSettings, UserSettingsKeys, WorkTaskInfo } from "@tm/context-distribution"
import { ILocalizationContext } from "@tm/localization"
import { SearchType } from "../../business"
import {
    mapAttributeToVehicleRecordSingleEventRequest,
    mapFilterToVehicleRecordSingleEventRequest,
    mapVehicleRecordArticleAddedToBasketEventRequest,
} from "../../data/mapper/vehicle-records"
import { PartsListImageCoordinate } from "../../data/model"
import { getArticlesByArticleNoWithOptVehicle, getArticlesByWholesalerArticleNos } from "../../data/repositories"
import { hasVehicleRecordDatVinOnly, publishModuleInfo } from "../../helper"
import { getBundleParams } from "../../utils"
import { openPartDetailsInModal } from "../../utils/modalHelper"
import ArticleSelection from "../_shared/articleSelection/article-selection"
import OeArticle from "../_shared/oe/oe-article"
import { Actions } from "./business"
import {
    getArticleUniqueId,
    getArticlesFromTraderArticleDictionary,
    getFiltersFromUrlQuery,
    getInternalIdFromArticleUniqueId,
    getTelesalesCustomerNo,
} from "./business/helpers"
import { ArticleGroupHeader } from "./components/ArticleGroupHeader/ArticleGroupHeader"
import { FastCalculatorAlternatives } from "./components/FastCalculatorAlternatives"
import { FiltersAnchor } from "./components/FilterAnchor"
import { ListContent } from "./components/ListContent"
import { LoadArticlesButton } from "./components/LoadArticlesButton"
import { NoSearch } from "./components/NoSearch"
import { OeInformation } from "./components/OeInformation"
import { PartToReplace } from "./components/PartToReplace"
import { Status } from "./components/Status"
import { ArticleComparison } from "./components/comparison"
import OePositions from "./components/oe/oe-positions"
import OeReplacements from "./components/oe/oe-replacements"
import SensitiveGraphics from "./components/sensitive-graphic"
import { castToArticleAttribute, getConfigProps, getSearchTypeFromUrl, getStatus, getTmaInfos, getUserSettings } from "./components/utils"
import WholesalerItem from "./components/wholesaler-item"
import { getComponentStyles } from "./styles"
import { WrapperTypes } from "./wrapper"
import { createOeInformationConfiguration } from "../ListV2/ArticleListConfiguration/useOeInformationConfiguration"
import { ReplaceButton } from "../_shared/article-item/replaceButton"

export type Props = WrapperTypes & {
    userContext: UserContext
    workTask?: WorkTaskInfo
    userSettings?: UserSettings
    setUserSetting(key: UserSettingsKeys, value: any): Promise<UserSettings>
    localization: ILocalizationContext
}

const TRANSLATION_REGEX = /\{\{(.*?)\}\}/g

const classNames = getComponentStyles()

type State = {
    partToReplaceId?: string
    partToReplaceData?: { orderItem: OrderItem; article?: Article } // { type: "Article", data: Article } | { type: "OrderItem", data: OrderItem }
    partToReplaceErpAlternatives?: Array<Article>

    showArticleComparision: boolean

    showLoadArticlesButton: boolean
    loadUntilFirstAvailableArticle: boolean
}

@importMicro
class ListComponent extends Component<Props, State> {
    private scrollToHelper = createRef<HTMLDivElement>()

    private articelListScroller = createRef<HTMLDivElement>()

    private loadingElementRef = createRef<HTMLDivElement>()

    private unsubscribe: Function

    unregisterViewStateLoaded?: () => void

    unregisterViewStateUpdated?: () => void

    unsubscribeArticleQuantityChange?: () => void

    unsubscribeModalClosed?: () => void

    ViewState_ActiveProviders: IModelContainer<{ key: string; value?: ActiveVehicleDataProviders }>

    currentProviders?: ActiveVehicleDataProviders

    constructor(props: Props) {
        super(props)

        bindSpecialReactMethods(this)
        this.state = {
            showArticleComparision: false,
            showLoadArticlesButton: false,
            loadUntilFirstAvailableArticle: false,
        }
        this.getRepairTimesUrl = this.getRepairTimesUrl.bind(this)
        this.handleShowArticleFeedback = this.handleShowArticleFeedback.bind(this)
        this.openArticleComparision = this.openArticleComparision.bind(this)
        this.ViewState_ActiveProviders = Container.getInstance(RegisteredModels.ViewState)

        let differentVehicle: boolean
        this.unsubscribe = props.actions.init(
            () => {
                if (!differentVehicle) {
                    return
                }

                const { searchType } = this.props.state

                switch (searchType) {
                    case SearchType.SYNONYM:
                    case SearchType.NODE:
                    case SearchType.PRODUCTGROUPS:
                    case SearchType.OE:
                    case SearchType.OE_POSITIONS:
                        this.props.actions.loadFilters()
                        break
                    case SearchType.TRADERARTICLENOS:
                        // Loading the articles for this search type is being triggered with redux when the component isn't rendered, so the vehicle is not set/outdated.
                        // With this workaround the articles are reloaded on mount after the correct vehicle is loaded.
                        this.props.actions.loadArticles()
                        break
                    case SearchType.DIRECT:
                        const query = this.props.state.usedFilters.query?.text || this.props.state.initialFilters?.query?.text

                        const { enableOeInformation } = this.props.state.oeInformationConfiguration

                        if (enableOeInformation && !this.props.state.initialFilters?.oePositions?.length && !!query) {
                            this.props.actions.loadOeInformation(query, this.props.state.initialFilters?.oeInformationWithoutCar)
                        }
                        break
                    default:
                }
            },
            (vehicle) => (differentVehicle = this.props.state.usedFilters.vehicle?.id != vehicle.id)
        )
    }

    componentWillUnmount() {
        if (!this.props.state.dontResetStateOnUnload) {
            this.props.actions.resetState() // NEXT-20101: The next time the list is called, all articles are otherwise still in the state. The ERP information might be loaded again before the next articles are loaded.
        }
        this.unregisterViewStateLoaded?.()
        this.unregisterViewStateUpdated?.()
        this.unsubscribeArticleQuantityChange?.()
        this.unsubscribeModalClosed?.()
        this.unsubscribe()
    }

    UNSAFE_componentWillMount() {
        const { routeKey, actions, location, match, moduleProps } = this.props
        const configProps = getConfigProps(this.props)
        const userSettings = getUserSettings(this.props)
        const compactViewEnabled = userSettings.compactView === undefined ? configProps.compactViewAsDefault : userSettings.compactView

        const oeInformationConfiguration = createOeInformationConfiguration(this.props.userContext)

        // set properties from configuration which are needed for the state
        actions.setConfigProperties(
            routeKey,
            configProps.groupParts || false,
            configProps.standaloneMode || false,
            compactViewEnabled,
            !!moduleProps?.publishModuleInfo,
            oeInformationConfiguration
        )

        TmaHelper.ArticleListFiltered.ArticleListFiltered.List.ViewOptions({
            compactView: compactViewEnabled,
            showArticleImages: !!this.props.state.showArticleImages,
            showVehicleRecordsFilters: !!getBundleParams().vehicleRecordsEnabled,
        })

        if (!configProps.standaloneMode) {
            return
        }

        actions.setListUrl(getCategoryTypeFromUrl(match.url), location.pathname + location.search)

        const searchType = getSearchTypeFromUrl(this.props.match.params.searchType)
        if (!searchType) {
            return
        }

        if (searchType !== this.props.state.searchType) {
            actions.setSearchType(searchType)
        }

        const initialData = getFiltersFromUrlQuery(location.search, location.state as any)

        if (userSettings.useEngineCodeFilter && this.props.state.usedFilters.vehicle) {
            initialData.engineCode = this.props.state.usedFilters.vehicle.engineCode
        }

        if (searchType) {
            const queryInfo = initialData?.query?.text ? `"${initialData.query.text}"` : ""
            switch (searchType) {
                case SearchType.UNINODE:
                case SearchType.UNIPRODUCTGROUPS:
                case SearchType.UNISEARCH: {
                    publishModuleInfo("{{1009}}", queryInfo)
                    break
                }
                case SearchType.OFFERS: {
                    publishModuleInfo("{{1276}}", this.props.state.usedFilters.offers?.advertisementCategory.description || "")
                    break
                }
                default: {
                    publishModuleInfo("{{389}}", queryInfo)
                }
            }
        }
    }

    componentDidMount() {
        const {
            scrollToRoute,
            state: { result, scrollTop },
        } = this.props

        if (!result.loading && !result.page.loading && result.parts.length) {
            if (this.scrollToHelper.current && scrollToRoute && scrollToRoute === this.props.match.params.scrollTo) {
                window.scrollTo(0, this.scrollToHelper.current.offsetTop)
            } else if (scrollTop !== undefined && this.articelListScroller.current) {
                this.articelListScroller.current?.scrollTo()
            }
        }
        this.subscribeToViewState()
        this.subscribeToModalClose()
        this.unsubscribeArticleQuantityChange = this.subscribeToArticleQuantityChange(this.props)
        this.processPartToReplaceId(this.props.location.search)

        const { searchType } = this.props.state

        // special special for NEXT-19648
        if (searchType === SearchType.DIRECT && this.props.userContext?.parameter?.catalogLight) {
            this.props.actions.setSearchType(SearchType.HIDE)
        }

        switch (searchType) {
            case SearchType.SYNONYM:
            case SearchType.NODE:
            case SearchType.PRODUCTGROUPS:
            case SearchType.OE:
            case SearchType.OE_POSITIONS:
                this.props.actions.loadFilters()
                break
            case SearchType.TRADERARTICLENOS:
                // Loading the articles for this search type is being triggered with redux when the component isn't rendered, so the vehicle is not set/outdated.
                // With this workaround the articles are reloaded on mount after the correct vehicle is loaded.
                this.props.actions.loadArticles()
                break
            default:
        }

        // eslint-disable-next-line no-console
        console.log("List routeKey", this.props.routeKey)
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const { state: thisState, routeKey } = this.props
        const { state: nextState, actions, location, match, history, workTask } = nextProps
        const configProps = getConfigProps(nextProps)
        const userSettings = getUserSettings(nextProps)

        if (
            !equals(thisState.usedFilters.vehicle?.id, nextState.usedFilters.vehicle?.id) &&
            !equals(nextState.usedFilters.vehicle?.id, workTask?.vehicle?.id)
        ) {
            TmaHelper.ArticleListFiltered.ArticleListFiltered.Search.AttachVehicle(nextState.usedFilters.vehicle)
        }

        this.unsubscribeArticleQuantityChange?.()
        this.unsubscribeArticleQuantityChange = this.subscribeToArticleQuantityChange(nextProps)

        const propsUserSettings = this.props.userSettings

        if (
            propsUserSettings?.articleListSettings?.viewOptions &&
            propsUserSettings.articleListSettings.viewOptions.compactView !== userSettings.compactView
        ) {
            const isCompactView = userSettings.compactView
            const compactView = isCompactView !== undefined ? isCompactView : configProps.compactViewAsDefault

            if (propsUserSettings) {
                TmaHelper.ArticleListFiltered.ArticleListFiltered.List.ViewOptionsChanged({
                    compactView,
                    showArticleImages: !!this.props.state.showArticleImages,
                    showVehicleRecordsFilters: !!getBundleParams().vehicleRecordsEnabled,
                })
            }

            actions.setConfigProperties(
                routeKey,
                thisState.groupParts!,
                thisState.standaloneMode!,
                compactView,
                !!publishModuleInfo,
                this.props.state.oeInformationConfiguration
            )
        }

        if (this.props.userSettings && nextProps.state.showArticleImages != this.props.state.showArticleImages) {
            TmaHelper.ArticleListFiltered.ArticleListFiltered.List.ViewOptionsChanged({
                compactView: userSettings.compactView === undefined ? configProps.compactViewAsDefault : userSettings.compactView,
                showArticleImages: !!nextProps.state.showArticleImages,
                showVehicleRecordsFilters: !!getBundleParams().vehicleRecordsEnabled,
            })
        }

        if (!configProps.standaloneMode) {
            return
        }
        const urlSearchType = getSearchTypeFromUrl(nextProps.match.params.searchType)

        if (nextState.searchType === urlSearchType && nextState.searchType !== thisState.searchType) {
            const nextPath = renderRoute(match.path, {
                ...match.params,
                searchType: SearchType[nextState.searchType].toLowerCase(),
            })

            if (nextPath !== location.pathname) {
                history.push(nextPath + location.search)
                return
            }
        }

        // Check if the url has changed
        if (this.props.location.pathname + this.props.location.search != location.pathname + location.search) {
            actions.setListUrl(getCategoryTypeFromUrl(match.url), location.pathname + location.search)
        }

        if (!urlSearchType) {
            return
        }

        if (nextState.searchType !== urlSearchType) {
            actions.setSearchType(urlSearchType)
        }

        const urlFilters = getFiltersFromUrlQuery(location.search, location.state as any)

        if (userSettings.useEngineCodeFilter && this.props.state.usedFilters.vehicle) {
            urlFilters.engineCode = this.props.state.usedFilters.vehicle.engineCode
        }

        if (this.isForcedToReload(nextProps) || !equals(nextState.initialFilters, urlFilters)) {
            actions.initializeFilters(urlFilters)
            this.setState({ loadUntilFirstAvailableArticle: false })
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { result: prevResult, initialFilters: prevInitialFilters, searchType: prevSearchType, usedFilters: prevUsedFilters } = prevProps.state
        const { result: thisResult, initialFilters: thisInitialFilters, searchType: thisSearchType, usedFilters: thisUsedFilters } = this.props.state

        const queryInfo = thisInitialFilters?.query?.text ? `"${thisInitialFilters.query.text}"` : ""
        if (
            prevSearchType !== thisSearchType ||
            prevInitialFilters?.query?.text !== thisInitialFilters?.query?.text ||
            prevUsedFilters.offers?.advertisementCategory.description !== thisUsedFilters.offers?.advertisementCategory.description
        ) {
            switch (thisSearchType) {
                case SearchType.UNINODE:
                case SearchType.UNIPRODUCTGROUPS:
                case SearchType.UNISEARCH:
                    publishModuleInfo("{{1009}}", queryInfo)
                    break
                case SearchType.OFFERS:
                    publishModuleInfo("{{1276}}", thisUsedFilters.offers?.advertisementCategory.description || "")
                    break
                default:
                    publishModuleInfo("{{389}}", queryInfo)
            }
        }

        const articleGroups = this.articelListScroller.current?.querySelectorAll(".article-group")
        let listHeight = 0
        articleGroups?.forEach((element) => {
            listHeight += element.clientHeight
        })

        const loadArticlesButtonShouldBeShown =
            !!thisUsedFilters.availability && !thisResult.page.endOfList && listHeight < (this.articelListScroller.current?.scrollHeight || 0)
        if (this.state.showLoadArticlesButton !== loadArticlesButtonShouldBeShown) {
            this.setState({ showLoadArticlesButton: loadArticlesButtonShouldBeShown })
        }

        if (this.state.loadUntilFirstAvailableArticle) {
            const [loading, noResult] = getStatus(this.props)
            if (!thisUsedFilters.availability || thisResult.page.endOfList || thisResult.page.index > 10 || prevUsedFilters !== thisUsedFilters) {
                this.setState({ loadUntilFirstAvailableArticle: false })
            } else if (!loading && noResult) {
                this.props.actions.loadNextArticles()
            }
        }

        if (prevResult.loading && !thisResult.loading && !prevResult.page.loading) {
            if (this.articelListScroller.current?.scrollTop) {
                this.articelListScroller.current?.scrollTo({ top: 0 })
            }
        }

        // if the loading Element is displayed, scroll it into the view
        // this logic was added to show the loading element, but it breaks the detailspage
        // https://jira.dvse.de/browse/NEXT-23409, we should refactor the List on the Detailspage
        // only scroll the List not the detailspage
        // if (!prevResult.loading && thisResult.loading) {
        //     this.loadingElementRef.current?.scrollIntoView()
        // }

        const {
            scrollToRoute,
            match: {
                params: { scrollTo },
            },
        } = this.props
        if (
            this.scrollToHelper.current &&
            scrollToRoute &&
            scrollToRoute === scrollTo &&
            ((prevResult.loading && !thisResult.loading && !prevResult.page.loading) ||
                (!thisResult.loading && !thisResult.page.loading && thisResult.parts.length && scrollTo !== prevProps.match.params.scrollTo))
        ) {
            window.scrollTo(0, this.scrollToHelper.current.offsetTop)
        }

        if (this.props.workTask?.id !== prevProps.workTask?.id) {
            this.currentProviders = undefined
            this.unregisterViewStateLoaded && this.unregisterViewStateLoaded()
            this.unregisterViewStateUpdated && this.unregisterViewStateUpdated()
            this.subscribeToViewState()
        }

        this.processPartToReplaceId(this.props.location.search)

        const { workTask } = this.props

        if (
            workTask?.vehicle?.registrationTypeId === RegistrationNoType.DatVin &&
            workTask?.vehicle?.registrationTypeId !== prevProps.workTask?.vehicle?.registrationTypeId
        ) {
            this.props.actions.loadArticles()
        }

        const { partToReplaceData } = this.state

        if (
            Morpheus.getParams<ConfigParams>().combineAlternativeArticlesFromDbAndErp &&
            thisSearchType === SearchType.ALTERNATIVES &&
            partToReplaceData?.article &&
            (partToReplaceData?.article !== prevState.partToReplaceData?.article || workTask?.vehicle?.id !== prevProps.workTask?.vehicle?.id)
        ) {
            const Erp: ErpContainer = Container.getInstance(RegisteredModels.ERP)

            Erp.action("getErpInfoDetails")({
                item: createErpInformationRequestItem(partToReplaceData.article, workTask?.vehicle?.id),
                distributorId: undefined, // TODO: set distributor id using "useDefaultErpSystem" hook when this component is refactored as function component
                telesalesCustomerNo: getTelesalesCustomerNo(workTask?.customer), // TODO: set telesales customer no using "useTelesalesCustomerNumber" hook when this component is refactored as function component
            }).then((response) => {
                const alternativeArticles = response.articles?.filter((a) => a.itemsRole === LinkedItemsRole.Alternative)

                if (!alternativeArticles?.length || !partToReplaceData.article) {
                    return
                }

                let prom

                if (alternativeArticles.some((a) => !!a.wholesalerArticleNumber)) {
                    prom = getArticlesByWholesalerArticleNos(
                        alternativeArticles.map((x) => x.wholesalerArticleNumber),
                        workTask?.vehicle?.tecDocTypeId
                    ).then((x) => getArticlesFromTraderArticleDictionary(x, undefined).articles)
                } else {
                    const articleIdentifier: Array<ArticleIdentifier> = []

                    alternativeArticles.forEach((a) => {
                        if (a.dataSupplierId && a.dataSupplierArticleNumber && partToReplaceData?.article?.productGroup?.id) {
                            articleIdentifier.push({
                                supplierId: a.dataSupplierId,
                                supplierArticleNo: a.dataSupplierArticleNumber,
                                productGroupId: partToReplaceData.article.productGroup.id,
                            })
                        }
                    })

                    prom = getArticlesByArticleNoWithOptVehicle(articleIdentifier)
                }

                prom.then((articles) => {
                    const { partToReplaceQuantity } = this.props.state

                    this.setState({
                        partToReplaceErpAlternatives: !partToReplaceQuantity
                            ? articles
                            : articles.map((x) => ({
                                  ...x,
                                  quantity: partToReplaceQuantity || x.quantity,
                              })),
                    })
                })
            })
        }
    }

    subscribeToArticleQuantityChange(props: Props) {
        return channel("WORKTASK").subscribe("BASKET/ARTICLE_QUANTITY_CHANGED", ({ article, quantity }) => {
            props.actions.changeQuantity(article!, quantity)
            if (article && this.state.partToReplaceErpAlternatives?.find((part) => part.id === article.id)) {
                this.setState((prevState) => ({
                    partToReplaceErpAlternatives: prevState.partToReplaceErpAlternatives?.map((part) => {
                        if (part.id === article.id) {
                            return {
                                ...part,
                                quantity,
                            }
                        }
                        return part
                    }),
                }))
            }
        })
    }

    subscribeToViewState() {
        if (!this.props.workTask?.id) {
            return
        }

        const subscription = this.ViewState_ActiveProviders.subscribe(`${encodeUniqueId(this.props.workTask?.id)}__ACTIVE_VEHICLE_DATA_PROVIDERS`)

        this.unregisterViewStateLoaded = subscription.addListener("loaded", (response) => {
            this.currentProviders = response.value
        })
        this.unregisterViewStateUpdated = subscription.addListener("updated", (response) => {
            this.currentProviders = response.value
        })

        subscription.load()
    }

    subscribeToModalClose() {
        if (!this.props.match.path.includes("modal")) {
            this.unsubscribeModalClosed = channel("GLOBAL").subscribe("MODAL/CLOSED", () => {
                const { actions } = this.props
                actions.reInitialiseGroupAndSupplierFilters()
            })
        }
    }

    isForcedToReload(nextProps: Props) {
        const current = parseQueryString(this.props.location.search).forceReload
        const next = parseQueryString(nextProps.location.search).forceReload
        return next !== current
    }

    processPartToReplaceId(urlQuery: string) {
        const partToReplaceId = parseQueryString(urlQuery).partToReplaceId as string | undefined

        if (partToReplaceId && partToReplaceId !== this.state.partToReplaceId) {
            this.setState({ partToReplaceId, partToReplaceData: undefined, partToReplaceErpAlternatives: undefined })

            Container.getInstance<OrderItem>(RegisteredModels.Basket_Order_ShowItem)
                .subscribe(partToReplaceId)
                .load()
                .then((orderItem) => {
                    const { dataSupplier, dataSupplierArticleNumber, productGroup } = orderItem.articleInfo

                    if (orderItem.articleInfoType === ArticleInfoType.TecdocArticle && dataSupplier && dataSupplierArticleNumber && productGroup) {
                        getArticlesByArticleNoWithOptVehicle([
                            {
                                supplierId: dataSupplier.id,
                                supplierArticleNo: dataSupplierArticleNumber,
                                productGroupId: productGroup.id,
                            },
                        ]).then((articles) => {
                            if (articles && articles.length) {
                                this.setState({
                                    partToReplaceData: {
                                        orderItem,
                                        article: {
                                            ...articles[0],
                                            quantity: this.props.state.partToReplaceQuantity || articles[0].quantity,
                                        },
                                    },
                                })
                            } else {
                                this.setState({ partToReplaceData: { orderItem } })
                            }
                        })
                    } else {
                        this.setState({ partToReplaceData: { orderItem } })
                    }
                })
        } else if (!partToReplaceId && this.state.partToReplaceId) {
            this.setState({
                partToReplaceId: undefined,
                partToReplaceData: undefined,
                partToReplaceErpAlternatives: undefined,
            })
        }
    }

    handleEndOfList(loading: boolean) {
        if (!loading) {
            this.props.actions.loadNextArticles()
        }
    }

    handleRequestArticleDetails(request: RequestArticleDetailsPayload) {
        const { partsRoutes } = getBundleParams()
        const { match } = this.props

        if (match.url.indexOf("/part-alternatives/") !== -1) {
            request.categoryType = "partAlternatives"
            request.inModal = true
        } else {
            request.categoryType = getCategoryTypeFromUrl(match.url)
        }

        if (this.props.state.searchType === SearchType.ALTERNATIVES) {
            request.inModal = true

            if (request.article) {
                try {
                    const logEntry: LogEntry = {
                        type: "Alternative article search - article details opened",
                        request: request.article,
                    }

                    Container.getInstance<LogEntry>(RegisteredModels.Logging).callAction("write", logEntry)
                } catch {}
            }
        }

        openPartDetailsInModal(request, match.params, partsRoutes)
    }

    handleRequestUniversalArticleSearch(query: string, resetQuery?: boolean, urlCallback?: (url: string) => void) {
        const { actions, match, location } = this.props
        if (resetQuery) {
            // When the last result returned no result, the navigation entry mustn't have the unsuccessful query in the url
            const searchParams = new URLSearchParams(location.search)
            searchParams.delete("query")
            actions.setListUrl(getCategoryTypeFromUrl(match.url), `${location.pathname}?${searchParams.toString()}`)
            // The following action has to be called async, otherwise the directSearch navigation url will again be
            // overwritten with the query string, which has been removed in the previous line.
            setTimeout(() => actions.searchArticles(query, "universalParts", match.params), 0)
            return
        }
        actions.searchArticles(query, "universalParts", match.params, undefined, undefined, undefined, undefined, urlCallback)
    }

    handleRequestArticleDirectSearch(query: string, searchFilter?: SearchFilters, inModal?: boolean, urlCallback?: (url: string) => void) {
        this.props.actions.searchArticles(query, "direct", this.props.match.params, searchFilter, undefined, inModal, undefined, urlCallback)
    }

    handleOeReplacementSelect(replacement: string) {
        this.props.actions.searchArticles(replacement, "direct", this.props.match.params, SearchFilters.OeReference)
    }

    handleOeInformationSelect(replacement: string) {
        this.props.actions.searchArticles(replacement, "direct", this.props.match.params, SearchFilters.OeReference, undefined, undefined, true)
    }

    handleRequestArticleAlternatives(article: Article) {
        const { articleAlternativesRoute, match } = this.props
        const { supplierArticleNo, productGroup } = article

        if (!articleAlternativesRoute) {
            return
        }

        const queryParams = new URLSearchParams()
        queryParams.set("query", supplierArticleNo)
        queryParams.set("productGroupIds", productGroup.id.toString())

        if (article.supplier?.manufacturerId) {
            queryParams.set("oeManufacturerIds", article.supplier.manufacturerId.toString())
        }

        const url = `${renderRoute(articleAlternativesRoute, { ...match.params })}?${queryParams.toString()}`

        Morpheus.showView("1", url)

        try {
            const logEntry: LogEntry = {
                type: "Alternative article search started",
                searchType: this.props.state.searchType,
                article: {
                    internalId: article.internalId,
                    supplierArticleNo: article.supplierArticleNo,
                    traderArticleNo: article.traderArticleNo,
                    matchingArticleNo: article.matchingArticleNo,
                    supplier: {
                        id: article.supplier.id,
                        name: article.supplier.name,
                    },
                    productGroup: {
                        id: article.productGroup.id,
                        name: article.productGroup.name,
                    },
                },
            }

            Container.getInstance<LogEntry>(RegisteredModels.Logging).callAction("write", logEntry)
        } catch {}
    }

    handleSelectSensitiveArea(coordinate: PartsListImageCoordinate) {
        const uniqueIds = this.props.state.result.parts
            .filter((part) => part.internalId === coordinate.referencedArticle && part.imageCoordinates?.includes(`,${coordinate.id};`))
            .map(getArticleUniqueId)
            .distinct() // Otherwise it will be selected and immediately unselected
        uniqueIds.forEach((id) => this.props.actions.selectPart(id))
    }

    handleAddArticleToBasket(article: Article) {
        const { vehicle } = this.props.state.usedFilters

        if (getBundleParams().vehicleRecordsEnabled && vehicle) {
            const request = mapVehicleRecordArticleAddedToBasketEventRequest(vehicle, article)
            const container: VehicleRecordsContainer = Container.getInstance(RegisteredModels.VehicleRecords) as VehicleRecordsContainer
            container
                .action("articleAddedToBasket")(request)
                .then(() => {
                    if (!hasVehicleRecordDatVinOnly()) {
                        this.props.actions.compareVehicleRecords(vehicle, undefined, false) // calling comparision because article list doesnt get reloaded on add to basket (as expected)
                        this.props.actions.showVehicleRecords(vehicle, article.productGroup.id.toString())
                    }
                })
        }

        if (this.state.partToReplaceId) {
            Morpheus.closeView("1")
        }
    }

    handleSelectAttributeFilterAction(attribute: ArticleAttribute, productGroupId?: number, fittingPosition?: number) {
        const { actions, state } = this.props
        const { usedFilters } = state

        if (!productGroupId) {
            return
        }
        actions.toggleAttributeFilter(attribute)

        if (
            getBundleParams().vehicleRecordsEnabled &&
            usedFilters &&
            usedFilters.articleAttributes.find((attr) => attr === `${attribute.id}|${attribute.key}`) &&
            usedFilters.vehicle
        ) {
            const { vehicle } = usedFilters
            const request = mapAttributeToVehicleRecordSingleEventRequest(vehicle, attribute, productGroupId, fittingPosition)
            const container: VehicleRecordsContainer = Container.getInstance(RegisteredModels.VehicleRecords) as VehicleRecordsContainer
            container
                .action("inlineFilterApplied")(request)
                .then(() => {
                    actions.showVehicleRecords(vehicle, productGroupId!.toString())
                })
        }
    }

    handleArticleAttributeSelect(article: Article, attribute: ArticleAttribute) {
        const { actions, state } = this.props
        const { searchType } = state

        switch (attribute.action) {
            case AttributeAction.DirectSearch:
                this.handleRequestArticleDirectSearch(attribute.value, undefined, true)
                break
            case AttributeAction.OpenAccessoryList:
            case AttributeAction.OpenPartsList:
                const { foundBySearchTerm } = getTmaInfos(state.usedFilters, state.searchType, state.customer)

                this.handleRequestArticleDetails({
                    article: {
                        productGroupId: article.productGroup.id,
                        supplierId: article.supplier.id,
                        supplierArticleNo: article.supplierArticleNo,
                    },
                    vehicleLinkageId: article.vehicleLinkageId,
                    subPage: attribute.action === AttributeAction.OpenPartsList ? "partslist" : "accessories",
                    foundBySearchTerm,
                    disableAddToBasket: !!article.referencedArticleModification?.isRemovedReferencedArticle,
                })
                break

            case AttributeAction.Filter:
            default:
                if (searchType === SearchType.UNINODE || searchType === SearchType.UNISEARCH || searchType === SearchType.UNIPRODUCTGROUPS) {
                    actions.toggleUniAttributeFilter(attribute as any)
                } else {
                    this.handleSelectAttributeFilterAction(attribute, article.productGroup.id, article.fittingSide)
                }
                break
        }
    }

    handleDeselectProductGroupAttributeFilter(productGroupFilter: AttributeFilterModel) {
        if (!productGroupFilter) {
            return
        }

        const attribute = castToArticleAttribute(productGroupFilter)
        const { usedFilters } = this.props.state

        const inUse = usedFilters.articleAttributes.find((value) => value == `${attribute.id}|${attribute.value}`)
        if (inUse) {
            this.props.actions.toggleAttributeFilter(attribute)
        }
    }

    handleSelectProductGroupAttributeFilter(productGroupFilter: AttributeFilterModel, previousProductGroupFilter?: AttributeFilterModel) {
        if (!productGroupFilter) {
            return
        }

        const { vehicle } = this.props.state.usedFilters

        if (previousProductGroupFilter) {
            this.handleDeselectProductGroupAttributeFilter(previousProductGroupFilter)
        } // previous selection needs to be deselect

        const attribute = castToArticleAttribute(productGroupFilter)
        this.props.actions.toggleAttributeFilter(attribute)

        if (getBundleParams().vehicleRecordsEnabled && vehicle) {
            const request = mapFilterToVehicleRecordSingleEventRequest(vehicle, productGroupFilter)
            const container: VehicleRecordsContainer = Container.getInstance(RegisteredModels.VehicleRecords) as VehicleRecordsContainer
            container.action("quickFilterApplied")(request)
        }
    }

    handleDeselectAllProductGroupFilter(productGroupFilters: Array<AttributeFilterModel>) {
        productGroupFilters.forEach((group) => {
            this.handleDeselectProductGroupAttributeFilter(group)
        })
    }

    handleShowArticleFeedback(article: Article) {
        const { articleFeedbackPath } = getBundleParams()
        if (articleFeedbackPath) {
            const url =
                renderRoute(articleFeedbackPath, {
                    ...this.props.match.params,
                    productGroupId: article.productGroup.id,
                    supplierId: article.supplier.id,
                    supplierName: article.supplier.name,
                    supplierArticleNo: article.supplierArticleNo,
                    itemId: article.id,
                    quantityValue: article.quantity || 1,
                    productGroupName: article.productGroup.name,
                    traderArticleNo: article.traderArticleNo || undefined,
                }) + createQueryString({ articleDescription: article.description })
            Morpheus.showView("1", url)
        }
    }

    getRepairTimesUrl(article: Article, rtProviders: RepairTimeProvider | Array<RepairTimeProvider>) {
        const {
            match,
            userSettings,
            state: {
                usedFilters: { vehicle },
            },
        } = this.props
        const { partsRoutes } = getBundleParams()
        const { repairTimeProviders } = getRepairTimeProviders()

        if (article.productGroup && vehicle && repairTimeProviders) {
            let provider
            if (Array.isArray(rtProviders)) {
                let activeRTProvider = this.currentProviders && this.currentProviders.repairTimes
                if (userSettings?.activeVehicleDataProviders?.repairTimes) {
                    activeRTProvider = userSettings.activeVehicleDataProviders.repairTimes
                }

                provider = getRepairTimesProvider(rtProviders, repairTimeProviders, activeRTProvider)
            } else {
                provider = getRepairTimesProviderStringByEnum(rtProviders)
            }

            if (!provider || !partsRoutes) {
                return
            }

            return decodeURIComponent(
                renderRoute(partsRoutes.repairTimesRoute, {
                    ...match.params,
                    provider,
                    productGroupId: article.productGroup.id,
                    supplierId: article.supplier.id,
                    supplierArticleNo: article.supplierArticleNo.replace(/\//g, "%252F"),
                    position: article.fittingSide,
                })
            )
        }
    }

    renderWholesalerParts() {
        const { state } = this.props
        const { showArticleImages, result, repairTimeAvailabilities, searchType, usedFilters } = state

        if (!result.wholesalerParts?.length) {
            return
        }

        return (
            <div className="article-group">
                <div className="article-list">
                    {result.wholesalerParts?.map((part, key) => (
                        <WholesalerItem
                            key={key}
                            part={part}
                            hideThumbnail={!showArticleImages}
                            repairTimeAvailabilities={repairTimeAvailabilities}
                            searchType={searchType}
                            getRepairTimesUrl={this.getRepairTimesUrl}
                            advertisementCategoryId={usedFilters.offers?.advertisementCategory.id}
                        />
                    ))}
                </div>
            </div>
        )
    }

    getBuyActionsRenderer(): ((part: Article) => ReactNode) | undefined {
        const {
            state: { initialFilters: { replaceButtonBundle } = {} },
        } = this.props

        if (replaceButtonBundle) {
            return (part: Article) => <ReplaceButton part={part} bundle={replaceButtonBundle} />
        }
    }

    handlePartToReplaceDropdownChange(item: { value: "uni" | "direct"; text: string }) {
        const { replacePartUniversalSearchUrl, replacePartDirectSearchUrl } = Morpheus.getParams<ConfigParams>()
        if (!this.state.partToReplaceData) {
            return
        }
        const { articleInfo } = this.state.partToReplaceData.orderItem
        const { match } = this.props
        if (item.value === "direct") {
            const query =
                articleInfo.articleNumber ||
                articleInfo.dataSupplierArticleNumber ||
                articleInfo.wholesalerArticleNumber ||
                articleInfo.oeArticleNumber
            if (query) {
                const url =
                    renderRoute(replacePartDirectSearchUrl, { ...match.params }) +
                    createQueryString({
                        query,
                        partToReplaceId: this.state.partToReplaceId,
                        partToReplaceQuantity: this.props.state.partToReplaceQuantity,
                    })
                Morpheus.showView("1", url)
            }
        } else if (item.value === "uni" && replacePartUniversalSearchUrl) {
            const url =
                renderRoute(replacePartUniversalSearchUrl, { ...match.params }) +
                createQueryString({
                    query: articleInfo.description,
                    partToReplaceId: this.state.partToReplaceId,
                    partToReplaceQuantity: this.props.state.partToReplaceQuantity,
                })
            Morpheus.showView("1", url)
        }
    }

    renderArticleSelection() {
        const { state, actions, showArticleComparision } = this.props
        const { result, maxArticleNumberToCompareReached } = state
        const selectedParts = result.parts.filter((part) => result.selected.indexOf(getArticleUniqueId(part)) !== -1)
        if (selectedParts.length) {
            const { vehicleId, customerId, foundBySearchTerm } = getTmaInfos(state.usedFilters, state.searchType, state.customer)
            const articleSelectionItem = {
                vehicleId,
                customerId,
                foundBySearchTerm,
                selectedArticles: selectedParts,
                allSelected: selectedParts.length === result.parts.length,
            }

            return (
                <Box position="relative">
                    <ArticleSelection
                        articleSelectionItem={articleSelectionItem}
                        maxArticleNumberToCompareReached={maxArticleNumberToCompareReached}
                        showArticleComparision={showArticleComparision || false}
                        onCompareArticles={this.openArticleComparision}
                        onToggleSelectAll={actions.toggleSelectAllParts}
                        hideBasketButton={!!state.fastCalculator}
                    />
                </Box>
            )
        }
    }

    openArticleComparision(carParts: Article[]) {
        this.setState({ showArticleComparision: true })
        TmaHelper.ArticleComparison.Parts.Click(carParts)
        // After opening the article comparison subscribe to any MODAL/OPENED event (meaning a modal was opened by Morpheus.showView)
        // to close the article comparison because the according modal would be overwritten.
        // Otherwise it would not be possible to open the comparison again - see NEXT-16776
        channel("GLOBAL").subscribeOnce("MODAL/OPENED", () => {
            this.handleArticleComparisionModalClose()
        })
    }

    renderArticleComparision() {
        if (!this.state.showArticleComparision) {
            return null
        }

        const { state, actions } = this.props
        // TODO: The following line creates a new reference on every render
        const selectedParts = state.result.parts.filter((part) => state.result.selected.indexOf(getArticleUniqueId(part)) !== -1)
        const { vehicleId, customerId, foundBySearchTerm } = getTmaInfos(state.usedFilters, state.searchType, state.customer)

        if (selectedParts.length > 1) {
            return (
                <Modal onClose={this.handleArticleComparisionModalClose} interruptOutsideClick containerClassName="article-comparision__modal">
                    <ArticleComparison
                        partsToCompare={selectedParts}
                        showArticleImages={!!state.showArticleImages}
                        vehicle={vehicleId ? state.usedFilters.vehicle : undefined}
                        customerId={customerId}
                        foundBySearchTerm={foundBySearchTerm}
                        previouslyOrderedArticles={state.result.previouslyOrderedArticles}
                        onUnselectArticle={actions.selectPart}
                        onRequestArticleDetails={this.handleRequestArticleDetails}
                        getRepairTimesUrl={this.getRepairTimesUrl}
                        onOpenPage={this.handleArticleComparisionModalClose}
                    />
                </Modal>
            )
        }
    }

    handleArticleComparisionModalClose() {
        this.setState({ showArticleComparision: false })
    }

    handleLoadUntilFirstAvailableArticle = () => {
        this.setState({ loadUntilFirstAvailableArticle: true })
    }

    render() {
        const { scrollable, panelTitle, isInTab } = getConfigProps(this.props)
        const { articleFiltersInLeft } = Morpheus.getParams<ConfigParams>()
        const {
            state,
            actions,
            match,
            articleAlternativesRoute,
            showAdditionalPrices,
            showErpPawnItems,
            showDocumentsInline,
            showReferenceLinksInCompact,
            showDocumentsInCompact,
            openDocumentsAsModal,
            localization: { translateText },
            clippedFiltersClassName,
        } = this.props
        const {
            searchType,
            initialFilters,
            result,
            usedFilters: { vehicle },
            fastCalculator,
        } = state

        const categoryType = getCategoryTypeFromUrl(match.url)

        let content

        if (searchType === SearchType.NONE) {
            return (
                <NoSearch
                    articleFiltersInLeft={!!articleFiltersInLeft}
                    categoryType={categoryType}
                    searchType={searchType}
                    clippedFiltersClassName={clippedFiltersClassName}
                />
            )
        }

        if (searchType === SearchType.HIDE || !initialFilters) {
            return null
        }

        let fastCalculatorAlternativeParts: Article[] = []
        if (fastCalculator?.alternatives?.parts?.length) {
            const filteredSuppliers = this.props.state.usedFilters.supplierIds
            fastCalculatorAlternativeParts = fastCalculator.alternatives.parts
            if (filteredSuppliers.length) {
                fastCalculatorAlternativeParts = fastCalculatorAlternativeParts.filter((part) =>
                    filteredSuppliers.some((x) => x === part.supplier.id)
                )
            }
        }

        const anyPartHasBonusSystems = this.props.state.result.parts.some((x) => getArticleBonusSystemsWithoutPoints(x).length)
        const [loading, noResult] = getStatus(this.props)
        const { partsListImage } = result

        content = (
            <>
                {!!state.oeReplacements?.manufacturers.length && (
                    <OeReplacements
                        query={state.usedFilters.query?.text}
                        manufacturers={state.oeReplacements.manufacturers}
                        onReplacementSelect={this.handleOeReplacementSelect}
                    />
                )}
                <OePositions positions={state.fastCalculator?.oeNrs || state.oeResult?.positions} onPositionSelect={actions.selectOePosition} />

                {state.oeInformationConfiguration?.enableOeInformation &&
                    !!state.oeInformation?.oeInformationList?.length &&
                    !state.oeInformation?.loading && (
                        <OeInformation
                            query={state.usedFilters.query?.text}
                            oeInformationList={state.oeInformation.oeInformationList}
                            onReplacementSelect={this.handleOeInformationSelect}
                            vehicle={initialFilters.oeInformationWithoutCar ? undefined : vehicle}
                            hideOePrice={state.hideOePrice}
                            oeManufacturerIds={initialFilters.oeManufacturerIds}
                            oeInformationConfiguration={state.oeInformationConfiguration}
                        />
                    )}

                {!(!state.oeResult?.parts.length || (searchType !== SearchType.OE_POSITIONS && result.loading)) && (
                    <>
                        <ArticleGroupHeader title={translateText(803)} />
                        <div className="article-list">
                            {state.oeResult.parts.map((part, key) => (
                                <OeArticle
                                    key={part.id || key}
                                    part={part}
                                    hideThumbnail={!state.showArticleImages}
                                    onArticleAttributeSelect={this.handleSelectAttributeFilterAction}
                                    selectedArticleAttributes={state.usedFilters.articleAttributes}
                                    vehicleRecordsComparisons={state.result.vehicleRecordsComparisons}
                                    showAdditionalPrices
                                    oeAttributesLoading={state?.oeResult?.oeAttributesLoading}
                                    replaceButtonBundle={state.initialFilters?.replaceButtonBundle}
                                />
                            ))}
                        </div>
                    </>
                )}

                <FastCalculatorAlternatives
                    articles={fastCalculatorAlternativeParts}
                    partToReplaceId={this.state.partToReplaceId}
                    partToReplaceData={this.state.partToReplaceData}
                    state={state}
                    actions={actions}
                    showDocumentsInCompact={showDocumentsInCompact}
                    showReferenceLinksInCompact={showReferenceLinksInCompact}
                    openDocumentsAsModal={openDocumentsAsModal}
                    showDocumentsInline={showDocumentsInline}
                    showAdditionalPrices={showAdditionalPrices}
                    showErpPawnItems={showErpPawnItems}
                    articleAlternativesRoute={articleAlternativesRoute}
                    onShowArticleFeedback={this.handleShowArticleFeedback}
                    onArticleAddToBasket={this.handleAddArticleToBasket}
                    getRepairTimesUrl={this.getRepairTimesUrl}
                    onArticleAttributeSelect={this.handleArticleAttributeSelect}
                    onRequestArticleDetails={this.handleRequestArticleDetails}
                    onRequestArticleDirectSearch={this.handleRequestArticleDirectSearch}
                    isCentralOrder={isCentralOrder}
                    match={match}
                    handleRequestArticleAlternatives={this.handleRequestArticleAlternatives}
                />

                <PartToReplace
                    partToReplaceId={this.state.partToReplaceId}
                    partToReplaceData={this.state.partToReplaceData}
                    state={state}
                    actions={actions}
                    showDocumentsInCompact={showDocumentsInCompact}
                    showReferenceLinksInCompact={showReferenceLinksInCompact}
                    openDocumentsAsModal={openDocumentsAsModal}
                    showDocumentsInline={showDocumentsInline}
                    showAdditionalPrices={showAdditionalPrices}
                    showErpPawnItems={showErpPawnItems}
                    articleAlternativesRoute={articleAlternativesRoute}
                    onShowArticleFeedback={this.handleShowArticleFeedback}
                    onArticleAddToBasket={this.handleAddArticleToBasket}
                    getRepairTimesUrl={this.getRepairTimesUrl}
                    onArticleAttributeSelect={this.handleArticleAttributeSelect}
                    onRequestArticleDetails={this.handleRequestArticleDetails}
                    onRequestArticleDirectSearch={this.handleRequestArticleDirectSearch}
                    isCentralOrder={isCentralOrder}
                    match={match}
                    handleRequestArticleAlternatives={this.handleRequestArticleAlternatives}
                    handlePartToReplaceDropdownChange={this.handlePartToReplaceDropdownChange}
                />
                <ListContent
                    state={state}
                    actions={actions}
                    partToReplaceData={this.state.partToReplaceData}
                    partToReplaceErpAlternatives={this.state.partToReplaceErpAlternatives}
                    handleSelectProductGroupAttributeFilter={this.handleSelectProductGroupAttributeFilter}
                    handleDeselectProductGroupAttributeFilter={this.handleDeselectProductGroupAttributeFilter}
                    handleDeselectAllProductGroupFilter={this.handleDeselectAllProductGroupFilter}
                    calculatorRoute={this.props.calculatorRoute}
                    handleArticleAttributeSelect={this.handleArticleAttributeSelect}
                    handleRequestArticleDetails={this.handleRequestArticleDetails}
                    handleRequestArticleDirectSearch={this.handleRequestArticleDirectSearch}
                    articleAlternativesRoute={articleAlternativesRoute}
                    handleRequestArticleAlternatives={this.handleRequestArticleAlternatives}
                    handleAddArticleToBasket={this.handleAddArticleToBasket}
                    getRepairTimesUrl={this.getRepairTimesUrl}
                    showActions
                    handleShowArticleFeedback={this.handleShowArticleFeedback}
                    showAdditionalPrices={showAdditionalPrices}
                    showErpPawnItems={showErpPawnItems}
                    showDocumentsInline={showDocumentsInline}
                    openDocumentsAsModal={openDocumentsAsModal}
                    showReferenceLinksInCompact={showReferenceLinksInCompact}
                    showDocumentsInCompact={showDocumentsInCompact}
                    matchParams={match.params}
                    isCentralOrder={isCentralOrder}
                    partToReplaceId={this.state.partToReplaceId}
                />
                <LoadArticlesButton
                    loading={loading}
                    noResult={noResult}
                    showLoadArticlesButton={this.state.showLoadArticlesButton}
                    pageIndex={this.props.state.result.page.index}
                    handleLoadUntilFirstAvailableArticle={this.handleLoadUntilFirstAvailableArticle}
                    loadNextArticles={this.props.actions.loadNextArticles}
                />
                {!!result.wholesalerParts?.length && this.renderWholesalerParts()}
                <Status
                    loading={(loading || state.fastCalculator?.alternatives?.loading) ?? false}
                    noResult={noResult}
                    showLoadArticlesButton={this.state.showLoadArticlesButton}
                    url={this.props.match.url}
                    handleRequestArticleDirectSearch={this.handleRequestArticleDirectSearch}
                    handleRequestUniversalArticleSearch={this.handleRequestUniversalArticleSearch}
                    userSettings={this.props.userSettings}
                    setUserSetting={this.props.setUserSetting}
                    state={this.props.state}
                    actions={this.props.actions}
                    loadingElementRef={this.loadingElementRef}
                />
            </>
        )

        if (scrollable) {
            // https://jira.dvse.de/browse/NEXT-23818.
            // Container was not able to scroll and onScrollBottom was never triggered.
            // Calculated height for modal and 100% for article list not in modal
            const modal = this.props.match.path.includes("/modal")
            content = (
                <Box sx={{ height: modal ? "calc(100vh - 150px)" : "100%" }}>
                    <ScrollContainer ref={this.articelListScroller} onScrollBottom={() => this.handleEndOfList(loading)} useDefaultStyling>
                        {content}
                    </ScrollContainer>
                </Box>
            )
        }

        if (searchType === SearchType.PARTSLIST && result.partsListImage) {
            content = <div className={classNames.partsListWithImageWrapper}>{content}</div>
        }

        content = (
            <div className="tk-parts" ref={!panelTitle ? this.scrollToHelper : undefined}>
                {!isInTab && articleFiltersInLeft && <FiltersAnchor searchType={searchType} className={clippedFiltersClassName} />}
                <div className={classes("list", "searchtreeV2", anyPartHasBonusSystems && "list--with-bonus-systems")}>
                    {searchType === SearchType.PARTSLIST && partsListImage && (
                        <SensitiveGraphics
                            image={partsListImage.image}
                            imageCoordinates={partsListImage.coordinates}
                            selectedArticles={result.selected.map(getInternalIdFromArticleUniqueId)}
                            onArticleSelect={this.handleSelectSensitiveArea}
                        />
                    )}
                    {this.renderArticleSelection()}
                    {this.renderArticleComparision()}
                    {content}
                </div>
                {!isInTab && !articleFiltersInLeft && <FiltersAnchor searchType={searchType} className={clippedFiltersClassName} />}
            </div>
        )

        if (panelTitle) {
            content = (
                <div className="panel" ref={this.scrollToHelper}>
                    <Typography variant="h2" className="panel__title">
                        {panelTitle.replace(TRANSLATION_REGEX, (s, num) => this.props.localization.translateText(num))}
                    </Typography>
                    <div className="panel__content">{content}</div>
                </div>
            )
        }

        return content
    }
}

export default connectComponent(Actions, withRouter(ListComponent))
