import { getStoredAuthorization, ajax, clone } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { mapFastCalculatorResponse } from "../../helpers"
import { AddCustomPartToConsumableRequest, AddCustomPartToConsumableResponse } from "./model"

export function addCustomPartToConsumable(request: AddCustomPartToConsumableRequest) {
    const url = `${getServiceUrl()}/AddCustomPartToConsumable`
    const authorization = getStoredAuthorization()
    const body = clone(request)

    return new Promise<AddCustomPartToConsumableResponse>((resolve, reject) =>
        ajax({ url, body, authorization, method: "POST" }).then((data) => {
            const mappedData = mapFastCalculatorResponse(data)
            if (mappedData?.selectedCalcState?.context || mappedData?.isQueued) {
                resolve(mappedData)
            } else {
                reject()
            }
        }, reject)
    )
}

function getServiceUrl(): string {
    const bundleParams = getBundleParams()
    return bundleParams.fastCalculatorServiceUrl
}
