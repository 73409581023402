import { ajax, getStoredAuthorization } from "@tm/utils"
import { ShowAllRequest, ShowByArticleRequest, ShowReturnDetailsRequest, ShowReturnDetailsResponse, ShowReturnsResponse } from "../../model"
import { getBundleParams } from "../../../utils"

function getServiceUrl(): string {
    return `${getBundleParams().vouchersServiceUrl}/Returns`
}

export function showAllReturns(body: ShowAllRequest) {
    const url = `${getServiceUrl()}/ShowAll`
    const authorization = getStoredAuthorization()
    return ajax<ShowReturnsResponse>({ url, body, authorization }, undefined, undefined, true).then((response) => response?.returns)
}

export function showReturnsByArticle(body: ShowByArticleRequest) {
    const url = `${getServiceUrl()}/ShowByArticle`
    const authorization = getStoredAuthorization()
    return ajax<ShowReturnsResponse>({ url, body, authorization }, undefined, undefined, true).then((response) => response?.returns)
}

export function showReturnDetails(body: ShowReturnDetailsRequest) {
    const url = `${getServiceUrl()}/ShowDetails`
    const authorization = getStoredAuthorization()

    return ajax<ShowReturnDetailsResponse>({ url, body, authorization }, undefined, undefined, true)
}
