import { useEffect, useState } from "react"
import { useLocalization } from "@tm/localization"
import { Box, FormControlLabel, DatePicker, Typography } from "@tm/components"
import { Item, OrderOptions, ItemTour } from "@tm/models"
import { BoxWithAlignItems, BoxWithColumnPaddingRight, IconWithMargin, RadioButtonSmallWithMargin, RadioGroupGrid } from "./StyledComponents"

type Props = {
    disableFields: boolean
    options: OrderOptions
    onSetTour(selectedTour: ItemTour, selectedOptionsItem?: Item, updateErpInformation?: boolean, updateOrderOptions?: boolean): void
    onUpdateDeliveryDate(deliveryDate?: Date, selectedOptionsItem?: Item): void
}

const tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate() + 1)
tomorrow.setHours(0, 0, 0, 0) // so dates slected in the datepciker can be properly compared

function isDateValid(value: Date | undefined): boolean {
    return value instanceof Date && value?.getTime() >= tomorrow.getTime()
}

export default function DeliveryDateOptionsComponent(props: Props) {
    const { translateText, translate, language } = useLocalization()
    const {
        options: { tours, deliveryDate, selectedOptionsItem },
        disableFields,
        onSetTour,
        onUpdateDeliveryDate,
    } = props

    const [selectedDeliveryDate, setSelectedDeliveryDate] = useState<Date | undefined>()
    const [selectedTourId, setSelectedTourId] = useState<string>()
    const [isDeliveryDateInvalid, setIsDeliveryDateInvalid] = useState(false)

    useEffect(() => {
        if (deliveryDate) {
            setSelectedDeliveryDate(deliveryDate)
        }
    }, [deliveryDate])

    useEffect(() => {
        if (
            !isDeliveryDateInvalid &&
            selectedDeliveryDate !== deliveryDate &&
            (selectedDeliveryDate === undefined || isDateValid(selectedDeliveryDate))
        ) {
            handleDeliveryDateAccept(selectedDeliveryDate)
        }
    }, [deliveryDate, selectedDeliveryDate, isDeliveryDateInvalid])

    useEffect(() => {
        if (tours && tours.tours.length) {
            const defaultTour = tours.tours.find((tour) => tour.isSelected)
            if (defaultTour) {
                const tourId = defaultTour.id
                setSelectedTourId(tourId)
            } else {
                setSelectedTourId(undefined)
            }
        }
    }, [tours, deliveryDate])

    function handleTourChange(_: unknown, id: string) {
        if (tours?.tours.length) {
            setSelectedTourId(id)
            const firstTourMatch = tours.tours.find((tourOrder) => tourOrder.id === id)
            if (firstTourMatch) {
                onSetTour({ ...firstTourMatch }, selectedOptionsItem, tours.updateErpInformationOnChange, tours.updateOrderOptionsOnChange)
            }
        }
    }

    function handleDeliveryDateAccept(value: Date | undefined) {
        if (deliveryDate !== value) {
            setIsDeliveryDateInvalid(false)
            onUpdateDeliveryDate(value, selectedOptionsItem)
        }
    }

    function handleDeliveryDateChange(value: Date | undefined) {
        setSelectedDeliveryDate(value)
        if (selectedDeliveryDate !== value && (value === undefined || isDateValid(value))) {
            setIsDeliveryDateInvalid(false)
        }
    }

    return (
        <BoxWithColumnPaddingRight>
            <BoxWithAlignItems>
                <IconWithMargin name="delivery-time" />
                <Typography variant="h4">{translate(13834)}</Typography>
            </BoxWithAlignItems>
            <BoxWithColumnPaddingRight pl="35px" pt="4px">
                <Box mb={0.5}>
                    <DatePicker
                        localisation={{ locale: language, region: null }}
                        onAccept={(e) => handleDeliveryDateAccept(e ?? undefined)}
                        onError={(e) => setIsDeliveryDateInvalid(true)}
                        onChange={(e) => handleDeliveryDateChange(e ?? undefined)}
                        label={translateText(98)}
                        value={selectedDeliveryDate ?? null}
                        disabled={disableFields}
                        minDate={tomorrow}
                    />
                </Box>
                <RadioGroupGrid onChange={handleTourChange}>
                    {tours?.tours.map((tour) => {
                        return (
                            <Box key={tour.id} display="flex">
                                <FormControlLabel
                                    value={tour.id}
                                    control={<RadioButtonSmallWithMargin disabled={disableFields} checked={tour.id === selectedTourId} />}
                                    label={<Typography variant="body2">{tour.description}</Typography>}
                                />
                            </Box>
                        )
                    })}
                </RadioGroupGrid>
            </BoxWithColumnPaddingRight>
        </BoxWithColumnPaddingRight>
    )
}
