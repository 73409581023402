export enum ECalcLinkTarget {
    None = 0,
    /// <summary>
    /// Opens in a Popup eg. Colorbox in Redesign
    /// </summary>
    Popup = 1,
    /// <summary>
    /// Opens in the root
    /// The Redesign could run in nested IFrames, this will redirect highest redesign
    /// </summary>
    Root = 2,
    /// <summary>
    /// Redirects the current context, eg. IFrame or the App is running in.
    /// </summary>
    Current = 3,
    /// <summary>
    /// Opens a new Tab
    /// </summary>
    NewTab = 4,
}
