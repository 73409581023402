import { stylesheet } from "typestyle"
import { getStyleTheme } from "@tm/context-distribution"

export function getComponentStyles() {
    const { margin, colors } = getStyleTheme()

    return stylesheet({
        main: {
            flex: 1,
            padding: `${margin.m} ${margin.xl}`,
            display: "flex",
            flexDirection: "column",
        },
        content: {
            display: "flex",
            marginTop: margin.m,
        },
        primaryColored: {
            color: colors.primary,
        },
    })

    // return deepAssign(merge("getComponentStyles", styles), styleOverwrite || {})
}
