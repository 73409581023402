import { CircularProgress } from "@tm/components"
import { FC, useState } from "react"
import { useStyle } from "@tm/context-distribution"
import MuiCustomModal from "./MuiCustomModal"

type Props = {
    open: boolean
    url: string
    onClose(open: boolean): void
}

const HaynesProServiceModal: FC<Props> = ({ open, url, onClose }) => {
    const [loading, setLoading] = useState(true)

    const handleOnClose = () => {
        onClose(false)
        setLoading(false)
    }

    const handleOnLoad = () => {
        setLoading(false)
    }

    return (
        <MuiCustomModal open={open} onOutsideClick={handleOnClose}>
            {(!url || loading) && <CircularProgress className={styles.absoluteLoader} />}
            <iframe src={url} className={styles.iframeFull} id="iframe_haynesPro_service" onLoad={handleOnLoad} />
        </MuiCustomModal>
    )
}
export default HaynesProServiceModal

const styles = useStyle({
    iframeFull: {
        width: "100%",
        height: "100%",
    },
    absoluteLoader: {
        position: "absolute",
        top: "50%",
        right: "50%",
    },
})(HaynesProServiceModal)
