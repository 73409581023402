import { useLocalization } from "@tm/localization"
import { openStreamPdf } from "@tm/utils"
import { useCallback, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import {
    addArticleEmbedded,
    addArticleWM,
    createCompilationEmbedded,
    createCompilationWM,
    deleteArticlesEmbedded,
    deleteArticlesWM,
    deleteCompilationsEmbedded,
    deleteCompilationsWM,
    findCompilationsEmbedded,
    findCompilationsWM,
    getContainingArticlesBuffered,
    importArticlesEmbedded,
    importArticlesWM,
    moveArticleEmbedded,
    showAmountsEmbedded,
    showAmountsWM,
    showCompilationEmbedded,
    showCompilationEmbeddedPdf,
    showCompilationWM,
    updateArticleEmbedded,
    updateArticleWM,
    updateCompilationEmbedded,
    updateCompilationWM,
} from ".."
import { isWM } from "../../utils"
import { ArticleInterface } from "../interfaces/compilation-article-interface"

const KEY_FIND = "compilations_find"
const KEY_SHOW = "compilations_show"
const KEY_CONTAINS = "compilations_contains"
const KEY_TOTALS = "compilations_totals"

export function useCompilations(query?: string, isEnabled = true) {
    const { data, isLoading, error } = useQuery(
        [KEY_FIND, query],
        () => (isWM() ? findCompilationsWM : findCompilationsEmbedded)(query).then((response) => response?.compilations),
        { staleTime: 60 * 1000, enabled: isEnabled } // 1 minute
    )

    return { compilations: data, isLoading, error }
}

export function useCompilation(compilationId?: string): any {
    if (!compilationId) {
        return {}
    }

    const { data, isLoading, error } = useQuery<any>(
        [KEY_SHOW, compilationId],
        () => (isWM() ? showCompilationWM : showCompilationEmbedded)(compilationId),
        { staleTime: 60 * 1000 } // 1 minute
    )

    return { compilation: data?.compilation, articles: data?.articles, isLoading, error }
}

export function useTotals(compilationId?: string) {
    const { data, isLoading, error } = useQuery(
        [KEY_TOTALS, compilationId],
        () => (isWM() ? showAmountsWM(compilationId) : showAmountsEmbedded(compilationId)),
        { staleTime: 60 * 1000, enabled: !!compilationId } // 1 minute
    )

    return { totals: data, totalsLoading: isLoading, error }
}

export const useImportArticles = () => {
    const queryClient = useQueryClient()
    const mutation = useMutation(isWM() ? importArticlesWM : importArticlesEmbedded, {
        onSuccess: () => {
            queryClient.invalidateQueries(KEY_FIND)
            queryClient.invalidateQueries(KEY_SHOW)
            queryClient.invalidateQueries(KEY_CONTAINS)
        },
    })

    return { importFile: mutation.mutateAsync }
}

export function useCreateCompilation() {
    const queryClient = useQueryClient()
    const mutation = useMutation(isWM() ? createCompilationWM : createCompilationEmbedded, {
        onSuccess: (_, response) => {
            queryClient.resetQueries(KEY_FIND)
            queryClient.resetQueries(KEY_CONTAINS)
            queryClient.resetQueries([KEY_SHOW, response])
        },
    })

    return { createCompilation: mutation.mutateAsync, creatingCompilation: mutation.isLoading }
}

export function useDeleteCompilations() {
    const queryClient = useQueryClient()
    const mutation = useMutation(isWM() ? deleteCompilationsWM : deleteCompilationsEmbedded, {
        onSuccess: () => {
            queryClient.invalidateQueries(KEY_FIND)
            queryClient.invalidateQueries(KEY_SHOW)
            queryClient.invalidateQueries(KEY_CONTAINS)
        },
    })

    return { deleteCompilations: mutation.mutate }
}

export function useAddArticle() {
    const queryClient = useQueryClient()
    const mutation = useMutation(isWM() ? addArticleWM : addArticleEmbedded, {
        onSuccess: (_, request) => {
            queryClient.resetQueries([KEY_SHOW, request.compilationId])
            queryClient.resetQueries([KEY_TOTALS, request.compilationId])
            queryClient.resetQueries([KEY_CONTAINS])
        },
    })

    return { addArticle: mutation.mutateAsync, isLoading: mutation.isLoading }
}

export function useUpdateCompilation() {
    const queryClient = useQueryClient()
    const mutation = useMutation(isWM() ? updateCompilationWM : updateCompilationEmbedded, {
        onSuccess: (_, request) => {
            queryClient.resetQueries(KEY_FIND)
            queryClient.resetQueries([KEY_SHOW, request.compilationId])
        },
    })

    return { updateCompilation: mutation.mutateAsync, updatingCompilation: mutation.isLoading }
}

export function useDeleteArticles() {
    const queryClient = useQueryClient()
    const mutation = useMutation(isWM() ? deleteArticlesWM : deleteArticlesEmbedded, {
        onSuccess: (_, request) => {
            queryClient.resetQueries([KEY_SHOW, request.compilationId])
            queryClient.resetQueries([KEY_TOTALS, request.compilationId])
            queryClient.resetQueries([KEY_CONTAINS])
        },
    })

    return { deleteArticles: mutation.mutateAsync, isLoading: mutation.isLoading }
}

export function useUpdateArticle() {
    const queryClient = useQueryClient()
    const mutation = useMutation(isWM() ? updateArticleWM : updateArticleEmbedded, {
        onSuccess: (_, request) => {
            queryClient.invalidateQueries([KEY_SHOW, request.compilationId])
            queryClient.resetQueries([KEY_TOTALS, request.compilationId])
        },
    })

    return { updateArticle: mutation.mutateAsync, updatingArticle: mutation.isLoading }
}

export function useArticleMove() {
    const queryClient = useQueryClient()
    const mutation = useMutation(moveArticleEmbedded, {
        onSuccess: (_, request) => {
            queryClient.resetQueries([KEY_SHOW, request.sourceCompilationId])
            queryClient.resetQueries([KEY_SHOW, request.targetCompilationId])
            queryClient.resetQueries([KEY_TOTALS, request.sourceCompilationId])
            queryClient.resetQueries([KEY_TOTALS, request.targetCompilationId])
            queryClient.resetQueries([KEY_CONTAINS])
        },
    })

    return { moveArticle: mutation.mutateAsync }
}

export const useCompilationsContains = (articles: ArticleInterface[], isEnabled = true) => {
    const { data, isLoading, error } = useQuery(
        [KEY_CONTAINS, articles],
        () =>
            getContainingArticlesBuffered(
                articles.map((article) => ({
                    supplierArticleNumber: article.supplierArticleNumber,
                    supplierId: article.supplierId || 0,
                    productGroupId: article.productGroupId || 0,
                }))
            ),
        { staleTime: 60 * 1000, enabled: !isWM() && isEnabled && !!articles.length } // 1 minute
    )

    return { containedArticles: data || [], loading: isLoading, error }
}

export function useShowCompilationPdf(compilationId?: string) {
    const { translateText } = useLocalization()
    const [loading, setLoading] = useState(false)

    const showCompilationPdf = useCallback(() => {
        if (!compilationId) {
            return
        }
        setLoading(true)

        showCompilationEmbeddedPdf(compilationId)
            .then((compilationPdf) => {
                if (compilationPdf) {
                    openStreamPdf(compilationPdf, translateText)
                }
            })
            .finally(() => setLoading(false))
    }, [compilationId, translateText])

    return { showCompilationPdf, loadingCompilationPdf: loading }
}
