import { getStoredAuthorization, ajax } from "@tm/utils"
import {
    SelectPaymentRequest,
    SetCustomOrderInformationRequest,
    SelectShipmentRequest,
    SelectBillingAddressRequest,
    SelectDeliveryAddressRequest,
    SetCustomOrderNumberRequest,
    SetOrderCommentRequest,
    SelectDeliveryDistributionRequest,
    SelectTourRequest,
    SetDeliveryDateRequest,
    SelectBillingModeRequest,
    ShowOptionsByWorktaskRequest,
    ShowOptionsByWorkTaskResponse,
    SelectOrderProcessingRequest,
    SelectBackorderRequest,
    ShowDefaultWarehouseResponse,
    ShowDefaultWarehouseRequest,
    ShowWarehousesResponse,
    ShowWarehousesRequest,
    ShowDefaultOptionsRequest,
    ShowDefaultOptionsResponse,
    ShowItemsOptionVersionsRequest,
    ShowItemsOptionVersionsResponse,
    SelectOrderOptionsResponse,
    ShowGlobalOptionsRequest,
    ShowGlobalOptionsResponse,
} from "@tm/models"
import { getBundleParams } from "../../../utils"

function getOrderOptionsUrl() {
    return getBundleParams().orderOptionsServiceUrl
}

function orderWarehousesEnabled() {
    return getBundleParams().orderWarehousesEnabled
}

export function selectBackorder(body: SelectBackorderRequest) {
    const url = `${getOrderOptionsUrl()}/SelectBackorder`
    const authorization = getStoredAuthorization()
    return ajax<SelectOrderOptionsResponse>({ url, body, authorization, method: "POST" })
}

export function selectBillingAddress(body: SelectBillingAddressRequest) {
    const url = `${getOrderOptionsUrl()}/SelectBillingAddress`
    const authorization = getStoredAuthorization()
    return ajax<SelectOrderOptionsResponse>({ url, body, authorization, method: "POST" })
}

export function selectBillingMode(body: SelectBillingModeRequest) {
    const url = `${getOrderOptionsUrl()}/SelectBillingMode`
    const authorization = getStoredAuthorization()
    return ajax<SelectOrderOptionsResponse>({ url, body, authorization, method: "POST" })
}

export function selectDeliveryAddress(body: SelectDeliveryAddressRequest) {
    const url = `${getOrderOptionsUrl()}/SelectDeliveryAddress`
    const authorization = getStoredAuthorization()
    return ajax<SelectOrderOptionsResponse>({ url, body, authorization, method: "POST" })
}

export function selectDeliveryDistribution(body: SelectDeliveryDistributionRequest) {
    const url = `${getOrderOptionsUrl()}/SelectDeliveryDistribution`
    const authorization = getStoredAuthorization()
    return ajax<SelectOrderOptionsResponse>({ url, body, authorization, method: "POST" })
}

export function selectPayment(body: SelectPaymentRequest) {
    const url = `${getOrderOptionsUrl()}/SelectPayment`
    const authorization = getStoredAuthorization()
    return ajax<SelectOrderOptionsResponse>({ url, body, authorization, method: "POST" })
}

export function selectShipment(body: SelectShipmentRequest) {
    const url = `${getOrderOptionsUrl()}/SelectShipment`
    const authorization = getStoredAuthorization()
    return ajax<SelectOrderOptionsResponse>({ url, body, authorization, method: "POST" })
}

export function selectTour(body: SelectTourRequest) {
    const url = `${getOrderOptionsUrl()}/SelectTour`
    const authorization = getStoredAuthorization()
    return ajax<SelectOrderOptionsResponse>({ url, body, authorization, method: "POST" })
}

export function selectOrderProcessing(body: SelectOrderProcessingRequest) {
    const url = `${getOrderOptionsUrl()}/SelectOrderProcessing`
    const authorization = getStoredAuthorization()
    return ajax<SelectOrderOptionsResponse>({ url, body, authorization, method: "POST" })
}

export function setCustomOrderInformation(body: SetCustomOrderInformationRequest) {
    const url = `${getOrderOptionsUrl()}/SetCustomOrderNumberAndComment`
    const authorization = getStoredAuthorization()
    return ajax<SelectOrderOptionsResponse>({ url, body, authorization, method: "POST" })
}

export function setCustomOrderNumber(body: SetCustomOrderNumberRequest) {
    const url = `${getOrderOptionsUrl()}/SetCustomOrderNumber`
    const authorization = getStoredAuthorization()
    return ajax<SelectOrderOptionsResponse>({ url, body, authorization, method: "POST" })
}

export function setDeliveryDate(body: SetDeliveryDateRequest) {
    const url = `${getOrderOptionsUrl()}/SetDeliveryDate`
    const authorization = getStoredAuthorization()
    return ajax<SelectOrderOptionsResponse>({ url, body, authorization, method: "POST" })
}

export function setOrderComment(body: SetOrderCommentRequest) {
    const url = `${getOrderOptionsUrl()}/SetOrderComment`
    const authorization = getStoredAuthorization()
    return ajax<SelectOrderOptionsResponse>({ url, body, authorization, method: "POST" })
}

export function showDefaultWarehouse(body: ShowDefaultWarehouseRequest) {
    if (!orderWarehousesEnabled()) {
        return Promise.resolve({ hasErrors: false })
    }

    const url = `${getOrderOptionsUrl()}/ShowDefaultWarehouse`
    const authorization = getStoredAuthorization()
    return ajax<ShowDefaultWarehouseResponse>({ url, body, authorization })
}

export function showWarehouses(body: ShowWarehousesRequest) {
    if (!orderWarehousesEnabled()) {
        return Promise.resolve({ orderWarehouses: [], hasErrors: false })
    }

    const url = `${getOrderOptionsUrl()}/ShowWarehouses`
    const authorization = getStoredAuthorization()
    return ajax<ShowWarehousesResponse>({ url, body, authorization })
}

export function showOptionsByWorktask(body: ShowOptionsByWorktaskRequest) {
    const url = `${getOrderOptionsUrl()}/ShowOptionsByWorktask`
    const authorization = getStoredAuthorization()
    return ajax<ShowOptionsByWorkTaskResponse>({ url, body, authorization, method: "POST" }, undefined, undefined, true)
}

export function showDefaultOptions(body: ShowDefaultOptionsRequest): Promise<ShowDefaultOptionsResponse | undefined> {
    const url = `${getOrderOptionsUrl()}/ShowDefaultOptions`
    const authorization = getStoredAuthorization()
    return ajax({ url, body, authorization }, undefined, undefined, true)
}

export function showItemOptionVersions(body: ShowItemsOptionVersionsRequest) {
    const url = `${getOrderOptionsUrl()}/ShowItemOptionVersions`
    const authorization = getStoredAuthorization()
    return ajax<ShowItemsOptionVersionsResponse>({ url, body, authorization, method: "POST" })
}

export function showGlobalOptions(body: ShowGlobalOptionsRequest): Promise<ShowGlobalOptionsResponse | undefined> {
    const url = `${getOrderOptionsUrl()}/ShowGlobalOptions`
    const authorization = getStoredAuthorization()
    return ajax({ url, body, authorization, method: "POST" }, undefined, undefined, true)
}
