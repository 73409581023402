import { ECalcButtonState, ECalcButtonStyle } from "../enums"
import { ButtonLayout, CalcStateButton } from "../model"

export function getButtonIcon(item: CalcStateButton) {
    switch (item.type) {
        case ECalcButtonState.Reset:
            return "synchronize"
        case ECalcButtonState.AdditionalWork:
            return "additional-work"
        case ECalcButtonState.FollowupWork:
            return "follow-up-work"
        case ECalcButtonState.Close:
            return "close"
        case ECalcButtonState.CreateQuote:
            return "voucher-add-kva"
        case ECalcButtonState.StartCalculation:
            return "arrow-right"
        default:
            return undefined
    }
}

export function getButtonLabel(item: CalcStateButton) {
    if (item.type === ECalcButtonState.Close) {
        return ""
    }

    return item.label
}

export function getButtonSkin(buttonStyle: ECalcButtonStyle, item?: CalcStateButton) {
    // TODO This is hotfix only
    if (item?.type === ECalcButtonState.Close) {
        return undefined
    }

    // TODO moveto helpers
    switch (buttonStyle) {
        case ECalcButtonStyle.Danger:
            return "danger"
        case ECalcButtonStyle.Info:
            return "highlight"
        case ECalcButtonStyle.Primary:
            return "primary"
        case ECalcButtonStyle.Success:
            return "success"
        case ECalcButtonStyle.Warning:
            return "highlight"
        case ECalcButtonStyle.Default:
        default:
            return undefined
    }
}

export function getButtonLayout(item: ECalcButtonStyle, isTab?: boolean): ButtonLayout[] {
    if (isTab && item === ECalcButtonStyle.Danger) {
        return ["ghost"]
    }

    switch (item) {
        case ECalcButtonStyle.Danger:
        case ECalcButtonStyle.Success:
        case ECalcButtonStyle.Primary:
        case ECalcButtonStyle.Info:
        case ECalcButtonStyle.Warning:
        case ECalcButtonStyle.Default:
            return ["dropshadow"]
        case ECalcButtonStyle.Outlined:
            return ["holo"]
        default:
            return ["ghost"]
    }
}
