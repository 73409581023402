import { PostSendOrderAction, PostSendOrderActionTargetType, PostSendOrderActionType, Item } from "@tm/models"
import * as Data from "../../../../data"
import { mapItemErpInformation } from "../../../../data/mapper"
import { ItemErpInformation, SelectedOrderOptions, SendCentralOrderRequest, SendWorkTaskOrderRequest, WorkTaskOrder } from "../../../../data/model"
import { BasketOrderGroup } from "../../../../models"

export function openOrderSentUrl(workTaskId: string) {
    Data.getOrderSentUrl(workTaskId).then((url) => {
        if (url) {
            window.open(url, "internal")
        }
    })
}

export function mapDistributorGroups(distriGroups: BasketOrderGroup[]) {
    let bonusPointTotals = 0
    const orderOptions: SelectedOrderOptions[] = []
    const itemErpInformation: ItemErpInformation[] = []
    distriGroups.forEach((basketOrderGroup) => {
        if (basketOrderGroup.hasPartsIncludedInOrder) {
            const erpInfo = mapItemErpInformation(basketOrderGroup?.basketParts)
            if (erpInfo?.length) {
                itemErpInformation.push(...erpInfo)
            }
            if (basketOrderGroup.selectedOrderOptions) {
                orderOptions.push(basketOrderGroup.selectedOrderOptions)
            }

            // Currently the Backend accepts the total bonus points of only one bonus system for the send order, so we use the first entry here only.
            // As soon as the send order endpoint accepts the total bonus points for multiple bonus systems,
            // we should remove this line of code here and pass "orderGroup?.bonusPointTotalsInOrder" in the request.
            bonusPointTotals += parseInt(Object.keys(basketOrderGroup.bonusPointTotalsInOrder ?? {})[0])
        }
    })
    return {
        bonusPointTotals,
        orderOptions,
        itemErpInformation,
    }
}

function mapSelectedOrderOptions(
    workTaskId: string,
    orderOptions: SelectedOrderOptions[] | undefined,
    wholesalerOrderConfirmation: boolean,
    orderConfirmationEmail?: string,
    deliveryDate?: Date
) {
    const options: SelectedOrderOptions[] = []
    if (orderOptions?.length) {
        orderOptions.forEach((option) => {
            if (option.workTaskId === workTaskId) {
                options.push({
                    ...option,
                    wholesalerOrderConfirmation,
                    orderConfirmationEmail,
                    // Complete delivery might require a delivery date
                    deliveryDate: option?.selectedDeliveryDistributionMode?.id === "1" && deliveryDate ? deliveryDate : option?.deliveryDate,
                })
            }
        })
    }
    return options
}

export function createSendWorkTaskOrderRequest(
    workTaskId: string,
    orderOptions: SelectedOrderOptions[] | undefined,
    itemErpInformation: ItemErpInformation[] | undefined,
    partItemVersions: Item[],
    wholesalerOrderConfirmation: boolean,
    telesalesCustomerNo: string | undefined,
    orderSystemId: number,
    distributorId?: number,
    orderConfirmationEmail?: string,
    deliveryDate?: Date,
    bonusPointTotals?: number,
    orderByDateAscending?: boolean
): SendWorkTaskOrderRequest {
    const options = mapSelectedOrderOptions(workTaskId, orderOptions, wholesalerOrderConfirmation, orderConfirmationEmail, deliveryDate)

    return {
        workTaskId,
        orderSystemId,
        distributorId,
        itemErpInformation,
        orderOptions: options.find((option) => !option.warehouseId),
        warehouseOrderOptions: options.filter((option) => option.warehouseId),
        telesalesCustomerNo,
        bonusPointTotals,
        orderByDateAscending,
        partItemVersions,
    }
}

export function createSendCentralOrderRequest(
    workTaskIds: string[],
    orderOptions: SelectedOrderOptions[] | undefined,
    itemErpInformation: ItemErpInformation[] | undefined,
    wholesalerOrderConfirmation: boolean,
    telesalesCustomerNo: string | undefined,
    orderSystemId: number,
    distributorId?: number,
    orderConfirmationEmail?: string,
    deliveryDate?: Date, // TODO: this property was copied from the createSendWorkTaskOrderRequest. It must be checked if it is indeed required when completeDeliveryTourStart is available in central ordering
    bonusPointTotals?: number,
    useOrderCollection?: boolean,
    globalOrderOptions?: SelectedOrderOptions,
    orderByDateAscending?: boolean
): SendCentralOrderRequest {
    const workTaskOrders: WorkTaskOrder[] = []

    workTaskIds.forEach((workTaskId) => {
        const options = mapSelectedOrderOptions(workTaskId, orderOptions, wholesalerOrderConfirmation, orderConfirmationEmail, deliveryDate)

        workTaskOrders.push({
            workTaskId,
            itemErpInformation,
            orderOptions: options.find((option) => !option.warehouseId),
            warehouseOrderOptions: options.filter((option) => option.warehouseId),
            bonusPointTotals,
        })
    })

    return {
        workTaskOrders,
        orderSystemId,
        distributorId,
        telesalesCustomerNo,
        useGlobalOrderOptions: !!globalOrderOptions,
        useOrderCollection,
        orderOptions: globalOrderOptions,
        orderByDateAscending,
    }
}

export function handlePostSendOrderAction(postSendOrderActions?: PostSendOrderAction[]) {
    postSendOrderActions?.forEach((postAction) => {
        switch (postAction.action) {
            case PostSendOrderActionType.OpenExternalUrl: {
                if (postAction.target === PostSendOrderActionTargetType.NewWindow) {
                    window.open(postAction.value, "_blank")
                }
                break
            }
            case PostSendOrderActionType.PostMessage: {
                if (postAction.target === PostSendOrderActionTargetType.ParentFrame) {
                    window.parent.postMessage(JSON.parse(postAction.value), "*")
                }
                break
            }
            default:
                break
        }
    })
}
