import { VeFiles } from "@tm/data"
import { useMutation } from "react-query"
import { useCallback } from "react"
import { useLocalization } from "@tm/localization"
import { useWorkTask } from "@tm/context-distribution"
import { ShareEmailTemplateProps } from "../models"

export function useGetEmailTemplate() {
    const { translateText } = useLocalization()
    const customer = useWorkTask()?.workTask?.customer

    const { isLoading, mutateAsync } = useMutation(VeFiles.getEmailTemplate)

    const getEmailTemplate = useCallback(
        (documentUrl: string, validUntil: string) => {
            const content: ShareEmailTemplateProps = {
                textIntroduction: translateText(13818).replace("{{0}}", `${customer?.firstName ?? ""} ${customer?.lastName ?? ""}`),
                textFileDescription: translateText(13820).replace("{{1}}", validUntil),
                documentLinkText: translateText(13819),
                textEnding: translateText(13821).replace("{{3}}", ""),
                textDisclaimer: translateText(13822),
                documentUrl,
            }

            const request: VeFiles.GetEmailTemplateRequest = {
                serializedContent: JSON.stringify(content),
                templateName: "DocumentSharingEmail",
            }

            return mutateAsync(request)
        },
        [mutateAsync, translateText, customer]
    )

    return { isLoading, getEmailTemplate }
}
