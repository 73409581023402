export enum ECalcInputType {
    None,
    Mileage,
    RegDate,
    EngineCode,
    Longlife,
    CalculateIncludedAdditionalWork,
    ArticlePrice,
    OeArticlePrice,
    GenArtPrice,
    ConsumablePrice,
    RepairTimePrice,
    ArticleQuantity,
    OeArticleQuantity,
    GenArtQuantity,
    ConsumableQuantity,
    RepairTimeQuantity,
    ReplaceArticleByAlternative,
    ReplaceArticleByOeAlternative,
    RemoveArticle,
    RemoveOeArticle,
    RemoveGenArt,
    RemoveConsumable,
    RemoveRepairTime,
    GlobalLabourRate,
    ConsumableSpecifications,
    ConsumableLabel,
    RemoveDropDown,
    FavoriteDropDown,
    CrossDeleteAccept,
    CrossDeleteDeny,
    SwitchOeGroup,
    CustomerName,
    CustomerAddress,
    LicensePlate,
    VIN,
    MaintenancePlanOverlayMileage,
    NextMaintenanceDate,
    NextMaintenanceMileage,
    NextGenInspectionDate,
    PrintTires,
    PrintFirstAid,
    PrintTiresSealant,
    PrintConsumableQuantities,
    PrintComment,
    Comment,
    MaintenancePlanOverlayInitialRegistration,
    PrintInspectionTimes,
    Something,
    History,
    ArticleInput,
}
