import { WorkTaskReadModel } from "@tm/models"
import { WorkTaskListItem } from "./model"
import { removeBrackets } from "../../../helpers"

export function mapWorkTaskReadmodelToListItem(workTask: WorkTaskReadModel): WorkTaskListItem {
    let { workTaskId, workTaskNo, customer, vehicle, createdDate, modifiedDate, statusValue } = workTask
    workTaskNo = workTaskNo || ""
    createdDate = createdDate || new Date()

    let customerDisplayName: string | undefined
    if (customer) {
        const tokens = []
        if (customer.firstName && customer.firstName.length) {
            tokens.push(`${customer.firstName.substr(0, 1)}.`)
        }
        tokens.push(customer.lastName)
        customerDisplayName = tokens.join(" ")
    }

    let vehicleDisplayName: string | undefined
    let vehiclePlateId: string | undefined
    let vehicleThumbnail: string | undefined
    if (vehicle) {
        const tokens = [vehicle.manufacturer, removeBrackets(vehicle.modelSeries), removeBrackets(vehicle.model)]
        vehicleDisplayName = tokens.join(" ")
        vehiclePlateId = vehicle.plateId
        vehicleThumbnail = vehicle.modelThumbnail
    }

    const result: WorkTaskListItem = {
        workTaskId,
        workTaskNo,
        customerDisplayName,
        vehicleDisplayName,
        vehiclePlateId,
        vehicleThumbnail,
        createdDate,
        modifiedDate,
        status: statusValue,
    }

    return result
}
