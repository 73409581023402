import { Note } from "@tm/models"
import { ajax, createBufferedRequestFunction, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../utils"

const findNotes = (articleIds: string[]): Promise<Note[]> => {
    const url = `${getBundleParams().partsServiceUrl}/Notes/GetByIds`
    const authorization = getStoredAuthorization()

    return ajax<{ notes?: Note[] }>({ url, body: { noteIds: articleIds }, authorization }).then((response) => response?.notes || [])
}

export const findNotesBuffered = createBufferedRequestFunction<string[], Note[], Note[]>({
    callService: (requests) => findNotes(requests.flat()),
    mapServiceResponseToResponse: (serviceResponse, request) => {
        const includeNoteForArticle = serviceResponse.filter((articleNote) =>
            request.some((noteId) => {
                return articleNote.noteId === noteId
            })
        )

        return includeNoteForArticle.length ? includeNoteForArticle : []
    },
})

export const saveNote = (body: Note): Promise<void> => {
    const url = `${getBundleParams().partsServiceUrl}/Notes/Save`
    const authorization = getStoredAuthorization()

    return ajax<void>({ url, body, authorization, method: "POST" })
}

export const deleteNote = (noteId: string): Promise<void> => {
    const url = `${getBundleParams().partsServiceUrl}/Notes/Delete`
    const authorization = getStoredAuthorization()

    return ajax<void>({ url, body: { noteId }, authorization, method: "POST" })
}
