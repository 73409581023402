import { Box, Button, CellContentPosition, Icon, Table, TableCellData, TableColumnData, TableRowData, styled } from "@tm/components"
import { useState } from "react"
import { useLocalization } from "@tm/localization"
import { getCurrencyFromUserContext } from "@tm/utils"
import { useUser } from "@tm/context-distribution"
import { em } from "csx"
import Accordion from "../../_shared/Accordion"
import { SelectedWork } from "../../../data/models"
import { calculationActions, useDrivemotiveStore } from "../../../data/state"

export default function CalculationWorksList() {
    const { translateText, currency } = useLocalization()
    const { userContext } = useUser()
    const currencyString = getCurrencyFromUserContext(userContext)
    const totals = useDrivemotiveStore((state) => state.calculation.totals)
    const works = useDrivemotiveStore((state) => state.calculation.calculationWorks)
    const hourRate = useDrivemotiveStore((state) => state.summary.hourRate)
    const [switchStates, setSwitchStates] = useState<{
        isExpanded: boolean
    }>({
        isExpanded: !(works.length > 0),
    })

    const handleClick = (isExpanded: boolean) => {
        setSwitchStates((prevState) => ({
            ...prevState,
            isExpanded: !isExpanded,
        }))
    }

    const handleDeleteWork = (work: SelectedWork) => {
        calculationActions.deleteWorkFromCalculation(work)
    }

    const tableColumns: TableColumnData[] = [
        // { header: translateText(58) }, //Aw Number
        { header: translateText(617).toUpperCase() },
        { header: translateText(13711).toUpperCase(), alignContent: CellContentPosition.right },
        { header: translateText(1550).toUpperCase(), alignContent: CellContentPosition.right },
        { header: translateText(57).toUpperCase(), alignContent: CellContentPosition.right },
        { alignContent: CellContentPosition.right },
    ]

    const getCells = (work: SelectedWork) => {
        const worksDescription = (
            <Box sx={{ width: "70em" }}>
                {work.mainWork}
                <br />
            </Box>
        )

        const cellData: TableCellData[] = [
            // { displayValue: "46 14 20 50", id: "AW-Nummer" },
            { displayValue: worksDescription, id: "worksDescription" },
            {
                displayValue: hourRate && <Box sx={{ display: "flex" }}>{currency(hourRate, currencyString)}</Box>,
                id: "Hourly rate",
            },
            {
                displayValue: work.hours && (
                    <>
                        {work.hours} {translateText(12412)}
                    </>
                ),
                id: "hours",
            },
            {
                displayValue: work.hours && hourRate && <Box sx={{ display: "flex" }}>{currency(hourRate * work.hours, currencyString)}</Box>,
                id: "Sum",
            },
            {
                displayValue: <StyledButton onClick={() => handleDeleteWork(work)} startIcon={<Icon name="delete" />} />,
                id: "delete",
            },
        ]

        return cellData
    }

    const displayData: TableRowData[] = works.map((work: SelectedWork, index: number) => ({
        cells: getCells(work),
        id: `${index}`,
        rowIndicatorWidth: 7,
    }))

    return (
        <Box sx={{ marginTop: "1em", display: "flex", flexDirection: "column", flex: "1", gap: "0.5em" }}>
            <Accordion
                icon={<Icon name="down" />}
                expandIcon={<Icon name="up" />}
                expanded={!switchStates.isExpanded}
                onClick={handleClick}
                underlinedHeader
            >
                <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                    {translateText(1668)}
                    <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                        {totals.repairTimesPrice.totalQuantity} {translateText(479)}
                        <Box sx={{ fontWeight: "bold", marginLeft: "1em" }}>{currency(totals.repairTimesPrice.value, currencyString)}</Box>
                    </Box>
                </Box>
                {works.length > 0 && (
                    <StyledTable
                        columns={tableColumns}
                        rows={displayData}
                        headerBackground="transparent"
                        fontSize={14}
                        headerStyle="default"
                        rowCap={0}
                        overflowY="auto"
                        rowStyle="uniform"
                    />
                )}
            </Accordion>
        </Box>
    )
}

const StyledTable = styled(Table)({
    ".row>span": {
        alignContent: "flex-start",
        flexWrap: "wrap",
    },
})

const StyledButton = styled(Button)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: em(0.25),
})
