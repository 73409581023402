import { useCallback, useEffect, useMemo, useState } from "react"
import { atom, useRecoilState, useResetRecoilState, useRecoilValue } from "recoil"
import { OrderResponseGroup } from "@tm/models"
import { useQuery } from "react-query"
import {
    CalculateCentralOrderRequest,
    CalculateCentralOrderResponse,
    CalculatedWorkTaskOrder,
    OrderItemGroup,
    ShowWorkTasksRequest,
    WorkTask,
} from "../../model"
import * as Data from "../.."
import { useGlobalOrderOptionsState } from "../../../hooks/useGlobalOrderOptionsState"
import { basketCalculation } from "../../../hooks/basketState/useCentralOrderBasketStateByWorkTask"
import { useCalculateCentralOrderData } from "./useCalculateCentralOrderData"
import { bundleChannel } from "../../../business"

type State = {
    pageIndex: number
    loading?: boolean
    hasMorePages: boolean
    loadingNextPage?: boolean
    error?: string
    dateTo?: Date
    dateFrom?: Date
    showOnlyUserWorkTasks?: boolean
    workTasks: CentralOrderWorkTaskState[]
    includedWorkTaskIds: string[]
    includedWorkTasks: CentralOrderWorkTaskState[]
    expandedWorkTaskIds: string[]
    selectedWorkTaskIds: string[]
    selectedDisabledWorkTaskIds: string[]
    allWorkTasksSelected: boolean
    orderCalculation?: CalculateCentralOrderResponse
    orderCalculationLoading?: boolean
    orderCalculationWithError?: boolean
    includedWorkTasksWithBasketErrors?: boolean
    orderBeingSent: boolean
}

export type CentralOrderWorkTaskState = {
    workTask: WorkTask
    loading: boolean
    includedInOrder: boolean
    expanded: boolean
    selected: boolean
    disabled: boolean
    succeededOrderGroups?: OrderResponseGroup[]
    orderGroups?: OrderItemGroup[]
    currentlyUpdatingPartIds: Set<string>
    hasBasketErrors?: boolean
}

export const centralOrderWorkTasksAtom = atom<State>({
    key: "basket_centralOrderWorkTasksAtom",
    default: {
        pageIndex: 1,
        loading: undefined,
        hasMorePages: false,
        loadingNextPage: false,
        allWorkTasksSelected: false,
        workTasks: [],
        includedWorkTasks: [],
        includedWorkTaskIds: [],
        expandedWorkTaskIds: [],
        selectedWorkTaskIds: [],
        selectedDisabledWorkTaskIds: [],
        orderBeingSent: false,
    },
})

export function useCentralOrderWorkTasks() {
    const [state, setState] = useRecoilState(centralOrderWorkTasksAtom)
    const [request, setRequest] = useState<ShowWorkTasksRequest>()
    const reset = useResetRecoilState(centralOrderWorkTasksAtom)
    const basketCalculations = useRecoilValue(basketCalculation)

    const { centralOrderParameters } = useGlobalOrderOptionsState()
    // This caueses an infine loop
    // const allBasketOrderGroups = useRecoilValue(basketOrderGroups)
    const pageSize = centralOrderParameters.orderPagingSize

    const load = useCallback(
        async (req?: ShowWorkTasksRequest) => {
            if (!req) {
                return
            }
            setState((prev) => ({
                ...prev,
                loading: prev.pageIndex + 1 !== req.pageIndex,
                workTasks: prev.loadingNextPage ? prev.workTasks : [],
                pageIndex: req.pageIndex,
                loadingNextPage: prev.pageIndex + 1 === req.pageIndex,
            }))

            try {
                const response = await Data.showWorkTasks(req)
                let workTasks: CentralOrderWorkTaskState[] = []
                if (response?.workTaskInfos) {
                    const mappedResult = response?.workTaskInfos?.map<CentralOrderWorkTaskState>((workTask) => ({
                        workTask,
                        loading: false,
                        includedInOrder: true,
                        expanded: true,
                        selected: false,
                        disabled: false,
                        currentlyUpdatingPartIds: new Set(),
                    }))
                    if (req.pageIndex > 1) {
                        workTasks = state.workTasks.concat(mappedResult)
                    } else {
                        workTasks = mappedResult
                    }
                }

                const workTaskIds = workTasks.map((workTask) => workTask.workTask.workTaskId)
                if (workTaskIds?.length) {
                    const { workTaskOrderGroups } = await Data.showCentralOrder({ workTaskIds })
                    workTasks = workTasks.map((workTask) => {
                        const found = workTaskOrderGroups.find((w) => w.workTaskId === workTask.workTask.workTaskId)
                        if (found) {
                            return { ...workTask, loading: false, orderGroups: found.orderGroups }
                        }
                        return workTask
                    })
                }

                setState((prev) => ({
                    ...prev,
                    loading: false,
                    hasMorePages: response?.workTaskInfos?.length === pageSize,
                    workTasks,
                    loadingNextPage: false,
                }))
            } catch (error: any) {
                setState((prev) => ({ ...prev, error: error?.message }))
            }
        },
        [setState, state, pageSize]
    )

    useQuery({
        queryKey: ["basket_centralOrderWorkTasks", request],
        queryFn: () => load(request),
    })

    useEffect(() => {
        const unsubscriptions: Array<() => void> = []
        unsubscriptions.push(
            bundleChannel().subscribe("ANY_ORDERS_SENDING", () => {
                setState((prev) => ({
                    ...prev,
                    orderBeingSent: true,
                }))
            })
        )

        unsubscriptions.push(
            bundleChannel().subscribe("ALL_ORDERS_SENT", () => {
                setState((prev) => ({
                    ...prev,
                    orderBeingSent: false,
                }))
            })
        )

        return () => {
            unsubscriptions.forEach((unsub) => unsub())
        }
    }, [])

    useEffect(() => {
        const includedWorkTasks = state.workTasks.filter((w) => w.includedInOrder)
        const includedWorkTasksWithBasketErrors = includedWorkTasks.some((w) => w.hasBasketErrors)
        const includedWorkTaskIds = includedWorkTasks.map((w) => w.workTask.workTaskId)
        const selectedWorkTaskIds = state.workTasks.filter((w) => w.selected).map((w) => w.workTask.workTaskId)
        const selectedDisabledWorkTaskIds = state.workTasks.filter((w) => w.disabled && w.selected).map((w) => w.workTask.workTaskId)
        const expandedWorkTaskIds = state.workTasks.filter((w) => w.expanded).map((w) => w.workTask.workTaskId)
        const allWorkTasksSelected = selectedWorkTaskIds.length === state.workTasks.length

        setState((prev) => ({
            ...prev,
            includedWorkTasks,
            includedWorkTasksWithBasketErrors,
            includedWorkTaskIds,
            selectedWorkTaskIds,
            selectedDisabledWorkTaskIds,
            expandedWorkTaskIds,
            allWorkTasksSelected,
        }))
    }, [state.workTasks])

    const [calculateCentralOrderRequest, workTaskOrdersCalculated] = useMemo<[CalculateCentralOrderRequest, boolean]>(() => {
        const calculatedWorkTaskOrders: CalculatedWorkTaskOrder[] = []
        let calculatedWorkTasksNumber = 0
        state.includedWorkTaskIds.forEach((workTaskId) => {
            if (basketCalculations[workTaskId]) {
                const { calculatedOrder } = basketCalculations[workTaskId].workTaskBasketCalculation
                if (calculatedOrder) {
                    calculatedWorkTasksNumber++
                    if (Object.keys(calculatedOrder.erpTotalsInOrder).length !== 0) {
                        calculatedWorkTaskOrders.push({
                            workTaskId,
                            numberOfItemsInOrder: calculatedOrder.numberOfItemsInOrder,
                            numberOfPositionsInOrder: calculatedOrder.numberOfPositionsInOrder,
                            erpTotalsInOrder: calculatedOrder.erpTotalsInOrder,
                        })
                    }
                }
            }
        })
        const calculateRequest: CalculateCentralOrderRequest = {
            calculatedWorkTaskOrders,
        }

        const calculated = calculatedWorkTasksNumber === state.includedWorkTaskIds.length
        return [calculateRequest, calculated]
    }, [basketCalculations, state.includedWorkTaskIds])

    const { orderCalculation, orderCalculationLoading, orderCalculationWithError } = useCalculateCentralOrderData(
        calculateCentralOrderRequest,
        workTaskOrdersCalculated
    )

    useEffect(() => {
        setState((prev) => ({ ...prev, orderCalculation, orderCalculationLoading, orderCalculationWithError }))
    }, [orderCalculation, orderCalculationLoading, orderCalculationWithError, setState])

    const search = useCallback(
        (dateTo?: Date, dateFrom?: Date, showOnlyUserWorkTasks?: boolean) => {
            const pageIndex = 1
            if (showOnlyUserWorkTasks !== undefined) {
                setRequest({ pageIndex, pageSize, dateTo, dateFrom, showOnlyUserWorkTasks })
            }
        },
        [pageSize]
    )

    const loadNextPage = useCallback(() => {
        const pageIndex = state.pageIndex + 1
        setRequest((prev) => ({ ...prev, pageSize, pageIndex }))
        setState((prev) => ({ ...prev, loadNextPage: true }))
    }, [state.pageIndex, setState, pageSize])

    const selectAll = useCallback(
        (selected: boolean) => {
            setState((prev) => ({ ...prev, workTasks: prev.workTasks.map((w) => ({ ...w, selected })) }))
        },
        [setState]
    )

    const setWorkTaskStatusByList = useCallback(
        (workTaskIds: string[], property: "includedInOrder" | "expanded", value: boolean) => {
            setState((prev) => ({
                ...prev,
                workTasks: prev.workTasks.map((w) => {
                    if (workTaskIds.includes(w.workTask.workTaskId)) {
                        return { ...w, [property]: value }
                    }
                    return w
                }),
            }))
        },
        [setState]
    )

    const toggleExpanded = useCallback(
        (workTaskId: string) => {
            setState((prev) => ({
                ...prev,
                workTasks: prev.workTasks.map((w) => {
                    if (workTaskId === w.workTask.workTaskId) {
                        return { ...w, expanded: !w.expanded }
                    }
                    return w
                }),
            }))
        },
        [setState]
    )

    const hideWorkTask = useCallback(
        (workTaskId: string) => {
            setState((prev) => ({
                ...prev,
                workTasks: prev.workTasks.filter((w) => w.workTask.workTaskId !== workTaskId),
            }))
        },

        [setState]
    )

    return { state, search, loadNextPage, reset, selectAll, setWorkTaskStatusByList, toggleExpanded, hideWorkTask }
}
