import { useWorkTask } from "@tm/context-distribution"
import { useActiveVehicleDataProviders } from "@tm/utils"
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react"
import { ArticleErpInfo, ArticleListType, OE } from "@tm/models"
import { ArticleListActionsProvider } from "../ArticleListActions"
import { PartsModuleStateProvider, usePartsModuleState } from "../PartsModuleState"
import { useErpInfos } from "../hooks/useErpInfos"
import { useFindeOeReplacement } from "../hooks/useFindeOeReplacement"
import { useOeInformation } from "../hooks/useOeInformation"
import { useListOptions } from "../hooks/useListOptions"
import { useProductGroupRepairTimes } from "../hooks/useProductGroupRepairTimes"
import { useShowArticleDetails } from "../hooks/useShowArticleDetails"
import { useSupplierLogos } from "../hooks/useSupplierLogos"
import { useWatchListData } from "../hooks/useWatchListData"
import { useArticles } from "./hooks/useArticles"
import { useFilters } from "./hooks/useFilters"
import { useListParams } from "./hooks/useListParams"
import { useSearchFilters } from "./hooks/useSearchFilters"
import { useBasketQuantities } from "../hooks/useBasketQuantities"
import { useNotes } from "../hooks/useNotes"
import { useHasLoadingEnded } from "../hooks/useHasLoadingEnded"
import { useTradeReferences } from "../hooks/useTradeReferences"
import { useArticleListConfiguration } from "../ArticleListConfiguration"
import { useOensArticles } from "../hooks/useOensArticles"
import { getBundleParams } from "../../../utils"
import { useLoadOePartErpInfos } from "../hooks/useErpInfos/useLoadOePartErpInfos"
import { ArticleListActions, DirectSearchModuleState } from "../models"
import { usePreviouslyOrderedArticles } from "../hooks/usePreviouslyOrderedArticles"

export function DirectSearchModuleStateProvider(props: PropsWithChildren<unknown>) {
    const type: ArticleListType = "direct"
    const [isEnabled, setIsEnabled] = useState(false)
    const [erpInfos, setErpInfos] = useState<ArticleErpInfo[]>([])

    const { enableOeInformation } = useArticleListConfiguration().oeInformation
    const enableOENS = getBundleParams().enableOENS ?? false

    const { workTaskId } = useWorkTask() ?? {}
    const options = useListOptions()
    const params = useListParams()
    const filtersData = useFilters(params, isEnabled)
    const articlesData = useArticles(params, erpInfos, isEnabled)
    const tradeReferences = useTradeReferences(articlesData.articles, isEnabled)
    const erpInfosData = useErpInfos({
        articles: articlesData.articles,
        isEnabled,
        startParams: params.startParams,
        tradeReferences,
        erpInfos,
        setErpInfos,
    })
    const supplierLogosData = useSupplierLogos(articlesData.supplierIds, options.showDataSupplierLogos && isEnabled)
    const watchListData = useWatchListData(articlesData.articles, !!options.hasWatchList && isEnabled && erpInfosData.isReady)
    const productGroupRepairTimesData = useProductGroupRepairTimes(articlesData.productGroupIds, isEnabled && erpInfosData.isReady)
    // data/TM.Next.Catalog/Articles/Filters/Search
    const searchFilters = useSearchFilters(params, isEnabled)
    // data/TM.Next.Catalog/Articles/GetOeInformation
    const oeInfo = useOeInformation(params, enableOeInformation && isEnabled)
    const oensArticles = useOensArticles(params, isEnabled && enableOENS)
    // data/TM.Next.OeResearch/OE/FindOeReplacement
    const oeReplacement = useFindeOeReplacement(params, isEnabled)
    const activeVehicleDataProvidersData = useActiveVehicleDataProviders(workTaskId)
    const showArticleDetails = useShowArticleDetails()
    const { basketQuantities, updateBasketQuantities } = useBasketQuantities(articlesData.articles, isEnabled && erpInfosData.isReady)
    const notes = useNotes(articlesData.articles, false, isEnabled && erpInfosData.isReady)
    const previouslyOrderedArticles = usePreviouslyOrderedArticles(articlesData.articles)

    const collectedOeParts: OE.OePart[] = useMemo(() => {
        const oeInfoParts: OE.OePart[] = []

        if (!oeInfo.isLoading && oeInfo.oeInformation) {
            oeInfoParts.push(...oeInfo.oeInformation.flatMap((oei) => oei.oeArticles.flatMap((oea) => oea.parts)))
        }

        if (oensArticles.oeParts) {
            oeInfoParts.push(...oensArticles.oeParts)
        }

        return oeInfoParts
    }, [oeInfo.oeInformation, oeInfo.isLoading, oensArticles.oeParts])

    const loadOePartErpInfos = useLoadOePartErpInfos(erpInfosData)

    useEffect(() => {
        if (collectedOeParts.length > 0) {
            loadOePartErpInfos(collectedOeParts.map((p) => ({ oePart: p, quantity: p.quantity || 1 })))
        }
    }, [collectedOeParts])

    const hasLoadingEnded = useHasLoadingEnded(
        supplierLogosData.isLoading,
        watchListData?.isLoading,
        productGroupRepairTimesData.isLoading,
        activeVehicleDataProvidersData.isLoading
    )

    /**
     * Do not wrap it with `useMemo` here but threat it as not reference stable and therefor not use it as any dependency for other hooks.
     */
    const state: DirectSearchModuleState = {
        type,
        start: useCallback(() => setIsEnabled(true), []),
        options,
        params,
        filters: filtersData,
        articles: articlesData,
        supplierLogos: hasLoadingEnded ? supplierLogosData : undefined,
        watchListData: hasLoadingEnded ? watchListData : undefined,
        productGroupRepairTimes: hasLoadingEnded ? productGroupRepairTimesData : undefined,
        activeVehicleDataProviders: hasLoadingEnded ? activeVehicleDataProvidersData.activeProviders : undefined,
        searchFilters,
        oeInformation: oeInfo,
        oensArticles,
        oeReplacement,
        basketQuantities,
        notes,
        tradeReferences,
        previouslyOrderedArticles,
        erpInfosData,
    }

    const articleActionsValue: ArticleListActions = {
        toggleSupplier: filtersData.toggleSupplier,
        showArticleDetails,
        updateBasketQuantities,
    }

    return (
        <PartsModuleStateProvider value={state}>
            <ArticleListActionsProvider value={articleActionsValue}>{props.children}</ArticleListActionsProvider>
        </PartsModuleStateProvider>
    )
}

export function useDirectSearchModuleState(): DirectSearchModuleState
export function useDirectSearchModuleState<TReturnType>(selector: (value: DirectSearchModuleState) => TReturnType): TReturnType
export function useDirectSearchModuleState<TReturnType>(
    selector?: (value: DirectSearchModuleState) => TReturnType
): DirectSearchModuleState | TReturnType {
    return usePartsModuleState<DirectSearchModuleState, TReturnType>((context) => {
        if (!selector) {
            return context as unknown as TReturnType
        }

        return selector(context) as unknown as TReturnType
    })
}
