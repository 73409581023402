import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { Box, Button, Icon, LoadingContainer, Stack, styled, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useEffect, useMemo } from "react"
import { useWorkTask } from "@tm/context-distribution"
import { UploadData } from "./UploadData"
import { FileCategory } from "../../models"
import { CategorySelection } from "../categorySelection/CategorySelection"
import { UploadArea } from "../fileUpload/UploadArea"
import { useUploadFile } from "../../hooks"
import { useUploadFileWithUrl } from "../../hooks/fileUpload"
import { getUploadSchema, UploadSchemaType } from "../../formSchemas/uploadSchema"

export const StyledHeader = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
})

type Props = {
    handleCloseViewOptions: () => void
}

export function UploadSection({ handleCloseViewOptions }: Props) {
    const { vehicle } = useWorkTask()?.workTask || {}
    const { translateText } = useLocalization()

    const { uploadedFileData, isLoading: isLoadingFileUpload, uploadFile } = useUploadFile()
    const { uploadSuccesfull, uploadFileWithUrl, isLoading: isLoadingUploadWithUrl } = useUploadFileWithUrl()

    const uploadSchema = useMemo(() => getUploadSchema(translateText), [translateText])
    const isLoading = isLoadingFileUpload || isLoadingUploadWithUrl

    const formMethods = useForm<UploadSchemaType>({
        resolver: zodResolver(uploadSchema),
        defaultValues: {
            documentDate: new Date(),
            category: FileCategory.Others,
            file: undefined,
        },
        mode: "all",
    })

    const { handleSubmit } = formMethods

    useEffect(() => {
        if (!isLoading && (uploadedFileData?.uploadKey || uploadSuccesfull)) {
            handleCloseViewOptions()
        }
    }, [isLoading, uploadedFileData?.uploadKey, handleCloseViewOptions, uploadSuccesfull])

    const onSubmit = (data: UploadSchemaType) => {
        if (data.file && vehicle?.id && data.category && data.mileage && data.documentDate) {
            if (typeof data.file === "string") {
                uploadFileWithUrl(
                    data.file,
                    vehicle?.id,
                    FileCategory[data.category],
                    data.mileage.toString(),
                    data.documentDate,
                    data.category === FileCategory.Invoice ? data.invoiceTotal?.toString() : undefined
                )
            } else {
                uploadFile(
                    data.file,
                    vehicle?.id,
                    FileCategory[data.category],
                    data.mileage.toString(),
                    data.documentDate,
                    data.category === FileCategory.Invoice ? data.invoiceTotal?.toString() : undefined
                )
            }
        }
    }

    return (
        <Stack m={1} sx={{ width: "40em" }}>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <LoadingContainer isLoading={isLoading}>
                    <StyledHeader>
                        <Typography sx={{ fontSize: "1.2em" }}>{translateText(13770)}</Typography>
                        <Button type="button" variant="text" onClick={handleCloseViewOptions}>
                            <Icon name="close" />
                        </Button>
                    </StyledHeader>
                    <Box>
                        <Typography fontSize="small">1. {translateText(3038)}</Typography>
                        <CategorySelection formMethods={formMethods} />

                        <Typography fontSize="small">2. {translateText(3031)}</Typography>
                        <UploadData formMethods={formMethods} />

                        <Typography fontSize="small">3. {translateText(3030)}</Typography>
                        <UploadArea formMethods={formMethods} />

                        <Button color="success" size="medium" type="submit" sx={{ displat: "flex", float: "right", marginTop: "1em" }}>
                            <Typography>{translateText(13774)}</Typography>
                        </Button>
                    </Box>
                </LoadingContainer>
            </form>
        </Stack>
    )
}
