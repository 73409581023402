import { WorkTaskInfo } from "@tm/context-distribution"
import { useCallback } from "react"
import { AddCustomWorkListRequest, CostEstimationOptions } from "@tm/models"
import { mapCustomRepairTimeItemToCustomWork } from "../../../../../data/mapper"
import { CustomRepairTimeItem } from "../../../../../data/model/custom-items"
import { useDeleteCustomRepairTimes } from "../../../../../data/hooks/custom-basket-items/useCustomRepairTimes"
import { CustomItemActions } from "../../CustomItemActions"

type Props = {
    workTask: WorkTaskInfo
    repairTime: CustomRepairTimeItem
    repairTimeDivision: number
    isSelected: boolean
    costEstimationOptions?: CostEstimationOptions
    onAddCustomWorks(request: AddCustomWorkListRequest): Promise<unknown>
    onEditClick(repairTime: CustomRepairTimeItem | undefined): void
    onToggleSelection(repairTime: CustomRepairTimeItem): void
}

export function CustomRepairTimesActions(props: Props) {
    const { workTask, repairTime, repairTimeDivision, onEditClick, isSelected, onToggleSelection, onAddCustomWorks, costEstimationOptions } = props
    const { mutateAsync: deleteRepairTimes } = useDeleteCustomRepairTimes()

    const handleDelete = useCallback((item: CustomRepairTimeItem) => deleteRepairTimes([item.id]), [deleteRepairTimes])

    const handleAddToBasket = useCallback(
        (item: CustomRepairTimeItem) =>
            onAddCustomWorks({
                workTaskId: workTask.id,
                repairTimeDivision,
                customWorks: [mapCustomRepairTimeItemToCustomWork(item)],
                vehicleId: workTask.vehicle?.id,
                usePercentageValues: true,
                costEstimationOptions,
            }),
        [onAddCustomWorks, workTask.id, workTask.vehicle?.id, repairTimeDivision, costEstimationOptions]
    )

    return (
        <CustomItemActions
            item={repairTime}
            onDelete={handleDelete}
            onEditClick={onEditClick}
            onAddToBasket={handleAddToBasket}
            isSelected={isSelected}
            onToggleSelection={onToggleSelection}
        />
    )
}
