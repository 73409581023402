import { useState } from "react"
import { Article, ArticleAttributes, IMicros } from "@tm/models"
import { Image, Text, Button } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useMicro } from "@tm/morpheus"
import Attributes from "./attributes"
import ArticleNumbers from "../ArticleNumbers"

type Props = {
    article: Article
    onSelect: (article: Article) => void
}

export default function ArticleListItemViewComponent(props: Props) {
    const { translateText } = useLocalization()
    const { renderMicro } = useMicro<IMicros>()
    const { article } = props
    const [showAllAttributes, setShowAllAttributes] = useState<boolean>(true)

    function handleAttributeMoreToggle() {
        setShowAllAttributes(!showAllAttributes)
    }

    function filterAttributes(attr: ArticleAttributes): ArticleAttributes {
        return {
            topAttributes: attr.topAttributes,
            articleAttributes: [],
            partsListAttributes: [],
            vehicleAttributes: [],
        }
    }

    function renderActions() {
        return (
            <Button size="l" onClick={() => props.onSelect(article)}>
                {translateText(310)}
            </Button>
        )
    }

    function renderAttributes() {
        let { attributes } = article
        if (!showAllAttributes) {
            attributes = attributes.map(filterAttributes)
        }
        return (
            <Attributes
                attributes={attributes}
                canSelect={false}
                onSelect={() => {}}
                isCompact={!showAllAttributes}
                selected={[]}
                ignoreAttributeKey
                vehicleEngineCode={undefined}
                toggleMoreAttributes={handleAttributeMoreToggle}
            />
        )
    }

    // what is this '-' ? why we can't set it's value to null or undefined?
    const productGroupName = !!article.productGroup && article.productGroup.name != "-" && article.productGroup.name

    return (
        <div className="list__item part-list__item">
            <div className="part-list__properties">
                <div className="toggle-attributes no-separator">
                    <Button layout={["ghost"]} icon={showAllAttributes ? "up" : "down"} onClick={handleAttributeMoreToggle} />
                </div>
                <div className="thumbnail no-separator">
                    <Image url={article.thumbnail ? article.thumbnail : ""} type="article" />
                </div>
                <div className="manufacturer">{article.supplier && <Text title={article.supplier.name}>{article.supplier.name}</Text>}</div>
                <ArticleNumbers
                    wholesalerArticleNumber={article.traderArticleNo}
                    productGroupId={article.productGroup.id}
                    supplierArticleNumber={article.supplierArticleNo}
                    supplierId={article.supplier.id}
                />
                <Text className="description" size="s">
                    {productGroupName || article.description || article.additionalDescription}
                </Text>
                <div className="price">{renderMicro("erp", "erp-info-prices", { data: article })}</div>
                <div className="avail">{renderMicro("erp", "erp-info-availability", { data: article })}</div>
                <div className="actions">{renderActions()}</div>
                <div className="article__cell article__cell--attributes">{renderAttributes()}</div>
            </div>
        </div>
    )
}
