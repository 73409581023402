import { useLocalization } from "@tm/localization"
import { useEffect } from "react"
import { ArticleErpInfo, DefaultArticleItemState } from "@tm/models"
import { mergeTeccomPrices, mergeWarehouses } from "../../../../../../erp/src/micros/_helpers/useErpInfo"
import { useTeccom } from "../../../../../../erp/src/micros/_helpers/useTeccom"
import { ErpInfosData } from "../../models"
import { useDefaultErpSystem } from "../useDefaultErpSystem"

export function useArticleExtendedDeliveryInfo(
    isEnabled: boolean,
    { setErpInfos }: ErpInfosData,
    articleErpInfo?: ArticleErpInfo
): DefaultArticleItemState["extendedDeliveryInfo"] {
    const { translateText } = useLocalization()

    const erpSystemConfig = useDefaultErpSystem()
    const erpInfo = articleErpInfo?.state === "success" ? articleErpInfo.response : undefined
    const {
        teccom: teccomInfo,
        loadTeccom: load,
        teccomLoading: isLoading,
    } = useTeccom(erpInfo?.isTeccomRequestAvailable, articleErpInfo?.request, erpSystemConfig?.id)

    useEffect(() => {
        if (!teccomInfo) {
            return
        }

        setErpInfos((prev) => {
            return prev.map<ArticleErpInfo>((item) => {
                // If the item is not the one we are looking for, we return it as is
                if (item.request.itemId !== teccomInfo.itemId) {
                    return item
                }

                // If the item has no ERP data loaded (yet), we return it as is
                const articleErpInfoResponse = item.state === "success" ? item.response : undefined
                if (!articleErpInfoResponse) {
                    return item
                }

                // Finally we merge the ERP data with the teccom data
                return {
                    ...item,
                    response: {
                        ...articleErpInfoResponse,
                        availability: teccomInfo.specialProcurementErpInformation?.availability ?? articleErpInfoResponse.availability,
                        orderOptions: teccomInfo.specialProcurementErpInformation?.orderOptions ?? articleErpInfoResponse.orderOptions,
                        prices: mergeTeccomPrices(articleErpInfoResponse.prices, teccomInfo.prices),
                        warehouses: mergeWarehouses(translateText, articleErpInfoResponse.warehouses, teccomInfo),
                    },
                }
            })
        })
    }, [teccomInfo, setErpInfos, translateText])

    return { showExtendedDeliveryInfo: isEnabled, info: teccomInfo, load, isLoading }
}
