import { getBundleParams } from "../utils"

export * from "./repositories/address-picker"
export * from "./repositories/sub-user"
export * from "./repositories/MDM-Params-dutchorder"
export * from "./repositories/externalCredentials"

export function getAddressPickerServiceUrl(): string {
    const bundleParams = getBundleParams()
    return bundleParams.addressPickerServiceUrl
}

export function getSubUserServiceUrl(): string {
    const bundleParams = getBundleParams()
    return bundleParams.subUserServiceUrl
}

export function getDutchOrderPlatesServiceUrl(): string | undefined {
    const bundleParams = getBundleParams()
    return bundleParams.dutchOrderPlatesServiceUrl
}

export function getAuthorityServiceUrl(): string {
    const bundleParams = getBundleParams()
    return bundleParams.authorityServiceUrl
}
