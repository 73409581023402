import { styled, Typography, Accordion, MuiAccordionSummary, AccordionDetails, Box, Button, Icon, Checkbox } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useEffect, useMemo, useState } from "react"
import { GeneralData, TiresAndWheelsDefekt } from "../../_shared"
import CollapsibleContent from "./collapsibleContent"
import { tyresWheelsActions, useFastServiceStore } from "../../../data"
import { CollapsibleData, CollapsibleInfo, WheelsAndTyresTab } from "../../../data/state/tyres-wheels"
import { Genart } from "../../../data/models"

type Props = {
    tab: WheelsAndTyresTab
}

export default function WheelCheckTab({ tab }: Props) {
    const { translateText } = useLocalization()

    const { selectedFilters, name } = useFastServiceStore((state) => state.tyresWheels.selectedTab)
    const spareWheel = useFastServiceStore((state) => state.tyresWheels.spareWheel)
    const works = useFastServiceStore((state) => state.worksState.works)
    const tabs = useFastServiceStore((state) => state.tyresWheels.tabs)
    const completedTabsCount: number = tabs.filter((x) => x.isComplete === true).length
    const isBrakeSystemNext: boolean = completedTabsCount === tabs.length - 1

    const [showDefektModal, setShowDefektModal] = useState<boolean>(false)
    const [showGeneralModal, setShowGeneralModal] = useState(false)
    const [brakeSystem, setBrakeSystem] = useState(false)
    const [selectedCollapsible, setSelectedCollapsible] = useState("")
    const completedAmount = useMemo(() => tab.collapsibleItems.filter((x) => x.completed).length, [tab.collapsibleItems])
    const currentStepName = "Tires wheels"
    const [fittingPosition, setFittingPosition] = useState<string | undefined>(undefined)
    const [genArtItem, setGenArtItem] = useState<Genart>({
        genArtNr: 6322,
        label: translateText(13828),
    })

    useEffect(() => {
        const workFound = Object.values(works)
            .flatMap((x) => x)
            .map((work) => {
                if (work.genArts) {
                    const workItem = work.genArts.filter((item) => item.genArtNr === 6322)
                    return workItem[0]
                }
            })
            .filter((item) => !!item)?.[0]

        if (workFound) {
            setGenArtItem(workFound)
        }
    }, [works, genArtItem.article?.id])

    useEffect(() => {
        // select the next item in list and if at the end of the list select the first available
        const index = tab.collapsibleItems.findIndex((x) => x.name === selectedCollapsible && x.completed)
        if (index !== -1 && index + 1 < tab.collapsibleItems.length && !tab.collapsibleItems[index + 1].completed) {
            setSelectedCollapsible(tab.collapsibleItems[index + 1].name)
        } else if (tab.collapsibleItems.some((x) => !x.completed) && tab.collapsibleItems.some((x) => x.completed)) {
            setSelectedCollapsible(tab.collapsibleItems.find((x) => !x.completed)!.name)
        }
    }, [completedAmount, brakeSystem])

    useEffect(() => {
        if (selectedCollapsible === "general") {
            setShowGeneralModal(true)
        }

        if (isBrakeSystemNext && !tab.isComplete) {
            handleBrakeSystemCollapsible(spareWheel ? "spareWheel" : "breakdownSet")
        }
    }, [selectedCollapsible, isBrakeSystemNext])

    const handleShowDefektModal = () => {
        setShowDefektModal(true)
    }

    const handleHideDefektModal = () => {
        setShowDefektModal(false)
    }

    const handleCollapsibleClick = (collapsibleName: string) => {
        setSelectedCollapsible(collapsibleName === selectedCollapsible ? "" : collapsibleName)
    }

    const handleCompleteCollapsible = (collapsibleName: string) => {
        tyresWheelsActions.completeCollapsible(tab.name, tab.type, collapsibleName)
        if (collapsibleName === "general") {
            setShowGeneralModal(false)
        }
    }

    const handleCloseGeneralModal = () => {
        setShowGeneralModal(false)
        // un-select first collapsible
        setSelectedCollapsible("")
    }

    const handleSizeBuild = () => {
        return `${selectedFilters.width}/${selectedFilters.height} R${selectedFilters.inch} ${selectedFilters.loadIndex}${selectedFilters.speedIndex}`
    }

    const renderButtonWrapper = (collapsibleName: string, info: CollapsibleData) => {
        const handleClick = () => {
            handleCompleteCollapsible(collapsibleName)
            if (collapsibleName === "brakeSystem") {
                setFittingPosition("breakdownSet")
            }
        }

        let disabled = true

        if (collapsibleName === "tyreDot") {
            disabled = !(!!info.profileDepth && !!info.dot)
        } else {
            disabled = !Object.values(info).length
        }

        if (collapsibleName === "brakeSystem") {
            disabled = !info.brakeSystem
        }

        return (
            <ButtonWrapper>
                <Button size="large" onClick={handleShowDefektModal}>
                    {translateText(12950)}
                </Button>
                <PaddingButton disabled={disabled} size="large" onClick={handleClick} color="success" startIcon={<Icon name="check" />} />
            </ButtonWrapper>
        )
    }

    const renderAppendix = (collapsibleName: string) => {
        if (collapsibleName === "general") {
            return (
                <Typography className="appendix">
                    {selectedFilters.manufacturer &&
                        `${selectedFilters.size.length > 4 ? selectedFilters.size : handleSizeBuild()} | ${selectedFilters.manufacturer ?? ""} | ${
                            selectedFilters.season ?? ""
                        }`}
                </Typography>
            )
        }
    }

    const renderCollapsibleContent = (data: CollapsibleInfo, idx: number, isExpanded: boolean) => {
        return (
            <StyledCenteredWrapper collapsibleName={data.name}>
                <StyledCollapsibleContent
                    key={idx}
                    collapsibleName={data.name}
                    tabName={name}
                    info={data.infoData}
                    isExpanded={isExpanded}
                    handleCompleteCollapsible={handleCompleteCollapsible}
                    genArtItem={genArtItem}
                    currentStepName={currentStepName}
                />
                {data.name !== "wholesaler" && renderButtonWrapper(data.name, data.infoData)}
            </StyledCenteredWrapper>
        )
    }

    const renderCollapsible = (data: CollapsibleInfo, idx: number) => {
        let isExpanded = selectedCollapsible === data.name
        if (data.name === "wholesaler") {
            isExpanded = true
        }

        if (data.name === "brakeSystem" && genArtItem.article) {
            isExpanded = true
        }
        return (
            <CustomAccordion expanded={isExpanded} className={data.name} key={idx}>
                <AccordionSummaryStyled onClick={() => handleCollapsibleClick(data.name)} name={data.name}>
                    {data.completed ? <Icon name="check" /> : <div className="notCheckedInsidePoint" />}
                    <Typography>{translateText(data.translateTextId)}</Typography>
                    {renderAppendix(data.name)}
                </AccordionSummaryStyled>
                <AccordionContent>{renderCollapsibleContent(data, idx, isExpanded)}</AccordionContent>
            </CustomAccordion>
        )
    }

    const handleBrakeSystemCollapsible = (position: "spareWheel" | "breakdownSet") => {
        const isBrakeSystemCompleted: boolean = tab.collapsibleItems.some((x) => x.name === "brakeSystem" && x.completed)
        if (tab.name === "spareWheel" && isBrakeSystemNext) {
            if (position === "breakdownSet" && !isBrakeSystemCompleted) {
                setSelectedCollapsible("brakeSystem")
            } else {
                setBrakeSystem(!brakeSystem)
            }
        }
    }

    const handleFittingPositionChange = (position: "spareWheel" | "breakdownSet") => {
        if (fittingPosition === position && !genArtItem.article) {
            setFittingPosition(undefined)
            setSelectedCollapsible("wholesaler")
            tyresWheelsActions.updateSpareWheel(false)
        } else {
            setFittingPosition(position)
            tyresWheelsActions.updateSpareWheel(position === "spareWheel")
        }
        handleBrakeSystemCollapsible(position)
        tyresWheelsActions.completeCollapsible(tab.name, tab.type, tab.collapsibleItems.first?.name)
    }

    const brakeSwitch = () => (
        <SwitchWrapper>
            <StyledCheckboxWrapper>
                <Typography sx={{ minWidth: "11em" }}>{translateText(13779)}</Typography>
                <Checkbox checked={fittingPosition === "spareWheel"} onChange={() => handleFittingPositionChange("spareWheel")} size="medium" />
            </StyledCheckboxWrapper>
            <StyledCheckboxWrapper>
                <Typography sx={{ minWidth: "11em" }}>{translateText(13780)}</Typography>
                <Checkbox checked={fittingPosition === "breakdownSet"} onChange={() => handleFittingPositionChange("breakdownSet")} size="medium" />
            </StyledCheckboxWrapper>
        </SwitchWrapper>
    )

    const renderContent = () => {
        if (tab.type === "spareWheel") {
            if (fittingPosition) {
                if (spareWheel) {
                    return tab.collapsibleItems
                        .filter((collapsible) => collapsible.name !== "brakeSystem" && collapsible.name !== "wholesaler")
                        .map(renderCollapsible)
                }
                if (!spareWheel) {
                    if (genArtItem.article) {
                        return tab.collapsibleItems
                            .filter((collapsible) => collapsible.name === "brakeSystem" || collapsible.name === "wholesaler")
                            .map(renderCollapsible)
                    }
                    return tab.collapsibleItems.filter((collapsible) => collapsible.name === "brakeSystem").map(renderCollapsible)
                }
            } else {
                if (genArtItem.article) {
                    return tab.collapsibleItems
                        .filter((collapsible) => collapsible.name === "brakeSystem" || collapsible.name === "wholesaler")
                        .map(renderCollapsible)
                }
                return tab.collapsibleItems.filter((collapsible) => collapsible.name === "wholesaler").map(renderCollapsible)
            }
        }

        return tab.collapsibleItems.map(renderCollapsible)
    }

    return (
        <>
            {tab.type === "spareWheel" && brakeSwitch()}
            {renderContent()}
            {showDefektModal && (
                <TiresAndWheelsDefekt
                    open={showDefektModal}
                    handleCloseModal={handleHideDefektModal}
                    collapsibleName={selectedCollapsible}
                    tabType={tab.name}
                />
            )}
            {showGeneralModal && (
                <GeneralData
                    open={showGeneralModal}
                    onComplete={handleCompleteCollapsible}
                    handleCloseModal={handleCloseGeneralModal}
                    isFront={tab.type === "front"}
                    selectedTabName={tab.name}
                    tabType={tab.type}
                />
            )}
        </>
    )
}

const CustomAccordion = styled(Accordion)(({ theme }) => ({
    marginTop: theme.margin?.xs ?? "0.1em",
    backgroundColor: "#efefef",
    boxShadow: "none",
    border: "1px solid #efefef",
    borderRadius: "3px",
    "&.general": {
        margin: 0,
        ".MuiCollapse-root": {
            display: "none",
        },
    },
    "&::before": {
        height: "0px",
        backgroundColor: "transparent",
    },
    position: "static",
}))

// TODO: Check if it can be migrated to the existing customized `AccordionSummary` from @tm/components
const AccordionSummaryStyled = styled((props: any) => (
    <MuiAccordionSummary expandIcon={<Icon name={props.name === "general" ? "next" : "down"} />} {...props} />
))(({ theme }) => ({
    margin: theme.margin?.xs ?? "0.1em",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(180deg)",
    },
    "& .MuiAccordionSummary-content": {
        alignItems: "center",
        ".MuiTypography-root": {
            marginLeft: theme.spacing(1),
        },
        ".appendix": {
            marginLeft: theme.spacing(2),
        },
        ".icon": {
            color: theme.colors?.success,
        },
    },
    ".notCheckedInsidePoint": {
        width: "1em",
        height: "1em",
        borderRadius: "100%",
        border: "1px solid #8c8c8c",
    },
}))

const PaddingButton = styled(Button)({
    marginLeft: ".5em",
})

const AccordionContent = styled(AccordionDetails)({
    backgroundColor: "white",
})

const StyledCenteredWrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== "collapsibleName",
})<{ collapsibleName?: string }>(({ collapsibleName }) => ({
    ...(collapsibleName !== "wholesaler" && {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    }),
}))

const StyledCollapsibleContent = styled(CollapsibleContent)(({ collapsibleName }) => ({
    ...(collapsibleName !== "wholesaler" && {
        display: "flex",
        flexDirection: "row",
        marginLeft: "1em",
        alignItems: "center",
    }),
}))

const ButtonWrapper = styled(Box)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifySelf: "flex-end",
})

const SwitchWrapper = styled(Box)({
    padding: "0 2em",
    display: "flex",
    flexDirection: "column",
})

const StyledCheckboxWrapper = styled(Box)({
    display: "flex",
})
