import { useSelectedVehicleLookup, useWorkTask } from "@tm/context-distribution"
import { CarModel, RegistrationNoType, Vehicle, VehicleSearchType, VehicleType } from "@tm/models"
import { concat, useAvailableVehicleSearchOptions } from "@tm/utils"
import { useCallback, useMemo, useState } from "react"
import { showModelsByRegistrationNo } from "../../../data/repositories/cars/showModels"
import { loadVehicleSuggestions } from "../../../data/repositories/data-picker"
import { findGsiVehicles } from "../../../data/repositories/gsi"
import { getBundleParams } from "../../../utils"

export type VehicleSuggestions = {
    type: "CUSTOMER" | "REGNO" | "VEHICLE"
    suggestions: string
    suggestionHighlight?: string
    vehicle?: Vehicle
    carModel?: CarModel & { plateId: string }
}

// find customer vehicles in NEXT DB
const loadSuggestionsCustomerVehicle = (query: string) => {
    return new Promise<VehicleSuggestions[]>((resolve, reject) => {
        findGsiVehicles({ query, searchType: VehicleSearchType.All, pageSize: 10 })
            .then((response) => {
                const result: VehicleSuggestions[] =
                    response?.vehicles.map((vehicle) => ({
                        suggestions: concat(" ", vehicle?.manufacturer, vehicle?.modelSeries, vehicle?.model),
                        type: "CUSTOMER",
                        vehicle,
                    })) || []
                resolve(result)
            })
            .catch((e) => reject(new Error(`GSI Vehicle search Faild: ${e.message}`)))
    })
}

// find vehicles
const loadSuggestionsVehicle = (query: string, vehicleType: VehicleType | undefined, selectedRegNoType: number | undefined) => {
    return new Promise<VehicleSuggestions[]>((resolve, reject) => {
        loadVehicleSuggestions(query, selectedRegNoType, vehicleType)
            .then((response) => {
                const result: VehicleSuggestions[] =
                    response?.map((hit) => ({ suggestions: hit.text || "", suggestionHighlight: hit.highlight, type: "VEHICLE" })) || []
                resolve(result)
            })
            .catch((e) => reject(new Error(`VehicleSuggestions Faild: ${e.message}`)))
    })
}

// search for Registration number
const loadModelsByRegistrationNo = (query: string) => {
    return new Promise<VehicleSuggestions[]>((resolve, reject) => {
        showModelsByRegistrationNo({ registrationNo: query, registrationNoTypeId: 2 })
            .then((response) => {
                const result: VehicleSuggestions[] =
                    response?.models.map((model) => ({
                        suggestions: model.fullDescription || model.description || "",
                        type: "REGNO",
                        carModel: { ...model, plateId: query },
                    })) || []
                resolve(result)
            })
            .catch((e) => reject(new Error(`RegistrationSearch Faild: ${e.message}`)))
    })
}

export function useVehicleSearchAutocomplete(vehicleType: VehicleType | undefined) {
    const [isFetching, setIsFetching] = useState(false)
    const [suggestions, setSuggestions] = useState<VehicleSuggestions[]>([])
    const { selectedVehicleLookup } = useSelectedVehicleLookup()
    const { availableVehicleSearches } = useAvailableVehicleSearchOptions()

    const { workTask } = useWorkTask() ?? {}
    const { vehicle } = workTask ?? {}

    const config = useMemo(() => {
        if (!!vehicle && !vehicle.countryCode) {
            return
        }

        if (availableVehicleSearches) {
            const { configuredVehicleLookups } = availableVehicleSearches
            const currentLookupConfig = configuredVehicleLookups.find(
                (lookup) => lookup.isSelectable && lookup.lookupTypeId === selectedVehicleLookup.lookupTypeId
            )
            return currentLookupConfig ?? availableVehicleSearches?.defaultVehicleLookup
        }
    }, [availableVehicleSearches, selectedVehicleLookup, vehicle])

    const selectedRegNoType = config && !selectedVehicleLookup.lookupTypeId ? config.lookupTypeId : selectedVehicleLookup.lookupTypeId

    const clearSuggestions = () => {
        setSuggestions([])
    }

    const findSuggestions = useCallback(
        (query: string) => {
            setIsFetching(true)

            const requestList = []
            requestList.push(loadSuggestionsVehicle(query, vehicleType, selectedRegNoType))

            // only request for registration number for "KennzeichenNiederland" => NEXT-27423
            if (query.length === 6 && config?.lookupTypeId === RegistrationNoType.KennzeichenNiederlande) {
                requestList.push(loadModelsByRegistrationNo(query))
            }

            Promise.allSettled(requestList).then((results) => {
                const flatResults = results.flatMap((a) => {
                    if (a.status === "fulfilled") {
                        return a.value
                    }
                    return []
                })

                const hasVehicleResults = flatResults.some((e) => e?.type === "CUSTOMER")
                const { disableCustomerVehicle } = getBundleParams()

                // Only search for customers if no vehicle was found
                if (!hasVehicleResults && !disableCustomerVehicle) {
                    loadSuggestionsCustomerVehicle(query).then((result) => {
                        setSuggestions([...flatResults, ...result])
                        setIsFetching(false)
                    })
                } else {
                    setSuggestions(flatResults)
                    setIsFetching(false)
                }
            })
        },
        [config, vehicleType]
    )

    return { findSuggestions, isFetching, suggestions, config, selectedRegNoType, clearSuggestions }
}
