import { em } from "csx"
import { useLocalization } from "@tm/localization"
import { Box, Icon, Typography, styled } from "@tm/components"
import { useState } from "react"
import { useUser } from "@tm/context-distribution"
import { getCurrencyFromUserContext } from "@tm/utils"
import { ArticleForOe, NotFoundArticle, OeProductGroup } from "../../../data/models/calculation"
import ArticleItem from "./articleItem"
import Accordion from "../../_shared/Accordion"
import NotFoundArticleComponent from "./NotFoundArticle"
import { useDrivemotiveStore } from "../../../data/state"

export default function ArticleList() {
    const { translateText, currency } = useLocalization()
    const { userContext } = useUser()
    const currencyString = getCurrencyFromUserContext(userContext)
    const oeProductGroups = useDrivemotiveStore((state) => state.calculation.calculationArticles)
    const [switchStates, setSwitchStates] = useState<{ isExpanded: boolean }>({ isExpanded: false })
    const totals = useDrivemotiveStore((state) => state.calculation.totals)

    const handleClick = (isExpanded: boolean) => {
        setSwitchStates((prevState) => ({
            ...prevState,
            isExpanded: !isExpanded,
        }))
    }

    const mapGenartToNotFoundArticle = (oeProductGroup: OeProductGroup, article?: ArticleForOe): NotFoundArticle => {
        return {
            label: oeProductGroup.label,
            price: article?.price || 0,
            quantity: article?.quantity || 1,
            totalPrice: article?.totalPrice || 0,
            oeNumber: article?.oeNumber || "",
        }
    }

    const renderArticle = (oeProductGroup: OeProductGroup) => {
        return (
            <StyledItem key={oeProductGroup.id}>
                <StyledLabel variant="h4">{oeProductGroup.label}</StyledLabel>
                <div className="article-list">
                    {oeProductGroup && oeProductGroup.articles.length === 0 && (
                        <NotFoundArticleComponent item={mapGenartToNotFoundArticle(oeProductGroup)} />
                    )}
                    {oeProductGroup.articles &&
                        oeProductGroup.articles.map((article, idx) => {
                            if (!article.article) {
                                return <NotFoundArticleComponent key={idx} item={mapGenartToNotFoundArticle(oeProductGroup, article)} />
                            }
                            return <ArticleItem key={idx} item={article} />
                        })}
                </div>
            </StyledItem>
        )
    }

    return (
        <Box sx={{ marginTop: "1em", display: "flex", flexDirection: "column", flex: "1", gap: "0.5em" }}>
            <Accordion
                icon={<Icon name="down" />}
                expandIcon={<Icon name="up" />}
                expanded={!switchStates.isExpanded}
                onClick={handleClick}
                underlinedHeader
            >
                <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                    {translateText(1667)}
                    <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                        {totals.articlesPrice.totalQuantity} {translateText(479)}
                        <Box sx={{ fontWeight: "bold", marginLeft: "1em" }}>{currency(totals.articlesPrice.value, currencyString)}</Box>
                    </Box>
                </Box>
                <>{oeProductGroups?.map(renderArticle)}</>
            </Accordion>
        </Box>
    )
}

const StyledLabel = styled(Typography)({
    padding: em(0.5),
})

const StyledItem = styled(Box)({
    marginTop: em(0.5),
})
