export enum ECalcInputStyle {
    None = 0,
    Text = 1,
    Number = 2,
    Slider = 3,
    Checkbox = 4,
    Date = 5,
    Button = 6,
    DropDown = 7,
    Multiline = 8,
    MonthYearDate = 9,
}
