import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { useStyle, useUser, useWorkTask } from "@tm/context-distribution"
import { Card, Headline, Scrollbar, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import Morpheus from "@tm/morpheus"
import { classes, getCurrencyFromUserContext, renderRoute, RouteComponentProps, withRouter } from "@tm/utils"
import { Article, ECalcOrigin, channel } from "@tm/models"
import { Badge, Box, Button, Divider, Icon, styled } from "@tm/components"
import { getComponentStyles } from "../_shared/styles"
import CustomEndModal from "../_shared/customEndModal"
import FastCalcSelection from "../_shared/modal/fastCalcSelection"
import { getLabourRate } from "../../helpers/userSettingsContextMethods"
import { createMemo, mapProductGroupIds } from "../../helpers"
import { createImportCalculationRequest } from "../../helpers/createImportCalculationRequest"
import { getBundleParams } from "../../utils"
import { stepNavigationActions, useFastServiceStore } from "../../data"
import { useBasketImports } from "../../../../basket/src/hooks/basketState/useBasketImports"
import { useRepairTimes } from "../../helpers/hooks/useRepairTimes"
import { PrintOptions, TopProductGroup } from "../../data/models"
import { useCostEstimationModuleParameter } from "../../../../basket/src/hooks/useCostEstimationModuleParameter"
import { SharePopover } from "./components/SharePopover"
import CustomDisplayOptionsModal from "../_shared/customDisplayOptionsModal"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props = RouteComponentProps<any>

const End: FC<Props> = ({ match }) => {
    const currentStepName = "end"
    const { translateText, languageId } = useLocalization()
    const { userSettings, userContext } = useUser()
    const currencyCode = useMemo(() => getCurrencyFromUserContext(userContext), [userContext])
    const [showPrintModal, setShowPrintModal] = useState(false)
    const [showDisplayOptionsModal, setShowDisplayOptionsModal] = useState(false)
    const [showFastCalcModal, setShowFastCalcModal] = useState(false)
    const printState = useFastServiceStore((state) => state.printOptions)
    const calculationDone = useFastServiceStore((state) => state.calculationDone)
    const selectedArticles = useFastServiceStore((state) => state.selectedArticles)
    const selectedItems = useFastServiceStore((state) => state.worksState.selectedItems)
    const setUpdatePrintOptions = useFastServiceStore((state) => state.setUpdatePrintOptions)
    const works = useFastServiceStore((state) => state.worksState.works)
    const workTimes = useFastServiceStore((state) => state.maintenancePlan.workTimes)
    const { workTask } = useWorkTask() || {}
    const { importFastCalculation } = useBasketImports()
    const classNames = getComponentStyles()
    const { totalRepairTimesFormatted } = useRepairTimes()

    const { hasCostEstimation } = useCostEstimationModuleParameter()

    useEffect(() => {
        stepNavigationActions.completeStep(currentStepName)

        const unsubscriptions: Array<() => void> = []
        const worktaskChannel = channel("WORKTASK", workTask?.id)
        unsubscriptions.push(
            worktaskChannel.subscribe("FAST-CALCULATOR/CALCULATION_DONE", (fastCalc) => {
                fastCalc.isFastCalcDone && useFastServiceStore.getState().setCalculationDone()
            })
        )

        return () => {
            unsubscriptions.forEach((unsub) => unsub())
        }
    }, [])

    const handleRequestCalculation = useCallback((ids: number[], topProductGroups: TopProductGroup[]) => {
        if (!getBundleParams().calculatorRoute) {
            return
        }

        const route = renderRoute(getBundleParams().calculatorRoute!, {
            ...match.params,
            productGroupIds: mapProductGroupIds(topProductGroups),
            origin: ECalcOrigin.FastService,
        })

        handleToggleFastCalcModal()

        Morpheus.showView("1", route)
    }, [])

    const handlePrintStateChange = (printKey: keyof PrintOptions) => {
        setUpdatePrintOptions(printKey)
        useFastServiceStore.getState().setEmptyPrint()
    }

    const handleTogglePrintModal = () => {
        setShowPrintModal((prevState) => !prevState)
    }

    const handleToggleFastCalcModal = () => {
        setShowFastCalcModal((prevState) => !prevState)
    }

    const handleToggleDisplayOptionsModal = () => {
        setShowDisplayOptionsModal((prevState) => !prevState)
    }

    const handleAddToCostEstimate = () => {
        if (!workTask?.id) {
            return
        }

        const memo = userSettings?.orderOptions.repairShopResponse?.addWorkTaskInfoToOrderItemMemo ? createMemo(workTask) : undefined

        const selectedRepairTimes = Object.entries(selectedItems)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .map(([_, value]) =>
                Object.values(value)
                    .map((x) => x.selectedRepairTimes!)
                    .flat()
                    .filter((x) => !!x)
            )
            ?.flat()

        const repairTimes = Object.values(workTimes).flat().concat(selectedRepairTimes)

        const consumables = Object.values(works)
            .flatMap((x) => x)
            .filter((y) => !!y.consumables.length && y.consumables.some((c) => c.article && c.isSelected))
            .map((z) => z.consumables)
            .flatMap((item) => item)

        const oeArticles = Object.entries(selectedItems)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .map(([_, value]) =>
                Object.values(value)
                    .filter((x) => x.selectedGenArts?.every((gA) => !gA.oeReplace))
                    .map((x) => x.selectedGenArts)
                    .flat()
                    .filter((x) => !!x)
            )
            ?.flat()

        const selectedItemsValues = Object.entries(selectedItems)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .map(([_, value]) => Object.values(value))
            .flat()

        const replacedArticles: Array<Article> = []

        selectedItemsValues.forEach((selectedItemsValue) => {
            selectedItemsValue.selectedGenArts?.forEach((genArt) => {
                if (genArt.oeReplace && genArt.article) {
                    replacedArticles.push(genArt.article)
                }
            })
        })

        const articles = [...replacedArticles, ...selectedArticles.filter((article) => article.supplier && article.productGroup)]

        const request = createImportCalculationRequest(
            articles,
            consumables,
            repairTimes,
            works,
            getLabourRate(userSettings),
            workTask,
            memo,
            currencyCode,
            oeArticles
        )

        if (!request) {
            return
        }

        importFastCalculation(request).then(
            () => {
                channel("APP").publish("TOAST_MESSAGE/SHOW", {
                    message: translateText(12998),
                    icon: "check",
                    skin: "success",
                    closeDelay: 3000,
                })
            },
            () => {
                channel("APP").publish("TOAST_MESSAGE/SHOW", {
                    message: translateText(12997),
                    icon: "error",
                    skin: "warning",
                    closeDelay: 3000,
                })
            }
        )
    }

    return (
        <>
            <Box className={style.textWraper}>
                <Headline size="m">{translateText(13030)}</Headline>
                <Text size="m">{translateText(13031)}</Text>
            </Box>
            <CustomEndModal
                open={showPrintModal}
                onOutsideClick={handleTogglePrintModal}
                printState={printState}
                onPrintStateChange={handlePrintStateChange}
            />
            <CustomDisplayOptionsModal open={showDisplayOptionsModal} onOutsideClick={handleToggleDisplayOptionsModal} />
            <FastCalcSelection open={showFastCalcModal} onOutsideClick={handleToggleFastCalcModal} onCalculation={handleRequestCalculation} />
            <Scrollbar>
                <Box className={classes(style.cardWrapper, classNames.wrapperMargins)}>
                    <Box className={style.wrapper}>
                        <Card className={style.card}>
                            <Text className={style.mainTitle} size="xl">
                                {translateText(13034)}
                            </Text>
                            <Text size="m" modifiers="sub">
                                {translateText(13035)}
                            </Text>
                            <Button
                                className={classNames.buttonPadding}
                                size="extralarge"
                                startIcon={<Icon name="fast-calculator" />}
                                onClick={handleToggleFastCalcModal}
                            >
                                {translateText(1042)}
                            </Button>
                            {calculationDone && <StyledBadge badgeContent={<Icon color="success" name="check" size="xl" />} />}
                        </Card>
                        {hasCostEstimation && (
                            <Card className={style.card}>
                                <Text className={style.mainTitle} size="xl">
                                    {translateText(13687)}
                                </Text>
                                <Text size="m" modifiers="sub">
                                    {translateText(12960)}
                                </Text>
                                <Button
                                    className={classNames.buttonPadding}
                                    size="extralarge"
                                    startIcon={<Icon name={languageId === "1" ? "voucher-kva" : "voucher-kva-international"} />}
                                    disabled={!totalRepairTimesFormatted}
                                    onClick={handleAddToCostEstimate}
                                >
                                    {translateText(1607)}
                                </Button>
                            </Card>
                        )}
                        <StyledCard className={classes(style.card, hasCostEstimation && style.lastCard)}>
                            <Text className={style.mainTitle} size="xl">
                                {translateText(13688)}
                            </Text>
                            <Text size="m" modifiers="sub">
                                {translateText(12961)}
                            </Text>
                            <StyledInnerBox>
                                <StyledBox>
                                    <Button
                                        className={classNames.buttonPadding}
                                        size="extralarge"
                                        startIcon={<Icon name="print" />}
                                        onClick={handleTogglePrintModal}
                                    >
                                        {translateText(13033)}
                                    </Button>

                                    <SharePopover printState={printState} onPrintStateChange={handlePrintStateChange} />
                                </StyledBox>
                                <StyledDivider />
                                <Button
                                    sx={{ marginLeft: "auto", marginRight: "auto" }}
                                    size="medium"
                                    startIcon={<Icon name="settings" />}
                                    onClick={handleToggleDisplayOptionsModal}
                                    variant="outlined"
                                >
                                    {translateText(844)}
                                </Button>
                            </StyledInnerBox>
                        </StyledCard>
                    </Box>
                </Box>
            </Scrollbar>
        </>
    )
}

export default withRouter(End)

const StyledInnerBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    gap: "1em",
    width: "100%",
})

const StyledDivider = styled(Divider)({
    width: "100%",
    border: "0",
    background: "#e0e0e0",
    height: "1px",
})

const StyledCard = styled(Card)({
    "& .card__inner": {
        padding: "2em 3em 1em 3em !important",
    },
})

const StyledBadge = styled(Badge)(({ theme }) => ({
    position: "absolute",
    top: "1px",
    right: "25px",
    "& .MuiBadge-badge": {
        padding: ".25rem",
        background: `${theme.colors?.success}`,
        height: "unset",
        borderRadius: "1rem",
        borderColor: "white !important",
        border: "1px solid",
        "& .icon": {
            fill: "white !important",
        },
    },
}))

const StyledBox = styled(Box)({
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
})

const style = useStyle({
    wrapper: {
        display: "grid",
        gridTemplateColumns: "auto auto",
    },
    card: {
        $nest: {
            ".card__inner": {
                display: "flex",
                flexDirection: "column",
                gap: "3em",
                padding: "3em",
                alignItems: "baseline",
                justifyContent: "space-between",
                height: "100%",
            },
        },
    },
    lastCard: {
        gridColumn: "span 2",
        justifySelf: "center",
    },
    mainTitle: {
        fontWeight: 600,
    },
    textWraper: {
        display: "flex",
        padding: "1em 1em 2em 2em",
        gap: ".5em",
        flexDirection: "column",
    },
    cardWrapper: {
        display: "flex",
        justifyContent: "center",
    },
})(End)
