import { Suspense, useState, useCallback } from "react"
import { WorkTaskInfo, useTelesalesCustomerNumber, useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { IMicros, PostSendOrderActionTargetType, PostSendOrderActionType } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { Box, NumberSelect, styled, Badge, Button, Loader, Stack, Icon } from "@tm/components"
import { concat, useExternalCatalogUrl } from "@tm/utils"

import { getQuantityFromQuantitiyGroupsDto, useAddArticleExternalUrl, createAddOePartListRequest } from "../../helpers"
import { useBasketMemo } from "../../hooks/useBasketMemo"
import { getBundleParams } from "../../utils"
import { useOeBasketInfo } from "./helpers/useOeBasketInfo"
import { useWorkTaskBasketState } from "../../hooks/basketState/useWorkTaskBasketState"
import { useBasketModuleParameter } from "../../hooks/useBasketModuleParameter"
import { useCreateSendOrderPostMessage } from "../../hooks/useCreateSendOrderPostMessage"
import { handlePostSendOrderAction } from "../../components/_shared/order-button/hooks/helper"

type Props = IMicros["basket"]["add-to-basket-oe-part"]

const NumberSelectWrapper = styled(Box)(() => ({
    marginRight: ".25em",
}))

function AddToBasketOePartComponent(props: Props & { workTask: WorkTaskInfo }) {
    const { part, buttonText, disabled, layout, onChangeQuantity, workTask } = props
    const { translateText, languageId } = useLocalization()
    const externalSystemId = getBundleParams().addToBasketExternalSystemId
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { basket } = useWorkTaskBasketState(workTask.id)
    const { loadingExternalCatalogUrl, externalCatalogUrl } = useExternalCatalogUrl({ externalSystemId, telesalesCustomerNo })
    const basketMemo = useBasketMemo(workTask)
    const { getMessageByOePart } = useCreateSendOrderPostMessage()

    const [_quantity, setQuantity] = useState(part.quantityValue ?? 1)
    const quantity = props.quantity ?? _quantity
    const [articleGetsAddedToBasket, setArticleGetsAddedToBasket] = useState(false)
    const { basketInfo, basketInfoLoading } = useOeBasketInfo(props.part, workTask)
    const url = useAddArticleExternalUrl(
        externalCatalogUrl,
        languageId,
        quantity,
        part.wholesalerArticleNumber,
        part.oeArticleNumber,
        undefined,
        part.productGroupId
    )

    const basketModule = useBasketModuleParameter()

    const handleChangeQuantity = useCallback(
        (amount: number) => {
            setQuantity(amount)
            onChangeQuantity?.(amount)
        },
        [onChangeQuantity]
    )

    const handleAddArticleClick = useCallback(() => {
        if (disabled || !part) {
            return
        }

        if (basketModule?.addToNextBasket !== false) {
            if (url) {
                Morpheus.showView("1", url)
            } else {
                const request = createAddOePartListRequest(part, quantity, workTask, basketMemo.position)

                setArticleGetsAddedToBasket(true)
                basket.actions.addOeParts(request).finally(() => {
                    setArticleGetsAddedToBasket(false)
                })
            }
        }
        if (basketModule?.sendPostMessage) {
            const message = getMessageByOePart([part], workTask.id)
            handlePostSendOrderAction(message)
        }
    }, [disabled, part, workTask, url, quantity, basketMemo.position, basketModule])

    // if the MDM config at the BasketModule has no Basketbutton, don't render it
    if (basketModule?.showBasketButton === false) {
        return null
    }

    if (!props.part) {
        console.warn("AddOeArticleToBasketComponent used but property not supplied: part")
        return null
    }

    function renderQuantity() {
        if (props.hideQuantityField || layout === "cost-estimation") {
            return null
        }

        return (
            <NumberSelectWrapper>
                <NumberSelect value={quantity} onValueChange={handleChangeQuantity} />
            </NumberSelectWrapper>
        )
    }

    function renderButton() {
        const handleRemovePart = (partIds: Array<string>) => {
            basket.actions.removeParts(partIds, true)
        }

        let badge
        if (articleGetsAddedToBasket || basketInfoLoading) {
            badge = (
                <Badge
                    title={translateText(1299)}
                    badgeContent={<Loader size="extrasmall" />}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    sx={{ position: "initial" }}
                />
            )
        } else {
            const { quantity, ids } = getQuantityFromQuantitiyGroupsDto(basketInfo?.articleQuantities, undefined, undefined)
            if (quantity && ids.length) {
                badge = (
                    <Badge
                        size="small"
                        title={translateText(1299)}
                        badgeContent={
                            <Stack direction="row">
                                {quantity}
                                <Icon name="close" width="12px" height="12px" sx={{ marginLeft: "2px" }} />
                            </Stack>
                        }
                        onClick={() => handleRemovePart(ids)}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        sx={{ position: "initial" }}
                    />
                )
            }
        }

        // The add-to-cost-estimation micro requires too much logic only to add the oe part in the basket igonring the RT.
        // The best solution is to add the cost-estimation layout to the add t basket Button
        let icon = "cart"
        if (layout === "cost-estimation") {
            icon = languageId === "1" ? "voucher-kva" : "voucher-kva-international"
        }

        return (
            <>
                {layout !== "cost-estimation" && badge}
                <Box minWidth={buttonText ? "13em" : "6em"}>
                    <Button
                        title={translateText(937)}
                        color={!disabled ? "highlight" : undefined}
                        className="addToBasketButton"
                        variant={!disabled ? "bordered" : undefined}
                        onClick={handleAddArticleClick}
                        startIcon={<Icon name={icon} />}
                        disabled={disabled || loadingExternalCatalogUrl}
                        fullWidth
                    >
                        {buttonText ? <>{buttonText}</> : ""}
                    </Button>
                </Box>
            </>
        )
    }

    return (
        <div className="tk-basket" title={disabled ? translateText(938) : undefined}>
            <div className={concat(" ", "add-to-basket", disabled && "add-to-basket--disabled")}>
                {renderQuantity()}
                {renderButton()}
            </div>
        </div>
    )
}

export default function Wrapper(props: Props) {
    const { workTask } = useWorkTask() ?? {}

    if (!workTask) {
        return null
    }
    return (
        <Suspense fallback={null}>
            <AddToBasketOePartComponent {...props} workTask={workTask} />
        </Suspense>
    )
}
