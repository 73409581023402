import { TopProductGroup } from "../data/models"

export const mapProductGroupIds = (topProductGroups: TopProductGroup[]) => {
    let productGroupIds = ""
    topProductGroups.forEach((item) => {
        if (item.binKrit100) {
            productGroupIds += `${item.genartId},${item.binKrit100};`
        } else {
            productGroupIds += `${item.genartId};`
        }
    })
    return productGroupIds
}
