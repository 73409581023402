import { CalcState } from "../../../../data/model"
import { ECalcState } from "../../../../data/enums"
import WorkContent from "./WorkContent"
import CalcSelectionContent from "./CalcSelectionContent"

type Props = {
    calcState: CalcState | undefined
    loading: boolean | undefined
    onClose?: () => void
}

export default function Content({ calcState, loading, onClose }: Props) {
    const interactionDialog = calcState && ECalcState.OverlayCalcStates & calcState?.type
    const worksDialog = calcState && ECalcState.WorksState & calcState?.type

    if (!calcState) {
        return null
    }

    if (worksDialog) {
        return <WorkContent calcState={calcState} loading={loading} onClose={onClose} />
    }

    if (interactionDialog) {
        return <CalcSelectionContent calcState={calcState} loading={loading} onClose={onClose} />
    }

    return null
}
