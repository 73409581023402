import { useCallback, useMemo } from "react"
import { ArticleListSortingMode } from "@tm/models"
import { Box, Button, styled, ArticleItem } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ArticleGroup } from "../components/ArticleGroup"
import { NextPageLoader } from "../components/NextPageLoader"
import { NoSearchResult } from "../components/NoSearchResult"
import { useArticleSelection } from "../hooks/useArticleSelection"
import { ArticleSelection } from "../components/ArticleSelection"
import { usePartsViewOptions } from "../hooks/usePartsViewOptions"
import { useOePartsModuleState } from "./OePartsModuleState"
import { getBundleParams } from "../../../utils"
import { OePositions } from "../components/OePositions"

import { ArticleGroupHeader } from "../../list/components/ArticleGroupHeader/ArticleGroupHeader"
import { ArticleListWrapper } from "../components/ArticleListWrapper"
import { OeArticleItemStateProvider } from "../components/OeArticle/OeArticleItemStateProvider"

const ModalArticleListWrapper = styled(ArticleListWrapper)({
    height: "100%",
    paddingLeft: 0,
})

export function OePartsList() {
    const { translateText } = useLocalization()
    const erpInfosData = useOePartsModuleState((x) => x.erpInfosData)
    const noResult = useOePartsModuleState((x) => x.params.noResult)
    const oePositions = useOePartsModuleState((x) => x.params.oePositions)
    const selectedOePart = useOePartsModuleState((x) => x.params.selectedOePart)
    const changeOePosition = useOePartsModuleState((x) => x.params.changeOePosition)

    const attributes = useOePartsModuleState((x) => x.params.attributes)
    const toggleAttribute = useOePartsModuleState((x) => x.params.toggleAttribute)
    const setAttributes = useOePartsModuleState((x) => x.params.setAttributes)
    const sortingProductGroups = useOePartsModuleState((x) => x.params.sorting.productGroups)
    const options = useOePartsModuleState((x) => x.options)

    const activateSortingForProductGroups = useOePartsModuleState((x) => x.params.sorting.setForProductGroups)
    const { articleGroups, isLoading, isLoaded, isFetchingNextPage, isFailed, hasNextPage, loadNextPage, isStalling, isFrontendFiltered } =
        useOePartsModuleState((x) => x.articles)
    const productGroupTopicIds = useOePartsModuleState((x) => x.productGroupTopicIds)

    const {
        partsViewSettings: { showVehicleRecordsFilters },
    } = usePartsViewOptions()

    const replaceButtonBundle = useOePartsModuleState((x) => x.params.startParams.replaceButtonBundle)

    const calculatorRoute = getBundleParams().fastCalculatorModalRoute

    const usedAttributeFilters = useMemo(() => attributes.map((x) => x.query ?? `${x.id}|${x.key ?? ""}`), [attributes])

    const handleResetAttributeFilters = useCallback(() => {
        if (usedAttributeFilters.length) {
            setAttributes([])
        }
    }, [usedAttributeFilters, setAttributes])

    const handleChangeSorting = useCallback(
        (productGroupId: number, value: ArticleListSortingMode | undefined) => {
            activateSortingForProductGroups([productGroupId], value)
        },
        [activateSortingForProductGroups]
    )

    const { selectedArticles } = useArticleSelection()
    let distance = ""
    if (selectedArticles.length > 0) {
        distance = "-50px"
    }

    return (
        <>
            <ArticleSelection
                maxArticleNumberToCompareReached
                showArticleComparision
                hideBasketButton={!!replaceButtonBundle || options.hideAddToBasketButton}
                erpInfosData={erpInfosData}
            />
            <Box position="relative" display="grid" gridTemplateRows="auto 1fr" overflow="hidden" mt={distance} height="100%">
                <Box p={1} maxHeight="20em">
                    <Box marginBottom={1}>
                        <OePositions onPositionSelect={changeOePosition} positions={oePositions} />
                    </Box>
                    {selectedOePart && (
                        <>
                            <ArticleGroupHeader title={translateText(803)} />
                            <OeArticleItemStateProvider article={selectedOePart}>
                                <ArticleItem variant="OE" />
                            </OeArticleItemStateProvider>
                        </>
                    )}
                </Box>

                <Box pr={1} overflow="hidden">
                    {(!isFetchingNextPage && !isLoading && isLoaded && !articleGroups.length) || isFailed || noResult ? (
                        <NoSearchResult searchType="oe" />
                    ) : (
                        <ModalArticleListWrapper>
                            {isLoaded &&
                                !isStalling &&
                                articleGroups.map((group) => (
                                    <ArticleGroup
                                        key={group.productGroup.id}
                                        group={group}
                                        currentSorting={sortingProductGroups[group.productGroup.id]}
                                        onChangeSorting={handleChangeSorting}
                                        calculatorRoute={calculatorRoute}
                                        showFilterOptions={showVehicleRecordsFilters}
                                        usedAttributeFilters={usedAttributeFilters}
                                        onToggleAttributeFilter={toggleAttribute}
                                        onResetAttributeFilters={handleResetAttributeFilters}
                                        productGroupTopicIds={productGroupTopicIds}
                                    />
                                ))}

                            {(((hasNextPage || isLoading) && !isFrontendFiltered) || isStalling) && (
                                <NextPageLoader
                                    loadNextPage={loadNextPage}
                                    canLoadNextArticles={hasNextPage}
                                    skeletonArticleCount={(!isLoading || isFetchingNextPage) && !isStalling ? 1 : 5}
                                    skeletonCategory
                                />
                            )}

                            {hasNextPage && isFrontendFiltered && (
                                <Box display="flex" justifyContent="center">
                                    <Button color="highlight" onClick={loadNextPage}>
                                        {translateText(12430)}
                                    </Button>
                                </Box>
                            )}
                        </ModalArticleListWrapper>
                    )}
                </Box>
            </Box>
        </>
    )
}
