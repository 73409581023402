import { ECalcState } from "../enums"
import { CalcState, FastCalculatorData, MappedFastCalculatorData } from "../model"

export function mapFastCalculatorResponse(data?: any): MappedFastCalculatorData | undefined {
    if (data?.isQueued) {
        return {
            fastCalculatorData: {
                activeCalcState: ECalcState.None,
                availableCalcStates: ECalcState.None,
                calcStates: [],
            },
            queueName: data?.queueName,
            isQueued: data?.isQueued,
            checkStatusUrl: data?.checkStatusUrl,
            requestQueueId: data?.requestQueueId,
        }
    }

    const response: FastCalculatorData = data?.dataOut
    if (response) {
        const { activeCalcState, calcStates, activeDataCalcState, activeDialogCalcState, activeOverlayCalcState } = response
        const serviceWorksCalcStates: CalcState[] = []
        const selectedCalcState = calcStates.find((x) => x.type === activeCalcState)
        const selectedDataCalcState = calcStates.find((x) => x.type === activeDataCalcState)
        const selectedDialogCalcState = calcStates.find((x) => x.type === activeDialogCalcState)
        const selectedOverlayCalcState = calcStates.find((x) => x.type === activeOverlayCalcState)
        const alternativeCalcArticles = calcStates.find((x) => x.type === ECalcState.AlternativeCalcArticles)

        // additionalWork and FollowupWork have 2 tabs that must be grouped together
        const filteredCalcStates = calcStates.filter((x) => {
            if ([ECalcState.AdditionalWork, ECalcState.FollowupWork].includes(x.type)) {
                x.tab && serviceWorksCalcStates.push(x)
                return false
            }
            return true
        })

        if (!selectedCalcState || !selectedCalcState.context) {
            return
        }

        return {
            fastCalculatorData: {
                ...response,
                calcStates: filteredCalcStates,
                serviceWorksCalcStates,
            },
            selectedCalcState,
            selectedDataCalcState,
            selectedDialogCalcState,
            selectedOverlayCalcState,
            alternativeCalcArticles,
        }
    }
}
