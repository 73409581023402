export enum ECalcInputValue {
    None = 0,
    Bool = 1,
    String = 2,
    Int = 3,
    Long = 4,
    DateTime = 5,
    Decimal = 6,
    Article = 7,
}
