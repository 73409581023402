import { useCallback, useEffect, MouseEvent, useMemo } from "react"
import { useCountryCodeToLicensePlate } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { channel, IMicros } from "@tm/models"
import { concat, encodeUniqueId, useWorkTaskTruckData } from "@tm/utils"
import { useMicro } from "@tm/morpheus"
import { Switch, Checkbox, Typography, LinkButton, Icon, LicensePlate } from "@tm/components"
import { CentralOrderWorkTaskState } from "../../../data/hooks/centralOrder/useCentralOrderWorkTasks"
import WorkTaskOrderButtonComponent, { WorkTaskOrderButtonConfigProps } from "../../_shared/order-button/worktask"
import { useGlobalOrderOptionsState } from "../../../hooks/useGlobalOrderOptionsState"
import { LoaderSmall, StackRow } from "../../StyledComponents"
import { useCentralOrderBasketStateByWorkTask } from "../../../hooks/basketState/useCentralOrderBasketStateByWorkTask"
import Totals from "./Totals"
import { useCentralOrderStateByWorkTask } from "../../../hooks/basketState/useCentralOrderStateByWorkTask"

type Props = WorkTaskOrderButtonConfigProps & {
    workTaskState: CentralOrderWorkTaskState
    onHideWorkTask(workTaskId: string): void
}

export default function WorkTaskInfo(props: Props) {
    const { workTaskState, onHideWorkTask } = props
    const { includedInOrder, selected, disabled, loading: workTaskStateLoading, workTask } = workTaskState

    const { translateText, translate } = useLocalization()
    const { toggleIncludedInOrder, toggleSelected, toggleDisabled, setSucceededOrderGroups } = useCentralOrderStateByWorkTask(workTask.workTaskId)
    const { workTaskBasketCalculationLoading, workTaskBasketCalculation, basket } = useCentralOrderBasketStateByWorkTask(workTask.workTaskId)

    const { workTaskTruckData } = useWorkTaskTruckData(workTask.workTaskId)
    const { globalOrderOptionsEnabled } = useGlobalOrderOptionsState()
    const { renderMicro } = useMicro<IMicros>()
    const customer = concat(" ", workTask.customerInfo?.firstName, workTask.customerInfo?.lastName) || workTaskTruckData?.customerName
    const vehicle = concat(" ", workTask.vehicleInfo?.manufacturer, workTask.vehicleInfo?.modelSeries, workTask.vehicleInfo?.model)
    const plateId = workTask.vehicleInfo ? workTask.vehicleInfo.plateId : workTaskTruckData?.plateNr
    const { plateCode } = useCountryCodeToLicensePlate(workTask?.vehicleInfo?.countryCode)
    const handleCompleteWorkTask = useCallback(() => {
        onHideWorkTask(workTask.workTaskId)
    }, [workTask.workTaskId, onHideWorkTask])

    const [workTaskDeactivateable, workTaskIncludeable] = useMemo(() => {
        let isDisabled = true
        let isIncluded = false
        if (
            basket.state.basketOrderGroups?.some((orderGroup) =>
                orderGroup.basketParts.some((basketPart) => basketPart.partItem.orderItem?.isIncluded && basketPart.erpInfoResponse?.isOrderable)
            )
        ) {
            isIncluded = true
            isDisabled = false
        }

        return [isDisabled, isIncluded]
    }, [basket.state.basketOrderGroups])

    useEffect(() => {
        toggleIncludedInOrder(workTaskIncludeable)
    }, [workTaskIncludeable])

    useEffect(() => {
        toggleDisabled(workTaskDeactivateable)
    }, [workTaskDeactivateable])

    useEffect(() => {
        const unsubscriptions: Array<() => void> = []

        unsubscriptions.push(
            channel("WORKTASK", workTask.workTaskId).subscribe("BASKET/ORDER_SENDING", () => {
                toggleDisabled(true)
            })
        )

        unsubscriptions.push(
            channel("WORKTASK", workTask.workTaskId).subscribe("BASKET/ORDER_SENT", ({ result }) => {
                if (result?.workTaskId === workTask.workTaskId) {
                    if (result.allHaveSucceeded) {
                        setSucceededOrderGroups(result.orders)
                    }

                    toggleDisabled(true)
                    toggleIncludedInOrder(false)
                }
            })
        )

        return () => {
            unsubscriptions.forEach((unsub) => unsub())
        }
    }, [workTask.workTaskId, toggleDisabled, toggleIncludedInOrder, setSucceededOrderGroups])

    function handleSelectWorktaskToggle(e: MouseEvent<HTMLElement>) {
        e?.stopPropagation()
        toggleSelected()
    }

    function handleProcessingStatusChange(e: MouseEvent<HTMLElement>): void {
        e?.stopPropagation()
        toggleIncludedInOrder()
    }

    return (
        <StackRow justifyContent="space-between" flex={1}>
            <StackRow spacing={1}>
                <LinkButton startIcon={<Icon name="partslink24" />} to={`/${encodeUniqueId(workTask.workTaskId)}`}>
                    {translate(2014)}
                </LinkButton>
                <Typography variant="h4" fontWeight="bold">
                    {!customer && !vehicle && translateText(29)}
                    {concat(" - ", customer, vehicle)}
                </Typography>
                {plateId && <LicensePlate countryCode={plateCode} value={plateId} />}
                {workTaskStateLoading && <LoaderSmall />}
            </StackRow>
            <StackRow spacing={16}>
                <Totals
                    numberOfItemsInOrder={workTaskBasketCalculation?.calculatedOrder?.numberOfItemsInOrder}
                    numberOfPositionsInOrder={workTaskBasketCalculation?.calculatedOrder?.numberOfPositionsInOrder}
                    loading={workTaskStateLoading || workTaskBasketCalculationLoading}
                    erpTotalsInOrder={workTaskBasketCalculation?.calculatedOrder?.erpTotalsInOrder}
                    workTaskIds={[workTask.workTaskId]}
                    hasErrors={basket.state.basketWithErrors && basket.state.basketErpInfosIndicator.hasErrors}
                />
                <StackRow spacing={1}>
                    {renderMicro("worktask", "complete-work-task-button", {
                        workTaskId: workTask.workTaskId,
                        onWorkTaskCompleted: handleCompleteWorkTask,
                    })}
                    {!globalOrderOptionsEnabled && (
                        <WorkTaskOrderButtonComponent
                            {...props}
                            workTaskId={workTask.workTaskId}
                            orderGroupsLoading={workTaskStateLoading}
                            orderGroups={basket.state.basketOrderGroups}
                        />
                    )}
                    <Switch
                        label={
                            <Typography variant="label" className="basket-centralOrder-worktaskInfo-includeOrder">
                                {translateText(772)}
                            </Typography>
                        }
                        checked={includedInOrder}
                        disabled={disabled}
                        labelPlacement="start"
                        size="small"
                        onClick={handleProcessingStatusChange}
                    />
                    <Checkbox checked={selected} onClick={handleSelectWorktaskToggle} disabled={workTaskBasketCalculationLoading} />
                </StackRow>
            </StackRow>
        </StackRow>
    )
}
