import { Article } from "@tm/models"
import { useMemo } from "react"
import { usePartsModuleState } from "../../PartsModuleState"

export function useArticleTradeReferences(article: Article) {
    const tradeReferences = usePartsModuleState((x) => x.tradeReferences)
    return useMemo(
        () => tradeReferences.find(([key]) => key.supplierId === article.supplier.id && key.supplierArticleNo === article.supplierArticleNo)?.[1],
        [article, tradeReferences]
    )
}
