import { useState, useCallback, useEffect, useMemo } from "react"
import { Badge, Button, Icon } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ConfigParams, RegisteredModels } from "@tm/models"
import { Container } from "@tm/nexus"
import { styled } from "@tm/components"

import Morpheus from "@tm/morpheus"
import { PredictiveRepairTime } from "../../../data/model/predictive"

type AddToCostEstimationButtonProps = {
    repairTime: PredictiveRepairTime
    workTaskId: string
    onAdd(repairTime: PredictiveRepairTime): void
    onRemove(id: string): void
    className?: string
}

function AddToCostEstimationButton({ repairTime, onRemove, onAdd, workTaskId, className }: AddToCostEstimationButtonProps) {
    const { translateText, languageId } = useLocalization()
    const { deleteButtonColorError } = Morpheus.getParams<ConfigParams>()
    const [loading, setLoading] = useState(false)
    const [basketItemId, setBasketItemId] = useState<string>()

    useEffect(() => {
        setBasketItemId(undefined)

        const subscription = Container.getInstance(RegisteredModels.Basket_HasRepairTimes).subscribe({
            workTaskId,
            repairTimesProvider: repairTime.providerId,
            repairTimeProviderWorkId: repairTime.mainWorkId,
        })

        const unsub = subscription.addListener(
            "loaded",
            (response) => {
                setBasketItemId(response?.id)
                setLoading(false)
            },
            () => {
                setBasketItemId(undefined)
                setLoading(false)
            }
        )

        subscription.loadIfRequired()

        return unsub
    }, [repairTime, workTaskId])

    const handleClick = useCallback(() => {
        if (basketItemId) {
            onRemove(basketItemId)
        } else {
            onAdd(repairTime)
        }

        setLoading(true)
    }, [basketItemId, repairTime, onRemove, onAdd])

    const icon = useMemo(() => {
        if (basketItemId) {
            return "delete"
        }
        if (languageId === "1") {
            return "voucher-kva"
        }
        return "voucher-kva-international"
    }, [basketItemId, languageId])

    return (
        <div className={className}>
            <Button
                className="btn"
                layout={["bordered"]}
                skin={basketItemId || deleteButtonColorError ? "danger" : "highlight"}
                title={basketItemId ? translateText(624) : translateText(916)}
                onClick={handleClick}
                icon={icon}
            />
            {loading ? <Badge skin="dark" loading /> : !!basketItemId && <Badge skin="dark" value={<Icon name="check" />} />}
        </div>
    )
}

export default styled(AddToCostEstimationButton)(() => ({
    position: "relative",
    ">.btn": {
        display: "flex",
        justifyContent: "center",
        width: "4em",
        padding: "0.25em",
    },
}))
