import { Box, styled } from "@tm/components"
import { Checkbox, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { VehicleAndCustomer } from "."
import MuiCustomModal from "./modal/MuiCustomModal"
import { PrintOptions } from "../../data/models"

type Props = {
    open: boolean
    onOutsideClick: () => void
    printState: PrintOptions
    onPrintStateChange: (printOptions: keyof PrintOptions) => void
}

export default function CustomEndModal({ open, onOutsideClick, printState, onPrintStateChange }: Props) {
    const { translateText } = useLocalization()

    const handleCheck = (key: keyof PrintOptions) => {
        onPrintStateChange(key)
    }

    return (
        <MuiCustomModal open={open} stateSelections={printState} printButton onOutsideClick={onOutsideClick}>
            <VehicleAndCustomer headerText={translateText(12962)} showServiceText />
            <StyledText size="m">{translateText(12963)}</StyledText>
            <StyledCheckRow>
                <Checkbox
                    size="xl"
                    onToggle={() => handleCheck("inspectionTimes")}
                    checked={printState.inspectionTimes}
                    label={translateText(12964)}
                />
            </StyledCheckRow>
            <StyledCheckRow>
                <Checkbox size="xl" onToggle={() => handleCheck("remarks")} checked={printState.remarks} label={translateText(12965)} />
            </StyledCheckRow>
            <StyledCheckRow>
                <Checkbox size="xl" onToggle={() => handleCheck("images")} checked={printState.images} label={translateText(12685)} />
            </StyledCheckRow>
            <StyledCheckRow>
                <Checkbox size="xl" onToggle={() => handleCheck("tyres")} checked={printState.tyres} label={translateText(240)} />
            </StyledCheckRow>
            <StyledCheckRow>
                <Checkbox
                    size="xl"
                    onToggle={() => handleCheck("nextMaintenance")}
                    checked={printState.nextMaintenance}
                    label={translateText(12966)}
                />
            </StyledCheckRow>
            <StyledCheckRow>
                <Checkbox size="xl" onToggle={() => handleCheck("showDefects")} checked={printState.showDefects} label={translateText(12845)} />
            </StyledCheckRow>
        </MuiCustomModal>
    )
}

const StyledCheckRow = styled(Box)(({ theme }) => ({
    display: "flex",
    marginBottom: theme.margin?.l ?? ".75em",
}))

const StyledText = styled(Text)(({ theme }) => ({
    display: "block",
    paddingBottom: theme.margin?.xl ?? "1em",
}))
