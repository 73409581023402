import { useUser, useWorkTask } from "@tm/context-distribution"
import { Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ECounterType, RegistrationNoType, VehicleType } from "@tm/models"
import { RouteComponentProps, TmaHelper, withRouter } from "@tm/utils"
import { useMemo } from "react"
import { matchPath } from "react-router"
import { Box } from "@tm/components"
import { getBundleParams } from "../../utils"
import { getLookupType } from "../../helpers"
import { getVehicleSearchUrl } from "../../helpers/routing"
import { VehicleParams } from "../search/component"
import { useResetFilters } from "../../data/hooks"
import { VehicleSearchFieldSwitch } from "../_shared/VehicleSearchFieldSwitch"

type Props = RouteComponentProps<{ vehicleType: string } & SearchRouteProps & SelectRouteParams>

type SearchRouteProps = {
    query: string
}

type SelectRouteParams = {
    view: string
    workTaskId: string
    manufacturerId?: any
    mainModelSeriesId?: any
    modelSeriesId?: any
    modelId?: any
}

function NavigationComponent(props: Props) {
    const vehicleType: VehicleType = parseInt(props.match.params.vehicleType)
    const localization = useLocalization()
    const workTask = useWorkTask()
    const { userContext } = useUser() ?? {}
    const resetFilters = useResetFilters(vehicleType)
    const checkAndMergeRegNoDetails = useMemo(() => {
        return new URLSearchParams(props.location.search).get(VehicleParams.CheckAndMergeRegNoDetails) === "true"
    }, [props.location.search])

    const startSearch = (query: string, registrationNoType?: RegistrationNoType) => {
        // Special for Create Business: replace dashes in query (see: NEXT-9621) 2 of 2
        if (getLookupType(userContext) === RegistrationNoType.KennzeichenPortugal && checkAndMergeRegNoDetails) {
            query = query?.replace(/-/g, "")
        }

        TmaHelper.GeneralCountEvent.Call(ECounterType.VehicleSelectionSearchSymbol)

        resetFilters() // Reset filters before opening search page
        props.history.push(
            getVehicleSearchUrl(props.match.params, {
                vehicleType,
                query,
                registrationNoType,
                forceUpdate: true,
                checkAndMergeRegNoDetails,
            })
        )
    }

    const handleAttachVehicleId = (vehicleId: string, query: string) => {
        TmaHelper.VehicleSelection.Search.FromWidget({ dataSourceId: RegistrationNoType.VehicleBase, query })
        workTask?.attachToWorkTask({ vehicle: vehicleId })
    }

    let value
    const matchSearch = matchPath<SearchRouteProps>(location.pathname, { path: getBundleParams().vehicleSearchUrl })
    if (matchSearch?.params.query) {
        value = decodeURIComponent(matchSearch.params.query)
    }

    if (getBundleParams().nextLight) {
        return null
    }

    return (
        <Toolbar title={localization.translateText(135)} className="tk-vehicle searchfield">
            <Box width="500px">
                <VehicleSearchFieldSwitch
                    {...props}
                    hideSpecialLicensePlateSearchField
                    initialValue={value}
                    onStartSearch={startSearch}
                    onAttachVehicleId={handleAttachVehicleId}
                    vehicleType={vehicleType}
                    showVrcScanButton={!workTask?.workTask?.vehicle}
                    disableEdsSearch
                    hideTooltip
                    size="small"
                />
            </Box>
        </Toolbar>
    )
}

export default withRouter(NavigationComponent)
