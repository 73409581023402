import { Box, CircularProgress, Modal, styled, useMediaQuery, useTheme, Button as MuiButton } from "@tm/components"
import { Button, Scrollbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { useFastServiceStore } from "../../../data"
import { getComponentStyles } from "../styles"
import { printMaintenance } from "../../end/business"
import { PrintOptions } from "../../../data/models"

type Props = {
    open: boolean
    stateSelections?: PrintOptions
    onOutsideClick: () => void
    handleOkButton?: () => void
    printButton?: boolean
    disabledOkButon?: boolean
    children: React.ReactNode
}

const MuiCustomModal: React.FC<Props> = ({ open, stateSelections, onOutsideClick, handleOkButton, printButton, disabledOkButon, children }) => {
    const muitheme = useTheme()
    const matchesLg = useMediaQuery(muitheme.breakpoints.up("lg"))
    const matchesXl = useMediaQuery(muitheme.breakpoints.up("xl"))
    const { translateText } = useLocalization()
    const printLoading = useFastServiceStore((state) => state.printLoading)
    const classNames = getComponentStyles()
    const { userSettings, userContext } = useUser()
    const customer = useWorkTask()?.workTask?.customer
    const pdfUrl = useFastServiceStore((state) => state.pdfUrl)

    const percentWidth = React.useMemo(() => (matchesXl ? 55 : matchesLg ? 70 : 85), [matchesLg, matchesXl])

    const handlePrint = () => {
        stateSelections &&
            printMaintenance(translateText, stateSelections, userContext.account?.username ?? "", userSettings, customer, () => {
                const printBtn = document.getElementById("print-btn")
                printBtn?.click()
            })
    }

    return (
        <Modal
            keepMounted
            open={open}
            hideCloseButton
            percentWidth={percentWidth}
            sx={{ "&&  .MuiPaper-root>.MuiBox-root": { flex: 1, display: "flex" } }}
        >
            <Scrollbar>
                <ContentFlex>{children}</ContentFlex>
            </Scrollbar>
            <StyledButtonWrapper>
                <Button size="xl" icon="close" onClick={onOutsideClick} />
                {printButton && (
                    <StyledMuiButtonWrapper>
                        <MuiButton color="highlight" className={classNames.buttonPadding} size="large" onClick={handlePrint} disabled={printLoading}>
                            {translateText(49)}
                            {printLoading && <StyledLoader />}
                        </MuiButton>
                        <div id="print-btn" style={{ visibility: "hidden" }} onClick={() => window.open(pdfUrl, "_blank")} />
                    </StyledMuiButtonWrapper>
                )}
                {handleOkButton && <Button size="xl" skin="success" icon="check" onClick={handleOkButton} disabled={disabledOkButon} />}
            </StyledButtonWrapper>
        </Modal>
    )
}

export default MuiCustomModal

const StyledButtonWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.margin?.s ?? "0.5em",
}))

const ContentFlex = styled(Box)({
    padding: "2em",
    flex: 1,
    height: "100%",
})

const StyledMuiButtonWrapper = styled(Box)({
    paddingLeft: ".5em",
    display: "flex",
    justifyContent: "center",
})

const StyledLoader = styled(CircularProgress)({
    width: "1em !important",
    height: "1em !important",
    marginLeft: "1em",
})
