import { useCallback } from "react"
import { QueryKey, useMutation, useQueryClient } from "react-query"
import { ArticleInfoType, ChangeItemsResponse, Item, RegisteredModels, ReplaceByCatalogPartRequest, channel, AvailabilityLog } from "@tm/models"
import { Container } from "@tm/nexus"
import { BasketPart } from "../../../../models"
import {
    BasketErpInformationResponseItem,
    ChangeItemDistributorRequest,
    ChangeQuantityResponse,
    GetErpInformationBasketResponse,
    PartItem,
    ShowWorkTaskBasketResponse,
} from "../../../model"
import * as Data from "../../.."
import { KEY as ERP_INFO_KEY } from "../queries/useErpInfosBasketData"
import { mapBasketPartsToItems, mapChangeQuantityResponse } from "../../../mapper"
import { useBasketUpdateWorkflow } from "../workflow/useBasketUpdateWorkflow"
import { useAddCustomPartList } from "../parts/useAddCustomPartList"
import { useAddCatalogPartList } from "../parts/useAddCatalogPartList "
import { useAddWholesalerPartList } from "../parts/useAddWholesalerPartList"
import { useAddOePartList } from "../parts/useAddOePartList"
import { reloadOeArticleQuantities, reloadSupplierArticleQuantities, reloadWholesalerArticleQuantities } from "../../.."
import { useRemovePartList } from "../parts/useRemovePartList"

export function useBasketMutations(
    workTaskId: string,
    workTaskBasketQueryKey: any[],
    partItems: PartItem[] | undefined,
    handleBasketUpdateWorkflow: ReturnType<typeof useBasketUpdateWorkflow>
) {
    const queryClient = useQueryClient()
    const addCatalogParts = useAddCatalogPartList(handleBasketUpdateWorkflow)
    const addCustomParts = useAddCustomPartList(handleBasketUpdateWorkflow)
    const addWholesalerParts = useAddWholesalerPartList(handleBasketUpdateWorkflow)
    const addOePartList = useAddOePartList(handleBasketUpdateWorkflow)
    const removePartList = useRemovePartList(handleBasketUpdateWorkflow, (response, request) =>
        handleRemoveItemsResponse(response, request.partIds, request.isExternalCall)
    )

    const { mutateAsync: includeItemList } = useMutation((items: Item[]) => Data.includeItemList(workTaskId, items), {
        onSuccess: (response) => handleToggleIncludeBasketPartResponse(response, true),
    })

    const { mutateAsync: excludeItemList } = useMutation((items: Item[]) => Data.excludeItemList(workTaskId, items), {
        onSuccess: (response) => handleToggleIncludeBasketPartResponse(response, false),
    })

    const { mutateAsync: changeItemQuantity } = useMutation(
        (request: { item: Item; quantityValue: number; availabilityLog?: AvailabilityLog }) =>
            Data.changeItemQuantity(request.item, request.quantityValue, undefined, request.availabilityLog),
        {
            onSuccess: handleChangeItemQuantityResponse,
        }
    )

    const { mutateAsync: removeAllWorkTaskItems } = useMutation(() => Data.removeAllWorkTaskItems(workTaskId), {
        onSuccess: handleRemoveAllItemsResponse,
    })

    const { mutateAsync: saveItemMemo } = useMutation(
        (request: { item: Item; memo: string; isNewNote: boolean }) =>
            request.isNewNote ? Data.attachItemMemo(request.item, request.memo) : Data.editItemMemo(request.item, request.memo),
        {
            onSuccess: handleSaveItemMemoResponse,
        }
    )

    const { mutateAsync: removeItemMemo } = useMutation((item: Item) => Data.removeItemMemo(item), {
        onSuccess: (response, request) => {
            handleSaveItemMemoResponse(response, { item: request })
        },
    })

    const { mutateAsync: changeItemDistributor } = useMutation((request: ChangeItemDistributorRequest) => Data.changeItemDistributor(request), {
        onSuccess: (response) => response && handleBasketUpdateWorkflow(workTaskId, response),
    })

    const { mutateAsync: replacePartByCatalogPart } = useMutation(
        (replaceByCatalogPartRequest: ReplaceByCatalogPartRequest) => Data.replacePartByCatalogPart(replaceByCatalogPartRequest),
        {
            onSuccess: (response, request) => handleReplacePartByCatalogPartResponse(response, request),
        }
    )

    const toggleIncludeBasketParts = useCallback(
        (parts: BasketPart[]) => {
            const items = mapBasketPartsToItems(parts, partItems)
            const somePartsAreIncludedInOrder = parts.some((part) => part.partItem.orderItem?.isIncluded)
            if (somePartsAreIncludedInOrder) {
                return excludeItemList(items)
            }
            return includeItemList(items)
        },
        [excludeItemList, includeItemList, partItems]
    )

    const changeBasketPartQuantity = useCallback(
        (part: BasketPart, quantityValue: number, availabilityLog?: AvailabilityLog) => {
            const item = mapBasketPartsToItems([part])[0]
            return changeItemQuantity({ item, quantityValue, availabilityLog })
        },
        [changeItemQuantity]
    )

    const savePartNote = useCallback(
        (part: BasketPart, note: string, isNewNote: boolean) => {
            const item = mapBasketPartsToItems([part])[0]
            return saveItemMemo({ item, memo: note, isNewNote })
        },
        [saveItemMemo]
    )

    const removePartNote = useCallback(
        (part: BasketPart) => {
            const item = mapBasketPartsToItems([part])[0]
            return removeItemMemo(item)
        },
        [removeItemMemo]
    )

    const replacePartWithCatalogPart = useCallback(
        (replaceByCatalogPartRequest: ReplaceByCatalogPartRequest) => {
            return replacePartByCatalogPart(replaceByCatalogPartRequest)
        },
        [replacePartByCatalogPart]
    )

    function handleChangeItemQuantityResponse(response: ChangeQuantityResponse | undefined) {
        if (!response) {
            return
        }
        workTaskId &&
            channel("WORKTASK", workTaskId).publish("BASKET/ARTICLE_QUANTITY_CHANGED", { quantity: response.changedQuantityPart.quantity.value })
        queryClient.setQueryData<ShowWorkTaskBasketResponse | undefined>(workTaskBasketQueryKey, (prev) => {
            if (!prev?.parts) {
                return
            }
            return {
                ...prev,
                parts: prev.parts.map((part) => {
                    const item = response.changedQuantityPart.id === part.id ? response.changedQuantityPart : undefined
                    if (item) {
                        const mappedPart = mapChangeQuantityResponse(part, item)
                        return mappedPart ?? part
                    }
                    return part
                }),
            }
        })
        handleBasketUpdateWorkflow(
            workTaskId,
            {
                basketUpdateWorkflow: response.basketUpdateWorkflow,
                changedItems: [{ id: response.changedQuantityPart.id, version: response.changedQuantityPart.version }],
            },
            response.changedQuantityPart.quantity.value
        )
    }

    function handleReplacePartByCatalogPartResponse(response: ChangeItemsResponse | undefined, request: ReplaceByCatalogPartRequest) {
        if (!response) {
            return
        }

        handleBasketUpdateWorkflow(workTaskId, response)
        reloadSupplierArticleQuantities(workTaskId, request.catalogPart)
    }

    // If no partIds are given, all parts should be removed.
    function removeItemsFromQueries(partIds?: string[]) {
        let partsToBeDeleted: PartItem[] = []

        queryClient.setQueryData<ShowWorkTaskBasketResponse | undefined>(workTaskBasketQueryKey, (prev) => {
            if (!prev?.parts) {
                return
            }
            partsToBeDeleted = partIds?.length ? prev.parts.filter((part) => partIds.includes(part.id)) : []
            return {
                ...prev,
                parts: partIds?.length ? prev.parts.filter((part) => !partIds.includes(part.id)) : [],
                orderGroups: prev.orderGroups?.map((orderGroup) => ({
                    ...orderGroup,
                    partIds: partIds?.length ? orderGroup.partIds.filter((partId) => !partIds.includes(partId)) : [],
                })),
                erpInfoGroups: prev.erpInfoGroups?.map((erpInfoGroup) => ({
                    ...erpInfoGroup,
                    partIds: partIds?.length ? erpInfoGroup.partIds.filter((partId) => !partIds.includes(partId)) : [],
                })),
            }
        })

        let pageSize = 0
        const keys: QueryKey[] = []
        const items: BasketErpInformationResponseItem[] = []

        // When more than one page is requested and parts from a single page are removed resulting in less pages:
        // i.e. pageSize is 5 and initially 6 parts were invoked having 2 pages: 1 with 5 partas and the other with 1.
        // if 2 parts are removed from the first page, the new first (and unique page) must be assembled in the Frontend
        queryClient
            .getQueriesData<GetErpInformationBasketResponse>({ queryKey: [ERP_INFO_KEY, workTaskId], active: true })
            .forEach(([key, response]) => {
                const responseItems = response.items ?? []

                pageSize = Math.max(pageSize, responseItems.length)
                keys.push(key)
                items.push(...(partIds?.length ? responseItems.filter((item) => !partIds.includes(item.itemId)) : []))
            })

        keys.forEach((key) => {
            if (!items.length) {
                queryClient.removeQueries(key)
                return
            }

            queryClient.setQueryData<GetErpInformationBasketResponse | undefined>(key, (prev) => {
                if (!prev?.items) {
                    return
                }

                return {
                    ...prev,
                    items: items.splice(0, pageSize),
                }
            })
        })

        return partsToBeDeleted
    }

    function handleRemoveItemsResponse(response: ChangeItemsResponse | undefined, partIds: string[], isExternalCall?: boolean) {
        if (!response) {
            return
        }

        const partsToBeDeleted = removeItemsFromQueries(partIds)

        handleBasketUpdateWorkflow(workTaskId, response)

        if (isExternalCall && partsToBeDeleted) {
            Container.getInstance(RegisteredModels.Worktask_BasketActivityDone).subscribe().load()

            // Only one article can be deleted from external
            const articleInfo = partsToBeDeleted[0].articleInformation
            if (articleInfo.articleInfoType === ArticleInfoType.OeArticle && articleInfo.articleNumber) {
                reloadOeArticleQuantities(workTaskId, {
                    oeArticleNumber: articleInfo.articleNumber,
                    productGroupId: articleInfo.productGroupId,
                    vehicleManufacturerId: articleInfo.supplierId,
                })
            } else if (articleInfo.articleInfoType === ArticleInfoType.WholesalerArticle && articleInfo.wholesalerArticleNumber) {
                reloadWholesalerArticleQuantities(workTaskId, { wholesalerArticleNumber: articleInfo.wholesalerArticleNumber })
            } else if (
                articleInfo.articleInfoType === ArticleInfoType.TecdocArticle &&
                articleInfo.supplierId &&
                articleInfo.productGroupId &&
                articleInfo.articleNumber
            ) {
                reloadSupplierArticleQuantities(workTaskId, {
                    dataSupplierArticleNumber: articleInfo.articleNumber,
                    dataSupplierId: articleInfo.supplierId,
                    productGroupId: articleInfo.productGroupId,
                })
            }
        }
    }

    function handleRemoveAllItemsResponse(response: ChangeItemsResponse | undefined) {
        if (!response) {
            return
        }

        removeItemsFromQueries()

        handleBasketUpdateWorkflow(workTaskId, response)
    }

    function handleToggleIncludeBasketPartResponse(response: ChangeItemsResponse | undefined, isIncluded: boolean) {
        if (!response) {
            return
        }

        if (response.changedItems) {
            queryClient.setQueryData<ShowWorkTaskBasketResponse | undefined>(workTaskBasketQueryKey, (prev) => {
                if (!prev?.parts) {
                    return
                }
                return {
                    ...prev,
                    parts: prev.parts.map((part) => {
                        const item = response.changedItems.find((x) => x.id === part.id)
                        if (item && part.orderItem) {
                            return {
                                ...part,
                                version: item.version,
                                orderItem: {
                                    ...part.orderItem,
                                    isIncluded,
                                },
                            }
                        }
                        return part
                    }),
                }
            })
        }
        handleBasketUpdateWorkflow(workTaskId, response)
    }

    function handleSaveItemMemoResponse(response: ChangeItemsResponse | undefined, request: { item: Item; memo?: string }) {
        if (!response) {
            return
        }
        queryClient.setQueryData<ShowWorkTaskBasketResponse | undefined>(workTaskBasketQueryKey, (prev) => {
            if (!prev?.parts) {
                return
            }
            return {
                ...prev,
                parts: prev.parts.map((part) => {
                    const item = response.changedItems.find((x) => x.id === part.id)
                    if (item && part.orderItem) {
                        return {
                            ...part,
                            version: item.version,
                            orderItem: {
                                ...part.orderItem,
                                memo: request.memo,
                            },
                        }
                    }
                    return part
                }),
            }
        })
        handleBasketUpdateWorkflow(workTaskId, response)
    }

    return {
        addCatalogParts,
        addCustomParts,
        addOePartList,
        addWholesalerParts,
        changeBasketPartQuantity,
        changeItemDistributor,
        removeAllWorkTaskItems,
        removePartList,
        removePartNote,
        replacePartWithCatalogPart,
        savePartNote,
        toggleIncludeBasketParts,
    }
}
