import { useCallback } from "react"
import { WorkTaskInfo } from "@tm/context-distribution"
import { AddCustomPartListRequest, CostEstimationOptions, CustomArticleItem } from "@tm/models"
import { mapCustomArticleItemToCustomPart } from "../../../../../data/mapper"
import { useBasketMemo } from "../../../../../hooks/useBasketMemo"
import { useDeleteCustomArticles } from "../../../../../data/hooks/custom-basket-items/useCustomArticles"
import { CustomItemActions } from "../../CustomItemActions"

type Props = {
    workTask: WorkTaskInfo
    article: CustomArticleItem
    articleNote?: string
    quantity: number
    isSelected: boolean
    costEstimationOptions?: CostEstimationOptions
    onAddCustomParts(request: AddCustomPartListRequest): Promise<unknown>
    onEditClick(article: CustomArticleItem | undefined): void
    onQuantityChange(article: CustomArticleItem, quantity: number): void
    onToggleSelection(article: CustomArticleItem): void
}

export function CustomArticlesActions(props: Props) {
    const {
        workTask,
        article,
        articleNote,
        onEditClick,
        quantity,
        onQuantityChange,
        isSelected,
        onToggleSelection,
        onAddCustomParts,
        costEstimationOptions,
    } = props
    const basketMemo = useBasketMemo(workTask)
    const { mutateAsync: deleteArticle } = useDeleteCustomArticles()

    const handleDelete = useCallback((item: CustomArticleItem) => deleteArticle([item.id]), [deleteArticle])

    const handleAddToBasket = useCallback(
        (item: CustomArticleItem, newQuantity: number) =>
            onAddCustomParts({
                workTaskId: workTask.id,
                customParts: [mapCustomArticleItemToCustomPart(item, newQuantity, basketMemo.position)],
                vehicleId: workTask.vehicle?.id,
                customerId: workTask.customer?.id,
                usePercentageValues: true,
                costEstimationOptions,
            }),
        [onAddCustomParts, workTask.id, workTask.vehicle?.id, workTask.customer?.id, basketMemo.position, costEstimationOptions]
    )

    return (
        <CustomItemActions
            item={article}
            itemNote={articleNote}
            onEditClick={onEditClick}
            onDelete={handleDelete}
            quantity={quantity}
            onQuantityChange={onQuantityChange}
            onAddToBasket={handleAddToBasket}
            isSelected={isSelected}
            onToggleSelection={onToggleSelection}
        />
    )
}
