import { OrderVoucherList } from "@tm/models"
import { VoucherIncludedWork, VoucherPart, VoucherWork } from "@tm/data/vouchers/costEstimations"
import { ShowOrdersResponse, ShowOrderDetailsResponse, OrderVoucherItemsByWorkTask, ExtendedOrderVoucherItem } from "../model"
import {
    VoucherPart as BasketVocherPart,
    VoucherWork as BasketVoucherWork,
    VoucherIncludedWork as BasketVoucherIncludedWork,
} from "../../../../basket/src/data/model/cost-estimation"

export function mapVoucherPartInPartItem(part: VoucherPart): BasketVocherPart {
    return {
        articleInformation: part.articleInformation,
        quantityValue: part.quantityValue,
        rebate: part.calculation.rebate,
        surcharge: part.calculation.surcharge,
        vatRate: part.calculation.vatRate,
        itemRole: part.itemRole,
        purchaseNetPrice: part.purchaseNetPrice,
        retailNetPrice: part.retailNetPrice,
        linkedItems: part.linkedItems?.map(mapVoucherPartInPartItem),
    }
}

export function mapVoucherWorkInWorkItem(work: VoucherWork): BasketVoucherWork {
    return {
        provider: work.provider,
        type: work.type,
        providerWorkId: work.providerWorkId,
        category: work.category,
        description: work.description,
        timeInMinutes: work.timeInMinutes,
        hourlyRateNet: work.hourlyRateNet,
        fixedPriceNetValue: work.fixedPriceNetValue,
        rebate: work.calculation.rebate,
        surcharge: work.calculation.surcharge,
        vatRate: work.calculation.vatRate,
        includedWorks: work.includedWorks.map(mapIncludedWorks),
        displayNumber: work.displayNumber,
        isFixedPrice: work.calculation.isFixedPrice,
        fittingSide: work.fittingSide,
        note: work.note,
        productGroupIds: work.productGroupIds,
        providerTimeInMinutes: work.providerTimeInMinutes,
        sortNo: work.sortNo,
    }
}

export function mapIncludedWorks(includedWork: VoucherIncludedWork): BasketVoucherIncludedWork {
    return {
        providerWorkId: includedWork.providerWorkId,
        category: includedWork.category,
        description: includedWork.description,
        timeInMinutes: includedWork.timeInMinutes,
        isCalculable: includedWork.isCalculable,
        isInternal: includedWork.isInternal,
        sortNo: includedWork.sortNo,
    }
}

/** ****************************************************************************************************************************************
 * The orderNumber shall not be mapped, sometimes a date time Stamp is stored as orderId https://jira.dvse.de/browse/NEXT-24102
 **************************************************************************************************************************************** */

function mapOrderVoucherList(data: any): OrderVoucherList | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        orderDate: data.orderDate ? new Date(data.orderDate) : undefined,
    }
}

export function mapShowOrdersResponse(data: any): ShowOrdersResponse | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        orders: (data.orders || []).map(mapOrderVoucherList),
    }
}

function mapExtendedOrderVoucherItem(data: any): ExtendedOrderVoucherItem | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        returnableUntil: data.returnableUntil ? new Date(data.returnableUntil) : undefined,
        returnInfo: data.returnInfo
            ? {
                  ...data.returnInfo,
                  returnDate: data.returnInfo?.returnDate ? new Date(data.returnInfo.returnDate) : undefined,
              }
            : undefined,
        vehicle: data.vehicle
            ? {
                  ...data.vehicle,
                  initialRegistration: data.vehicle?.initialRegistration ? new Date(data.vehicle.initialRegistration) : undefined,
              }
            : undefined,
    }
}

function mapOrderVoucherItemsByWorkTask(data: any): OrderVoucherItemsByWorkTask | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        orderVoucherItems: (data.orderVoucherItems || []).map(mapExtendedOrderVoucherItem),
    }
}

export function mapShowOrderDetailsResponse(data: any): ShowOrderDetailsResponse | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        orderDate: data.orderDate ? new Date(data.orderDate) : undefined,
        orderItemsByWorkTask: (data.orderItemsByWorkTask || []).map(mapOrderVoucherItemsByWorkTask),
    }
}
