import { useTelesalesCustomerNumber, useUser } from "@tm/context-distribution"
import { useMemo } from "react"
import { useRecoilValue } from "recoil"
import { basketErp, basketPartsLoadingIds } from "./useCentralOrderBasketStateByWorkTask"
import { useCentralOrderWorkTasks } from "../../data/hooks/centralOrder/useCentralOrderWorkTasks"
import { useSendCentralOrder } from "../../components/_shared/order-button/hooks/useSendCentralOrder"

export function useIsCentralOrderBasketsLoading(partsRequired?: boolean) {
    const { userContext } = useUser() ?? {}
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { includedWorkTaskIds, orderBeingSent } = useCentralOrderWorkTasks().state
    const workTasksPartsLoading = !!useRecoilValue(basketPartsLoadingIds).length
    const allBasketErp = useRecoilValue(basketErp)
    const { includedBasketOrderGroups } = useSendCentralOrder(telesalesCustomerNo)

    const isLoading = useMemo(() => {
        let disabled = userContext ? userContext.parameter.orderDisabled : false

        if (!disabled) {
            disabled =
                (!!partsRequired && includedWorkTaskIds?.length === 0) ||
                workTasksPartsLoading ||
                orderBeingSent ||
                (!!partsRequired && !includedBasketOrderGroups.some((orderGroup) => orderGroup.hasIncludedPartsOrderable)) ||
                includedBasketOrderGroups.some((orderGroup) => orderGroup.hasPartsBeingUpdated) ||
                includedBasketOrderGroups.some((orderGroup) => orderGroup.orderOptionsLoading) ||
                includedWorkTaskIds.some((workTaskId) => !!allBasketErp[workTaskId]?.basketErpInfosIndicator.isLoading) ||
                (!!partsRequired &&
                    includedBasketOrderGroups.some(
                        (orderGroup) => orderGroup.orderOptionsGroup?.hasErrors && !!orderGroup.orderOptionsGroup?.errorText
                    ))
        }
        return disabled
    }, [orderBeingSent, userContext, workTasksPartsLoading, allBasketErp, includedBasketOrderGroups, includedWorkTaskIds, partsRequired])
    return isLoading
}
