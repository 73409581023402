import { BundleComponent } from "@tm/morpheus"
import { Manager } from "./component"
import { withStoreProvider } from "../../data/helpers"
import { ManagerState, reduce, transmit } from "./business"

const ManagerBundle: BundleComponent<ManagerState, typeof Manager> = {
    name: "manager",
    component: withStoreProvider(Manager as any) as any, // TODO
    transmit,
    reduce,
}

export { ManagerBundle, Manager }
