import { History } from "history"
import { match as Match } from "react-router"
import { OrderVoucherSupplierItem, Article, ECalcOrigin, Vehicle, CarModelDetailsResponse, OE } from "@tm/models"
import { UserSettings } from "@tm/context-distribution"
import Morpheus, { ActionDispatch, AsyncAction } from "@tm/morpheus"
import { BundleActions, BundleActionTypes } from "../../../business"
import { ECalcInputType, ECalcState, ESearchLink } from "../../../data/enums"
import {
    CalcSelection,
    CalcSelectionItem,
    Services,
    SummaryContext,
    FastCalculatorData,
    CalcState,
    ReplaceArticle,
    CalcGenArt,
    CalcArticle,
    AlternativeCalcStateContext,
} from "../../../data/model"
import { getLabourRate, getProductGroupIds, getSelectedServicesFromWS, getTopProductGroups } from "../../../data/helpers"

import { MainActionType, MainActions } from "../../main/business"
import { MainState } from "../../main"
import { navigateWithReplaceParam } from "../../../helpers"
import { TopProductGroup } from "../../../data/repositories/fastcalculator-calcProductGroups/model"

export type FastCalcState = {
    vehicle?: Vehicle
    vehicleIdUsed?: string
    loading?: boolean
    error?: boolean
    fastCalculatorData: FastCalculatorData
    selectedCalcSelection?: CalcSelection
    selectedServices: Array<CalcSelectionItem>
    unsavedServices: Array<CalcSelectionItem>
    selectedCalcState?: CalcState
    selectedDataCalcState?: CalcState
    selectedDialogCalcState?: CalcState
    selectedOverlayCalcState?: CalcState
    alternativeCalcArticles?: CalcState
    carModel?: CarModelDetailsResponse
    costEstimationUrl?: string

    engineCode?: string
    initialRegistration?: Date
    mileage?: number
    longLife?: boolean

    additionalData: {
        loading: boolean
        articles: Array<Article>
        orderHistory: Array<OrderVoucherSupplierItem>
    }
    vehicleId?: string
    isQueued?: boolean
    requestQueueId?: string
    checkStatusUrl?: string
    calcOrigin: ECalcOrigin
    replaceArticle?: ReplaceArticle
    inModal?: boolean
}

export type ComponentActionType =
    | BundleActionTypes
    | { type: "SELECT_CATEGORY"; payload: CalcSelection }
    | { type: "RESET_ADDITIONAL_DATA" }
    | { type: "ARTICLES_LOADING" }
    | { type: "ARTICLES_EMPTY_RESPONSE" }
    | { type: "ARTICLES_LOADED"; payload: Array<Article> }
    | {
        type: "ORDER_HISTORY_LOADED"
        payload: {
            orderHistory: Array<OrderVoucherSupplierItem>
            appendData: boolean
        }
    }
    | { type: "ADD_SELECTED_SERVICE"; payload: { item: CalcSelectionItem; existsInWS?: boolean } }
    | { type: "RESET_SELECTED_SERVICE" }
    | { type: "SET_CALC_ORIGIN"; payload: { calcOrigin: ECalcOrigin } }
    | { type: "REPLACE_ARTICLE_FINISH"; payload: { replacePart: Article | OE.OePart; history: History<unknown> } }
    | { type: "REPLACE_ARTICLE_RESET" }
    | { type: "REPLACE_ARTICLE_CANCEL"; payload: { history: History<unknown>; languageId: string; userSettings: UserSettings | undefined } }
    | { type: "REPLACE_ARTICLE_START"; payload: ReplaceArticle }

const DEFAULT_STATE: FastCalcState = {
    fastCalculatorData: {
        activeCalcState: ECalcState.None,
        availableCalcStates: ECalcState.None,
        calcStates: [],
    },
    unsavedServices: [],
    selectedServices: [],
    loading: true,
    additionalData: {
        loading: false,
        articles: [],
        orderHistory: [],
    },
    isQueued: false,
    calcOrigin: ECalcOrigin.Next,
}

export function reduce(state = { ...DEFAULT_STATE }, action: MainActionType): FastCalcState {
    switch (action.type) {
        case "SET_CONFIG_PROPS": {
            return {
                ...state,
                costEstimationUrl: action.payload.costEstimationUrl,
                inModal: action.payload.inModal,
            }
        }
        case "SET_CALC_ORIGIN": {
            return {
                ...state,
                calcOrigin: action.payload.calcOrigin,
            }
        }
        case "QUEUE_START": {
            return {
                ...state,
                requestQueueId: action.payload.requestQueueId,
                checkStatusUrl: action.payload.checkStatusUrl,
                isQueued: true,
                loading: false,
            }
        }
        case "QUEUE_FINISHED": {
            return {
                ...state,
                isQueued: false,
            }
        }
        case "QUEUE_CANCELLED": {
            return {
                ...state,
                isQueued: false,
                requestQueueId: "",
            }
        }
        case "FASTCALCULATOR_LOADING": {
            return {
                ...state,
                loading: true,
                ...(action.payload && { tecDocUsed: action.payload }),
            }
        }
        case "FASTCALCULATOR_LOADED": {
            const {
                selectedCalcState,
                fastCalculatorData,
                selectedDataCalcState,
                selectedDialogCalcState,
                selectedOverlayCalcState,
                alternativeCalcArticles,
            } = action.payload

            const initialRegistration = selectedCalcState?.context?.inputs?.find((x: any) => x.type == ECalcInputType.RegDate)?.value as string
            const engineCode = selectedCalcState?.context?.inputs?.find((x: any) => x.type == ECalcInputType.EngineCode)?.value as string
            const mileage = selectedCalcState?.context?.inputs?.find((x: any) => x.type == ECalcInputType.Mileage)?.value as number | undefined
            const longLife = selectedCalcState?.context?.inputs?.find((x: any) => x.type == ECalcInputType.Longlife)?.value

            const selectedServicesFromWS = getSelectedServicesFromWS(selectedCalcState?.context as SummaryContext)
            const unsavedDeselections = state.unsavedServices.filter((s) => s.isSelected === true)
            const unsavedSelections = state.unsavedServices.filter((s) => s.isSelected === false)
            const serviceList: CalcSelectionItem[] = []

            selectedServicesFromWS.forEach((service) => {
                const contains = unsavedDeselections.filter((s) => s.id === service.id)
                if (contains.length === 0) {
                    serviceList.push(service)
                }
            })

            return {
                ...state,
                loading: false,
                selectedCalcState,
                selectedDataCalcState,
                selectedDialogCalcState,
                fastCalculatorData,
                selectedOverlayCalcState,
                alternativeCalcArticles,
                error: false,
                engineCode,
                initialRegistration: (initialRegistration && new Date(initialRegistration)) || undefined,
                mileage,
                longLife,
                selectedServices: serviceList.length > 0 ? serviceList.concat(unsavedSelections) : unsavedSelections,
                isQueued: false,
            }
        }
        case "FASTCALCULATOR_ERROR": {
            return {
                ...state,
                error: true,
                loading: false,
                isQueued: false,
            }
        }
        case "RESET_ADDITIONAL_DATA": {
            return {
                ...state,
                additionalData: {
                    loading: false,
                    articles: [],
                    orderHistory: [],
                },
            }
        }
        case "ARTICLES_LOADING": {
            return {
                ...state,
                additionalData: {
                    ...state.additionalData,
                    loading: true,
                },
            }
        }
        case "ARTICLES_EMPTY_RESPONSE": {
            return {
                ...state,
                additionalData: {
                    ...state.additionalData,
                    loading: false,
                },
            }
        }
        case "ARTICLES_LOADED": {
            return {
                ...state,
                additionalData: {
                    ...state.additionalData,
                    loading: false,
                    articles: [...state.additionalData.articles, ...action.payload],
                },
            }
        }
        case "ORDER_HISTORY_LOADED": {
            const { orderHistory, appendData } = action.payload

            return {
                ...state,
                additionalData: {
                    ...state.additionalData,
                    orderHistory: !appendData ? orderHistory : state.additionalData.orderHistory.concat(orderHistory),
                },
            }
        }
        case "SELECT_CATEGORY": {
            return {
                ...state,
                selectedCalcSelection: action.payload,
            }
        }
        case "SELECTION_DIALOG_CLOSE": {
            return {
                ...state,
                selectedOverlayCalcState: undefined,
            }
        }
        case "DETAILS_LOADED": {
            const { modelDetails, vehicleId } = action.payload
            return {
                ...state,
                carModel: modelDetails,
                vehicleIdUsed: vehicleId,
            }
        }
        case "VEHICLE_SET": {
            return {
                ...state,
                vehicle: action.payload,
            }
        }
        case "ADD_SELECTED_SERVICE": {
            const validService: Services = {
                selectedServices: [...state.selectedServices],
                unsavedServices: [...state.unsavedServices],
            }
            const newService = action.payload.item

            const calcType = state.selectedCalcState?.type
            if (calcType === ECalcState.FastCockpitNext || calcType === ECalcState.CalculationNext) {
                handleChecboxToggles(validService, newService, action.payload.existsInWS)
            }

            return {
                ...state,
                unsavedServices: validService.unsavedServices,
                selectedServices: validService.selectedServices,
            }
        }
        case "RESET_SELECTED_SERVICE": {
            return {
                ...state,
                selectedServices: [],
                unsavedServices: [],
                isQueued: false,
                requestQueueId: "",
            }
        }

        case "REPLACE_ARTICLE_START":
            return {
                ...state,
                replaceArticle: action.payload,
            }

        case "REPLACE_ARTICLE_RESET": {
            return {
                ...state,
                replaceArticle: undefined,
            }
        }

        default:
            break
    }

    return state
}

export function receive(action: MainActionType, dispatch: ActionDispatch<MainActionType, MainState>) {
    switch (action.type) {
        case "REPLACE_ARTICLE_CANCEL": {
            dispatch(replaceArticleCancel(action.payload))
            break
        }
        case "REPLACE_ARTICLE_FINISH": {
            dispatch(replaceArticleFinish(action.payload))
            break
        }
        default:
            break
    }
}

function handleChecboxToggles(validServices: Services, newService: CalcSelectionItem, existsInWS?: boolean) {
    if (existsInWS && newService.isSelected) {
        // case where we uncheck the one that is checked on the server
        // we need to add it to a unsavedServices list so it will toggle on the server
        validServices.unsavedServices.push(newService)

        // remove it from selectedServices so the button will go disbaled if needed
        validServices.selectedServices = validServices.selectedServices.filter((s) => s.id !== newService.id)
    } else if (existsInWS && !newService.isSelected) {
        // case where we check the one that is checked on the server
        // we need to remove it from the unsavedServices list so it will not toggle on the server
        validServices.unsavedServices = validServices.unsavedServices.filter((s) => s.id !== newService.id)

        // add it back to the selectedServices so the button will go enabled
        validServices.selectedServices.push(newService)
    } else if (newService.isSelected) {
        // case where we uncheck one that is NOT YET on the server
        // remove from the unsavedServices list and from the selectedService to disable button
        validServices.unsavedServices = validServices.unsavedServices.filter((s) => s.id !== newService.id)
        validServices.selectedServices = validServices.selectedServices.filter((s) => s.id !== newService.id)
    } else if (!newService.isSelected) {
        // case where we check one that is NOT YET on the server
        // add itt to the ewlySelected list and selectedService to enable button
        validServices.unsavedServices.push(newService)
        validServices.selectedServices.push(newService)
    }
}

function selectCategory(category: CalcSelection): MainActionType {
    return { type: "SELECT_CATEGORY", payload: category }
}

function replaceArticleStart(
    match: Match<Record<string, string>>,
    search: string,
    inModal: boolean | undefined,
    genArt: CalcGenArt,
    part: CalcArticle | undefined
): MainActionType {
    return { type: "REPLACE_ARTICLE_START", payload: { match, search, genArt, inModal: inModal ?? false, part } }
}

function replaceArticleCancel({
    history,
    languageId,
    userSettings,
}: {
    history: History<unknown>
    languageId: string
    userSettings: UserSettings | undefined
}): AsyncAction<MainActionType, MainState> {
    return (dispatch, getState) => {
        const {
            fastCalculator: { replaceArticle, inModal },
        } = getState()

        // this is needed because parent transit redux dispatch enters for each component (main, productGroups) and not to fire twice
        // inModal = undefined = uninitialized, false - main FC, true - productGroups FC in modal
        // replaceArticle is not undefined => replace in progress, undefined -> wrong component or refresh
        if (!replaceArticle && typeof inModal === "undefined") {
            return false
        }

        // this is case of refresh
        if (!replaceArticle) {
            dispatch({ type: "REPLACE_ARTICLE_RESET" })
            Morpheus.getHistory().goBack()
            return
        }

        navigateWithReplaceParam(history, replaceArticle, true)

        const productGroupIds = getProductGroupIds(replaceArticle.match?.params?.productGroupIds)

        if (productGroupIds) {
            const topProductGroups: TopProductGroup[] | undefined = getTopProductGroups(replaceArticle.match?.params?.productGroupIds)

            dispatch(
                MainActions.startCalculationWithProductGroups(
                    productGroupIds,
                    languageId,
                    getLabourRate(userSettings),
                    replaceArticle.match?.params?.origin,
                    topProductGroups,
                    () => {
                        dispatch({ type: "REPLACE_ARTICLE_RESET" })
                        navigateWithReplaceParam(history, replaceArticle, false)
                    }
                )
            )
            return
        }

        dispatch(
            MainActions.initFastCalculator(false, undefined, undefined, () => {
                dispatch({ type: "REPLACE_ARTICLE_RESET" })
                navigateWithReplaceParam(history, replaceArticle, false)
            })
        )
    }
}

function replaceArticleFinish({
    replacePart,
    history,
}: {
    replacePart: Article | OE.OePart
    history: History<unknown>
}): AsyncAction<MainActionType, MainState> {
    return (dispatch, getState) => {
        const {
            fastCalculator: { alternativeCalcArticles, replaceArticle, inModal },
        } = getState()

        // this is needed because parent transit redux dispatch enters for each component (main, productGroups) and not to fire twice
        // inModal = undefined = uninitialized, false - main FC, true - productGroups FC in modal
        // replaceArticle is not undefined => replace in progress, undefined -> wrong component or refresh

        if (!replaceArticle || typeof inModal === "undefined") {
            return false
        }

        const alternativesContext = alternativeCalcArticles?.context as AlternativeCalcStateContext

        // If the replacement part is the same as the chosen part do nothing
        if (replaceArticle.part?.id == replacePart.id) {
            return
        }

        dispatch({ type: "REPLACE_ARTICLE_RESET" })

        navigateWithReplaceParam(history, replaceArticle, true)

        //  If the replacement part is a suggestion of the Fast Calculator submit the replace input instead
        const alternative = alternativesContext?.articles?.find((x) => x.id == replacePart.id)
        if (alternative?.replaceInput) {
            dispatch(
                MainActions.handleInputSubmit(alternative.replaceInput, () => {
                    navigateWithReplaceParam(history, replaceArticle, false)
                })
            )
            return
        }

        replacePart = replacePart as Article

        if (replacePart.supplierArticleNo && replacePart.productGroup?.id && replacePart.internalId && replacePart.supplier?.id) {
            dispatch(
                MainActions.handleAddPartFromArticleList(
                    {
                        eArtNr: replacePart.supplierArticleNo,
                        genArtNr: replacePart.productGroup.id,
                        iArtNr: replacePart.internalId,
                        supplierId: replacePart.supplier.id,
                        genArtId: replaceArticle.genArt.id,
                        oeGroupId: (replaceArticle.part as any)?.oeId, // TODO missing type ?
                        partId: replaceArticle.part?.id,
                    },
                    ESearchLink.Direct,
                    () => {
                        navigateWithReplaceParam(history, replaceArticle, false)
                    }
                )
            )
        }
        // else {
        //     actions.handleAddPartFromArticleList(({
        //         eArtNr: (replacePart as any).eArtNr,
        //         genArtNr: genArt.genArtNr,
        //         iArtNr: (replacePart as any).iArtNr,

        //         supplierId: (replacePart as any).manufacturerId,
        //         oeGroupId: part?.oeId,
        //         partId: part?.id
        //     }) as any, ESearchLink.Direct)
        // }
    }
}

export type IActions = typeof Actions

export const Actions = {
    ...BundleActions,
    selectCategory,
    replaceArticleStart,
}
