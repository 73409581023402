import { useCallback, useState, useEffect } from "react"
import { useLocalization } from "@tm/localization"
import { useSelection } from "@tm/utils"
import { Loader, Text, Skeleton } from "@tm/controls"
import { Article } from "@tm/models"
import CompilationInfo from "./components/compilation-info"
import PartsList from "./components/parts-list"
import PartsSelection from "./components/parts-selection"
import { useCompilation, useTotals } from "../../../../data/hooks/useCompilations"
import { isWM } from "../../../../utils"

type Props = {
    compilationId: string
    encodedWorkTaskId: string
    singleCompilationList?: boolean
}

export default function CompilationDetailsComponent(props: Props) {
    const { translateText, currency } = useLocalization()
    const [selectionMode, setSelectionMode] = useState<"MOVE" | "DEFAULT">("DEFAULT")
    const { compilationId } = props

    const { compilation, articles, isLoading } = useCompilation(compilationId)
    const { totals, totalsLoading } = useTotals(compilationId)

    const getAllIds = useCallback(() => articles?.map((article: Article) => article.id) || [], [articles])
    const { selectedIds, toggleSelected, selectAll, unselectAll } = useSelection(getAllIds)

    useEffect(() => {
        unselectAll()
    }, [compilationId])

    const handleSelectPart = useCallback(
        (partId: string, moveMode?: boolean) => {
            setSelectionMode(moveMode ? "MOVE" : "DEFAULT")
            toggleSelected(partId)
        },
        [toggleSelected]
    )

    const renderPartsSelection = () => {
        if (compilationId && articles?.length) {
            const selectedArticles = articles.filter((article: Article) => selectedIds.includes(article.id))
            if (selectedArticles.length) {
                const allSelected = articles.length === selectedArticles.length
                return (
                    <PartsSelection
                        compilationId={compilationId}
                        allSelected={allSelected}
                        selectedArticles={selectedArticles}
                        onSelectAll={selectAll}
                        onUnselectAll={unselectAll}
                        selectionMode={selectionMode}
                    />
                )
            }
        }
    }

    const renderPartList = () => {
        if (!articles) {
            return null
        }

        return (
            <PartsList
                encodedWorkTaskId={props.encodedWorkTaskId}
                compilationId={compilationId}
                loadingPositions={totalsLoading}
                positionCount={totals?.positionCount || 0}
                parts={articles}
                selectedPartIds={selectedIds}
                onSelectPart={handleSelectPart}
                singleCompilationList={props.singleCompilationList}
            />
        )
    }

    const renderTotals = () => {
        return (
            <div className="total">
                <Text size="m" className="label">
                    {translateText(62)}
                </Text>
                <Text size="m" className="price" modifiers={["strong"]}>
                    {totalsLoading ? <Loader /> : totals ? currency(totals.customerPriceTotal, totals.currencyCode || totals.currencySymbol) : "-"}
                </Text>
            </div>
        )
    }

    return (
        <div className="tk-compilations compilation-details">
            {isLoading ? (
                <>
                    <Skeleton variant="text" height="63px" />
                    <Skeleton variant="text" height="63px" />
                    <Skeleton variant="text" height="63px" />
                </>
            ) : (
                compilation && (
                    <>
                        {!props.singleCompilationList && <CompilationInfo compilation={compilation} />}
                        <div className="parts-selection">{renderPartsSelection()}</div>
                        {renderPartList()}
                    </>
                )
            )}
            {isWM() && renderTotals()}
        </div>
    )
}
