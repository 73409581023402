import { clone } from "@tm/utils"
import { WidgetStartState } from "./model"
import { BundleActionTypes, BundleActions } from "../../../business"
import { ECalcInputType } from "../../../data/enums"
import { CalcSelection } from "../../../data/model"
import { MainActionType } from "../../main/business"

export type ComponentActionType =
    | BundleActionTypes
    | { type: "SELECT_CATEGORY"; payload: CalcSelection }
    | { type: "SKIP_RELOAD"; payload: { skipReload: boolean } }

const DEFAULT_STATE: WidgetStartState = {
    vehicleDetails: {},
    skipReload: false,
}

export function reduce(state = clone(DEFAULT_STATE), action: MainActionType): WidgetStartState {
    switch (action.type) {
        case "FASTCALCULATOR_LOADED": {
            const { selectedCalcState } = action.payload
            const engineCode = selectedCalcState?.context?.inputs?.find((x) => x.type == ECalcInputType.EngineCode)?.value as string
            const initialRegistration = selectedCalcState?.context?.inputs?.find((x) => x.type == ECalcInputType.RegDate)?.value as string
            const mileage = selectedCalcState?.context?.inputs?.find((x) => x.type == ECalcInputType.Mileage)?.value as number | undefined
            const longLife = selectedCalcState?.context?.inputs?.find((x) => x.type == ECalcInputType.Longlife)?.value

            return {
                ...state,
                vehicleDetails: {
                    engineCode,
                    mileage: (!!mileage && mileage) || undefined,
                    initialRegistration: (initialRegistration && new Date(initialRegistration)) || undefined,
                    longLife,
                },
            }
        }

        case "SKIP_RELOAD": {
            const { skipReload } = action.payload
            return {
                ...state,
                skipReload,
            }
        }

        default:
            return state
    }
}

export type IActions = typeof Actions

export const Actions = {
    ...BundleActions,
}
