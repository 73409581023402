import { create } from "zustand"
import { createJSONStorage, devtools, persist } from "zustand/middleware"
import { isDevtoolEnabled } from "@tm/utils"
import { createMainSlice, MainSlice } from "./main"
import { zustandSessionStorage } from "./sessionStorage"
import { replacer, reviver } from "./sessionStorage/helper"

export type EdsStore = MainSlice

export const useEdsStore = create<EdsStore>()(
    devtools(
        persist(
            (...a) => ({
                ...createMainSlice(...a),
            }),
            {
                name: "EDSStore",
                skipHydration: true,
                storage: createJSONStorage(() => zustandSessionStorage, { replacer, reviver }),
                version: 0,
            }
        ),
        { name: "EDS Store", enabled: isDevtoolEnabled() }
    )
)

export const { resetStore, mainActions, vehicleActions, treeActions: treeNavigationActions, graphicActions } = useEdsStore.getState()
