import { Box, Icon, Stack, Typography } from "@tm/components"
import { useEffect, useState } from "react"
import { CategoryType } from "@tm/models"
import { useWorkTask } from "@tm/context-distribution"
import { AISearchField } from "./AiSearchField"
import SearchTreeWidget from "../../widget/components/SearchTreeWidget"
import { useAiSearch } from "./hooks/useAiSearch"

export type Props = {
    searchFieldWidth?: string
    aiSwitch?: React.ReactNode
    isOnIndustryDashboard?: boolean
}

export function AiSearch(props: Props) {
    const { searchFieldWidth, aiSwitch, isOnIndustryDashboard } = props
    const { loading, onSearch } = useAiSearch()
    const [searchtreeType, setSearchtreeType] = useState<CategoryType | undefined>()
    const workTask = useWorkTask()

    useEffect(() => {
        // set searchtyp after loading the worktask
        if (workTask?.workTask) {
            if (workTask?.workTask?.vehicle) {
                setSearchtreeType("vehicleParts")
            } else {
                setSearchtreeType("universalParts")
            }
        }
    }, [workTask?.workTask])

    const onStartSearch = async (query: string) => {
        onSearch(query)
    }

    return (
        <>
            <Stack alignItems={!isOnIndustryDashboard ? "flex-start" : "center"} spacing={!isOnIndustryDashboard ? 0 : 1}>
                <Typography variant="h1" textTransform="uppercase" fontWeight="bold" fontSize={!isOnIndustryDashboard ? "16px" : "26px"}>
                    The Ne
                    <Icon
                        sx={{ verticalAlign: "middle", marginLeft: "4px" }}
                        name="ki-search"
                        width={!isOnIndustryDashboard ? "25px" : "40px"}
                        height={!isOnIndustryDashboard ? "25px" : "40px"}
                    />
                    t Generation AI Search
                </Typography>
                <AISearchField
                    onStartSearch={onStartSearch}
                    loading={loading}
                    searchFieldWidth={searchFieldWidth}
                    lowSearchField={!isOnIndustryDashboard}
                />
            </Stack>
            <Box sx={{ position: "absolute", right: "16px", top: "16px" }}>{aiSwitch}</Box>

            {searchtreeType && <SearchTreeWidget searchTreeType={searchtreeType} isOnIndustryDashboard={isOnIndustryDashboard} />}
        </>
    )
}
