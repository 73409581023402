export enum ECalcLink {
    None = 0,
    ArticleDetail = 1,
    UniSearch = 2,
    DirectSearch = 3,
    OESearch = 4,
    ArticleList = 5,
    FastTD = 6,
    MaintenancePlanPrint = 7,
}
