import { IBundle } from "@tm/morpheus"

import AddingTools from "./components/adding-tools"
import ArticleAddedMessage from "./components/article-added-message"
import CentralOrder from "./components/central-order"
import CentralOrderButton from "./components/central-order-button"
import CentralOrderNavigation from "./components/central-order-navigation"
import CostEstimation from "./components/cost-estimation"
import Navigation from "./components/navigation"
import Notifications from "./components/notifications"
import ShellExportButton from "./components/navigation/components/ShellExportButton"
import ShowAllOrdersButton from "./components/show-all-orders-button"
import Summary from "./components/summary"
import Totals from "./components/totals"
import WorktaskOrder from "./components/worktask-order"
import WorktaskOrderButton from "./components/worktask-order-button"
import AddToBasket from "./micros/add-to-basket"
import AddToBasketOePart from "./micros/add-to-basket-oe-part"
import AddToBasketWholesalerPart from "./micros/add-to-basket-wholesaler-part"
import AddToCostEstimate from "./micros/add-to-cost-estimate"
import AddToCostEstimateOePart from "./micros/add-to-cost-estimate-oe-part"
import ChangeDistributorButton from "./micros/change-distributor-button"
import ActiveOrderModal from "./components/active-order-modal"
import ExportVoucherButton from "./components/ExportVoucherButton"
import { initBasketBundle, version } from "./utils"

/**
 * This is a namespace that contains documentation elements belonging to the Bundles Basket
 * domain.
 *
 * @namespace Basket
 */

const bundle: IBundle = {
    ...version,
    stylesUrl: "~/styles.css",
    components: [
        AddingTools,
        ArticleAddedMessage,
        CostEstimation,
        Navigation,
        Notifications,
        CentralOrder,
        CentralOrderButton,
        CentralOrderNavigation,
        ShellExportButton,
        ShowAllOrdersButton,
        Summary,
        Totals,
        WorktaskOrder,
        WorktaskOrderButton,
        ActiveOrderModal,
        ExportVoucherButton,
    ],
    micros: [AddToBasket, AddToBasketOePart, AddToBasketWholesalerPart, AddToCostEstimate, ChangeDistributorButton, AddToCostEstimateOePart],
    init: initBasketBundle,
}

export default bundle
