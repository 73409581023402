import { Icon, Text } from "@tm/controls"
import { useSelector } from "react-redux"
import { em } from "csx"
import { useStyle, useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { Typography, VehicleImage } from "@tm/components"
import { CalcInputComponent } from "../../_shared"
import { summarySelector } from "../../../data/helpers"
import { ECalcInputStyle } from "../../../data/enums"

export default function Header() {
    const { toDoLabel, definedVehicleLabel, historyEntries } = useSelector(summarySelector)
    const { translateText } = useLocalization()
    const vehicle = useWorkTask()?.workTask?.vehicle

    return (
        <div className={style.header}>
            <div>
                <Text modifiers="strong">{toDoLabel}</Text>
                <div className={style.headerData}>
                    {vehicle?.modelThumbnail ? (
                        <VehicleImage
                            modelImage={vehicle.modelThumbnail}
                            modelSeriesImage={vehicle.modelSeriesThumbnail || ""}
                            vehicleType={vehicle.vehicleType}
                            className={style.image}
                        />
                    ) : (
                        <Icon className={style.icon} name="undefined-car" />
                    )}
                    <Typography variant="body3" marginLeft="0.5em" display="block" fontWeight="600">
                        {definedVehicleLabel}
                    </Typography>
                </div>
            </div>
            {!!historyEntries?.dropDownItems.length && (
                <div>
                    <Text modifiers="strong"> {translateText(12585)} </Text>
                    <div className={style.headerData}>
                        <CalcInputComponent
                            className={style.historyDropDown}
                            item={{
                                ...historyEntries,
                                style: ECalcInputStyle.DropDown,
                                isDisabled: false,
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

const style = useStyle({
    header: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
    },
    headerData: {
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        marginTop: em(1),
        alignItems: "center",
    },
    image: {
        height: em(2),
        marginLeft: em(0.5),
        verticalAlign: "middle",
        $nest: {
            "&.error-image svg": {
                width: em(4),
                height: em(4),
            },
        },
    },
    icon: {
        width: em(4),
        marginLeft: em(0.5),
        verticalAlign: "middle",
    },
    calculationHistory: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    paddingRight: {
        paddingRight: em(1),
    },
    historyDropDown: {
        display: "flex",
        flex: 1,
        $nest: {
            ".dropdown .popover": {
                whiteSpace: "nowrap",
                flexWrap: "wrap",
            },
            ".popover__inner": {
                maxWidth: "initial",
            },
        },
    },
})(Header)
