import { ECalcInputType } from "../enums"

export function getCalcStateLabel(item: ECalcInputType, currency: string, translateText: (id: number) => string) {
    // TODO moveto helpers
    switch (item) {
        case ECalcInputType.GlobalLabourRate:
            return translateText(622)
        case ECalcInputType.ConsumableQuantity:
            return translateText(89)
        case ECalcInputType.ConsumableLabel:
            return translateText(118)
        case ECalcInputType.ConsumablePrice:
        case ECalcInputType.GenArtPrice:
        case ECalcInputType.RepairTimePrice:
        case ECalcInputType.OeArticlePrice:
        case ECalcInputType.ArticlePrice:
            return currency ?? "EUR"
    }
}
