import { ECalcStateButtonIcon } from "../enums"

export function getCalcStateIcon(item: ECalcStateButtonIcon | undefined) {
    switch (item) {
        case ECalcStateButtonIcon.Bin:
            return "delete"
        case ECalcStateButtonIcon.Replace:
            return "replace"
        case ECalcStateButtonIcon.Add:
            return "plus"
        case ECalcStateButtonIcon.NoFavorite:
            return "vote-star"
        case ECalcStateButtonIcon.IsFavorite:
            return "filled-star"
        case ECalcStateButtonIcon.TMSuggestion:
            return "partsindicator"
        case ECalcStateButtonIcon.ArtList:
            return "replace"
        default:
            return "none"
    }
}
