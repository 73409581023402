import { Article, ArticleAttribute, AttributeAction, SearchFilters } from "@tm/models"
import { useCallback, useMemo } from "react"
import { useHandleClickDetails } from "./useArticleItem/useHandleClickDetails"
import { useHandleStartDirectSearch } from "./useHandleStartDirectSearch"

export function useHandleClickAttribute(
    article: Article,
    onClickDetails: ReturnType<typeof useHandleClickDetails>,
    onClickAttribute?: (attribute: ArticleAttribute) => void
) {
    const handleStartDirectSearch = useHandleStartDirectSearch()

    const handleClickAttributeFilter = useCallback(
        (attribute: ArticleAttribute) => {
            switch (attribute.action) {
                case AttributeAction.DirectSearch:
                    handleStartDirectSearch(attribute.value, SearchFilters.All)
                    break
                case AttributeAction.OpenAccessoryList:
                    onClickDetails("accessories")
                    break
                case AttributeAction.OpenPartsList:
                    onClickDetails("partslist")
                    break
                default: {
                    onClickAttribute?.(attribute)
                }
            }
        },
        [handleStartDirectSearch, onClickDetails, onClickAttribute]
    )

    const handleClickAttribute = useMemo(
        () =>
            onClickAttribute
                ? (id: number, value?: string) => {
                      const attribute = article.attributes
                          .reduce<ArticleAttribute[]>((prev, cur) => {
                              return [...prev, ...cur.topAttributes, ...cur.partsListAttributes, ...cur.vehicleAttributes, ...cur.articleAttributes]
                          }, [])
                          .find((attr) => attr.id === id && attr.value === value)
                      if (attribute) {
                          handleClickAttributeFilter(attribute)
                      }
                  }
                : undefined,
        [onClickAttribute, article.attributes, handleClickAttributeFilter]
    )

    return handleClickAttribute
}
