import { Article, ErpInformation, ImportRepairEstimationRequest, ImportResponse, TmaEModule } from "@tm/models"
import { AsyncAction } from "@tm/morpheus"
import { clone, equals } from "@tm/utils"
import { batch } from "react-redux"
import { WorkTask } from "@tm/context-distribution"
import { BundleActions, BundleActionTypes } from "../../../business"
import { getRepairTimeDivision } from "../../../data/helpers"
import { EreSelectionSteps, ICalculationItem, Work } from "../../../data/models"
import { useContainer } from "../../../data/repositories"
import { MainState } from "../../main"
import { MainActions, MainActionsType } from "../../main/business"
import { createCostEstimationRequest, createEurotaxGetCalculationDataRequest } from "./helper"
import { CalculationState } from "./model"

export * from "./model"

export type ComponentActionType =
    | BundleActionTypes
    | { type: "CALCULATION_DATA_LOADING" }
    | { type: "CALCULATION_DATA_ERROR" }
    | { type: "ADD_TO_SHOPPING_BASKET_SUCCESS" }
    | { type: "ADD_TO_SHOPPING_BASKET_ERROR" }
    | { type: "SELECT_PART_ITEM"; payload: { oeArticle: ICalculationItem; part: Article } }
    | { type: "SELECT_OE_PART_ITEM"; payload: { oeArticle: ICalculationItem } }
    | { type: "SELECT_OE_ARTICLE"; payload: { oeArticle: ICalculationItem } }
    | { type: "SELECT_WORK"; payload: { work: Work } }
    | { type: "CHANGE_OE_ARTICLE_QUANTITY"; payload: { item: ICalculationItem; quantity: number } }
    | { type: "CHANGE_ARTICLE_QUANTITY"; payload: { item: ICalculationItem; quantity: number } }
    | { type: "SET_ERP_ARTICLES"; payload: ErpInformation[] }

export const DEFAULT_STATE: CalculationState = {
    erpArticles: [],
}

export const reduce = (state = clone(DEFAULT_STATE), action: ComponentActionType): CalculationState => {
    switch (action.type) {
        case "RESTART_PROCESS": {
            return {
                ...DEFAULT_STATE,
            }
        }
        case "CALCULATION_DATA_LOADING": {
            return {
                ...state,
                calculationLoading: true,
            }
        }
        case "CALCULATION_DATA_ERROR": {
            return {
                ...state,
                calculationLoading: false,
                calculationError: true,
            }
        }
        case "CALCULATION_DATA_LOADED": {
            let { works } = action.payload
            const { items } = action.payload
            const repairTimeDivision = getRepairTimeDivision()

            works = works?.map((x) => ({
                ...x,
                time: (x.time && x.time * repairTimeDivision) ?? 0,
                total: (x.hourlyRate * ((x.time && x.time * repairTimeDivision) ?? 0)) / repairTimeDivision,
            }))
            const initialSparePrice = items?.reduce((accumulator, item) => {
                return accumulator + (item.oeArticle.oePriceValue || 0) * item.oeArticle.quantityValue
            }, 0)

            return {
                ...state,
                calculationLoading: false,
                calculationError: false,
                calculationLoaded: true,
                works,
                items,
                initialSparePartsPrice: initialSparePrice,
            }
        }
        case "ADD_TO_SHOPPING_BASKET_SUCCESS": {
            return {
                ...state,
                addToShoppingBasketDone: true,
            }
        }
        case "ADD_TO_SHOPPING_BASKET_ERROR": {
            return {
                ...state,
                addToShoppingBasketDone: true,
            }
        }
        case "SELECT_PART_ITEM": {
            const { oeArticle, part } = action.payload

            return {
                ...state,
                items: state.items?.map((x) => ({
                    ...x,
                    ...(equals(x, oeArticle) && { selectedPart: (!equals(x.selectedPart, part) && part) || undefined }),
                })),
                addToShoppingBasketDone: false,
            }
        }
        case "SELECT_OE_PART_ITEM": {
            const { oeArticle } = action.payload
            return {
                ...state,
                items: state.items?.map((x) => ({
                    ...x,
                    ...(equals(x, oeArticle) && { selectedPart: undefined }),
                })),
            }
        }
        case "SET_ERP_ARTICLES": {
            return {
                ...state,
                erpArticles: [...state.erpArticles, ...action.payload],
            }
        }
        case "SELECT_OE_ARTICLE": {
            const { oeArticle } = action.payload

            return {
                ...state,
                items: state.items?.map((x) => ({ ...x, ...(equals(x, oeArticle) && { isSelected: !x.isSelected }) })),
                addToShoppingBasketDone: false,
            }
        }
        case "SELECT_WORK": {
            const { work } = action.payload

            return {
                ...state,
                works: state.works?.map((x) => ({ ...x, ...(equals(x, work) && { isSelected: !x.isSelected }) })),
                addToShoppingBasketDone: false,
            }
        }
        case "CHANGE_OE_ARTICLE_QUANTITY": {
            const { item, quantity } = action.payload

            return {
                ...state,
                items: state.items?.map((x) => ({ ...x, ...(equals(x, item) && { oeArticle: { ...x.oeArticle, quantityValue: quantity } }) })),
                addToShoppingBasketDone: false,
            }
        }
        case "CHANGE_ARTICLE_QUANTITY": {
            const { item, quantity } = action.payload

            return {
                ...state,
                items: state.items?.map((x) => ({
                    ...x,
                    ...(equals(x, item) && x.selectedPart && { selectedPart: { ...x.selectedPart, quantity } }),
                })),
                addToShoppingBasketDone: false,
            }
        }
        default: {
            return state
        }
    }
}

const getArticles =
    (historyId: number, sendEreSession?: boolean): AsyncAction<MainActionsType, MainState> =>
    (dispatch, getState) => {
        const state = getState()

        const request = createEurotaxGetCalculationDataRequest(historyId, state, sendEreSession)
        dispatch({ type: "CALCULATION_DATA_LOADING" })

        useContainer()
            .action("getCalculationData")(request)
            .then(
                (response) => {
                    batch(() => {
                        dispatch({ type: "CALCULATION_DATA_LOADED", payload: response })
                        dispatch(MainActions.getHistory(state.manager.vehicle!.tecDocTypeId, state.manager.vehicle!.id))
                    })
                },
                () =>
                    batch(() => {
                        dispatch(MainActions.changeStep(EreSelectionSteps.SUMMARY))
                        dispatch(MainActions.reset())
                    })
            )
    }

const addToShoppingBasket =
    (
        importToCostEstimation: (importRequest: ImportRepairEstimationRequest, tmaModule: TmaEModule) => Promise<ImportResponse | undefined>,
        repairTimeDivision: number,
        fullWorkTask: WorkTask,
        memo?: string
    ): AsyncAction<ComponentActionType, MainState> =>
    (dispatch, getState) => {
        const request = createCostEstimationRequest(getState(), repairTimeDivision, fullWorkTask, memo)

        request &&
            importToCostEstimation(request, TmaEModule.REPESTIMATE_EUROTAX).then(
                (_) => {
                    dispatch({ type: "ADD_TO_SHOPPING_BASKET_SUCCESS" })
                },
                (_) => dispatch({ type: "ADD_TO_SHOPPING_BASKET_ERROR" })
            )
    }

const changeOeArticleQuantity = (item: ICalculationItem, quantity: number): ComponentActionType => ({
    type: "CHANGE_OE_ARTICLE_QUANTITY",
    payload: { item, quantity },
})
const changeArticleQuantity = (item: ICalculationItem, quantity: number): ComponentActionType => ({
    type: "CHANGE_ARTICLE_QUANTITY",
    payload: { item, quantity },
})
const selectPart = (oeArticle: ICalculationItem, part: Article): ComponentActionType => ({ type: "SELECT_PART_ITEM", payload: { oeArticle, part } })
const selectOePart = (oeArticle: ICalculationItem): ComponentActionType => ({
    type: "SELECT_OE_PART_ITEM",
    payload: { oeArticle },
})
const selectOeArticle = (oeArticle: ICalculationItem): ComponentActionType => ({ type: "SELECT_OE_ARTICLE", payload: { oeArticle } })
const selectWork = (work: Work): ComponentActionType => ({ type: "SELECT_WORK", payload: { work } })
const setErpArticles = (articles: ErpInformation[]): ComponentActionType => ({ type: "SET_ERP_ARTICLES", payload: articles })

export const Actions = {
    ...BundleActions,
    addToShoppingBasket,
    selectOeArticle,
    selectPart,
    selectOePart,
    selectWork,
    changeOeArticleQuantity,
    changeArticleQuantity,
    setErpArticles,
    getArticles,
}
