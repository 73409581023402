import { useLocalization } from "@tm/localization"
import { Grid } from "@tm/components"
import { LightColoredIcon, LightContrastTypography } from "../../StyledComponents"

type Props = {
    currencyCode: string
    showRepairTimesInHours?: boolean
    showWorks?: boolean
    partCount: number
    partCosts: number
    workCosts?: number
    repairTimeTotal?: number
    showGrossLabel?: boolean
}

export default function SummaryDetails(props: Props) {
    const { partCosts, repairTimeTotal, showRepairTimesInHours, showGrossLabel, partCount, workCosts, showWorks, currencyCode } = props
    const { translateText, currency, number } = useLocalization()

    return (
        <Grid container spacing={1} alignItems="center" sx={{ rowGap: 1 }}>
            {/* Column headers */}
            <Grid item xs={5.5} />
            <Grid item xs={2} textAlign="right">
                <LightContrastTypography variant="label">{translateText(89)}</LightContrastTypography>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={3.5} textAlign="right">
                <LightContrastTypography variant="label">{translateText(showGrossLabel ? 13668 : 694)}</LightContrastTypography>
            </Grid>

            {/* Parts Rows */}
            <Grid item xs={1}>
                <LightColoredIcon name="cart-summary" />
            </Grid>
            <Grid item xs={4.5}>
                <LightContrastTypography>{translateText(90)}</LightContrastTypography>
            </Grid>
            <Grid item xs={2} textAlign="right">
                <LightContrastTypography>{number(partCount, 2)}</LightContrastTypography>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={3.5} textAlign="right">
                <LightContrastTypography>{currency(partCosts, currencyCode)}</LightContrastTypography>
            </Grid>
            {showWorks && (
                <>
                    {/* Works Rows */}
                    <Grid item xs={1}>
                        <LightColoredIcon name="repairtimes-summary" />
                    </Grid>
                    <Grid item xs={4.5}>
                        <LightContrastTypography>{translateText(83)}</LightContrastTypography>
                    </Grid>
                    <Grid item xs={2} textAlign="right">
                        <LightContrastTypography>{!!repairTimeTotal && number(repairTimeTotal, 2)}</LightContrastTypography>
                    </Grid>
                    <Grid item xs={1}>
                        <LightContrastTypography>{!!repairTimeTotal && showRepairTimesInHours && translateText(60)}</LightContrastTypography>
                    </Grid>
                    <Grid item xs={3.5} textAlign="right">
                        <LightContrastTypography>{currency(workCosts ?? 0, currencyCode)}</LightContrastTypography>
                    </Grid>
                </>
            )}
        </Grid>
    )
}
