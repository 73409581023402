import { EReplaceButtonBundle, channel } from "@tm/models"
import { useCallback, useEffect } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router"
import { useLocalization } from "@tm/localization"
import { useUser } from "@tm/context-distribution"
import { MainActions } from "../main/business"

export function Manager() {
    const history = useHistory()
    const dispatch = useDispatch()
    const { languageId } = useLocalization()
    const { userSettings } = useUser() ?? {}

    const handleReplaceCallback = useCallback(
        ({ part: replacePart, bundle, cancelAction }) => {
            if (bundle !== EReplaceButtonBundle.FastCalculatorNext) {
                return false
            }

            if (cancelAction) {
                dispatch(MainActions.replaceArticleCancel(history, languageId, userSettings))
                return
            }

            dispatch(MainActions.replaceArticleFinish(replacePart, history))
        },
        [history, dispatch]
    )

    useEffect(() => {
        const unsub = channel("GLOBAL").clearAndSubscribe("PARTS/REPLACE_PART", handleReplaceCallback)

        return unsub
    }, [handleReplaceCallback])

    return null
}
