import { ArticleIdentifier } from "@tm/models"
import { useArticlesByArticleNumbersWithOptionalVehicle } from "@tm/utils"
import { useMemo } from "react"
import { useQuery } from "react-query"
import { useLocalization } from "@tm/localization"
import * as Data from "../../../../data"
import { mapReturnVoucherItemToArticleIdentifier } from "../../../../data/mapper"
import { ShowReturnDetailsResponse } from "../../../../data/model"

export const KEY_SHOW_RETURN_DETAILS = "vouchers__useReturnDetails"
export function useReturnDetails(voucherId: string | undefined) {
    const { language } = useLocalization()
    const {
        data,
        isLoading: returnDetailsLoading,
        refetch,
    } = useQuery([KEY_SHOW_RETURN_DETAILS, voucherId], () => (voucherId ? Data.showReturnDetails({ voucherId }) : undefined), {
        enabled: !!voucherId,
        staleTime: 60 * 1000, // 1 minute
    })

    const articleIdentifiers = useMemo((): Array<ArticleIdentifier> | undefined => {
        return data?.items && mapReturnVoucherItemToArticleIdentifier(data?.items)
    }, [data?.items])

    const articles = useArticlesByArticleNumbersWithOptionalVehicle(articleIdentifiers, language)

    const returnDetails = useMemo((): ShowReturnDetailsResponse | undefined => {
        if (data && articles.length) {
            return {
                ...data,
                items: data.items.map((item) => {
                    const match = articles.find(
                        (article) =>
                            item.dataSupplier?.id === article.supplier.id &&
                            item.dataSupplierArticleNumber === article.supplierArticleNo &&
                            item.productGroup?.id === article.productGroup.id
                    )
                    return match
                        ? {
                              ...item,
                              thumbnailUrl: match.thumbnail,
                          }
                        : item
                }),
            }
        }
        return data
    }, [data, JSON.stringify(articles)])

    return {
        returnDetails,
        returnDetailsLoading,
        invalidateShowOrderDetails: refetch,
    }
}
