import { useLocalization } from "@tm/localization"
import { AddressItem, OrderOptions } from "../../../../../../../../data/model"
import { VoucherDetailsField } from "./VoucherDetailsField"

type Props = {
    orderOptions: OrderOptions
}

function getAddressValue(address: AddressItem) {
    return `${address.displayName || ""}, ${address.addressAddition || ""}, ${address.street || ""}, ${address.zip || ""} ${address.city || ""}, ${
        address.country || ""
    }`
}

export default function OrderOptionsFields({ orderOptions }: Props) {
    const { translateText, date } = useLocalization()

    if (!orderOptions) {
        return null
    }

    const { orderComment, paymentMode, shipmentMode, deliveryAddress, billingAddress, billingMode, deliveryDistributionMode, deliveryDate } =
        orderOptions

    return (
        <>
            {paymentMode && <VoucherDetailsField label={translateText(440)} value={paymentMode} />}
            {shipmentMode && <VoucherDetailsField label={translateText(1346)} value={shipmentMode.description} />}
            {billingMode && <VoucherDetailsField label={translateText(1830)} value={billingMode} />}
            {deliveryDistributionMode && <VoucherDetailsField label={translateText(1347)} value={deliveryDistributionMode} />}
            {deliveryDate && <VoucherDetailsField label={translateText(96)} value={date(deliveryDate, "g")} />}
            {orderComment && <VoucherDetailsField label={translateText(1248)} value={orderComment} />}
            {deliveryAddress && <VoucherDetailsField label={translateText(13221)} value={getAddressValue(deliveryAddress)} />}
            {billingAddress && <VoucherDetailsField label={translateText(12584)} value={getAddressValue(billingAddress)} />}
        </>
    )
}
