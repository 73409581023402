import { DefaultShareOptions, Share, ShareOption, ShareType } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { useEffect, useMemo, useState } from "react"
import { useFastServiceStore } from "../../../data"
import { PrintOptions } from "../../../data/models"
import { printMaintenance } from "../business"
import { usePdfDownload } from "../../../helpers/hooks/usePdfDownload"
import { useCarteligenceLinkShare } from "../../../../../notifications/src/data/hooks"
import { useEmailShare } from "../../../hooks/useEmailShare"
import { ShareAdditionalContent } from "./ShareAdditionalContent"

type Props = {
    printState: PrintOptions
    onPrintStateChange: (printOptions: keyof PrintOptions) => void
}

export function FastServiceFileShare({ printState, onPrintStateChange }: Props) {
    const { translateText } = useLocalization()
    const pdfUrlError = useFastServiceStore((state) => state.printError)
    const pdfUrlLoading = useFastServiceStore((state) => state.printLoading)
    const pdfUrlFromState = useFastServiceStore((state) => state.pdfUrl)
    const pdfExpiresAt = useFastServiceStore((state) => state.pdfExpiresAt)
    const { userSettings, userContext } = useUser()
    const customer = useWorkTask()?.workTask?.customer

    const { sendToCarteligence, disabled: carteligenceDisabled } = useCarteligenceLinkShare()
    const { sendEmail } = useEmailShare()
    const { downloadPdf } = usePdfDownload()

    const [pdfUrl, setPdfUrl] = useState("")

    useEffect(() => {
        if (!pdfUrlFromState || !pdfExpiresAt || Date.now() >= pdfExpiresAt) {
            setPdfUrl("")
        } else {
            setPdfUrl(pdfUrlFromState)
        }
    }, [pdfUrlFromState])

    const handlePrint = () => {
        printState && printMaintenance(translateText, printState, userContext.account?.username ?? "", userSettings, customer)
    }

    const shareOptionList: ShareOption[] = useMemo(
        () => [
            {
                ...DefaultShareOptions.WhatsApp,
                contentOverride: (content: string) => {
                    return `${translateText(13782)}: ${content}`
                },
            },
            {
                ...DefaultShareOptions.Email,
                type: ShareType.Email,
                handler: sendEmail,
                subject: translateText(13782) ?? "",
                initialEmails: [customer?.email ?? ""],
                bccEmails: [userSettings?.repairShop?.contactInfo?.email ?? ""],
            },
            {
                ...DefaultShareOptions.Carteligence,
                disabled: carteligenceDisabled,
                handler: async (content: string) => {
                    return sendToCarteligence(translateText(13782), content)
                },
            },
            {
                ...DefaultShareOptions.Download,
                handler: (content: string) => {
                    return downloadPdf(content)
                },
            },
        ],
        [downloadPdf, sendToCarteligence, carteligenceDisabled, userSettings, customer, translateText, sendEmail]
    )

    const additionalContent = useMemo(
        () => <ShareAdditionalContent printState={printState} onPrintStateChange={onPrintStateChange} isLoading={pdfUrlLoading} />,
        [printState, onPrintStateChange, pdfUrlLoading]
    )

    return (
        <Share
            shareOptions={shareOptionList}
            url={pdfUrl}
            errorMessage={pdfUrlError ? translateText(13301) : ""}
            onShareCompleted={() => {}}
            generateLink={handlePrint}
            isLoading={pdfUrlLoading}
            additionalContent={additionalContent}
            preventAutomaticGeneration
        />
    )
}
