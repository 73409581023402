import { getStoredAuthorization, ajax, clone } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { mapFastCalculatorResponse } from "../../helpers"
import { SubmitDropdownItemClickRequest, SubmitDropdownItemClickResponse } from "./model"

export function submitDropdownItemClick(request: SubmitDropdownItemClickRequest) {
    const url = `${getServiceUrl()}/SubmitDropdownItemClick`
    const authorization = getStoredAuthorization()
    const body = clone(request)
    body.parentCalcState.context = undefined

    return new Promise<SubmitDropdownItemClickResponse>((resolve, reject) =>
        ajax({ url, body, authorization, method: "POST" }).then((data) => {
            const mappedData = mapFastCalculatorResponse(data)
            if (mappedData?.selectedCalcState?.context || mappedData?.isQueued) {
                resolve(mappedData)
            } else {
                reject()
            }
        }, reject)
    )
}

function getServiceUrl() {
    const bundleParams = getBundleParams()
    return bundleParams.fastCalculatorServiceUrl
}
