import { getStoredAuthorization, ajax } from "@tm/utils"
import { ShowAllRequest, ShowByArticleRequest, ShowByCustomerRequest, ShowByVehiclesRequest, ShowCostEstimationsResponse } from "../../model"
import { getBundleParams } from "../../../utils"

function getServiceUrl(): string {
    return `${getBundleParams().vouchersServiceUrl}/Vouchers`
}

// TODO configurable cost-estimation: move ALL these functions to tm/data

export function showCostEstimationsByVehicles(body: ShowByVehiclesRequest) {
    const url = `${getServiceUrl()}/ShowByVehicles`
    const authorization = getStoredAuthorization()

    return ajax<ShowCostEstimationsResponse>({ url, body, authorization, method: "POST" }, undefined, undefined, true).then(
        (response) => response?.costEstimations
    )
}

export function showCostEstimationsByCustomer(body: ShowByCustomerRequest) {
    const url = `${getServiceUrl()}/ShowByCustomer`
    const authorization = getStoredAuthorization()

    return ajax<ShowCostEstimationsResponse>({ url, body, authorization }, undefined, undefined, true).then((response) => response?.costEstimations)
}

export function showCostEstimationsByArticle(body: ShowByArticleRequest) {
    const url = `${getServiceUrl()}/ShowByArticle`
    const authorization = getStoredAuthorization()

    return ajax<ShowCostEstimationsResponse>({ url, body, authorization }, undefined, undefined, true).then((response) => response?.costEstimations)
}

export function showAllCostEstimations(body: ShowAllRequest) {
    const url = `${getServiceUrl()}/ShowAll`
    const authorization = getStoredAuthorization()

    return ajax<ShowCostEstimationsResponse>({ url, body, authorization }, undefined, undefined, true).then((response) => response?.costEstimations)
}

export function showCostEstimationPdf(costEstimationId: string): Promise<Array<number> | undefined> {
    const url = `${getServiceUrl()}/ShowDetailsPdf`
    const authorization = getStoredAuthorization()
    const body = { id: costEstimationId }

    return ajax({ url, body, authorization, responseType: "blob" })
}

export function deleteCostEstimations(costEstimationIds: Array<string>) {
    const url = `${getServiceUrl()}/Delete`
    const authorization = getStoredAuthorization()
    const body = { ids: costEstimationIds }

    return ajax({ url, body, authorization, method: "POST" })
}
