import { Box, Divider, styled, Switch } from "@tm/components"
import { Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useEffect, useState } from "react"
import { VehicleAndCustomer } from "."
import MuiCustomModal from "./modal/MuiCustomModal"
import { useFastServiceStore } from "../../data"
import { DisplayOptionKeys, PrintOptions } from "../../data/models"

type Props = {
    open: boolean
    onOutsideClick: () => void
}

export default function CustomDisplayOptionsModal({ open, onOutsideClick }: Props) {
    const { translateText } = useLocalization()
    const printOptionsFromState = useFastServiceStore((state) => state.printOptions)
    const setReplacePrintOptions = useFastServiceStore((state) => state.setReplacePrintOptions)

    const [printOptions, setPrintOptions] = useState<PrintOptions>(printOptionsFromState)

    useEffect(() => {
        setPrintOptions(printOptionsFromState)
    }, [printOptionsFromState])

    const handleCheck = (key: keyof PrintOptions) => {
        setPrintOptions((prevOptions) => ({
            ...prevOptions,
            [key]: !prevOptions[key],
        }))
    }

    const handleClickOutside = () => {
        onOutsideClick()
        setPrintOptions(printOptionsFromState)
    }

    const handleSaveState = () => {
        setReplacePrintOptions(printOptions)
        onOutsideClick()
    }

    return (
        <MuiCustomModal open={open} handleOkButton={handleSaveState} onOutsideClick={handleClickOutside}>
            <VehicleAndCustomer headerText={translateText(844)} showServiceText />
            <Divider sx={{ margin: "1.5em 0" }} light />
            <StyledText size="m">{translateText(13825)}</StyledText>
            <StyledCheckRow>
                <Switch
                    onChange={() => handleCheck(DisplayOptionKeys.InspectionTimes)}
                    checked={printOptions?.inspectionTimes}
                    label={translateText(12964)}
                />
            </StyledCheckRow>
            <StyledCheckRow>
                <Switch onChange={() => handleCheck(DisplayOptionKeys.Remarks)} checked={printOptions.remarks} label={translateText(12965)} />
            </StyledCheckRow>
            <StyledCheckRow>
                <Switch onChange={() => handleCheck(DisplayOptionKeys.Images)} checked={printOptions?.images} label={translateText(12685)} />
            </StyledCheckRow>
            <StyledCheckRow>
                <Switch onChange={() => handleCheck(DisplayOptionKeys.Tyres)} checked={printOptions?.tyres} label={translateText(240)} />
            </StyledCheckRow>
            <StyledCheckRow>
                <Switch
                    onChange={() => handleCheck(DisplayOptionKeys.NextMaintenance)}
                    checked={printOptions?.nextMaintenance}
                    label={translateText(12966)}
                />
            </StyledCheckRow>
            <StyledCheckRow>
                <Switch
                    onChange={() => handleCheck(DisplayOptionKeys.ShowDefects)}
                    checked={printOptions?.showDefects}
                    label={translateText(12845)}
                />
            </StyledCheckRow>
        </MuiCustomModal>
    )
}

const StyledCheckRow = styled(Box)(({ theme }) => ({
    display: "flex",
    marginBottom: theme.margin?.l ?? ".75em",
}))

const StyledText = styled(Text)(({ theme }) => ({
    display: "block",
    paddingBottom: theme.margin?.xl ?? "1em",
}))
