import { FilterCriterias } from "../../../data/enums"
import { RimDetailsArticle, RimErpInfo } from "../../../data/model"
import { RimsDetailsArticlesRequest } from "../../../data/repositories/wheels-loadRimDetailsArticles/model"
import { RimRestrictionRequest } from "../../../data/repositories/wheels-loadRimRestrictions/model"
import { TiresRestrictionsRequest } from "../../../data/repositories/wheels-loadTireRestrictions/model"
import { TireSizesRequest } from "../../../data/repositories/wheels-loadTireSizes/model"
import { mapSelectedFiltersForRequest } from "../../wheels-list/business/helper"
import { SelectedFilters } from "../../wheels-list/business/model"
import { WheelsDetailsState } from "./model"

export function createRimDetArticlesRequest(carparkId: number, diameter: string, idrimdesign: number, selectedFilters: Partial<SelectedFilters>, articlesId?: string, registrationNo?: string, isSSW?: boolean, isConti? : boolean, rimErpInformations?: RimErpInfo[], isAdditionalManufacturerSelected?: boolean): RimsDetailsArticlesRequest {
    const rimFilter = mapSelectedFiltersForRequest(selectedFilters)
    let rimInfo: string | undefined = ""
    
    if (rimErpInformations) {
        rimInfo = mapRimInfo(rimErpInformations, diameter)
    }

    return {
        idrim: 0,
        diameter,
        idrimdesign,
        rimFilter,
        articlesId: !!articlesId ? articlesId : undefined,
        registrationNo,
        isSSW,
        isConti,
        carparkId,
        rimInfo,
        isAdditionalManufacturerSelected
    }
}

function mapRimInfo(rimErpInformations: RimErpInfo[], diameter: string) {
    const relevantInformations = rimErpInformations.filter(rimInfo => rimInfo.diameterSize === diameter.toString() && rimInfo.wholesalerArticleNumber?.length > 0)
    
    if (!relevantInformations.length) return 
    
    let rimInfo: string = ""
    relevantInformations.forEach(info => {
        rimInfo += info.dataSupplierArticleNumber + "," + info.wholesalerArticleNumber + ";"
    })
    return rimInfo
}

export function createRimRestrictionRequest(carparkId: number, selectedRimDetailsArticle?: RimDetailsArticle): RimRestrictionRequest {
    return {
        carparkId: carparkId,
        idrim: selectedRimDetailsArticle?.idRim ?? 0,
        appId: selectedRimDetailsArticle?.applicationId ?? 0
    }
}

export function createTireRestrictionRequest(carparkId: number, state: WheelsDetailsState): TiresRestrictionsRequest | undefined {
    const { selectedRimDetailsArticle, selectedTireSize } = state

    if (!selectedTireSize) return
    return {
        carparkId,
        idrim: (selectedRimDetailsArticle && selectedRimDetailsArticle.idRim) || 0,
        idtyresize: selectedTireSize?.idTyreSize ?? 0,
        appId: selectedRimDetailsArticle?.applicationId ?? 0
    }
}

export function createTireSizesRequest(carparkId: number, state: WheelsDetailsState): TireSizesRequest {
    const { selectedRimDetailsArticle, selectedFilters } = state

    let rimFilter = mapSelectedFiltersForRequest(selectedFilters)
    if (selectedRimDetailsArticle?.applicationId)
        rimFilter += FilterCriterias.applicationId + ',' + selectedRimDetailsArticle.applicationId + ';'
    return {
        carparkId: carparkId,
        idrim: selectedRimDetailsArticle?.idRim ?? 0,
        rimFilter
    }
}