import { Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ModuleHeaderSlot } from "./ModuleHeaderSlot"
import { Navigation } from "./Navigation"
import { Options } from "./Options"
import { Search } from "./Search"
import { StyledModuleHeader } from "../StyledComponents"

export function ModuleHeader() {
    const { translateText } = useLocalization()

    return (
        <StyledModuleHeader>
            <Typography variant="h1" pr={2} pb={1}>
                {translateText(993)}
            </Typography>
            <ModuleHeaderSlot title={translateText(176)}>
                <Navigation />
            </ModuleHeaderSlot>
            <ModuleHeaderSlot title={translateText(135)}>
                <Search />
            </ModuleHeaderSlot>
            <ModuleHeaderSlot title={translateText(361)}>
                <Options />
            </ModuleHeaderSlot>
        </StyledModuleHeader>
    )
}
