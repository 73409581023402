import { ReactNode, useMemo } from "react"
import { BonusPoints, ImageViewer, Box, IconButton, Icon, Stack, Typography } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { BonusSystemImage, Collapsible, List, MessageInline, PanelSection } from "@tm/controls"
import { LocalizationProps, useLocalization } from "@tm/localization"
import { Article, ArticleAttributes, ArticleReferenceType, IMicros, RepairTimeProvider, RequestArticleDetailsPayload } from "@tm/models"
import { useMicro } from "@tm/morpheus"
import { concat, getArticleBonusSystemsWithoutPoints, getArticleBonusSystemsWithPoints, isPseudoArticle, useErpConfig } from "@tm/utils"

import ArticleCell from "../../../_shared/article-cell"
import ArticleImageComponent, { Props as ArticleImageComponentProps } from "../../../_shared/article-image"
import DetailsComponent from "../../../_shared/references"
import { mapAttributesToKeyValueItem } from "../../../../data/mapper"
import { KeyValueItemProps } from "../../../../data/model"
import KeyValueItem from "../../../details/components/KeyValueItem"
import { ArticleExtended } from "../../business"
import { getArticleUniqueId } from "../../business/helpers"
import ArticleNumbers from "../../../_shared/ArticleNumbers"
import { getBundleParams } from "../../../../utils"
import { useWatchlist } from "../../../ListV2/hooks/useArticleItem/useWatchlist"

type Props = {
    article: ArticleExtended
    showArticleImage: boolean
    vehicleId?: string
    customerId?: string
    foundBySearchTerm?: string
    expandImages: boolean
    expandProperties: boolean
    expandDetailedInfo: boolean
    expandReferences: boolean
    previouslyOrdered: boolean
    hoveredAttribute?: string
    hideAddToBasketButton?: boolean
    hideAdditionalArticleAlternatives?: boolean
    getRepairTimesUrl?(article: Article, rtProviders: Array<RepairTimeProvider> | RepairTimeProvider): string | undefined // Currently not used, but it can be used in the future when needed for the kva button when this modal supports breadcrumb
    onRequestArticleDetails(request: RequestArticleDetailsPayload): void
    onUnselectArticle?(articleUniqueId: string): void
    onToggleExpandImages(): void
    onToggleExpandProperties(): void
    onToggleExpandDetailedInfo(): void
    onToggleExpandReferences(): void
    onSetHoveredAttribute(item: KeyValueItemProps | undefined): void
    onOpenPage?(): void
}

export function ArticleComparisonItem(props: Props) {
    const {
        article,
        previouslyOrdered,
        showArticleImage,
        vehicleId,
        customerId,
        foundBySearchTerm,
        expandImages,
        expandDetailedInfo,
        hoveredAttribute,
        expandProperties,
        expandReferences,
        hideAddToBasketButton,
        hideAdditionalArticleAlternatives,
    } = props

    const { translateText } = useLocalization()
    const { userContext } = useUser() ?? {}
    const { renderMicro } = useMicro<IMicros>()

    const basketArticles = useMemo(() => (article ? [article] : []), [article])

    function handleCloseButtonClick() {
        props.onUnselectArticle?.(getArticleUniqueId(article))
    }

    function handleDetailsButtonClick(subPage?: string, scrollTo?: string) {
        props.onRequestArticleDetails({
            article: {
                productGroupId: article.productGroup.id,
                supplierId: article.supplier.id,
                supplierArticleNo: article.supplierArticleNo,
            },
            vehicleLinkageId: article.vehicleLinkageId,
            subPage,
            scrollTo,
            foundBySearchTerm,
            disableAddToBasket: !!article.referencedArticleModification?.isRemovedReferencedArticle,
            initialQuantity: article.quantity,
        })
    }

    function handleItemHover(item: KeyValueItemProps | undefined) {
        props.onSetHoveredAttribute(item)
    }

    function renderHeader() {
        const productGroupName = article.productGroup.name || "-"
        return (
            <Stack direction="row" justifyContent="space-between" alignItems="center" mb={0.5}>
                {productGroupName}
                <IconButton
                    sx={{ visibility: !props.onUnselectArticle ? "hidden" : undefined }}
                    title={translateText(317)}
                    onClick={handleCloseButtonClick}
                >
                    <Icon name="cancel-x" />
                </IconButton>
            </Stack>
        )
    }

    function renderMessages() {
        if (!previouslyOrdered) {
            return null
        }

        return <MessageInline size="xs" iconSize="s" message="" description={translateText(80)} />
    }

    function renderGeneralInformation() {
        return (
            <Stack spacing={0.5} height="12rem">
                <Stack direction="row" justifyContent="space-between">
                    {renderInfoItem(translateText(91), <ArticleNumbers wholesalerArticleNumber={article.traderArticleNo || "-"} />)}
                    {renderActionsButtons()}
                </Stack>
                <Stack direction="row" spacing={0.5}>
                    {renderThumbnail()}
                    {renderArticleNumbers()}
                    {renderErpInformation()}
                </Stack>
                {renderMessages()}
            </Stack>
        )
    }

    function renderArticleNumbers() {
        const className = concat(
            " ",
            "article-comparision-item__general-information__article-numbers",
            userContext?.parameter.positionChangeEArtNrHArtNr && "positon-switch"
        )

        return (
            <div className={className}>
                {!userContext?.parameter.hideDealerPartNumber &&
                    renderInfoItem(translateText(92), <ArticleNumbers supplierArticleNumber={article.supplierArticleNo || "-"} />)}
                {renderInfoItem(translateText(71), <Typography fontWeight="bold">{article.supplier.name || "-"}</Typography>)}
            </div>
        )
    }

    function renderInfoItem(title: string, item: ReactNode) {
        return (
            <Stack mb={1}>
                <Typography variant="body3">{title}</Typography>
                {item}
            </Stack>
        )
    }

    function renderThumbnail() {
        if (!showArticleImage) {
            return
        }

        const hasGraphicalPartsImage: boolean =
            article.isPartsListAvailable && article.existImage && (!!article.imageCoordinates || isPseudoArticle(article))
        const clickable: boolean = article.existImage || hasGraphicalPartsImage

        let className = "article__thumbnail"
        if (clickable) {
            className += " article__thumbnail--clickable"
        }

        const thumbnailProps: Omit<ArticleImageComponentProps, keyof LocalizationProps> = {
            thumbnailClassName: className,
            thumbnailUrl: article.thumbnail,
            internalArticleId: article.internalId,
            vehicleLinkageId: article.vehicleLinkageId,
            enableLargeView: clickable,
        }

        if (hasGraphicalPartsImage) {
            thumbnailProps.enableLargeView = false
            thumbnailProps.thumbnailDescription = translateText(1500)
            thumbnailProps.customThumbnailComponent = <div className="partslist-hint" title={translateText(1535)} />
        } else if (clickable) {
            thumbnailProps.thumbnailDescription = translateText(1503)
        }

        return (
            <ArticleCell bemModifier="thumbnail">
                <ArticleImageComponent {...thumbnailProps} />
            </ArticleCell>
        )
    }

    function renderBonusSystems() {
        const bonusSystems = getArticleBonusSystemsWithoutPoints(article)

        return bonusSystems?.map((x) => (
            <BonusSystemImage key={x.id} className="article-comparision-item__bonus-system" id={x.id} name={x.name} imageUrl={x.imageUrl} />
        ))
    }

    function renderBonusPoints() {
        const bonusSystems = getArticleBonusSystemsWithPoints(article)
        return (
            <Box className="article-comparision-item__bonus-points" mr={1}>
                {bonusSystems?.map((x) => (
                    <BonusPoints
                        key={x.id}
                        bonusSystem={{
                            ...x,
                            name: translateText(13113),
                        }}
                        label={translateText(1826)}
                    />
                ))}
            </Box>
        )
    }

    function renderActionsButtons() {
        return (
            <Box display="flex">
                {renderBonusSystems()}
                {renderBonusPoints()}
                <Box mr={1}>{renderAddToBasket()}</Box>
                {renderAddToCompilations()}
            </Box>
        )
    }

    function renderAddToBasket() {
        if (hideAddToBasketButton || !article.showAddToBasket) {
            return
        }

        return renderMicro("basket", "add-to-basket", {
            data: basketArticles,
            vehicleId,
            customerId,
            foundBySearchTerm,
            erpType: "list",
        })
    }

    function renderAddToCompilations() {
        const watchList = article && useWatchlist && useWatchlist(article)

        if (article.showAddToBasket) {
            const compilationMicro = renderMicro("compilations", "add-to-compilation", {
                article,
                createOrAddSingleWatchlist: getBundleParams().highlightWatchlistButton ? watchList?.createOrAddWatchlist : undefined,
            })

            if (compilationMicro) {
                return compilationMicro
            }
        }

        return null
    }

    const { erpSystemConfigs } = useErpConfig()

    function renderErpInformation() {
        return (
            erpSystemConfigs && (
                <div className="article-comparision-item__micros">
                    <div className="article-comparision-item__micros-body">
                        {renderMicro("erp", "erp-info", {
                            data: article,
                            onAlternativesClick: () => {
                                handleDetailsButtonClick("alternative-articles")
                            },
                            onReplacementArticlesClick: () => {
                                handleDetailsButtonClick("replacement-articles")
                            },
                            onGraduatedPricesClick: () => {
                                handleDetailsButtonClick("stocks", "prices")
                            },
                        })}
                    </div>
                </div>
            )
        )
    }

    function renderDetailedInformation() {
        return (
            <div className="article-comparision-item__detailed-information">
                {renderSectionDetailedInfos()}
                {renderImages()}
                {renderProperties()}
                {renderReferences()}
            </div>
        )
    }

    function renderSectionDetailedInfos() {
        const { packaging, references, traderDescription, description, status, additionalDescription } = article

        const eanNumbers = references?.filter((ref) => ref?.referenceType === ArticleReferenceType.EuropeanArticleNo) ?? []

        const items: Array<KeyValueItemProps> = [
            { description: translateText(3116), value: traderDescription },
            { description: translateText(377), value: description },
            { description: translateText(378), value: additionalDescription },
            {
                description: translateText(379),
                value: status.map((item) => {
                    let text = item.description
                    if (item.country && status.length > 1) {
                        text += ` (${item.country})`
                    }
                    return { text }
                }),
                valuesAsList: true,
            },
            {
                description: translateText(383),
                value: concat(" ", packaging?.packagingUnit?.toString(), packaging?.isSelfServicePackaging ? `(${translateText(384)})` : undefined),
            },
            { description: translateText(385), value: packaging?.quantityPerUnit?.toString() ?? "" },
            {
                description: translateText(386),
                value: packaging ? [packaging.batchSize?.toString() ?? "", packaging.batchSize2?.toString() ?? ""] : "",
            },
            {
                description: translateText(387),
                value: eanNumbers.map((x) => ({
                    text: x.referenceNo,
                    modificationState: x.modificationState,
                    asTag: true,
                })),
            },
        ]

        return (
            <PanelSection>
                <Collapsible name={translateText(407)} initiallyOpened={expandDetailedInfo} onChangeOpen={props.onToggleExpandDetailedInfo}>
                    <List
                        view={KeyValueItem}
                        items={items.filter((x) => x.value && (!Array.isArray(x.value) || (x.value.length && x.value.some((y) => !!y))))}
                        className="key-value-list key-value-list--attributes"
                    />
                </Collapsible>
            </PanelSection>
        )
    }

    function renderAttributes(attributes: ArticleAttributes) {
        const originalText = translateText(1642)
        const optimizedText = translateText(1643)
        const topAttributes: Array<KeyValueItemProps> = mapAttributesToKeyValueItem(
            attributes.topAttributes,
            true,
            originalText,
            optimizedText,
            hoveredAttribute
        )
        const vehicleAttributes: Array<KeyValueItemProps> = mapAttributesToKeyValueItem(
            attributes.vehicleAttributes,
            false,
            originalText,
            optimizedText,
            hoveredAttribute
        )
        const articleAttributes: Array<KeyValueItemProps> = mapAttributesToKeyValueItem(
            attributes.articleAttributes,
            false,
            originalText,
            optimizedText,
            hoveredAttribute
        )

        return (
            <List
                onItemHover={handleItemHover}
                view={KeyValueItem}
                items={[...topAttributes, ...vehicleAttributes, ...articleAttributes]}
                className="key-value-list key-value-list--attributes"
            />
        )
    }

    function renderImages() {
        if (!article.images) {
            return
        }

        return (
            <PanelSection>
                <Collapsible
                    name={translateText(12685)}
                    initiallyOpened={expandImages}
                    onChangeOpen={props.onToggleExpandImages}
                    className="article-comparision-item__images"
                >
                    <ImageViewer images={article.images} isLoading={false} />
                </Collapsible>
            </PanelSection>
        )
    }

    function renderProperties() {
        const { attributes } = article

        if (!attributes.length) {
            return null
        }

        let content
        if (attributes.length === 1) {
            content = <>{renderAttributes(attributes[0])}</>
        } else {
            content = attributes.map((value, id) => (
                <div key={id}>
                    <div className="article-comparision-item__detailed-information__vehicle">
                        {id + 1}.&nbsp;
                        <Icon name="car" />
                    </div>
                    {renderAttributes(value)}
                </div>
            ))
        }

        return (
            <PanelSection>
                <Collapsible name={translateText(397)} initiallyOpened={expandProperties} onChangeOpen={props.onToggleExpandProperties}>
                    {content}
                </Collapsible>
            </PanelSection>
        )
    }

    function renderReferences() {
        return (
            <PanelSection>
                <Collapsible name={translateText(398)} initiallyOpened={expandReferences} onChangeOpen={props.onToggleExpandReferences}>
                    <DetailsComponent className="article-comparison__references" article={article} onReferenceClicked={props.onOpenPage} />
                </Collapsible>
            </PanelSection>
        )
    }

    return (
        <div className="article-comparision-item">
            {renderHeader()}
            {renderGeneralInformation()}
            {renderDetailedInformation()}
        </div>
    )
}
