import { Icon, ListItemButton, ListItemIcon, ListItemText, Tooltip, styled } from "@tm/components"
import { CustomArticle } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { useUser } from "@tm/context-distribution"
import { getCurrencyFromUserContext } from "@tm/utils"
import { StyledListItem } from "./shared"

type Props = {
    item: CustomArticle
    handleConsumableSelect: (selected: CustomArticle) => void
    handleSaveDefaultForCategory: (consumable: CustomArticle) => void
    isSelected?: boolean
    note?: string
}

export default function ConsumableItem({ item, handleConsumableSelect, handleSaveDefaultForCategory, isSelected, note }: Props) {
    const user = useUser()
    const { translateText } = useLocalization()
    const currencyString = getCurrencyFromUserContext(user?.userContext)
    const showPurchasePrice = user.userSettings?.showPurchasePrice

    return (
        <StyledListItem disablePadding isSelected={isSelected}>
            <ListItemButton
                sx={{ display: "grid", gridTemplateColumns: "1fr 2fr 1fr 1fr 1fr 1fr" }}
                role={undefined}
                onClick={() => {
                    handleConsumableSelect(item)
                }}
                dense
            >
                <ListItemText id={item.articleNumber} primary={item.articleNumber} />
                <ListItemText id={item.description} primary={item.description} />
                <ListItemText
                    id={item.id}
                    sx={{ visibility: showPurchasePrice ? "visible" : "hidden" }}
                    primary={!!item.purchasePrice && `${item.purchasePrice?.toFixed(2)} ${currencyString}`}
                />
                <ListItemText id={item.id} primary={!!item.retailPrice && `${item.retailPrice?.toFixed(2)} ${currencyString}`} />
                <ListItemIcon>
                    <Icon
                        onClick={() => handleSaveDefaultForCategory(item)}
                        name={item.isDefaultForCategory ? "filled-star" : "vote-star"}
                        width="1.5em"
                        height="1.5em"
                    />
                </ListItemIcon>
                {note ? (
                    <Tooltip
                        title={
                            <>
                                {translateText(12874)} <br /> {note}
                            </>
                        }
                        variant="light"
                    >
                        <StyledIcon name="notes-added" />
                    </Tooltip>
                ) : (
                    <></>
                )}
            </ListItemButton>
        </StyledListItem>
    )
}

const StyledIcon = styled(Icon)(({ theme }) => ({
    fill: theme.overwrites?.components?.notesButton?.background || theme.palette.primary.main || "#202020",
}))
