import { useLocalization } from "@tm/localization"
import { Text, Headline } from "@tm/controls"
import { Article } from "@tm/models"
import ArticleListItemView from "./list-item"

type Props = {
    articles: Array<Article>
    onSelect: (article: Article) => void
}

export default function SelectArticlesComponent(props: Props) {
    const { translateText } = useLocalization()

    const renderPartItem = (article: Article) => <ArticleListItemView key={article.id} article={article} onSelect={props.onSelect} />

    return (
        <div className="tk-basket select-articles parts-list article-list">
            <div className="select-articles__header">
                <div className="content">
                    <Headline size="l">{translateText(1324)}</Headline>
                    <Text>{translateText(1325)}</Text>
                </div>
            </div>
            <div className="select-articles__content">
                <div className="list part-list">{props.articles.map(renderPartItem)}</div>
            </div>
        </div>
    )
}
