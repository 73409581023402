import { useMemo } from "react"
import { Box, Icon, ScrollContainer, Typography, LoadingContainer } from "@tm/components"
import { useWorkTask } from "@tm/context-distribution"
import { CalcState } from "../../../../data/model"
import { ECalcState } from "../../../../data/enums"
import { saveVehicleToWorkTask } from "../../../../data/helpers"
import SelectionItems from "../../selectionItems"
import CalcStateButtonComponent from "../../calcStateButton"
import { getCalcSelection } from "../../../../data/helpers/context"

type Props = {
    calcState: CalcState
    loading: boolean | undefined
    // eslint-disable-next-line react/no-unused-prop-types
    onClose?: () => void
}

export default function CalcSelectionContent({ calcState, loading }: Props) {
    const worktask = useWorkTask()

    const context = useMemo(() => getCalcSelection(calcState), [calcState?.context])

    if (!context) {
        return null
    }

    const handleLonglifeSwitch = (data: boolean) => {
        saveVehicleToWorkTask(data, worktask?.workTask?.vehicle, worktask?.attachToWorkTask)
    }

    const handleChange = () => {
        if (calcState.type !== ECalcState.VehicleType) {
            return
        }

        const selectedSelection = context?.items.find((x) => x.isSelected)?.properties?.first()?.value
        if (selectedSelection) {
            saveVehicleToWorkTask(selectedSelection, worktask?.workTask?.vehicle, worktask?.attachToWorkTask)
        }
    }

    return (
        <Box display="flex" flexDirection="column" gap="1em">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" alignItems="center">
                    <Icon name="info" height="1.5em" width="1.5em" />
                    <Typography variant="h4" component="span" pl={2}>
                        {context.label}
                    </Typography>
                </Box>
                <Box display="flex" gap="0.5rem" flexDirection="row-reverse">
                    {calcState?.buttons?.map((button) => (
                        <CalcStateButtonComponent item={button} key={button.id} onClick={handleChange} isDisabled={loading} isOverlayCalcState />
                    ))}
                </Box>
            </Box>
            <LoadingContainer minHeight="3em" maxHeight="500px" isLoading={loading}>
                <ScrollContainer sx={{ position: "relative" }}>
                    <SelectionItems items={context.items} isFromDialog isOverlayCalcState handleLonglifeSwitch={handleLonglifeSwitch} />
                </ScrollContainer>
            </LoadingContainer>
        </Box>
    )
}
