export enum ECalcCmd {
    None,
    SubmitInitialDataCalcState,
    SubmitFastCockpitCalcState,
    SubmitVehicleTypeCalcState,
    SubmitMaintenanceSystemCalcState,
    SubmitIntervalCalcState,
    SelectSelectionItem,
    ResetCalculation,
    SetActiveCalcState,
    SubmitMileageInput,
    SubmitRegDateInput,
    SubmitEngineCodeInput,
    SubmitLonglifeInput,
    SubmitCalculateIncludedAdditionalWorkInput,
    SubmitPriceInput,
    SubmitQuantityInput,
    SubmitReplaceArticleByAlternativeInput,
    SubmitReplaceOeArticleByAlternativeInput,
    SubmitRemoveArticleInput,
    SubmitRemoveOeArticleInput,
    SubmitRemoveGenArtInput,
    SubmitRemoveConsumableInput,
    SubmitRemoveRepairTimeInput,
    SubmitAdditionalWorkCalcState,
    SubmitFollowupWorkCalcState,
    AddPartFromArticleList,
    SubmitGlobalLabourRate,
    SubmitConsumableSpecificationDropDownClick,
    SubmitConsumableLabelDropDownClick,
    SubmitEngineCodeDropDownItemClick,
    SubmitConsumableLabelInput,
    SubmitRemoveDropDownInput,
    SubmitFavoriteDropDownInput,
    ClickIncludeSuggestedWorksButton,
    SubmitCrossDeleteAcceptInput,
    SubmitSwitchOeGroupInput,
    SetActiveTopProductgroups,
    SubmitMaintenancePlanOverlayInput,
    SubmitFastCockpitNextCalcState,
    ServerSideCmd,
    DestroyCalc,
}
