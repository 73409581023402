export enum ECalcState {
    None = 0,
    InitialData = 1,
    FastCockpit = 1 << 1,
    VehicleType = 1 << 2,
    MaintenanceSystem = 1 << 3,
    Interval = 1 << 4,
    Calculation = 1 << 5,
    AdditionalWork = 1 << 6,
    FollowupWork = 1 << 7,
    FastPackages = 1 << 8,
    MaintenancePlanOverlay = 1 << 9,
    MaintenancePlanPrint = 1 << 10,
    Basket = 1 << 11,
    Quote = 1 << 12,
    ServiceProvider = 1 << 13,
    GenArtPrices = 1 << 14,
    CrossDeleteDialog = 1 << 15,
    PlaceHolderDialog = 1 << 16,
    Error = 1 << 17,
    Log = 1 << 18,
    FastCockpitPreviewNext = 1 << 19,
    LastCalcPreviewNext = 1 << 20,
    FastCockpitNext = 1 << 21,
    CalculationNext = 1 << 22,
    AlternativeCalcArticles = 1 << 23,
    BasketNext = 1 << 24,
    QuoteNext = 1 << 25,

    /// <summary>
    ///   Combination of all presentable calc states
    /// </summary>
    PresentableCalcState = FastCockpit |
        VehicleType |
        MaintenanceSystem |
        Interval |
        Calculation |
        LastCalcPreviewNext |
        FastCockpitPreviewNext |
        FastCockpitNext,
    /// <summary>
    ///   Combination of all widget calc states
    /// </summary>
    WidgetCalcState = FastCockpit | VehicleType | MaintenanceSystem | Interval | Calculation | LastCalcPreviewNext | FastCockpitPreviewNext,

    /// <summary>
    ///   Combination of all overlay calc states
    /// </summary>
    OverlayCalcStates = AdditionalWork |
        FollowupWork |
        FastPackages |
        MaintenancePlanOverlay |
        AlternativeCalcArticles |
        Interval |
        MaintenanceSystem |
        VehicleType,

    WorksState = FollowupWork | AdditionalWork,

    /// <summary>
    ///   Combination of all data calc states
    /// </summary>
    DataCalcStates = MaintenancePlanPrint | Basket | Quote | ServiceProvider | GenArtPrices | BasketNext | QuoteNext,

    /// <summary>
    ///   Combination of all dialog calc states
    /// </summary>
    DialogCalcStates = CrossDeleteDialog | PlaceHolderDialog,

    /// <summary>
    ///   Combination of all calc state combinations
    /// </summary>
    AllCalcStates = InitialData | PresentableCalcState | OverlayCalcStates | DataCalcStates | DialogCalcStates,
}
