import { Article, CustomArticle } from "@tm/models"
import { StateCreator } from "zustand"
import { FastServiceStore } from "../.."
import { addOrRemoveItem } from "../../../helpers/addOrRemoveItem"
import { Genart, RecordCustomWorks, RecordWorks, RepairTime, Work } from "../../models"
import { WorksSlice, WorksState } from "./model"
import { CustomWork, PanelState } from "../../../models"
import { convertCustomWorks } from "../../../helpers/convertCustomWorks"

function getInitialState(): WorksState {
    return {
        worksState: {
            selectedItems: {},
            savedItemLubricants: {},
            works: {},
            customWorks: {},
        },
    }
}

export const createWorksSlice: StateCreator<FastServiceStore, [["zustand/devtools", never]], [], WorksSlice> = (set, get) => ({
    ...getInitialState(),
    worksActions: {
        updateAllWorks: (works: RecordWorks) => set((state) => updateAllWorks(state, works)),
        updateCustomWorks: (customWorks: RecordCustomWorks) => set((state) => updateCustomWorks(state, customWorks)),
        updateCustomWorkField: (customWorks: CustomWork[], customWorkId: number, customWorkState: PanelState) =>
            set((state) => updateCustomWorkField(state, customWorks, customWorkId, customWorkState)),
        setDefaultNextPanel: (stepName: string) => set((state) => setDefaultNextPanel(state, stepName)),
        updateSelectedGenarts: (item: Genart, path: string, stepName: string) => set((state) => updateSelectedGenarts(state, item, path, stepName)),
        updateSelectedRepairtimes: (item: RepairTime, path: string, stepName: string) =>
            set((state) => updateSelectedRepairtimes(state, item, path, stepName)),
        updateSelectedLubricant: (item: string, path: string, stepName: string) =>
            set((state) => updateSelectedLubricant(state, item, path, stepName)),
        updateSavedLubricant: (item: string, path: string, stepName: string) => set((state) => updateSavedLubricant(state, item, path, stepName)),
        updateWorkField: (workField: { key: string; value: string | number }, path: string, stepName: string) =>
            set((state) => updateWorkField(state, workField, path, stepName)),
        updateArticleQuantity: (path: string, stepName: string, quantity: number, id: string) =>
            set((state) => updateArticleQuantity(state, path, stepName, quantity, id)),
        updateOeArticle: (path: string, stepName: string, quantity: number, price: number, designation: string, id: string, oeNumber: string) =>
            set((state) => updateOeArticle(state, path, stepName, quantity, price, designation, id, oeNumber)),
        replaceArticle: (path: string, stepName: string, id: string, newArticle: Article, oeReplacement?: boolean) =>
            set((state) => replaceArticle(get(), state, path, stepName, id, newArticle, oeReplacement)),
        replaceConsumable: (stepName: string, consumableId: string, selectedConsumableArticle: CustomArticle) =>
            set((state) => replaceConsumable(state, stepName, consumableId, selectedConsumableArticle), false, "Replace consumable"),
        reset: () => set(getInitialState(), false, "Reset Works Slice"),
    },
})

function updateAllWorks(state: WorksSlice, works: RecordWorks): Partial<WorksSlice> {
    return {
        worksState: {
            ...state.worksState,
            works,
        },
    }
}

function updateCustomWorks(state: WorksSlice, customWorks: RecordCustomWorks): Partial<WorksSlice> {
    return {
        worksState: {
            ...state.worksState,
            customWorks,
        },
    }
}

function updateCustomWorkField(state: WorksSlice, customWorks: CustomWork[], customWorkId: number, customWorkState: PanelState): Partial<WorksSlice> {
    const newCustomWorks = [...customWorks]
    newCustomWorks[customWorkId] = { ...newCustomWorks[customWorkId], isCompleted: customWorkState !== PanelState.None, panelState: customWorkState }
    const newCustomWorksRecord = convertCustomWorks(newCustomWorks)

    return {
        worksState: {
            ...state.worksState,
            customWorks: newCustomWorksRecord,
        },
    }
}

function updateSelectedGenarts(state: WorksSlice, item: Genart, path: string, stepName: string): Partial<WorksSlice> {
    const { selectedItems } = state.worksState
    const { selectedGenArts } = selectedItems?.[stepName]?.[path] || {}

    const newValue = addOrRemoveItem([...(selectedGenArts || [])], item)
    return {
        worksState: {
            ...state.worksState,
            selectedItems: {
                ...selectedItems,
                [stepName]: {
                    ...selectedItems[stepName],
                    [path]: {
                        ...selectedItems[stepName]?.[path],
                        selectedGenArts: newValue,
                    },
                },
            },
        },
    }
}

function updateSelectedRepairtimes(state: WorksSlice, item: RepairTime, path: string, stepName: string): Partial<WorksSlice> {
    const { selectedItems } = state.worksState
    const { selectedRepairTimes } = selectedItems?.[stepName]?.[path] || {}
    const newValue = addOrRemoveItem([...(selectedRepairTimes || [])], item)

    return {
        worksState: {
            ...state.worksState,
            selectedItems: {
                ...selectedItems,
                [stepName]: {
                    ...selectedItems[stepName],
                    [path]: {
                        ...selectedItems[stepName]?.[path],
                        selectedRepairTimes: newValue,
                    },
                },
            },
        },
    }
}

function updateSelectedLubricant(state: WorksSlice, item: string, path: string, stepName: string): Partial<WorksSlice> {
    const { selectedItems } = state.worksState

    return {
        worksState: {
            ...state.worksState,
            selectedItems: {
                ...selectedItems,
                [stepName]: {
                    ...selectedItems[stepName],
                    [path]: {
                        ...selectedItems[stepName]?.[path],
                        selectedLubricant: item,
                    },
                },
            },
        },
    }
}

function updateSavedLubricant(state: WorksSlice, item: string, path: string, stepName: string): Partial<WorksSlice> {
    const { savedItemLubricants } = state.worksState

    return {
        worksState: {
            ...state.worksState,
            savedItemLubricants: {
                [stepName]: {
                    ...savedItemLubricants[stepName],
                    [path]: {
                        ...savedItemLubricants[stepName]?.[path],
                        selectedLubricant: item,
                    },
                },
            },
        },
    }
}

function setDefaultNextPanel(state: WorksSlice, stepName: string): Partial<WorksSlice> {
    const { works, selectedItems } = state.worksState
    if (!works || !works[stepName]) {
        return {}
    }

    const firstWorkSelectionStatus = selectedItems[stepName]?.[works[stepName][0]?.label]?.selectedStatusButton

    if (firstWorkSelectionStatus && firstWorkSelectionStatus !== "none") {
        return {}
    }

    return {
        worksState: {
            ...state.worksState,
            nextPanel: works[stepName][0],
        },
    }
}

function updateWorkField(state: WorksSlice, workField: { key: string; value: string | number }, path: string, stepName: string): Partial<WorksSlice> {
    const { selectedItems, works } = state.worksState
    const { key, value } = workField
    const selectedKeyItems = Object.keys(selectedItems[stepName] || {}).filter((x) => selectedItems[stepName][x].selectedStatusButton !== "none")
    const nextPanel =
        value === "defectButton" || value === "none" || key === "safetyStatus" || key === "comment"
            ? works[stepName]?.find((x) => x.label === path)
            : getNextPanel(
                  works[stepName]?.findIndex((x) => x.label === path),
                  selectedKeyItems,
                  works[stepName]
              )

    return {
        worksState: {
            ...state.worksState,
            nextPanel,
            selectedItems: {
                ...selectedItems,
                [stepName]: {
                    ...selectedItems[stepName],
                    [path]: {
                        ...selectedItems[stepName]?.[path],
                        [key]: value,
                    },
                },
            },
        },
    }
}

function updateArticleQuantity(state: WorksSlice, path: string, stepName: string, quantity: number, id: string): Partial<WorksSlice> {
    const { works } = state.worksState
    const newWorks: Work[] = works[stepName].map((x) => ({
        ...x,
        genArts: x.genArts.map((y) => ({ ...y, ...(y.article && { ...(y.article.id === id && { article: { ...y.article, quantity } }) }) })),
    }))

    return {
        worksState: {
            ...state.worksState,
            works: {
                ...works,
                [stepName]: [...(newWorks || [])],
            },
        },
    }
}

function updateOeArticle(
    state: WorksSlice,
    path: string,
    stepName: string,
    quantity: number,
    price: number,
    designation: string,
    id: string,
    oeNumber: string
): Partial<WorksSlice> {
    const { works } = state.worksState
    const newWorks: Work[] = works[stepName]?.map((x) => ({
        ...x,
        genArts: x.genArts?.map((y) => ({
            ...y,
            ...(y.fsArticle && {
                ...(y.fsArticle.id === id && {
                    fsArticle: { ...y.fsArticle, quantity, price, oeLabel: designation, oeNrs: [oeNumber] },
                }),
            }),
        })),
    }))

    return {
        worksState: {
            ...state.worksState,
            works: {
                ...works,
                [stepName]: [...(newWorks || [])],
            },
        },
    }
}

function replaceConsumable(state: WorksSlice, stepName: string, consumableId: string, selectedConsumableArticle: CustomArticle): Partial<WorksSlice> {
    const { works } = state.worksState

    const newWorks = works[stepName].map((work) => {
        if (work.consumables.find((c) => c.id === consumableId)) {
            const consumables = work.consumables.map((consumable) => {
                const consumableArticle = consumable?.article
                if (consumableArticle && consumable.id === consumableId) {
                    if (consumableArticle) {
                        consumableArticle.genArtLabel = selectedConsumableArticle.description
                        consumableArticle.id = selectedConsumableArticle.id
                        consumableArticle.hArtNr = selectedConsumableArticle.articleNumber
                        consumableArticle.supplierArtNr = selectedConsumableArticle.articleNumber
                        consumableArticle.price = selectedConsumableArticle.retailPrice
                        if (consumable.quantity && selectedConsumableArticle.retailPrice) {
                            consumableArticle.totalPrice = consumable.quantity * selectedConsumableArticle.retailPrice
                        } else {
                            consumableArticle.totalPrice = 0
                        }

                        return {
                            ...consumable,
                            article: consumableArticle,
                        }
                        // consumable.article = consumableArticle
                    }
                }

                return consumable
            })

            return {
                ...work,
                consumables,
            }
        }

        return { ...work }
    })

    return {
        worksState: {
            ...state.worksState,
            works: {
                ...works,
                [stepName]: [...(newWorks || [])],
            },
        },
    }
}

function replaceArticle(
    store: FastServiceStore,
    state: WorksSlice,
    path: string,
    stepName: string,
    id: string,
    newArticle: Article,
    oeReplacement?: boolean
): Partial<WorksSlice> {
    const { works } = state.worksState
    const newWorks = works[stepName].map((work) => {
        let newGenarts: Genart[] = []
        if (work.genArts?.length > 0) {
            newGenarts = work.genArts.map((genart) => {
                if (genart.fsArticle && genart.fsArticle?.id === id) {
                    if (genart.article) {
                        // eslint-disable-next-line no-param-reassign
                        newArticle.quantity = genart.article.quantity
                        store.setReplaceArticle(genart.article, newArticle)
                    }

                    if (oeReplacement) {
                        genart.oeReplace = true
                    }
                    // eslint-disable-next-line no-param-reassign
                    genart.article = newArticle
                } else if (genart.genArtNr === 6322 && genart.article) {
                    // eslint-disable-next-line no-param-reassign
                    newArticle.quantity = genart.article.quantity
                    store.setReplaceArticle(genart.article, newArticle)
                    return { genArtNr: newArticle.productGroup.id, label: path, article: newArticle, oeReplace: oeReplacement }
                } else {
                    store.setUpdateSelectedArticles(newArticle)
                    return { genArtNr: newArticle.productGroup.id, label: path, article: newArticle, oeReplace: oeReplacement }
                }

                return genart
            })
        } else {
            store.setUpdateSelectedArticles(newArticle)
            newGenarts = [{ genArtNr: newArticle.productGroup.id, label: path, article: newArticle, oeReplace: oeReplacement }]
        }

        return {
            ...work,
            genArts: newGenarts,
        }
    })

    return {
        worksState: {
            ...state.worksState,
            works: {
                ...works,
                [stepName]: [...(newWorks || [])],
            },
        },
    }
}

const getNextPanel = (currentIndex: number, selectedItems: string[], works: Work[]) => {
    for (let i = currentIndex + 1; i <= works.length; i++) {
        if (works[i] && !selectedItems.includes(works[i]?.label)) {
            return works[i]
        }
    }
    for (let y = 0; y <= currentIndex; y++) {
        if (!selectedItems.includes(works[y].label)) {
            return works[y]
        }
    }
    return works[0]
}
