import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Icon, LoadingContainer, styled, Switch, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useFastServiceStore } from "../../../data"
import { PrintOptions } from "../../../data/models"

type ShareAdditionalContentProps = {
    printState: PrintOptions
    onPrintStateChange: (printOptions: keyof PrintOptions) => void
    isLoading: boolean
}

export function ShareAdditionalContent({ printState, onPrintStateChange, isLoading }: ShareAdditionalContentProps) {
    const { translateText } = useLocalization()

    const handleCheck = (key: keyof PrintOptions) => {
        useFastServiceStore.getState().setPrintLoaded("")
        onPrintStateChange(key)
    }

    return (
        <LoadingContainer isLoading={isLoading}>
            <Accordion defaultExpanded sx={{ padding: "0.5em" }}>
                <StyledAccordionSummary expandIcon={<Icon name="down" />}>
                    <StyledBox>
                        <StyledSubtitle>{translateText(844)}</StyledSubtitle>
                    </StyledBox>
                </StyledAccordionSummary>
                <AccordionDetails>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6}>
                            <Switch
                                label={<StyledText>{translateText(12964)}</StyledText>}
                                labelPlacement="start"
                                checked={printState.inspectionTimes}
                                onChange={() => handleCheck("inspectionTimes")}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Switch
                                label={<StyledText>{translateText(12965)}</StyledText>}
                                labelPlacement="start"
                                checked={printState.remarks}
                                onChange={() => handleCheck("remarks")}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Switch
                                label={<StyledText>{translateText(12685)}</StyledText>}
                                labelPlacement="start"
                                checked={printState.images}
                                onChange={() => handleCheck("images")}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Switch
                                label={<StyledText>{translateText(240)}</StyledText>}
                                labelPlacement="start"
                                checked={printState.tyres}
                                onChange={() => handleCheck("tyres")}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Switch
                                label={<StyledText>{translateText(12966)}</StyledText>}
                                labelPlacement="start"
                                checked={printState.nextMaintenance}
                                onChange={() => handleCheck("nextMaintenance")}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Switch
                                label={<StyledText>{translateText(12845)}</StyledText>}
                                labelPlacement="start"
                                checked={printState.showDefects}
                                onChange={() => handleCheck("showDefects")}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </LoadingContainer>
    )
}

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    "& .MuiAccordionSummary-content.Mui-expanded": {
        marginLeft: theme.spacing(1),
    },
}))

const StyledBox = styled(Box)({
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
})

const StyledSubtitle = styled(Typography)({
    variant: "body1",
    fontWeight: "medium",
})

const StyledText = styled(Typography)({
    variant: "body2",
    fontWeight: "regular",
})
