import { useEffect } from "react"
import { withRouter } from "@tm/utils"
import { useWorkTask } from "@tm/context-distribution"
import { Box, Loader } from "@tm/components"
import Main from "./Main"
import { useEdsStore } from "../../data/state"

const HistoryWrapper: React.FC = () => {
    const [hydrated, setHydrated] = React.useState(false)
    const [historyRestored, setHistoryRestored] = React.useState(false)
    const [hasWorktaskId, setHasWorktaskId] = React.useState(false)
    const worktask = useWorkTask()?.workTask

    useEffect(() => {
        if (!hasWorktaskId && worktask) {
            setHasWorktaskId(true)
        }
    }, [worktask, hasWorktaskId])

    useEffect(() => {
        return () => {
            setHistoryRestored(false)
            setHasWorktaskId(false)
            setHydrated(false)
        }
    }, [])

    useEffect(() => {
        if (hasWorktaskId) {
            useEdsStore.persist.rehydrate()
        }

        const unsubHydrate = useEdsStore.persist.onHydrate(() => setHydrated(false))
        const unsubFinishHydration = useEdsStore.persist.onFinishHydration(() => setHydrated(true))
        setHydrated(useEdsStore.persist.hasHydrated())

        return () => {
            unsubHydrate()
            unsubFinishHydration()
        }
    }, [hasWorktaskId])

    useEffect(() => {
        if (!hydrated || !hasWorktaskId) {
            return
        }

        restoreHistory()
    }, [hydrated, hasWorktaskId])

    function restoreHistory() {
        setHistoryRestored(true)
    }

    if (historyRestored) {
        return <Main />
    }

    return (
        <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Loader />
        </Box>
    )
}

export default withRouter(HistoryWrapper)
