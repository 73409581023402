import { useState, useEffect, useCallback } from "react"
import { decodeUniqueId } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { Button, Badge } from "@tm/controls"
import { ServiceBase, RepairTimeProvider, RegisteredModels, ConfigParams } from "@tm/models"
import { Container } from "@tm/nexus"
import { Icon } from "@tm/components"
import Morpheus from "@tm/morpheus"
import { useBasketModuleParameter } from "../../../../basket/src/hooks/useBasketModuleParameter"

type Props<T extends ServiceBase> = {
    service: T
    provider: RepairTimeProvider
    workTaskId: string
    onAddToBasket(service: T): void
    onRemoveRepairTime(id: string, service: T): void
}

export default function AddToBasket<T extends ServiceBase>({ service, provider, workTaskId, onAddToBasket, onRemoveRepairTime }: Props<T>) {
    const { translateText, languageId } = useLocalization()
    const { deleteButtonColorError } = Morpheus.getParams<ConfigParams>()
    const [basketId, setBasketId] = useState<string>()
    const { showCostEstimationButton } = useBasketModuleParameter()

    useEffect(() => {
        setBasketId(undefined)

        const subscription = Container.getInstance(RegisteredModels.Basket_HasRepairTimes).subscribe({
            workTaskId: decodeUniqueId(workTaskId),
            repairTimesProvider: provider,
            repairTimeProviderWorkId: service.repairTimeNo,
        })

        const unregister = subscription.addListener(
            "loaded",
            (response) => {
                setBasketId(response.id)
            },
            () => {
                setBasketId(undefined)
            }
        )

        subscription.load()

        return unregister
    }, [provider, workTaskId, service.repairTimeNo])

    const handleClick = useCallback(() => {
        if (basketId) {
            onRemoveRepairTime(basketId, service)
        } else {
            onAddToBasket(service)
        }
    }, [basketId, service, onRemoveRepairTime, onAddToBasket])

    if (!showCostEstimationButton) {
        return null
    }

    let icon = languageId === "1" ? "voucher-kva" : "voucher-kva-international"
    if (basketId) {
        icon = "delete"
    }

    return (
        <>
            {!!basketId && <Badge skin="dark" value={<Icon name="check" />} />}
            <Button
                className="kva-button"
                layout={["bordered"]}
                skin={basketId || deleteButtonColorError ? "danger" : "highlight"}
                title={basketId ? translateText(624) : translateText(916)}
                onClick={handleClick}
            >
                <Icon name={icon} width={30} />
            </Button>
        </>
    )
}
