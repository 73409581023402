import { useUser } from "@tm/context-distribution"
import { ArticleIdentifier, ArticleInfoType, ReactQueryKeys } from "@tm/models"
import { useArticlesByArticleNumbersWithOptionalVehicle, useSelection } from "@tm/utils"
import { useCallback, useEffect, useMemo } from "react"
import { useQuery } from "react-query"
import { useLocalization } from "@tm/localization"
import * as Data from "../../../../data"
import { mapOrderCatalogPartToArticleIdentifier } from "../../../../data/mapper"
import { ShowOrderDetailsResponse } from "../../../../data/model"

const KEY_SHOW_ORDER_DETAILS = ReactQueryKeys.vouchers_useOrderDetails
export function useOrderDetails(voucherId: string | undefined) {
    const { language } = useLocalization()
    const { hasMailRetoure } = useUser().userContext
    const {
        data,
        isLoading: orderDetailsLoading,
        refetch,
    } = useQuery(
        [KEY_SHOW_ORDER_DETAILS, voucherId],
        () => (voucherId ? Data.showOrderDetails({ voucherId, hasReturnItems: hasMailRetoure }) : undefined),
        {
            enabled: !!voucherId,
            staleTime: 60 * 1000, // 1 minute
        }
    )

    const getAllOrderItemIds = useCallback(() => {
        return (
            data?.orderItemsByWorkTask.flatMap((x) =>
                x.orderVoucherItems.filter((item) => item.articleInfoType === ArticleInfoType.TecdocArticle).map((item) => item.id)
            ) || []
        )
    }, [data?.orderItemsByWorkTask])

    const { selectedIds, toggleSelected, selectAll, unselectAll } = useSelection(getAllOrderItemIds)

    useEffect(() => {
        unselectAll()
    }, [unselectAll, voucherId])

    const articleIdentifiers = useMemo((): Array<ArticleIdentifier> | undefined => {
        const orderParts = data?.orderItemsByWorkTask.flatMap((x) => x.orderVoucherItems)
        const filteredParts = orderParts?.filter(
            (part) => part.articleInfoType === ArticleInfoType.TecdocArticle || part.articleInfoType === ArticleInfoType.WholesalerArticle
        )
        return filteredParts && mapOrderCatalogPartToArticleIdentifier(filteredParts)
    }, [data?.orderItemsByWorkTask])

    const articles = useArticlesByArticleNumbersWithOptionalVehicle(articleIdentifiers, language)

    const orderDetails = useMemo((): ShowOrderDetailsResponse | undefined => {
        if (data && articles.length) {
            return {
                ...data,
                orderItemsByWorkTask: data.orderItemsByWorkTask.map((items) => ({
                    ...items,
                    orderVoucherItems: items.orderVoucherItems.map((item) => {
                        const match = articles.find(
                            (article) =>
                                item.articleInfo.dataSupplier?.id === article.supplier.id &&
                                item.articleInfo.dataSupplierArticleNumber === article.supplierArticleNo &&
                                item.articleInfo.productGroup?.id === article.productGroup.id
                        )

                        return match
                            ? {
                                  ...item,
                                  articleInfo: {
                                      ...item.articleInfo,
                                      thumbnailUrl: match.thumbnail,
                                  },
                              }
                            : item
                    }),
                })),
            }
        }
        return data
    }, [data, JSON.stringify(articles)])

    return { orderDetails, orderDetailsLoading, selectedIds, toggleSelected, selectAll, unselectAll, invalidateShowOrderDetails: refetch }
}
