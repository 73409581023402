import { Button, ReplaceButton, Tag } from "@tm/controls"
import { useActions } from "@tm/morpheus"
import { CalcInput } from "../../../data/model"
import { MainActions } from "../../main/business"
import { getButtonSkin } from "../../../data/helpers"
import { ECalcInputType } from "../../../data/enums"
import { getCalcStateIcon } from "../../../data/helpers/getCalcInputIcon"

type Props = {
    className?: string
    item: CalcInput
}

export default function CalcInputButton(props: Props) {
    const { className, item } = props
    const actions = useActions(MainActions, "handleInputSubmit")

    if (!item) {
        return null
    }

    const icon = getCalcStateIcon(item.icon)

    const handleChange = (event?: MouseEvent) => {
        event?.stopPropagation()
        actions.handleInputSubmit(item)
    }

    function handleSwitchOeGroup() {
        actions.handleInputSubmit(item)
    }

    if (item.type === ECalcInputType.SwitchOeGroup) {
        return (
            <Tag
                onClick={handleSwitchOeGroup}
                label={item.description}
                value={item.label}
                layout="holo"
                skin={item.isDisabled ? "primary" : undefined}
            />
        )
    }

    if (icon === "replace") {
        return <ReplaceButton className="price" onClick={handleChange} isWm={false} disabled={item.isDisabled} />
    }

    return (
        <Button className={className} disabled={item.isDisabled} onClick={handleChange} skin={getButtonSkin(item.btnStyle)} icon={icon}>
            {item.label}
        </Button>
    )
}
