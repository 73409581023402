import { Component } from "react"
import { Icon } from "@tm/controls"
import { em, percent, margin, color } from "csx"
import { getStyleTheme, StyleProps, withStyle, WithWorkTaskProps, withWorkTask } from "@tm/context-distribution"
import { bindSpecialReactMethods } from "@tm/utils"
import { CarModelDetailsResponse, Vehicle, VehicleType } from "@tm/models"
import { VehicleImage } from "@tm/components"
import { MotivationGraphic, CalcInput, Milestone, SummaryContext } from "../../../data/model"
import { ECalcInputStyle } from "../../../data/enums"
import { connector, getLastModelDetails, saveVehicleToWorkTask } from "../../../data/helpers"
import { BorderedCollapsible, CalcInputComponent } from "../../_shared"
import { MainState } from "../../main"

type Props = WithWorkTaskProps &
    StyleProps<typeof stylesheet> & {
        motivationGraphic?: MotivationGraphic
        defineVehicleLabel: string
        defineVehicleDescription: string
        inputs: CalcInput[]
        vehicle: Vehicle | undefined
        carModel?: CarModelDetailsResponse
    }

class VehicleDefinitionComponent extends Component<Props> {
    constructor(props: Props) {
        super(props)
        bindSpecialReactMethods(this)
    }

    renderMilestone(milestone: Milestone, index: number) {
        const { style } = this.props
        return (
            <div className={style.milestone} key={index} style={{ left: percent(milestone.positionPercent) }}>
                <Icon skin={(milestone.reached && "primary") || undefined} size="l" name="raceflag" />
                {milestone.label}
            </div>
        )
    }

    onChangeCallBack = (data: string | Date | number | boolean) => {
        saveVehicleToWorkTask(data, this.props.workTask?.vehicle, this.props.attachToWorkTask)
    }

    render() {
        const { defineVehicleLabel, inputs, style, motivationGraphic, vehicle, carModel } = this.props
        return (
            <BorderedCollapsible name={defineVehicleLabel} initiallyOpened>
                <div className={style.wrapper}>
                    <div className={style.vehicleSlider}>
                        <VehicleImage
                            modelImage={vehicle?.modelThumbnail || ""}
                            modelSeriesImage={vehicle?.modelSeriesThumbnail || ""}
                            vehicleType={vehicle?.vehicleType || VehicleType.PassengerCar}
                            className={style.vehicle}
                        />

                        {motivationGraphic?.milestones.map(this.renderMilestone)}

                        <div className={style.defaultSlider}>
                            <div className={style.completedSlider} />
                        </div>
                    </div>

                    <div className={style.inputs}>
                        {inputs.map((input) => (
                            <CalcInputComponent
                                item={input}
                                key={input.id}
                                onChangeCallBack={this.onChangeCallBack}
                                showBorder={input.style != ECalcInputStyle.Slider}
                                modelDetails={carModel?.modelDetails}
                            />
                        ))}
                    </div>
                </div>
            </BorderedCollapsible>
        )
    }
}

function stylesheet(props: Props) {
    const { motivationGraphic } = props
    const theme = getStyleTheme()
    const { colors } = theme

    return {
        wrapper: {
            margin: "auto",
            maxWidth: em(60),
            display: "flex",
            flexDirection: "column",
        },
        vehicleSlider: {
            position: "relative",
            marginBottom: em(1),
            height: em(3),
        },
        defaultSlider: {
            backgroundColor: color(colors.warning).mix(colors.danger).toString(),
            height: em(0.4),
            marginTop: em(2.5),
        },
        completedSlider: {
            backgroundColor: colors.success,
            height: percent(100),
            transition: "width .3s ease",
            width: percent((motivationGraphic?.reachedPercent ?? 0) > 90 ? 100 : motivationGraphic?.reachedPercent || 0),
        },
        milestone: {
            position: "absolute",
            display: "flex",
            opacity: theme.opacity.disabled,
        },
        vehicle: {
            position: "absolute",
            left: percent(motivationGraphic?.reachedPercent ?? 0),
            top: em(1),
            height: em(1.5),
            $nest: {
                "&.error-image": {
                    top: em(-0.1),
                    $nest: {
                        svg: {
                            width: em(4),
                            height: em(4),
                        },
                    },
                },
            },
        },
        vehicleDescription: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: margin(em(1), 0),
        },
        inputs: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
    }
}

function mapStateToProps(
    store: MainState
): Pick<Props, "defineVehicleLabel" | "inputs" | "defineVehicleDescription" | "motivationGraphic" | "vehicle" | "carModel"> {
    const context = store.fastCalculator.selectedCalcState?.context as SummaryContext | undefined

    return {
        defineVehicleLabel: context?.defineVehicleLabel ?? "",
        defineVehicleDescription: context?.defineVehicleDescription ?? "",
        inputs: context?.inputs ?? [],
        motivationGraphic: context?.motivationGraphic,
        vehicle: store.fastCalculator.vehicle,
        carModel: store.fastCalculator.carModel ?? getLastModelDetails(),
    }
}

export default connector(withWorkTask(withStyle(stylesheet, VehicleDefinitionComponent)), mapStateToProps)
