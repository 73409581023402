import { History } from "history"
import { PayloadAction } from "@tm/morpheus"
import { Article, OE } from "@tm/models"
import { UserSettings } from "@tm/context-distribution"
import { BundleActions } from "../../../business"
import { MainActionType } from "../../main/business"

// eslint-disable-next-line @typescript-eslint/ban-types
export type ManagerState = {}

export function reduce(state = {}, action: MainActionType): ManagerState {
    switch (action.type) {
        default:
            return state
    }
}

export function transmit(action: PayloadAction<MainActionType>) {
    switch (action.type) {
        case "REPLACE_ARTICLE_CANCEL":
        case "REPLACE_ARTICLE_FINISH": {
            return action
        }

        default:
            break
    }
}

function replaceArticleFinish(replacePart: Article | OE.OePart, history: History<unknown>): MainActionType {
    return { type: "REPLACE_ARTICLE_FINISH", payload: { replacePart, history } }
}

function replaceArticleCancel(history: History<unknown>, languageId: string, userSettings: UserSettings | undefined): MainActionType {
    return { type: "REPLACE_ARTICLE_CANCEL", payload: { history, languageId, userSettings } }
}

export type IActions = typeof Actions

export const Actions = {
    ...BundleActions,
    replaceArticleFinish,
    replaceArticleCancel,
}
