import { useCallback, useEffect, useMemo, useState } from "react"
import { useStyle } from "@tm/context-distribution"
import { SelectionList, SelectionListItem, Tag, Text } from "@tm/controls"
import { LocalizationProps } from "@tm/localization"
import { SearchFilters } from "@tm/models"
import { TmaHelper } from "@tm/utils"
import { SearchFilter } from "../../../../data/model"

type SearchFilterProps = {
    selectedSearchFilters?: SearchFilters
    searchfilterResultCounts: SearchFilter[]
    onSelection: (selectedFilter: SearchFilters) => void
    onSearchFilterChanged?(selected: Array<SelectionListItem>): void
} & LocalizationProps

export const SearchFiltersComponent = (props: SearchFilterProps) => {
    const { translate, translateText } = props.localization
    const [activeSearchFilter, setActiveSearchFilter] = useState(props.selectedSearchFilters || SearchFilters.All)

    const handleSearchFilterChange = (selected: Array<SelectionListItem>) => {
        props.onSearchFilterChanged && props.onSearchFilterChanged(selected)
        let newFilter: SearchFilters = 0 as SearchFilters

        selected.forEach((x) => {
            const value = parseInt(x.query)
            newFilter |= value
        })

        // Set all search filters if all deselected or all selected
        // @ts-ignore
        if (newFilter === 0 || newFilter === 127 || newFilter === 1920) {
            newFilter = SearchFilters.All
        }

        setActiveSearchFilter(newFilter)

        props.onSelection && props.onSelection(newFilter)
    }

    const searchFilterItems = useMemo<SelectionListItem[]>(
        () => getSearchFilters(translateText, props.searchfilterResultCounts),
        [props.searchfilterResultCounts]
    )

    const isSearchFilterActive = useCallback(
        (searchFilter: SearchFilters) => {
            return (activeSearchFilter & searchFilter) === searchFilter
        },
        [activeSearchFilter]
    )

    useEffect(() => {
        handleTmaData(searchFilterItems, isSearchFilterActive)
    }, [searchFilterItems, isSearchFilterActive])

    return (
        <div className="parts__search-filters">
            <Text size="s">
                {translate(1014)}
                <Tag
                    className={style.tag}
                    layout="ghost"
                    onClick={() => {
                        if (activeSearchFilter != SearchFilters.All) {
                            setActiveSearchFilter(SearchFilters.All)
                            const { onSelection, onSearchFilterChanged } = props
                            onSelection && onSelection(SearchFilters.All)
                            onSearchFilterChanged && onSearchFilterChanged(searchFilterItems)
                        }
                    }}
                    value={translateText(12683)}
                />
                :
            </Text>
            <SelectionList
                size="m"
                groups={[{ priority: "high", items: searchFilterItems }]}
                selected={searchFilterItems.filter((x) => isSearchFilterActive(parseInt(x.query)))}
                onChange={handleSearchFilterChange}
                localization={props.localization}
            />
        </div>
    )
}

function getSearchFilters(translateText: (value: number) => string, searchfilterResultCounts: SearchFilter[]): Array<SelectionListItem> {
    const getFilterResultCount = (searchFilter: SearchFilters): number => {
        return searchfilterResultCounts.filter((x) => x.filterType & searchFilter).reduce((prev, cur) => prev + cur.count, 0)
    }

    const mapSearchFilter = (value: SearchFilters, label: string): SelectionListItem => {
        return {
            query: value.toString(),
            name: label,
            resultCount: getFilterResultCount(value),
        }
    }

    return [
        mapSearchFilter(SearchFilters.OeReference, translateText(185)),
        mapSearchFilter(SearchFilters.UtilityNo, translateText(392)),
        mapSearchFilter(SearchFilters.ReplacementNo, translateText(393)),
        mapSearchFilter(SearchFilters.SupplierArticleNo, translateText(92)),
        mapSearchFilter(SearchFilters.TradeReference, translateText(91)),
        mapSearchFilter(SearchFilters.EuropeanArticleNumber, translateText(387)),
    ]
}

async function handleTmaData(items: Array<SelectionListItem>, isSearchFilterActive: (searchFilter: SearchFilters) => boolean) {
    TmaHelper.ArticleListFiltered.ArticleListFiltered.Search.SetSearchFilters(
        items.map((item) => ({
            id: parseFloat(item.query),
            isSelected: isSearchFilterActive(parseFloat(item.query)),
            articleCount: item.resultCount || 0,
        }))
    )
}

const style = useStyle({
    tag: {
        fontWeight: "normal",
        margin: 0,
        verticalAlign: "baseline",
    },
})(SearchFiltersComponent)
