import { createSelector, createSelectorCreator, defaultMemoize } from "reselect"
import { equals } from "@tm/utils"
import { ECalcResult } from "../enums"
import { CalcResult, CalculationContext, SummaryContext } from "../model"
import { MainState } from "../../components/main"

export const activeCalcStateSelector = createSelector(
    (s: MainState) => s.fastCalculator.fastCalculatorData.activeCalcState,
    (x) => x
)

export const activeCalcStateSelectorWidget = createSelector(
    (s: MainState) => s.fastCalculator.selectedCalcState,
    (x) => x?.context
)

export const calcStates = createSelector(
    (s: MainState) => s.fastCalculator.selectedCalcState,
    (s: MainState) => s.fastCalculator.selectedDataCalcState,
    (s: MainState) => s.fastCalculator.selectedDialogCalcState,
    (selectedCalcState, selectedDataCalcState, selectedDialogCalcState) => ({
        selectedCalcState,
        selectedDataCalcState,
        selectedDialogCalcState,
    })
)

export const contextSelector = createSelector(
    (s: MainState) => s.fastCalculator.selectedCalcState?.context as CalculationContext | undefined,
    (context) => {
        const currencySign = context?.wkz ?? "EUR"
        const hasInvalidArticles = context?.articles?.genArts?.some(
            (x) => (!x.articles && !x.price.value) || x.articles?.some((y) => y.priceEditable && !y.price.value) || !x.quantity.value
        )
        const hasInvalidRepairTimes = context?.repairTimes?.repairTimes?.some((x) => x.quantity.isInvalid)
        const hasInvalidConsumabled = context?.consumables?.consumables.some((x) => !x.price.value || !x.quantity.value)
        return {
            currencySign,
            hasInvalidArticles,
            hasInvalidRepairTimes,
            hasInvalidConsumabled,
        }
    }
)

const getCalcResults = (calcResults: CalcResult[]) => {
    const articlePrice = calcResults?.find((x) => x.type == ECalcResult.Articles)?.totalPrices[0]
    const repairTimesPrice = calcResults?.find((x) => x.type == ECalcResult.RepairTimes)?.totalPrices[0]
    const totalPrice = calcResults?.find((x) => x.type == ECalcResult.Total)?.totalPrices[0]
    const totalPriceVat = calcResults?.find((x) => x.type == ECalcResult.Total)?.totalPrices[1]
    return { articlePrice, repairTimesPrice, totalPrice, totalPriceVat }
}

const createDeeperEqualSelector = createSelectorCreator(defaultMemoize, equals)

const calculationSelector = createSelector(
    (s: MainState) => s.fastCalculator.selectedCalcState,
    (calcState) => (calcState?.context as CalculationContext).results
)

export const rezSelector = createSelector(calculationSelector, (results) => getCalcResults(results))

export const deepResultSelector = createDeeperEqualSelector(rezSelector, ({ articlePrice, repairTimesPrice, totalPrice, totalPriceVat }) => ({
    articlePrice,
    repairTimesPrice,
    totalPrice,
    totalPriceVat,
}))

export const repairTimeSelector = (s: MainState) => (s.fastCalculator.selectedCalcState?.context as CalculationContext).repairTimes

export const summarySelector = (s: MainState) => s.fastCalculator.selectedCalcState?.context as SummaryContext

export const CalcStateButtonSelector = (s: MainState) => {
    return {
        costEstimationUrl: s.fastCalculator.costEstimationUrl,
        vehicle: s.fastCalculator.vehicle,
        initialRegistration: s.fastCalculator.initialRegistration,
        mileage: s.fastCalculator.mileage,
        engineCode: s.fastCalculator.engineCode,
        longLife: s.fastCalculator.longLife,
        selectedServices: s.fastCalculator.selectedServices,
        selectedCalcState: s.fastCalculator.selectedCalcState,
    }
}

export const ArticleItemSelector = (s: MainState) => {
    return {
        vehicle: s.fastCalculator.vehicle,
        additionalData: s.fastCalculator.additionalData,
    }
}

export const CarModelSelector = (s: MainState) => {
    return {
        carModel: s.fastCalculator.carModel,
    }
}
export const SelectedOverlayCalcStateSelector = (s: MainState) => {
    return {
        selectedOverlayCalcState: s.fastCalculator.selectedOverlayCalcState,
    }
}

export const SelectedCalcStateSelector = (s: MainState) => {
    return {
        selectedCalcState: s.fastCalculator.selectedCalcState,
    }
}

export const AlternativeCalcArticlesSelector = (s: MainState) => {
    return {
        alternativeCalcArticles: s.fastCalculator.alternativeCalcArticles,
    }
}

export const VehicleSelector = (s: MainState) => {
    return {
        vehicle: s.fastCalculator.vehicle,
    }
}

export const ModalSelectionSelector = (s: MainState) => {
    return {
        selectedOverlayCalcState: s.fastCalculator.selectedOverlayCalcState,
        loading: s.fastCalculator.loading,
    }
}

export const StatusSelector = (s: MainState) => {
    return {
        loading: s.fastCalculator.loading,
        error: s.fastCalculator.error,
        isQueued: s.fastCalculator.isQueued,
    }
}

export const CalculatorDataSelector = (s: MainState) => {
    return {
        fastCalculatorData: s.fastCalculator.fastCalculatorData,
    }
}
