import { Loader } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { channel } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { useEffect, useRef } from "react"
import { batch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { Box, Button, Icon, Image, Typography, useTheme } from "@tm/components"
import { toolkitPath } from "../../utils"
import { useContainer } from "../../data/repositories"
import { externSelector, MainActions, managerSelector } from "../main/business"
import { MatchParams } from "../../data/models"

export let asWegasWindow: Window | null = null

export default function ExternComponent() {
    const container = useContainer()
    const matchParams = useParams<MatchParams>()
    const { translateText } = useLocalization()
    const { url, getArticlesInProgress, getArticlesFailed } = useSelector(externSelector)
    const { taskId } = useSelector(managerSelector)
    const actions = useActions(MainActions, "getCalculationData", "setDataFromHistory", "reset", "changeStep")
    const contentRef = useRef<HTMLDivElement>(null)
    const theme = useTheme()

    useEffect(() => {
        if ((!url && !!matchParams.taskId) || (!asWegasWindow?.closed && taskId !== matchParams.taskId)) {
            asWegasWindow?.close()
            asWegasWindow = null
        }

        if ((!!url || !!matchParams.taskId) && (!asWegasWindow || asWegasWindow.closed)) {
            openTool(!url && !getArticlesInProgress && !!matchParams.taskId)
        }
    }, [])

    const openTool = async (openFromHistory?: boolean) => {
        const navigationDom = document?.querySelector(".sg-navigation")

        if (!contentRef?.current || !navigationDom) {
            return
        }

        let sgUrl = url

        if (openFromHistory) {
            sgUrl = await container
                .action("getUrlForPage")(matchParams.taskId!)
                .then(
                    (res) => {
                        res && actions.setDataFromHistory(matchParams.taskId!, res)
                        return res
                    },
                    () => {
                        channel("APP").publish("TOAST_MESSAGE/SHOW", { message: translateText(12644), closeDelay: 3000, skin: "warning" })

                        batch(() => {
                            actions.reset()
                            actions.changeStep("summary")
                        })

                        return undefined
                    }
                )
        }

        if (!sgUrl?.length) {
            return
        }

        const opts = `height=${contentRef.current.offsetHeight - navigationDom.clientHeight},width=${
            contentRef.current.offsetWidth
        },location=0,resizable=1,scrollbars=1,left=${window.screenLeft + 15},top=${window.screenTop + 260},status=yes`
        asWegasWindow = window.open(sgUrl, "_blank", opts)
    }

    const getArticles = () => {
        asWegasWindow?.close()
        asWegasWindow = null

        actions.getCalculationData()
    }

    return (
        <Box sx={{ flex: "1", width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }} ref={contentRef}>
            <Box sx={{ display: "flex", flex: "1", alignItems: "center", flexDirection: "column", justifyContent: "center" }}>
                <Box sx={{ display: "flex", verticalAlign: "middle", alignItems: "center", margin: "2em 0" }}>
                    <Typography className="summary-title" size="xl">
                        {translateText(12529)}
                    </Typography>
                    <Image
                        style={{ verticalAlign: "middle", flex: "1", width: "80px", marginLeft: theme.margin?.m }}
                        src={`${toolkitPath}/images/Saint-Gobain.png`}
                    />
                </Box>
                {!asWegasWindow?.closed && !getArticlesInProgress && <Loader type="connection" />}
                {getArticlesInProgress && <Loader />}
                {getArticlesFailed && !getArticlesInProgress && (
                    <Button style={{ backgroundColor: theme.colors?.primary }} onClick={() => getArticles()}>
                        <Icon name="synchronize" /> {translateText(401)}
                    </Button>
                )}
            </Box>
        </Box>
    )
}
