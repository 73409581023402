import { useEffect, useState } from "react"
import { Box, Icon, FormControlLabel, Typography, Stack, Select, MenuItem } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { AddressSelectionItem, Item, OrderOptions } from "@tm/models"
import { getTitleTypeTextId } from "@tm/utils"
import { getAddressLabels, getAddressSelectLabels } from "../../../../helpers"
import {
    IconWithMargin,
    RadioButtonSmallWithMargin,
    RadioGroupGrid,
    BoxWithColumnPaddingRight,
    BoxWithAlignItems,
    ButtonWithIcon,
    RowStack,
} from "./StyledComponents"
import { getBundleParams } from "../../../../utils"
import DeliveryAddressesInformationTextFields from "./DeliveryAddressesInformationTextFields"

const MAX_ITEMS_IN_RADIO_GROUP = 3

type Props = {
    disableFields: boolean
    options: OrderOptions
    onSetDeliveryAddress(
        address: AddressSelectionItem,
        selectedOptionsItem?: Item,
        updateErpInformation?: boolean,
        updateOrderOptions?: boolean,
        updateOrderOnChange?: boolean
    ): void
}

export default function DeliveryAddressesInformationComponent({ options, onSetDeliveryAddress, disableFields }: Props) {
    const { translate, translateText } = useLocalization()
    const { deliveryAddresses, selectedOptionsItem } = options
    const { hideOrderOptionsContactData } = getBundleParams()
    const [selectedDeliveryAddressId, setSelectedDeliveryAddressId] = useState<string>("")
    const [isSaving, setIsSaving] = useState(false)
    const [addressBeingEdited, setAddressBeingEdited] = useState<AddressSelectionItem | undefined>(undefined)
    const addressSelectLabels = getAddressSelectLabels(deliveryAddresses?.addresses)

    useEffect(() => {
        setIsSaving(false)
    }, [deliveryAddresses])

    useEffect(() => {
        if (deliveryAddresses?.addresses.length) {
            const defaultDeliveryAddress = deliveryAddresses.addresses.find((deliveryAddress) => deliveryAddress.isSelected)
            const deliveryAddressId = defaultDeliveryAddress ? defaultDeliveryAddress.id : deliveryAddresses.addresses[0].id
            setSelectedDeliveryAddressId(deliveryAddressId)
        }
    }, [deliveryAddresses])

    function handleDeliveryAddressChange(_: unknown, id: string) {
        if (deliveryAddresses?.addresses.length) {
            const firstDeliveryAddressMatch = deliveryAddresses.addresses.find((address) => address.id === id)
            setSelectedDeliveryAddressId(id)

            if (firstDeliveryAddressMatch) {
                setIsSaving(true)
                onSetDeliveryAddress(
                    firstDeliveryAddressMatch,
                    selectedOptionsItem,
                    deliveryAddresses.updateErpInformationOnChange,
                    deliveryAddresses.updateOrderOptionsOnChange,
                    deliveryAddresses.updateOrderOnChange
                )
            }
        }
    }

    function renderItemLabels(address: AddressSelectionItem) {
        let titleText = ""
        if (address.titleType) {
            const textId = getTitleTypeTextId(address.titleType)
            if (textId) {
                titleText = translateText(textId)
            }
        }

        const labels = getAddressLabels(address, false, titleText, hideOrderOptionsContactData)
        return (
            !!labels.length && (
                <Stack>
                    {labels.map((x) => {
                        return (
                            <Typography key={x} pl={4} variant="body2">
                                {x}
                            </Typography>
                        )
                    })}
                </Stack>
            )
        )
    }

    function renderEditButton(address: AddressSelectionItem) {
        return (
            <ButtonWithIcon
                disabled={isSaving}
                startIcon={<Icon name="edit_change" />}
                title={translateText(122)}
                onClick={() => setAddressBeingEdited(address)}
            />
        )
    }

    function renderItem(address: AddressSelectionItem) {
        return (
            <Box key={address.id} display="flex" alignItems="flex-start">
                <Stack spacing={0.5}>
                    <FormControlLabel
                        value={address.id}
                        control={<RadioButtonSmallWithMargin disabled={disableFields} checked={address.id === selectedDeliveryAddressId} />}
                        sx={{ whiteSpace: "pre" }}
                        label={address.description}
                    />
                    {renderItemLabels(address)}
                </Stack>
                {address.isSelected && address.isEditable && renderEditButton(address)}
            </Box>
        )
    }

    function renderDeliveryAdressSelection() {
        const selectedEditableAddress = deliveryAddresses?.addresses.filter((address) => address.isSelected && address.isEditable).first()
        return !!deliveryAddresses?.addresses?.length && deliveryAddresses?.addresses?.length <= MAX_ITEMS_IN_RADIO_GROUP ? (
            <Stack>
                <RadioGroupGrid onChange={handleDeliveryAddressChange} rowcap="8px" minwidth="420px">
                    {deliveryAddresses?.addresses.map((address) => {
                        return renderItem(address)
                    })}
                </RadioGroupGrid>
            </Stack>
        ) : (
            <RowStack paddingLeft="35px" alignItems="center">
                <Select
                    onChange={(e) => handleDeliveryAddressChange(e, e.target.value as string)}
                    value={selectedDeliveryAddressId}
                    disabled={isSaving}
                >
                    {!!addressSelectLabels?.length &&
                        addressSelectLabels?.map(([id, label]) => {
                            return (
                                <MenuItem value={id} key={id}>
                                    <Typography>{label}</Typography>
                                </MenuItem>
                            )
                        })}
                </Select>
                <Box>{selectedEditableAddress && renderEditButton(selectedEditableAddress)}</Box>
            </RowStack>
        )
    }

    return (
        <BoxWithColumnPaddingRight>
            <BoxWithAlignItems>
                <IconWithMargin name="user" />
                <Typography variant="h4">{translate(13221)}</Typography>
            </BoxWithAlignItems>
            {addressBeingEdited ? (
                <DeliveryAddressesInformationTextFields
                    addressToEdit={addressBeingEdited}
                    options={options}
                    onSetDeliveryAddress={onSetDeliveryAddress}
                    onCloseEditMode={() => setAddressBeingEdited(undefined)}
                />
            ) : (
                renderDeliveryAdressSelection()
            )}
        </BoxWithColumnPaddingRight>
    )
}
