import { YqVehicleDetails } from "../../../models"
import { MainSlice } from "./model"

export function setYQVehicle(state: MainSlice, vehicle: YqVehicleDetails): Partial<MainSlice> {
    return {
        vehicle: {
            ...state.vehicle,
            isLoading: false,
            error: undefined,
            data: {
                ...state.vehicle.data,
                yqVehicle: vehicle,
            },
        },
    }
}

export function setVin(state: MainSlice, newValue: string): Partial<MainSlice> {
    return {
        vehicle: {
            ...state.vehicle,
            data: {
                vin: newValue,
            },
        },
    }
}

export function setVehicleLoading(state: MainSlice, loading: boolean): Partial<MainSlice> {
    return {
        vehicle: {
            ...state.vehicle,
            isLoading: loading,
            error: undefined,
        },
    }
}

export function setVehicleError(state: MainSlice, errorMessage: number | string | undefined): Partial<MainSlice> {
    return {
        vehicle: {
            ...state.vehicle,
            isLoading: false,
            error: errorMessage,
        },
    }
}
