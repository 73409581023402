import { useMemo } from "react"
import { useUser } from "@tm/context-distribution"
import { UserModuleType } from "@tm/models"

export function useBasketModuleParameter() {
    const { userContext } = useUser()
    return useMemo(() => {
        const workTaskOrderModule = userContext.modules?.find((x) => x.type === UserModuleType.WorkTaskOrder)
        const addToBasketModule = userContext.modules?.find((x) => x.type === UserModuleType.AddToBasket)
        return {
            hasWorkTaskOrder: !!workTaskOrderModule,
            addToNextBasket: addToBasketModule?.parameters?.find((x) => x.key === "AddToNextBasket")?.value === "1",
            showBasketButton: addToBasketModule?.parameters?.find((x) => x.key === "ShowBasketButton")?.value === "1",
            showCostEstimationButton: addToBasketModule?.parameters?.find((x) => x.key === "ShowCostEstimationButton")?.value === "1",
            sendPostMessage: addToBasketModule?.parameters?.find((x) => x.key === "SendPostMessage")?.value === "1",
        }
    }, [userContext])
}
