import { OE, RequestArticleListPayload, Vehicle } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { equals } from "@tm/utils"
import { displayPartPosition } from "."
import { oePartRD, Part, PartsGroup } from "../models"

export function createArticleListRequest(part: Part, items: PartsGroup[], vehicle: Vehicle, callbackTranslate: (key: React.ReactText) => string): RequestArticleListPayload {
    const { showoePrice } = Morpheus.getParams<{ [key: string]: string }>("eurotax")
    
    const oePosition = selectedProductGroupParts(part, items)
        .map(val => val.map((x) => mapOePosition({
            ...x,
            ...{ isSelected: x.partId == part.partId }
            },
            vehicle,
            callbackTranslate,
            !Boolean(+showoePrice)
            )
        ))
        .reduce((acc, val) => acc.concat(val))
        .sort((a, b) => !!a.isSelected > !!b.isSelected ? -1 : 1)


    return {
        oePositions: oePosition,
        tecDocTypeId: vehicle.tecDocTypeId,
        inModal: true,
    }
}
function mapOePosition(part: Part, vehicle: Vehicle, callbackTranslate: (key: React.ReactText) => string, hideOePrice: boolean): OE.OePosition {
    const oePart = mapOePart(part, vehicle, hideOePrice)
    const replacements = part.replacements.map(x => mapReplacements(x, oePart))
    const partPosition = displayPartPosition(part.links, part.rechts, callbackTranslate)

    return {
        number: part.partNumber,
        description: partPosition,
        additionalDescription: part.materialDescription,
        parts: [oePart],
        replacements: replacements,
        isSelected: part.isSelected
    }
}

function mapOePart(part: Part, vehicle: Vehicle, hideOePrice: boolean): OE.OePart {
    const oePrice = mapOePartPrice(part)

    return {
        number: part.partNumber,
        description: part.mastercodeDescription,
        manufacturerId: vehicle.tecDocManufacturerId,
        manufacturerName: vehicle.manufacturer,
        prices: hideOePrice ? [] : [oePrice],
        attributes: part.attributes,
        fittingPosition: part.fittingPosition,
        oeArticleOrigin:  {
            module: OE.OeArticleModule.GraphicalPartsIdentification,
            provider: OE.OeArticleProvider.Eurotax,
        },
    }
}

function mapOePartPrice(part: Part): OE.OePartPrice {
    return {
        value: part.price,
        currency: part.currency
    }
}

function mapReplacements(number: string, oePart: OE.OePart): OE.OeNumber {
    return {
        number: number,
        parts: [{ ...oePart, ...{ number, attributes: undefined } }]
    }
}

export function mapOePartRD(item: Part, callbackTranslate: (key: React.ReactText) => string): oePartRD {
    const partPosition = displayPartPosition(item.links, item.rechts, callbackTranslate)

    return {
        oeNumber: item.partNumber,
        description: item.mastercodeDescription,
        additionalDescription: partPosition,
        oeReplacements: item.replacements,
        currency: item.currency,
        price: item.price,
        isSelected: !!item.isSelected
    }
}

export function selectedProductGroupParts(part: Part, items: PartsGroup[]) {
    return items.map(item => item.parts.find(x => equals(x, part)) && item.parts).filter(Boolean) as Part[][]
}