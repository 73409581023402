import { useMemo } from "react"
import { useSelector } from "react-redux"
import { getStyleTheme, useStyle, useWorkTask } from "@tm/context-distribution"
import { ECalcInputStyle, ECalcState } from "../../../data/enums"
import { bem, saveVehicleToWorkTask } from "../../../data/helpers"
import { CarModelSelector, activeCalcStateSelectorWidget } from "../../../data/helpers/selectors"
import { CalcInputComponent } from "../../_shared"
import { fastCalculatorSelector } from "../../main/business"
import SelectionDialog from "../../_shared/modals/SelectionDialog"

export default function SummaryPreview() {
    const { carModel } = useSelector(CarModelSelector)
    const widgetContext = useSelector(activeCalcStateSelectorWidget)
    const { selectedOverlayCalcState } = useSelector(fastCalculatorSelector)
    const worktask = useWorkTask()
    const style = useMemo(() => getStyle(), [])

    const interactionDialog = selectedOverlayCalcState && ECalcState.OverlayCalcStates & selectedOverlayCalcState?.type

    const onChangeCallBack = (data: string | Date | number | boolean) => {
        saveVehicleToWorkTask(data, worktask?.workTask?.vehicle, worktask?.attachToWorkTask)
    }

    return (
        <>
            {interactionDialog && <SelectionDialog calcState={selectedOverlayCalcState} />}
            <div className={style.inputs}>
                {widgetContext?.inputs?.map((input) => (
                    <CalcInputComponent
                        key={input.id}
                        item={input}
                        className={bem(style.input, input.style === ECalcInputStyle.Slider && "slider")}
                        modelDetails={carModel?.modelDetails}
                        onChangeCallBack={onChangeCallBack}
                        showBorder={input.style !== ECalcInputStyle.Slider}
                    />
                ))}
            </div>
        </>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        inputs: {
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
        },
        input: {
            flex: "1 0 30%",
            marginRight: theme.margin.m,
            $nest: {
                "&--slider": {
                    marginTop: theme.margin.m,
                },
            },
            minWidth: "7em",
            marginBottom: theme.margin.m,
        },
    })(SummaryPreview)
}
