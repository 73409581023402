import * as React from "react"
import { useDispatch } from "react-redux"
import { useStyle, useUser } from "@tm/context-distribution"
import { Table, SuggestionField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { CalcDropDownItem, CalcInput } from "../../../data/model"
import { getCalcStateLabel, getCurrencyFromUserContext } from "../../../data/helpers"
import CalcDropdownItem from "../calcDropdownItem"
import { MainActions } from "../../main/business"

type Props = {
    item: CalcInput
}

const CalcInputConsumableLabel: React.FC<Props> = ({ item }) => {
    const _searchFieldRef = React.useRef<SuggestionField<any>>(null)
    const submitSelection = false

    const { translateText } = useLocalization()
    const userContext = useUser()?.userContext
    const label = getCalcStateLabel(item.type, getCurrencyFromUserContext(userContext), translateText)
    const [text, setText] = React.useState<string>(item.value || item.label)
    const [currentValue, setCurrentValue] = React.useState<string>("")
    const dispatch = useDispatch()

    React.useEffect(() => {
        let { value } = item
        if (value != text) {
            if (value == "") {
                // hackish
                value = item.label
            }

            setText(value)
            setCurrentValue(value)
        }
    }, [item.value])

    const handleDropDownSelect = (dropdownItem: CalcDropDownItem) => {
        if (!dropdownItem.label) {
            // if it's save btn
            dispatch(MainActions.handleDropdownItemClick({ ...item, value: text }, { ...item.dropDownItems[0], value: text }))
            return
        }
        dispatch(MainActions.handleDropdownItemClick(item, dropdownItem))
    }

    const handleConfirm = (inputValue: string, forceSubmit?: boolean) => {
        if (typeof inputValue != "string" || submitSelection) {
            return
        }
        if ((text == inputValue && inputValue != "" && inputValue != currentValue) || forceSubmit) {
            setCurrentValue(inputValue)
            dispatch(MainActions.handleInputSubmit({ ...item, value: inputValue }))
        } else {
            setCurrentValue(inputValue)
        }
    }

    const renderTableCollumns = () => {
        return [
            <Table.Column
                renderItemContent={(data: CalcDropDownItem) => (
                    <Table.Cell className={style.flexBody}>
                        <CalcDropdownItem
                            item={data}
                            onDropdownSelect={() => {
                                handleDropDownSelect(data)
                            }}
                        />
                    </Table.Cell>
                )}
            />,
        ]
    }

    const handleOnClose = () => {
        if (!_searchFieldRef?.current?.state.open) {
            handleConfirm(text, text !== "" && text !== item.value)
        } // also hackish
    }

    const handleSuggestion = (dropdownItem: CalcDropDownItem) => {
        if (!dropdownItem.label) {
            return
        }
        handleDropDownSelect(dropdownItem)
    }

    return (
        <SuggestionField
            floatingLabel
            label={item.label || label}
            value={text}
            disabled={item.isDisabled}
            className={style.floatingLabel}
            onChange={(text) => setText(text)}
            renderTableColumns={renderTableCollumns}
            suggestions={item.dropDownItems?.sort((a, b) => ((a?.index ?? 99) > (b?.index ?? 99) ? -1 : 1)) || []} // higherindex should appear on top
            onChangeConfirm={handleConfirm}
            requestSuggestions={() => {}}
            minCharactersToSuggest={-1}
            maxHeight={250}
            showSearch={false}
            onSuggestionSelect={handleSuggestion}
            ref={_searchFieldRef}
            onClose={handleOnClose}
        />
    )
}

const style = useStyle({
    flexBody: {
        flex: 1,
    },
    floatingLabel: {
        $nest: {
            ".input__label": {
                transform: "translateY(-.5rem)",
                position: "absolute",
                pointerEvents: "none",
                transition: "all .2s ease",
            },
            ".input__field": {
                transform: "translate(.25rem, .5em)",
            },
        },
    },
})(CalcInputConsumableLabel)

export default CalcInputConsumableLabel
