import { getStoredAuthorization, ajax, clone } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { ClickButtonRequest, ClickButtonResponse } from "./model"
import { mapFastCalculatorResponse } from "../../helpers"

export function clickButton(request: ClickButtonRequest) {
    const url = `${getServiceUrl()}/ClickButton`
    const authorization = getStoredAuthorization()
    const body = clone(request)
    body.parentCalcState.context = undefined

    return new Promise<ClickButtonResponse>((resolve, reject) =>
        ajax({ url, body, authorization, method: "POST" }).then((data) => {
            const mappedData = mapFastCalculatorResponse(data)
            if (mappedData?.selectedCalcState?.context || mappedData?.isQueued) {
                resolve(mappedData)
            } else {
                reject()
            }
        }, reject)
    )
}

function getServiceUrl(): string {
    const bundleParams = getBundleParams()
    return bundleParams.fastCalculatorServiceUrl
}
